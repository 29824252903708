// @ts-nocheck
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Scalars = {
    Any: any,
    Boolean: boolean,
    Float: number,
    ID: string,
    Int: number,
    JSONW: any,
    Long: any,
    String: string,
    Time: any,
    Upload: any,
}

export interface Adapter {
    description: Scalars['String']
    endpoint: Scalars['String']
    id: Scalars['ID']
    settings: AdapterSetting[]
    type: AdapterType
    __typename: 'Adapter'
}

export interface AdapterSetting {
    IsSecure: Scalars['Boolean']
    id: Scalars['ID']
    key: Scalars['String']
    value: Scalars['String']
    __typename: 'AdapterSetting'
}

export type AdapterType = 'PMS' | 'SMART_LOCK' | 'THERMOSTAT'

export interface Address {
    addressType: (AddressType | null)
    city: Scalars['String']
    coordinate: (Coordinate | null)
    country: Scalars['String']
    entrance: (Scalars['String'] | null)
    googlePlaceID: (Scalars['String'] | null)
    id: Scalars['ID']
    isDefault: (Scalars['Boolean'] | null)
    state: (Scalars['String'] | null)
    streetName: (Scalars['String'] | null)
    streetNumber: (Scalars['String'] | null)
    zipcode: (Scalars['String'] | null)
    __typename: 'Address'
}

export type AddressType = 'BILLING_ADDRESS'

export type AdmissionFeeType = 'COMPLIMENTARY' | 'FREE' | 'NONE'

export type AllowUserTaskStatus = 'COMPLETED' | 'NOT_COMPLETED'

export interface AmenitiesList {
    items: (Amenity[] | null)
    pager: PageInfo
    __typename: 'AmenitiesList'
}

export type AmenitiesOrderBy = 'LOCATION_ASC' | 'LOCATION_DESC' | 'NAME_ASC' | 'NAME_DESC'

export interface Amenity {
    accessibleToBuildingIds: (Scalars['String'] | null)[]
    accessibleToBuildings: AmenityBuilding[]
    amenityCalendarSettings: (AmenityCalendarSettings | null)
    availableDates: (Scalars['Time'][] | null)
    bookingOptions: ((BookingOption | null)[] | null)
    cancellationPolicy: (Scalars['String'] | null)
    customAvailableDates: (Scalars['Time'][] | null)
    customBookingOptions: ((BookingOption | null)[] | null)
    customPaymentOptions: (PaymentOption[] | null)
    depositAmount: (Scalars['Float'] | null)
    description: Scalars['String']
    enabledPaymentMethods: PaymentMethod[]
    /** first available date for the amenity based on minReservationDaysInAdvance */
    firstAvailableDate: (Scalars['Time'] | null)
    id: Scalars['ID']
    /** A flag to indicate if the amenity is active or not, if not active, it will not be visible to the users */
    isActive: Scalars['Boolean']
    /** A flag to indicate if the amenity is reservable or not, if not reservable, users will not be able to make reservations for it */
    isReservable: Scalars['Boolean']
    lastAvailableDate: (Scalars['Time'] | null)
    location: Scalars['String']
    /** The building in which the amenity is located */
    locationBuilding: (Building | null)
    locationBuildingId: Scalars['ID']
    managersToNotify: (Scalars['ID'][] | null)
    media: AmenityMedia[]
    name: Scalars['String']
    pmsChargeCode: (Scalars['String'] | null)
    pmsDepositChargeCode: (Scalars['String'] | null)
    pmsDepositGlAccountNumber: (Scalars['String'] | null)
    pmsGlAccountNumber: (Scalars['String'] | null)
    requireAgreement: Scalars['Boolean']
    requireApproval: Scalars['Boolean']
    stripeAccountId: (Scalars['String'] | null)
    /** @deprecated No longer supported */
    stripeProductId: (Scalars['String'] | null)
    timezone: Scalars['String']
    __typename: 'Amenity'
}

export interface AmenityBuilding {
    Building: (Building | null)
    BuildingId: Scalars['ID']
    __typename: 'AmenityBuilding'
}

export interface AmenityCalendarSettings {
    amenity: (Amenity | null)
    amenityId: Scalars['ID']
    checkIn: (Scalars['String'] | null)
    checkOut: (Scalars['String'] | null)
    id: Scalars['ID']
    isActive: Scalars['Boolean']
    openingHours: AmenityOpeningHours[]
    reservationSettings: AmenityReservationSettings
    type: AmenityCalendarType
    __typename: 'AmenityCalendarSettings'
}

export type AmenityCalendarType = 'ALWAYS_OPEN' | 'CUSTOM' | 'DAILY' | 'OVER_NIGHT'

export interface AmenityCalendarUnavailability {
    amenityCalendar: AmenityCalendarSettings
    amenityCalendarId: Scalars['ID']
    createdAt: Scalars['Time']
    endDate: Scalars['Time']
    id: Scalars['ID']
    reason: (Scalars['String'] | null)
    startDate: Scalars['Time']
    __typename: 'AmenityCalendarUnavailability'
}

export type AmenityCalendarUnavailabilityOrderBy = 'END_DATE_ASC' | 'END_DATE_DESC' | 'START_DATE_ASC' | 'START_DATE_DESC'

export type AmenityDepositStatus = 'DEPOSIT_CHARGED' | 'DEPOSIT_PAID' | 'DEPOSIT_RELEASED' | 'PENDING_DEPOSIT'

export type AmenityDepositType = 'CARD' | 'CASH' | 'CHECK'

export interface AmenityMedia {
    id: Scalars['ID']
    type: AmenityMediaType
    url: Scalars['String']
    __typename: 'AmenityMedia'
}

export type AmenityMediaType = 'GIF' | 'IMAGE' | 'VIDEO'

export interface AmenityOpeningHours {
    closingTime: Scalars['String']
    dayOfWeek: (DayOfWeek | null)
    openingTime: Scalars['String']
    __typename: 'AmenityOpeningHours'
}

export interface AmenityReservation {
    FeeAmount: (Scalars['Float'] | null)
    agreementSignedAt: (Scalars['Time'] | null)
    amenity: Amenity
    amenityId: Scalars['ID']
    amountPaid: (Scalars['Float'] | null)
    /** @deprecated Use statusUpdateNote instead */
    cancellationNote: (Scalars['String'] | null)
    cancellationRequestNote: (Scalars['String'] | null)
    cancellationRequestStatus: (CancellationRequestStatus | null)
    createdAt: Scalars['Time']
    /** @deprecated Use statusUpdateNote instead */
    denialReason: (Scalars['String'] | null)
    depositAutoReleaseDate: (Scalars['Time'] | null)
    depositChargeDate: (Scalars['Time'] | null)
    depositChargeNote: (Scalars['String'] | null)
    depositChargedAmount: (Scalars['Float'] | null)
    depositPaymentLink: (Scalars['String'] | null)
    depositStatus: (AmenityDepositStatus | null)
    depositType: (AmenityDepositType | null)
    endDate: Scalars['Time']
    externalPaymentId: (Scalars['String'] | null)
    id: Scalars['ID']
    paidAt: (Scalars['Time'] | null)
    paymentLink: (Scalars['String'] | null)
    paymentMethod: (PaymentMethod | null)
    paymentStatus: (AmenityReservationPaymentStatus | null)
    pmsPaymentId: (Scalars['String'] | null)
    pmsRefundId: (Scalars['String'] | null)
    pmsReversalId: (Scalars['String'] | null)
    refundAmount: (Scalars['Float'] | null)
    refundMethod: (RefundMethod | null)
    refundedAt: (Scalars['Time'] | null)
    reservationId: Scalars['String']
    reservationType: AmenityReservationType
    startDate: Scalars['Time']
    status: (AmenityReservationStatus | null)
    statusUpdateNote: (Scalars['String'] | null)
    timeLine: (AmenityReservationTimeLine | null)
    unit: (Unit | null)
    unitId: (Scalars['ID'] | null)
    user: User
    userId: Scalars['ID']
    __typename: 'AmenityReservation'
}

export interface AmenityReservationOption {
    amenityCalendarId: Scalars['ID']
    durationInMinutes: Scalars['Int']
    id: Scalars['ID']
    label: (Scalars['String'] | null)
    paymentOptions: (PaymentOption[] | null)
    price: (Scalars['Float'] | null)
    /** @deprecated Use paymentOptions instead */
    priceByPaymentMethod: (PriceByPaymentMethod | null)
    productName: (Scalars['String'] | null)
    __typename: 'AmenityReservationOption'
}

export type AmenityReservationOrderBy = 'END_DATE_ASC' | 'END_DATE_DESC' | 'START_DATE_ASC' | 'START_DATE_DESC'

export type AmenityReservationPaymentStatus = 'FAILED' | 'PAID' | 'PENDING' | 'REFUNDED'

export interface AmenityReservationSettings {
    amenityCalendarId: Scalars['ID']
    /** A maximum number of days in advance that a reservation can be made */
    maxReservationDaysInAdvance: Scalars['Int']
    /** A minimum number of days in advance that a reservation can be made */
    minReservationDaysInAdvance: Scalars['Int']
    postReservationBufferMinutes: (Scalars['Int'] | null)
    /**
     * @deprecated Use reservationOptions instead
     * Array of possible durations in minutes for a reservation
     */
    reservationDurationOptions: Scalars['Int'][]
    reservationOptions: (AmenityReservationOption[] | null)
    /** A time period for which the reservation quota is applicable */
    reservationQuotaFrequency: (ReservationQuotaFrequency | null)
    /** A maximum number of reservations that can be made in a given time period */
    reservationQuotaLimit: (Scalars['Int'] | null)
    /** A type of reservation quota, either per user or per unit */
    reservationQuotaType: (ReservationQuotaType | null)
    /** A maximum number of users that can reserve a slot at a time */
    slotCapacity: Scalars['Int']
    slotDurationInMinutes: Scalars['Int']
    __typename: 'AmenityReservationSettings'
}

export type AmenityReservationStatus = 'APPROVED' | 'CANCELLED' | 'DENIED' | 'PENDING_APPROVAL' | 'PENDING_PAYMENT'

export type AmenityReservationTimeLine = 'CURRENT' | 'FUTURE' | 'PAST'

export type AmenityReservationType = 'FREE' | 'PAID'

export interface AppDashboardLink {
    appPageLinkDate: (AppPageLinkData | null)
    audienceType: AppDashboardLinkAudienceType
    building: (Building | null)
    buildingId: (Scalars['ID'] | null)
    community: (Community | null)
    communityId: (Scalars['ID'] | null)
    disabled: Scalars['Boolean']
    displayText: Scalars['String']
    emailLinkData: (EmailLinkData | null)
    externalAppLinkData: (ExternalAppLinkData | null)
    iconResourceKey: (Scalars['String'] | null)
    id: Scalars['ID']
    linkType: AppDashboardLinkType
    ordering: Scalars['Int']
    organization: (Organization | null)
    organizationId: Scalars['ID']
    phoneLinkData: (PhoneLinkData | null)
    section: (SectionType | null)
    smsLinkData: (SmsLinkData | null)
    webLinkData: (WebLinkData | null)
    __typename: 'AppDashboardLink'
}

export type AppDashboardLinkAudienceType = 'BUILDING' | 'COMMUNITY' | 'ORGANIZATION'

export type AppDashboardLinkType = 'APP_PAGE' | 'EMAIL' | 'EXTERNAL_APP' | 'PHONE' | 'SMS' | 'WEB'

export interface AppLinkType {
    android: (Scalars['String'] | null)
    ios: (Scalars['String'] | null)
    uri_prefix: (Scalars['String'] | null)
    __typename: 'AppLinkType'
}

export interface AppMoveInSettings {
    isVisibleToUser: (Scalars['Boolean'] | null)
    __typename: 'AppMoveInSettings'
}

export interface AppOnboardingScreen {
    bullets: (Scalars['String'][] | null)
    screenType: Scalars['String']
    __typename: 'AppOnboardingScreen'
}

export type AppOnboardingStatus = 'COMPLETE' | 'IN_COMPLETE'

export interface AppPageLinkData {
    pageName: Scalars['String']
    __typename: 'AppPageLinkData'
}

export type AppPageName = 'EVENTS' | 'GIVE_US_FEEDBACK' | 'GROUPS' | 'INBOX' | 'MEET_YOUR_NEIGHBORS' | 'MY_WALLET' | 'NOTIFICATIONS'

export interface AppReputationSettings {
    reputationLink: (Scalars['String'] | null)
    __typename: 'AppReputationSettings'
}

export interface AppSettings {
    amenitiesEnabled: Scalars['Boolean']
    awayNoticeSettings: AwayNoticeSettings
    /**
     *  Community Id is required for legacy settings
     * We get it from the hood_id_deprecated on user until we align the frontend to model v2
     */
    communityId: Scalars['String']
    dashboardLinks: AppDashboardLink[]
    embeddedApplications: ResidentEmbeddedApplications[]
    legacy: (LegacySettings | null)
    moveInSettings: AppMoveInSettings
    renewalSurveySettings: (RenewalSurveySettings | null)
    rentPaymentEnabled: Scalars['Boolean']
    reputation: AppReputationSettings
    serviceRequestsSettings: ServiceRequestsSettings
    termsAndConditionsStatus: TermsAndConditionsStatus
    thermostatsEnabled: Scalars['Boolean']
    /** @deprecated Use visitorsSettings instead */
    visitorsEnabled: Scalars['Boolean']
    visitorsSettings: VisitorsSettings
    __typename: 'AppSettings'
}

export interface Applicant {
    applicantSubmissionStep: ApplicantSubmissionStep
    applicationStatus: (ApplicationStatus | null)
    assetsReport: AssetReportResult
    birthDate: (Scalars['Time'] | null)
    buildingId: Scalars['ID']
    city: (Scalars['String'] | null)
    coApplicants: Applicant[]
    createdAt: Scalars['Time']
    creditReport: CreditReportResult
    criminalBackgroundCheck: CriminalBackgroundReportResult
    decidingPMUser: (User | null)
    decidingPMUserId: (Scalars['ID'] | null)
    documents: ApplicantDocuments
    email: Scalars['String']
    firstName: Scalars['String']
    id: Scalars['ID']
    identityVerification: IdentityVerificationResult
    incomeReport: IncomeReportResult
    lastName: Scalars['String']
    leaseRecommendationStatus: (LeaseRecommendationStatus | null)
    /** Data used to generate the lease, dynamic so it's untyped */
    leaseTerms: (ApplicantLeaseTerms | null)
    mainApplicantId: (Scalars['ID'] | null)
    middleName: (Scalars['String'] | null)
    organizationId: Scalars['ID']
    pets: PetDetails[]
    phone: (Scalars['String'] | null)
    relation: (Scalars['String'] | null)
    screeningStatus: ScreeningStatus
    state: (Scalars['String'] | null)
    street: (Scalars['String'] | null)
    summary: (ApplicantSummary | null)
    type: ApplicantType
    unit: Unit
    unitId: Scalars['ID']
    zip: (Scalars['String'] | null)
    __typename: 'Applicant'
}

export interface ApplicantCreateResponse {
    id: Scalars['ID']
    idvToken: Scalars['String']
    __typename: 'ApplicantCreateResponse'
}

export interface ApplicantDocument {
    availableUntil: Scalars['Time']
    lastModified: Scalars['Time']
    /** signed generated s3 url for the document */
    url: Scalars['String']
    __typename: 'ApplicantDocument'
}

export interface ApplicantDocuments {
    applicantConsentDocument: (ApplicantDocument | null)
    assetsReportDocument: (ApplicantDocument | null)
    creditScoreReportDocument: (ApplicantDocument | null)
    identityVerificationImagesDocument: ApplicantDocument[]
    incomeReportDocument: (ApplicantDocument | null)
    __typename: 'ApplicantDocuments'
}


/** Lease manager input to generate the lease */
export interface ApplicantLeaseTerms {
    amenities: (LeaseAmenity | null)
    /** @deprecated Use concessions instead */
    concessionOf: (Scalars['Float'] | null)
    concessions: ((Concession | null)[] | null)
    leaseEndDate: (Scalars['Time'] | null)
    leaseStartDate: (Scalars['Time'] | null)
    monthlyRent: (Scalars['Float'] | null)
    /** @deprecated Use concessions instead */
    monthsOfConcession: (Scalars['Int'][] | null)
    pool: (LeaseAmenity | null)
    securityDeposit: (Scalars['Float'] | null)
    thirdPartyGuarantor: (Scalars['Boolean'] | null)
    __typename: 'ApplicantLeaseTerms'
}

export type ApplicantOrderBy = 'CREATED_AT_ASC' | 'CREATED_AT_DESC'

export interface ApplicantPublicData {
    applicantSubmissionStep: ApplicantSubmissionStep
    coApplicants: (CoApplicantPublicData[] | null)
    email: Scalars['String']
    firstName: Scalars['String']
    id: Scalars['ID']
    lastName: Scalars['String']
    mainApplicant: (MainApplicantPublicData | null)
    middleName: (Scalars['String'] | null)
    plaidIdvToken: (Scalars['String'] | null)
    unit: UnitPublicData
    unitId: Scalars['ID']
    __typename: 'ApplicantPublicData'
}

export type ApplicantSubmissionStep = 'ASSET' | 'COMPLETED' | 'CONSENT' | 'IDENTITY_VERIFICATION' | 'INCOME' | 'INVITE_CO_APPLICANT' | 'PAYMENT' | 'START'

export interface ApplicantSummary {
    assets: (AssetReportSummary | null)
    credit: (CreditReportSummary | null)
    criminal: (CriminalBackgroundReportSummary | null)
    identity: (IdentityVerificationSummary | null)
    income: (IncomeReportSummary | null)
    __typename: 'ApplicantSummary'
}

export type ApplicantType = 'CO_OCCUPANT' | 'CO_SIGNER' | 'GUARANTOR' | 'MAIN' | 'ROOMMATE'

export interface ApplicantUpdateResponse {
    id: Scalars['ID']
    idvToken: Scalars['String']
    __typename: 'ApplicantUpdateResponse'
}

export interface ApplicantsInviteResponse {
    /** list of emails that failed to be invited */
    failed: Scalars['String'][]
    /** list of emails that succeeded to be invited */
    succeeded: Scalars['String'][]
    __typename: 'ApplicantsInviteResponse'
}

export type ApplicationPage = 'EVENT' | 'EVENTS_PAGE' | 'HOME_UPDATE' | 'INVOICES' | 'MOVE_IN_CHECKLIST' | 'MY_TICKETS' | 'PLACE' | 'PROFILE' | 'SERVICE' | 'USER'

export interface ApplicationSettings {
    assetsThreshold: Scalars['Float']
    buildingId: Scalars['ID']
    consentHTMLTemplate: (Scalars['String'] | null)
    creditScoreThreshold: Scalars['Int']
    criminalRecordsThreshold: Scalars['Int']
    id: Scalars['ID']
    incomeThresholdNumberOfRentMonths: Scalars['Int']
    isIdentityVerificationRequired: Scalars['Boolean']
    organizationId: Scalars['ID']
    plaidIDVTemplateId: (Scalars['String'] | null)
    __typename: 'ApplicationSettings'
}

export type ApplicationStatus = 'AWAITING_COUNTERSIGN' | 'AWAITING_REVIEW' | 'LEASE_SENT' | 'LEASE_SIGNED' | 'REJECTED' | 'STARTED'

export interface AssetReportResult {
    details: (Scalars['String'] | null)
    status: ScreeningCheckStatus
    totalAssets: (Scalars['Int'] | null)
    __typename: 'AssetReportResult'
}

export interface AssetReportSummary {
    assetsSum: Scalars['Int']
    minAssetsThreshold: Scalars['Int']
    status: SummaryStatus
    __typename: 'AssetReportSummary'
}

export type AttachmentsBehavior = 'NOT_AVAILABLE' | 'OPTIONAL' | 'REQUIRED'

export type AttachmentsCategory = 'INSPECTION' | 'MOVE_IN' | 'SERVICE_REQUEST'

export interface AttachmentsUploadUrlResponse {
    URL: Scalars['String']
    key: Scalars['String']
    __typename: 'AttachmentsUploadUrlResponse'
}

export type AttendanceStatus = 'CANCELLED' | 'CHECKED_IN' | 'CHECKED_IN_WO_RSVP' | 'GOING'

export interface AuthChallenge {
    authSession: Scalars['String']
    type: authChallengeType
    __typename: 'AuthChallenge'
}

export interface AuthChallengeVerifyResponse {
    token: AuthToken
    __typename: 'AuthChallengeVerifyResponse'
}

export interface AuthToken {
    accessToken: Scalars['String']
    refreshToken: Scalars['String']
    __typename: 'AuthToken'
}

export interface AuthTokenRefreshResponse {
    token: AuthToken
    __typename: 'AuthTokenRefreshResponse'
}

export type AuthenticationMethod = 'INIT_TOKEN' | 'OAUTH'

export interface AutomationActionDescriptor {
    actionParams: ((AutomationDescriptorParam | null)[] | null)
    displayName: Scalars['String']
    id: Scalars['ID']
    __typename: 'AutomationActionDescriptor'
}

export interface AutomationDescriptor {
    availableActions: AutomationActionDescriptor[]
    displayName: Scalars['String']
    id: Scalars['ID']
    predicateParams: AutomationDescriptorParam[]
    ruleType: RuleType
    supportedScopes: AutomationRuleScope[]
    __typename: 'AutomationDescriptor'
}

export interface AutomationDescriptorParam {
    availableValues: (AutomationDescriptorParamOption[] | null)
    displayName: Scalars['String']
    id: Scalars['ID']
    isMandatory: Scalars['Boolean']
    maxValue: (Scalars['Int'] | null)
    minValue: (Scalars['Int'] | null)
    type: AutomationDescriptorParamType
    uiOrder: Scalars['Int']
    __typename: 'AutomationDescriptorParam'
}

export interface AutomationDescriptorParamOption {
    key: Scalars['String']
    value: Scalars['String']
    __typename: 'AutomationDescriptorParamOption'
}

export type AutomationDescriptorParamType = 'DATE' | 'INT' | 'MULTI_SELECT' | 'SINGLE_SELECT' | 'STRING'

export interface AutomationRule {
    action: AutomationRuleAction
    automationDescriptorId: Scalars['ID']
    buildingIds: (Scalars['String'][] | null)
    communityIds: (Scalars['String'][] | null)
    createdAt: Scalars['Time']
    createdByUser: (User | null)
    createdByUserId: (Scalars['ID'] | null)
    description: (Scalars['String'] | null)
    id: Scalars['ID']
    isActive: Scalars['Boolean']
    name: Scalars['String']
    organizationId: Scalars['String']
    predicateParamValues: AutomationRuleParamValue[]
    scope: AutomationRuleScope
    stats: AutomationRuleStats
    __typename: 'AutomationRule'
}

export interface AutomationRuleAction {
    actionDescriptorId: Scalars['ID']
    actionParams: (AutomationRuleParamValue[] | null)
    __typename: 'AutomationRuleAction'
}

export interface AutomationRuleParamValue {
    paramDescriptorId: Scalars['ID']
    value: Scalars['Any']
    __typename: 'AutomationRuleParamValue'
}

export type AutomationRuleScope = 'BUILDINGS' | 'COMMUNITIES' | 'ORGANIZATION'

export interface AutomationRuleStats {
    lastWeek: Scalars['Int']
    total: Scalars['Int']
    __typename: 'AutomationRuleStats'
}

export interface AutomationTriggerHistory {
    actionData: Scalars['String']
    createdAt: Scalars['Time']
    eventData: Scalars['String']
    id: Scalars['ID']
    ruleId: Scalars['ID']
    status: Scalars['String']
    __typename: 'AutomationTriggerHistory'
}

export interface AwayNotice {
    awayNoticeServiceReservations: AwayNoticeServiceReservation[]
    createdAt: Scalars['Time']
    endDate: Scalars['Time']
    id: Scalars['ID']
    leaseContract: LeaseContract
    leaseContractId: Scalars['ID']
    notes: (Scalars['String'] | null)
    organizationId: Scalars['ID']
    startDate: Scalars['Time']
    status: AwayNoticeStatus
    timeline: Timeline
    __typename: 'AwayNotice'
}

export type AwayNoticeOrderBy = 'START_DATE_ASC' | 'START_DATE_DESC'

export interface AwayNoticeServiceReservation {
    awayNoticeServiceType: Scalars['String']
    notes: (Scalars['String'] | null)
    __typename: 'AwayNoticeServiceReservation'
}

export interface AwayNoticeSettings {
    awayNoticeServiceTypes: (Scalars['String'][] | null)
    buildingId: Scalars['ID']
    id: (Scalars['ID'] | null)
    isEnabled: Scalars['Boolean']
    __typename: 'AwayNoticeSettings'
}

export type AwayNoticeStatus = 'APPROVED' | 'PENDING'

export interface Bedroom {
    askedRent: (Scalars['Float'] | null)
    id: Scalars['ID']
    leaseContracts: ((LeaseContract | null)[] | null)
    name: Scalars['String']
    realEstateListing: (RealEstateListing | null)
    squareMeters: (Scalars['Int'] | null)
    unit: Unit
    __typename: 'Bedroom'
}

export interface BookingOption {
    endTime: Scalars['Time']
    startTime: Scalars['Time']
    __typename: 'BookingOption'
}

export interface Brand {
    buildingsIds: (Scalars['ID'][] | null)
    colors: BrandColors
    emailOTPLogo: (Scalars['String'] | null)
    id: Scalars['ID']
    landLordLogo: (Scalars['String'] | null)
    organizationId: Scalars['String']
    title: Scalars['String']
    __typename: 'Brand'
}

export interface BrandColors {
    primary: Scalars['String']
    primaryDark: Scalars['String']
    __typename: 'BrandColors'
}

export interface Broadcast {
    attachments: (BroadcastAttachment[] | null)
    audiences: BroadcastAudience[]
    /** @deprecated Will be removed in the future */
    category: (BroadcastCategory | null)
    content: (BroadcastContent | null)
    createdAt: Scalars['Time']
    createdBy: User
    createdByUserId: Scalars['ID']
    dateSent: (Scalars['Time'] | null)
    emailPayload: (BroadcastEmailPayload | null)
    id: Scalars['ID']
    /** @deprecated Will be replaced with mediums: [BroadcastMedium!] */
    medium: (NotificationMedium | null)
    mediums: (BroadcastMedium[] | null)
    notifications: (NotificationsList | null)
    scheduledBroadcast: (ScheduledBroadcast | null)
    sendStatus: BroadcastSendStatus
    stats: BroadcastStatParam[]
    updatedBy: (User | null)
    updatedByUserId: (Scalars['ID'] | null)
    __typename: 'Broadcast'
}

export interface BroadcastAttachment {
    id: Scalars['ID']
    url: Scalars['String']
    __typename: 'BroadcastAttachment'
}

export type BroadcastAttachmentType = 'IMAGE'

export interface BroadcastAudience {
    audienceParams: BroadcastAudienceParams
    type: BroadcastAudienceType
    __typename: 'BroadcastAudience'
}

export type BroadcastAudienceParams = (BuildingAudienceParams | CommunityAudienceParams | EventAudienceParams | InterestGroupsAudienceParams | SpecificUsersAudienceParams) & { __isUnion?: true }

export type BroadcastAudienceType = 'BUILDING' | 'COMMUNITY' | 'EVENT_TYPE' | 'INTEREST_GROUP' | 'SPECIFIC_MEMBERS'

export type BroadcastCategory = 'BILLING_AND_PAYMENTS' | 'EVENTS' | 'GROUPS' | 'MAINTENANCE' | 'MOVE_IN' | 'PAYMENT_REMINDERS' | 'SPECIAL_OFFERS'

export interface BroadcastContent {
    cta: (CtaData | null)
    deepLink: (DeepLink | null)
    message: Scalars['String']
    title: Scalars['String']
    __typename: 'BroadcastContent'
}

export interface BroadcastEmailPayload {
    fromEmail: Scalars['String']
    fromName: Scalars['String']
    __typename: 'BroadcastEmailPayload'
}

export type BroadcastMedium = 'EMAIL' | 'MOBILE_APP'

export type BroadcastSendStatus = 'DRAFT' | 'FAILED' | 'IN_PROCESS' | 'NOTIFICATIONS_CREATED' | 'TEMPLATE'

export type BroadcastSourceType = 'PROPERTY_MANAGER' | 'SEND_BROADCAST'

export type BroadcastStatName = 'AUDIENCE_WITHOUT_APP' | 'BOUNCED' | 'CLICKED_ON_CTA' | 'DELIVERABLE_AUDIENCE' | 'DELIVERED' | 'OPENED' | 'SENT' | 'TOTAL_AUDIENCE'

export interface BroadcastStatParam {
    count: Scalars['Int']
    rate: (Scalars['String'] | null)
    status: BroadcastStatName
    __typename: 'BroadcastStatParam'
}

export interface BroadcastsList {
    items: Broadcast[]
    pageInfo: PageInfo
    __typename: 'BroadcastsList'
}

export type BroadcastsOrderBy = 'AUDIENCE_ASC' | 'AUDIENCE_DESC' | 'CATEGORY_ASC' | 'CATEGORY_DESC' | 'CREATED_AT_ASC' | 'CREATED_AT_DESC' | 'DATE_SENT_ASC' | 'DATE_SENT_DESC' | 'STATUS_ASC' | 'STATUS_DESC' | 'TITLE_ASC' | 'TITLE_DESC' | 'UPDATED_AT_ASC' | 'UPDATED_AT_DESC'

export interface Building {
    address: (Address | null)
    buildingNumber: (Scalars['Int'] | null)
    /** @deprecated Has only 1 community, use community field instead */
    communities: (Community[] | null)
    community: (Community | null)
    communityId: (Scalars['ID'] | null)
    /** The external ID of the building coming from the PMS */
    externalId: (Scalars['ID'] | null)
    id: Scalars['ID']
    legalName: (Scalars['String'] | null)
    name: (Scalars['String'] | null)
    paymentReminders: (PaymentReminders | null)
    pmsAdapterId: (Scalars['ID'] | null)
    settings: (BuildingSettings | null)
    /**
     * Status indicates if the units in the building are enabled or disabled
     * status will be enabled if ALL units in building are enabled otherwise it will be disabled
     */
    status: BuildingStatus
    streetName: Scalars['String']
    units: Unit[]
    __typename: 'Building'
}

export interface BuildingAudienceParams {
    buildingIds: (Scalars['ID'][] | null)
    __typename: 'BuildingAudienceParams'
}

export type BuildingMode = 'COOL' | 'HEAT'

export interface BuildingSettings {
    amenityReservationTemplateName: (Scalars['String'] | null)
    buildingId: Scalars['String']
    calcContractStatusByDate: Scalars['Boolean']
    isLocksEnabled: Scalars['Boolean']
    isPackagesEnabled: Scalars['Boolean']
    isRentPaymentMobileEnabled: Scalars['Boolean']
    isRentPaymentSupported: Scalars['Boolean']
    isShiftNotesEnabled: Scalars['Boolean']
    isVisitorsEnabled: Scalars['Boolean']
    paymentPolicyFAQLink: (Scalars['String'] | null)
    paymentPortalLink: (Scalars['String'] | null)
    thermostatAdapterId: (Scalars['String'] | null)
    __typename: 'BuildingSettings'
}

export type BuildingStatus = 'DISABLED' | 'ENABLED'

export interface Byline {
    count: (Scalars['Int'] | null)
    type: (BylineType | null)
    value: (Scalars['String'] | null)
    __typename: 'Byline'
}

export type BylineType = 'COMMENT' | 'DISCUSSION' | 'EVENT_CHECKED_IN' | 'GOOD_VIBES' | 'HAVE_PET_BOTH' | 'LIKE' | 'NEW_NEIGHBOR' | 'NEW_NEIGHBOR_BOTH' | 'SAME_BUILDING' | 'SAME_GROUP' | 'SAME_INDUSTRY_OCCUPATION' | 'SAME_MOVE_IN_DAY' | 'VETERAN_NEIGHBOR' | 'VETERAN_NEIGHBOR_BOTH'

export interface Calendar {
    id: Scalars['ID']
    intervalDuration: Scalars['Int']
    __typename: 'Calendar'
}

export type CancellationRequestStatus = 'APPROVED' | 'DENIED' | 'PENDING'

export type CheckInStatus = 'CHECKED_IN' | 'CHECKED_OUT'

export interface CoApplicantPublicData {
    email: Scalars['String']
    firstName: Scalars['String']
    id: Scalars['ID']
    lastName: Scalars['String']
    middleName: (Scalars['String'] | null)
    type: ApplicantType
    __typename: 'CoApplicantPublicData'
}


/** The Community entity */
export interface Community {
    buildings: Building[]
    coordinates: (Coordinate | null)
    country: CountryCode
    displayName: Scalars['String']
    id: Scalars['ID']
    language: Language
    name: Scalars['String']
    organization: (Organization | null)
    organizationId: Scalars['ID']
    settings: (CommunitySettings | null)
    state: (Scalars['String'] | null)
    timezone: Scalars['String']
    uiMetadata: (CommunityUiMetadata | null)
    __typename: 'Community'
}

export interface CommunityAudienceParams {
    communityIds: Scalars['ID'][]
    __typename: 'CommunityAudienceParams'
}

export interface CommunityBuildings {
    buildingsIds: Scalars['ID'][]
    communityId: Scalars['ID']
    __typename: 'CommunityBuildings'
}

export interface CommunitySettings {
    communityId: Scalars['ID']
    moderationSlackChannel: (Scalars['String'] | null)
    socialProfileSuspendDurationInMinutes: Scalars['Int']
    __typename: 'CommunitySettings'
}

export interface CommunityUiMetadata {
    appOnboardingScreens: (AppOnboardingScreen[] | null)
    collateralUrl: (Scalars['String'] | null)
    communityLogoUrl: (Scalars['String'] | null)
    dashboardLogoUrl: (Scalars['String'] | null)
    hideVennLogo: (Scalars['Boolean'] | null)
    __typename: 'CommunityUiMetadata'
}

export interface Concession {
    amount: Scalars['Float']
    month: Month
    year: Scalars['String']
    __typename: 'Concession'
}

export type ContractStatus = 'ACTIVE' | 'APPLICANT' | 'CANCELLED' | 'EVICTED' | 'FUTURE' | 'INACTIVE'

export type ContractTimeline = 'CURRENT' | 'FUTURE' | 'PAST'


/** Coordinates */
export interface Coordinate {
    latitude: (Scalars['Float'] | null)
    latitudeDelta: (Scalars['Float'] | null)
    longitude: (Scalars['Float'] | null)
    longitudeDelta: (Scalars['Float'] | null)
    __typename: 'Coordinate'
}


/** Available country codes */
export type CountryCode = 'CA' | 'DE' | 'IL' | 'US'

export interface CreditReportResult {
    creditScore: (Scalars['Int'] | null)
    details: (Scalars['String'] | null)
    status: ScreeningCheckStatus
    __typename: 'CreditReportResult'
}

export interface CreditReportSummary {
    averageCreditScore: Scalars['Int']
    minCreditThreshold: Scalars['Int']
    status: SummaryStatus
    __typename: 'CreditReportSummary'
}

export interface CriminalBackgroundReportResult {
    details: (Scalars['String'] | null)
    numberOfOffenses: (Scalars['Int'] | null)
    status: ScreeningCheckStatus
    __typename: 'CriminalBackgroundReportResult'
}

export interface CriminalBackgroundReportSummary {
    status: SummaryStatus
    sumOfCriminalRecords: Scalars['Int']
    __typename: 'CriminalBackgroundReportSummary'
}

export interface CtaData {
    applicationPage: ApplicationPage
    ctaText: (Scalars['String'] | null)
    description: (Scalars['String'] | null)
    entityId: (Scalars['ID'] | null)
    params: (Scalars['String'] | null)
    __typename: 'CtaData'
}

export type DayOfWeek = 'FRIDAY' | 'MONDAY' | 'SATURDAY' | 'SUNDAY' | 'THURSDAY' | 'TUESDAY' | 'WEDNESDAY'


/** Deep Link can either be a list of entities, or a page of a specific entity */
export interface DeepLink {
    applicationPage: ApplicationPage
    description: (Scalars['String'] | null)
    entityId: (Scalars['ID'] | null)
    params: (Scalars['String'] | null)
    __typename: 'DeepLink'
}

export interface DeliveryPackage {
    additionalInfo: (Scalars['String'] | null)
    arrivedOnDate: (Scalars['Time'] | null)
    building: (Building | null)
    buildingId: (Scalars['String'] | null)
    courier: (Scalars['String'] | null)
    id: Scalars['ID']
    lastReminderDate: (Scalars['Time'] | null)
    packageNumber: (Scalars['String'] | null)
    pickUpDate: (Scalars['Time'] | null)
    pickUpLocation: (Scalars['String'] | null)
    status: (DeliveryPackageStatus | null)
    unit: (Unit | null)
    unitId: (Scalars['String'] | null)
    user: (User | null)
    userId: (Scalars['String'] | null)
    __typename: 'DeliveryPackage'
}

export type DeliveryPackageOrderBy = 'ARRIVED_ON_DATE_AT_ASC' | 'ARRIVED_ON_DATE_AT_DESC'

export type DeliveryPackageStatus = 'ARRIVED' | 'PICKED_UP' | 'RETURNED'

export interface DeliveryPackagesList {
    items: DeliveryPackage[]
    __typename: 'DeliveryPackagesList'
}

export type DeviceMode = 'AUTO' | 'COOL' | 'HEAT' | 'OFF'

export type DevicePlatform = 'ANDROID' | 'IOS'


/**
 *  The discussion entity
 * (known as social moment in the old API)
 */
export interface Discussion {
    audienceBuildings: (Building[] | null)
    authorUser: User
    commentsCount: (Scalars['Int'] | null)
    id: Scalars['ID']
    image: (Scalars['String'] | null)
    imageAspectRatio: (Scalars['Float'] | null)
    interactingSocialProfiles: (SocialProfile[] | null)
    isLikedByCurrentUser: Scalars['Boolean']
    latestActivity: (Scalars['Time'] | null)
    likesCount: (Scalars['Int'] | null)
    locationLabel: (Scalars['String'] | null)
    publishedAt: (Scalars['Time'] | null)
    status: DiscussionPublishStatus
    survey: (Survey | null)
    text: (Scalars['String'] | null)
    type: DiscussionType
    updatedAt: Scalars['Time']
    wasViewedByCurrentUser: (Scalars['Boolean'] | null)
    __typename: 'Discussion'
}


/** Comments on discussions */
export interface DiscussionComment {
    authorSocialProfile: SocialProfile
    createdAt: Scalars['Time']
    id: Scalars['ID']
    message: Scalars['String']
    __typename: 'DiscussionComment'
}


/** Comments with paging */
export interface DiscussionCommentsList {
    items: DiscussionComment[]
    pageInfo: PageInfo
    __typename: 'DiscussionCommentsList'
}


/** Options to sort the comments list */
export type DiscussionCommentsOrderBy = 'CREATED_AT_ASC' | 'CREATED_AT_DESC'


/** Available statuses for discussions */
export type DiscussionPublishStatus = 'PENDING' | 'PUBLISHED' | 'REJECTED' | 'UNPUBLISHED'


/** Available types for discussions */
export type DiscussionType = 'DISCUSSION' | 'POLL'


/** Discussions with paging */
export interface DiscussionsList {
    items: Discussion[]
    pageInfo: PageInfo
    __typename: 'DiscussionsList'
}


/** Options to sort the discussions list */
export type DiscussionsOrderBy = 'PUBLISHED_AT_ASC' | 'PUBLISHED_AT_DESC'

export interface EmailDomain {
    allowDomainUsersAsSenders: (Scalars['Boolean'] | null)
    createdAt: Scalars['Time']
    dkimHost: (Scalars['String'] | null)
    dkimValue: (Scalars['String'] | null)
    domain: Scalars['String']
    externalId: Scalars['ID']
    id: Scalars['ID']
    organizationId: Scalars['ID']
    returnPath: (Scalars['String'] | null)
    returnPathCname: (Scalars['String'] | null)
    verifcationStatus: (Scalars['String'] | null)
    __typename: 'EmailDomain'
}

export interface EmailDomainSender {
    allowedCommunities: ((Community | null)[] | null)
    allowedCommunityIds: (Scalars['ID'][] | null)
    allowedUserIds: (Scalars['ID'][] | null)
    allowedUsers: ((User | null)[] | null)
    createdAt: Scalars['Time']
    domainId: Scalars['ID']
    fromEmail: Scalars['String']
    fromName: Scalars['String']
    id: Scalars['ID']
    isSystemDefault: Scalars['Boolean']
    organizationId: Scalars['ID']
    __typename: 'EmailDomainSender'
}

export interface EmailLinkData {
    emailAddress: Scalars['String']
    subject: (Scalars['String'] | null)
    __typename: 'EmailLinkData'
}

export interface EmailSenderDomain {
    domain: Scalars['String']
    isVerified: Scalars['Boolean']
    __typename: 'EmailSenderDomain'
}

export interface EmailSenderForUser {
    fromEmail: Scalars['String']
    fromName: Scalars['String']
    __typename: 'EmailSenderForUser'
}

export interface EmailSettings {
    defaultSystemEmail: (Scalars['ID'] | null)
    domains: EmailSenderDomain[]
    endpoint: Scalars['String']
    fromEmails: Scalars['String'][]
    id: Scalars['ID']
    organizationId: Scalars['ID']
    __typename: 'EmailSettings'
}

export interface EmbeddedApplicationAdapter {
    buildingIds: (Scalars['ID'][] | null)
    endpoint: Scalars['String']
    id: Scalars['ID']
    isEnabled: Scalars['Boolean']
    name: Scalars['String']
    organizationId: Scalars['ID']
    settings: SecureKeyValue[]
    type: EmbeddedApplicationType
    __typename: 'EmbeddedApplicationAdapter'
}

export type EmbeddedApplicationAuthenticationMethod = 'NONE' | 'SAML2'

export type EmbeddedApplicationCallbackHandlingMethod = 'CALLBACK_URL' | 'IFRAME_HOOK' | 'NONE' | 'WINDOW_EVENT'

export interface EmbeddedApplicationSaml2Settings {
    acsURL: Scalars['String']
    metadataURL: Scalars['String']
    relayState: (Scalars['String'] | null)
    userCustomAttributes: ((KeyValueParam | null)[] | null)
    __typename: 'EmbeddedApplicationSaml2Settings'
}

export type EmbeddedApplicationType = 'MARKETPLACE' | 'MOVE_IN_TASK' | 'RENT_PAYMENT'

export interface EmbeddedApplicationWindowEventSettings {
    errorMessageJsonPath: Scalars['String']
    failureJsonPath: Scalars['String']
    origin: Scalars['String']
    successJsonPath: Scalars['String']
    __typename: 'EmbeddedApplicationWindowEventSettings'
}

export type EntityType = 'BUILDING' | 'UNIT'

export interface Event {
    RVSPs: (Scalars['Int'] | null)
    admissionFeeType: AdmissionFeeType
    attendanceCancellable: (Scalars['Boolean'] | null)
    attendees: (EventAttendee[] | null)
    audienceBuildingIds: (Scalars['ID'][] | null)
    audienceBuildings: (Building[] | null)
    availableTickets: (Scalars['Int'] | null)
    category: (Scalars['String'] | null)
    checkIns: (Scalars['Int'] | null)
    communities: (Community[] | null)
    communityIds: (Scalars['ID'][] | null)
    description: (Scalars['String'] | null)
    endDate: (Scalars['Time'] | null)
    hoodId: Scalars['ID']
    id: Scalars['ID']
    imageResourceId: (Scalars['ID'] | null)
    locationBuilding: (Building | null)
    locationBuildingId: (Scalars['ID'] | null)
    maxTickets: (Scalars['Int'] | null)
    name: Scalars['String']
    ownershipType: (EventOwnershipType | null)
    place: (Place | null)
    placeId: (Scalars['ID'] | null)
    publish: Scalars['Boolean']
    residentHostIds: (Scalars['ID'][] | null)
    residentHosts: (User[] | null)
    shouldGenerateQRCode: Scalars['Boolean']
    startDate: (Scalars['Time'] | null)
    statistics: (EventStatistics | null)
    status: (EventStatus | null)
    /** @deprecated use MyEventRating instead or point to the new resolver for getting event rating */
    surveyId: (Scalars['ID'] | null)
    ticketPrice: (Scalars['Float'] | null)
    virtualLink: (Scalars['String'] | null)
    __typename: 'Event'
}

export interface EventAttendance {
    attendanceStatus: AttendanceStatus
    eventDetails: Event
    eventId: Scalars['ID']
    rating: (Scalars['Int'] | null)
    __typename: 'EventAttendance'
}

export interface EventAttendee {
    attendanceStatus: AttendanceStatus
    eventId: Scalars['ID']
    id: Scalars['ID']
    rating: (Rating | null)
    user: User
    userId: Scalars['ID']
    __typename: 'EventAttendee'
}

export interface EventAudienceParams {
    eventId: Scalars['ID']
    __typename: 'EventAudienceParams'
}

export type EventOwnershipType = 'EXTERNAL' | 'IN_HOUSE'

export interface EventSearchResult {
    buildingName: (Scalars['String'] | null)
    communityId: Scalars['ID']
    endDate: (Scalars['Time'] | null)
    id: Scalars['ID']
    name: Scalars['String']
    organizationId: Scalars['ID']
    placeName: (Scalars['String'] | null)
    startDate: (Scalars['Time'] | null)
    __typename: 'EventSearchResult'
}

export interface EventStatistics {
    ratingAverage: (Scalars['Float'] | null)
    ratingCount: (Scalars['Int'] | null)
    __typename: 'EventStatistics'
}

export type EventStatus = 'FUTURE' | 'ON_GOING' | 'PAST'

export type EventSuggestionTimeframe = 'AFTERNOON' | 'EVENING' | 'MORNING'


/** Events with paging */
export interface EventsList {
    items: Event[]
    pageInfo: PageInfo
    __typename: 'EventsList'
}


/** Options to sort the events list */
export type EventsOrderBy = 'START_DAY_ASC' | 'START_DAY_DESC'

export interface ExternalAppLinkData {
    androidFallbackLink: Scalars['String']
    androidLink: Scalars['String']
    iosFallbackLink: Scalars['String']
    iosLink: Scalars['String']
    __typename: 'ExternalAppLinkData'
}

export type FanMode = 'AUTO' | 'HIGH' | 'LOW' | 'MED' | 'OFF'

export interface FeatureFlag {
    id: Scalars['ID']
    key: Scalars['String']
    name: Scalars['String']
    __typename: 'FeatureFlag'
}


/** The featured slide entity - featured content on the feed */
export interface FeaturedSlide {
    audienceBuildings: (FeaturedSlideBuildings[] | null)
    callToAction: (FeaturedSlideCallToAction | null)
    communityId: Scalars['ID']
    createdBy: (User | null)
    createdById: (Scalars['ID'] | null)
    description: (Scalars['String'] | null)
    id: Scalars['ID']
    imageResourceId: (Scalars['String'] | null)
    linkType: LinkType
    name: (Scalars['String'] | null)
    priority: (Scalars['Int'] | null)
    publishEndDate: (Scalars['Time'] | null)
    publishStartDate: (Scalars['Time'] | null)
    __typename: 'FeaturedSlide'
}

export interface FeaturedSlideBuildings {
    building: Building
    id: Scalars['ID']
    __typename: 'FeaturedSlideBuildings'
}


/** call to action on featured slide */
export interface FeaturedSlideCallToAction {
    label: Scalars['String']
    link: FeaturedSlideLink
    __typename: 'FeaturedSlideCallToAction'
}


/** link in call to action */
export interface FeaturedSlideLink {
    name: Scalars['String']
    type: LinkType
    value: Scalars['String']
    __typename: 'FeaturedSlideLink'
}

export interface FeaturedSlideList {
    items: FeaturedSlide[]
    pageInfo: PageInfo
    __typename: 'FeaturedSlideList'
}


/** Options to sort the featured slides list */
export type FeaturedSlidesOrderBy = 'CreatedAt_ASC' | 'CreatedAt_DESC' | 'Name_ASC' | 'Name_DESC' | 'Priority_ASC' | 'Priority_DESC' | 'PublishStartDate_ASC' | 'PublishStartDate_DESC'

export type Gender = 'FEMALE' | 'MALE' | 'NA' | 'NON_BINARY'

export interface GeneralInfoPage {
    cards: (GeneralInfoPageCards | null)
    communityId: Scalars['ID']
    createdAt: Scalars['Time']
    createdBy: (User | null)
    createdById: (Scalars['ID'] | null)
    description: Scalars['String']
    id: Scalars['ID']
    image: (Image | null)
    publish: (Scalars['Boolean'] | null)
    subtitle: Scalars['String']
    title: Scalars['String']
    __typename: 'GeneralInfoPage'
}


/** Entities connected to general info pages as cards */
export interface GeneralInfoPageCards {
    eventIds: (Scalars['ID'][] | null)
    events: (Event[] | null)
    placeIds: (Scalars['ID'][] | null)
    places: (Place[] | null)
    serviceIds: (Scalars['ID'][] | null)
    serviceProductIds: (Scalars['ID'][] | null)
    __typename: 'GeneralInfoPageCards'
}

export interface GeneralInfoPageList {
    items: GeneralInfoPage[]
    pageInfo: PageInfo
    __typename: 'GeneralInfoPageList'
}


/** Order by options for general info pages */
export type GeneralInfoPagesOrderBy = 'CREATED_AT_ASC' | 'CREATED_AT_DESC'

export interface GuestKey {
    key: Scalars['String']
    __typename: 'GuestKey'
}


/** The hood entity - deprecated! use Community instead */
export interface Hood {
    coordinates: (Scalars['Any'] | null)
    country: CountryCode
    displayName: Scalars['String']
    id: Scalars['ID']
    language: (Scalars['String'] | null)
    name: Scalars['String']
    portfolio: (Portfolio | null)
    /** @deprecated use portfolio connection instead */
    portfolioId: (Scalars['ID'] | null)
    state: (Scalars['String'] | null)
    timezone: (Scalars['String'] | null)
    __typename: 'Hood'
}

export interface HoodAggregations {
    activeSocialProfilesCount: Scalars['Int']
    newSocialProfilesCount: Scalars['Int']
    __typename: 'HoodAggregations'
}

export interface HoodRoot {
    aggregations: (HoodAggregations | null)
    broadcast: (Broadcast | null)
    broadcasts: (BroadcastsList | null)
    discussion: Discussion
    discussionComments: (DiscussionCommentsList | null)
    discussions: (DiscussionsList | null)
    discussionsSearchSuggestions: (Scalars['String'][] | null)
    event: Event
    events: (EventsList | null)
    featuredSlidesSearchSuggestions: (Scalars['String'][] | null)
    /** @deprecated use community instead */
    hoodDetails: Hood
    hoodId: Scalars['ID']
    hoodSettings: HoodSetting[]
    interestGroup: InterestGroup
    interestGroups: (InterestGroupsList | null)
    managersSocialProfiles: (SocialProfile[] | null)
    place: Place
    service: Service
    serviceProduct: ServiceProduct
    socialProfiles: (SocialProfilesList | null)
    user: User
    users: (UsersList | null)
    __typename: 'HoodRoot'
}

export interface HoodSetting {
    hoodId: Scalars['ID']
    key: Scalars['String']
    /** The key format will be hoodSettingGroup.hoodSetting.value */
    value: Scalars['String']
    __typename: 'HoodSetting'
}

export interface HubMetadata {
    background: (Scalars['String'] | null)
    firstTimeWalkMe: Scalars['Boolean']
    nickName: (Scalars['String'] | null)
    onboarded: Scalars['Boolean']
    picture: (Scalars['String'] | null)
    promoAmenities: Scalars['Boolean']
    promoMoveIn: Scalars['Boolean']
    renewals: Scalars['Boolean']
    roles: (Scalars['String'][] | null)
    tShirtSize: (Scalars['String'] | null)
    tasks: (Scalars['String'][] | null)
    __typename: 'HubMetadata'
}

export interface IdentityVerificationResult {
    details: (Scalars['String'][] | null)
    identityOverrideTimestamp: (Scalars['Time'] | null)
    isIdentityVerified: Scalars['Boolean']
    status: ScreeningCheckStatus
    __typename: 'IdentityVerificationResult'
}

export interface IdentityVerificationSummary {
    areAllIdentitiesVerified: Scalars['Boolean']
    status: SummaryStatus
    __typename: 'IdentityVerificationSummary'
}

export interface Image {
    format: (Scalars['String'] | null)
    resourceId: Scalars['ID']
    __typename: 'Image'
}

export interface IncomeReportResult {
    annualIncome: (Scalars['Int'] | null)
    details: (Scalars['String'][] | null)
    reportType: (Scalars['String'] | null)
    status: ScreeningCheckStatus
    __typename: 'IncomeReportResult'
}

export interface IncomeReportSummary {
    incomeSum: Scalars['Int']
    minIncomeThreshold: Scalars['Int']
    numOfSkipped: Scalars['Int']
    status: SummaryStatus
    __typename: 'IncomeReportSummary'
}

export interface InitTokenParams {
    expiresAt: (Scalars['Time'] | null)
    refreshToken: (Scalars['String'] | null)
    token: Scalars['String']
    __typename: 'InitTokenParams'
}


/**
 * The interest group entity
 * This entity doesn't exist on the DB but is pulled from chat_client
 */
export interface InterestGroup {
    createdAt: Scalars['Time']
    createdByUser: (User | null)
    createdByUserId: Scalars['ID']
    description: (Scalars['String'] | null)
    disabled: Scalars['Boolean']
    groupSuggestedByNeighbor: (Scalars['Boolean'] | null)
    id: Scalars['ID']
    imageUrl: Scalars['String']
    isPublic: Scalars['Boolean']
    memberCount: Scalars['Int']
    name: Scalars['String']
    socialProfilesPreview: (SocialProfile[] | null)
    __typename: 'InterestGroup'
}

export interface InterestGroupInsightCategory {
    description: Scalars['String']
    id: Scalars['ID']
    name: Scalars['String']
    __typename: 'InterestGroupInsightCategory'
}

export interface InterestGroupInsightDiscussion {
    InterestGroup: InterestGroup
    category: InterestGroupInsightCategory
    categoryId: Scalars['ID']
    endAt: Scalars['Time']
    id: Scalars['ID']
    interestGroupId: Scalars['ID']
    labels: InterestGroupLabel[]
    members: InterestGroupMember[]
    messageIds: Scalars['ID'][]
    messages: InterestGroupMessage[]
    sentiment: InterestGroupSentiment
    shortDescription: Scalars['String']
    startAt: Scalars['Time']
    topic: Scalars['String']
    __typename: 'InterestGroupInsightDiscussion'
}

export interface InterestGroupInsightSummary {
    dateFrom: Scalars['Time']
    dateTo: Scalars['Time']
    interestGroupId: Scalars['ID']
    members: InterestGroupMember[]
    messageIds: Scalars['ID'][]
    summary: Scalars['String']
    __typename: 'InterestGroupInsightSummary'
}

export interface InterestGroupLabel {
    color: Scalars['String']
    icon: Scalars['String']
    id: Scalars['ID']
    name: Scalars['String']
    __typename: 'InterestGroupLabel'
}

export interface InterestGroupMember {
    user: User
    user_id: Scalars['ID']
    __typename: 'InterestGroupMember'
}

export interface InterestGroupMembersList {
    members: InterestGroupMember[]
    pageInfo: PageInfo
    __typename: 'InterestGroupMembersList'
}

export interface InterestGroupMessage {
    communityId: Scalars['ID']
    id: Scalars['ID']
    interestGroupId: Scalars['ID']
    organizationId: Scalars['ID']
    streamCreatedAt: Scalars['Time']
    streamUpdatedAt: (Scalars['Time'] | null)
    text: Scalars['String']
    user: User
    userId: Scalars['ID']
    __typename: 'InterestGroupMessage'
}

export interface InterestGroupSearchResult {
    communityId: Scalars['ID']
    id: Scalars['ID']
    name: Scalars['String']
    organizationId: Scalars['ID']
    __typename: 'InterestGroupSearchResult'
}

export type InterestGroupSentiment = 'NEGATIVE' | 'NEUTRAL' | 'POSITIVE'

export interface InterestGroupsAudienceParams {
    interestGroupIds: (Scalars['ID'][] | null)
    __typename: 'InterestGroupsAudienceParams'
}


/** Interest groups with paging */
export interface InterestGroupsList {
    items: InterestGroup[]
    pageInfo: PageInfo
    __typename: 'InterestGroupsList'
}

export type InviteStatus = 'ACCEPTED' | 'EXPIRED' | 'PENDING'

export type JobItemEntityType = 'BUILDING' | 'DELIVERY_PACKAGE' | 'LEASE_CONTRACT' | 'RESIDENT' | 'SUPPORT_CATEGORY' | 'SUPPORT_TICKET' | 'SUPPORT_TICKET_ATTACHMENT' | 'SUPPORT_TICKET_COMMENT' | 'UNIT'

export type JobItemStatus = 'COMPLETED' | 'DISABLED' | 'FAILED' | 'QUEUED'

export interface JobItemStatusCount {
    count: Scalars['Int']
    status: SyncJobItemStatus
    __typename: 'JobItemStatusCount'
}

export interface KeyValueParam {
    key: Scalars['String']
    value: Scalars['String']
    __typename: 'KeyValueParam'
}


/** Available languages */
export type Language = 'EN' | 'HE'

export interface LeaseAmenity {
    access: Scalars['Boolean']
    amount: Scalars['Float']
    __typename: 'LeaseAmenity'
}

export interface LeaseContract {
    activeTaskLists: UserTaskList[]
    bedroom: (Bedroom | null)
    bedroomId: (Scalars['String'] | null)
    contractTimeline: (ContractTimeline | null)
    endDate: Scalars['Time']
    /** The external ID of the building coming from the PMS */
    externalId: (Scalars['ID'] | null)
    hood: (Hood | null)
    hoodId: Scalars['String']
    id: Scalars['ID']
    isDraft: Scalars['Boolean']
    isTerminatedEarly: Scalars['Boolean']
    lateFeeApplicable: Scalars['Boolean']
    lateFeeGracePeriod: Scalars['Int']
    ledger: LeaseLedger
    metadata: (Scalars['Any'] | null)
    moveInDate: (Scalars['Time'] | null)
    moveOutDate: (Scalars['Time'] | null)
    moveOutReason: (MoveOutReason | null)
    originalEndDate: Scalars['Time']
    pmsStatus: (Scalars['String'] | null)
    renewalScore: (RenewalRiskScore | null)
    renewalSurveyAssignment: (RenewalSurveyAssignment | null)
    rent: Scalars['Float']
    securityDepositAmount: (Scalars['Float'] | null)
    startDate: Scalars['Time']
    status: (ContractStatus | null)
    unit: (Unit | null)
    unitId: (Scalars['String'] | null)
    user: (User | null)
    userId: Scalars['String']
    __typename: 'LeaseContract'
}

export type LeaseContractOrderBy = 'CREATED_AT_ASC' | 'CREATED_AT_DESC'

export interface LeaseContractsList {
    items: ((LeaseContract | null)[] | null)
    pageInfo: PageInfo
    __typename: 'LeaseContractsList'
}

export interface LeaseLedger {
    BalanceDue: Scalars['Float']
    charges: LeaseLedgerCharge[]
    payments: LeaseLedgerPayment[]
    /** @deprecated Use BalanceDue instead */
    totalUnpaidCharges: Scalars['Float']
    __typename: 'LeaseLedger'
}

export interface LeaseLedgerCharge {
    amount: Scalars['Float']
    amountPaid: Scalars['Float']
    code: Scalars['String']
    date: Scalars['Time']
    description: (Scalars['String'] | null)
    id: Scalars['ID']
    __typename: 'LeaseLedgerCharge'
}

export interface LeaseLedgerPayment {
    amount: Scalars['Float']
    id: Scalars['ID']
    paidAt: Scalars['Time']
    __typename: 'LeaseLedgerPayment'
}

export type LeaseRecommendationStatus = 'NOT_RECOMMENDED' | 'PENDING_SCREENING' | 'RECOMMENDED'


/**
 * HERE THERE BE DRAGONS!
 * This is a legacy settings struct, the naming is unclear, the existance of
 * the fields is illogical in some cases but for backward compatibility
 * it must be here.
 * 
 * no new fields should be added here ever.
 * we should only remove them one by one managing breaking changes in mobile app.
 */
export interface LegacySettings {
    cancelOrderEnabled: (Scalars['Boolean'] | null)
    cancelOrderUserId: (Scalars['String'] | null)
    customCommunityGuidelinesObjectsArray: (Scalars['String'] | null)
    dashboardAdditionalOptions: (Scalars['String'] | null)
    enableMaintenanceChat: (Scalars['Boolean'] | null)
    hasCustomCommunityGuidelines: (Scalars['Boolean'] | null)
    immediatePaymentEnabled: (Scalars['Boolean'] | null)
    landlordImage: (Scalars['String'] | null)
    locksEnabled: (Scalars['Boolean'] | null)
    locksProvider: (Scalars['String'] | null)
    locksSharingEnabled: (Scalars['Boolean'] | null)
    locksSupportPhone: (Scalars['String'] | null)
    maintenanceUserID: (Scalars['String'] | null)
    mobileSettingsIsDemoHood: (Scalars['Boolean'] | null)
    mobileSettingsNewAppDisplayName: (Scalars['String'] | null)
    mobileSettingsNewAppLink: (Scalars['String'] | null)
    mobileSettingsNewAppModalEnabled: (Scalars['Boolean'] | null)
    moveInSettingsAdditionalInterestedParty: (Scalars['String'] | null)
    moveInSettingsEnabled: (Scalars['Boolean'] | null)
    moveInSettingsEnabledUsers: (Scalars['String'] | null)
    moveInSettingsUseHardCodedTemplate: (Scalars['Boolean'] | null)
    packagesEnabled: (Scalars['Boolean'] | null)
    paymentInvoicesEnabled: (Scalars['Boolean'] | null)
    paymentProviderUrl: (Scalars['String'] | null)
    preventUsersFromPostingInFeed: (Scalars['Boolean'] | null)
    supportDescriptionOptionalEnabled: (Scalars['Boolean'] | null)
    supportEntryNotesMandatoryEnabled: (Scalars['Boolean'] | null)
    supportProvider: (Scalars['String'] | null)
    supportSubjectOptionalEnabled: (Scalars['Boolean'] | null)
    supportTicketCovidQuestionsEnabled: (Scalars['Boolean'] | null)
    supportTicketEnabled: (Scalars['Boolean'] | null)
    supportTicketEntryNoteEnabled: (Scalars['Boolean'] | null)
    supportUserId: (Scalars['String'] | null)
    walletEnabled: (Scalars['Boolean'] | null)
    yardiCommentsEmailAddress: (Scalars['String'] | null)
    __typename: 'LegacySettings'
}


/** Types of links available for featured slides */
export type LinkType = 'chatGroup' | 'createDiscussion' | 'custom' | 'event' | 'generalInfoPage' | 'place' | 'selfProfile' | 'service' | 'user'

export interface LockCredentials {
    credentials: Scalars['String']
    provider: Scalars['String']
    __typename: 'LockCredentials'
}

export interface LockProvider {
    adapterId: Scalars['String']
    authenticationMethod: AuthenticationMethod
    initTokenParams: (InitTokenParams | null)
    isBluetoothEnabled: Scalars['Boolean']
    isGuestAccessEnabled: Scalars['Boolean']
    oAuthParams: (OAuthParams | null)
    provider: Scalars['String']
    providerSpecificParams: (KeyValueParam[] | null)
    __typename: 'LockProvider'
}

export interface LockProviderKey {
    adapterId: Scalars['String']
    id: Scalars['String']
    isSharable: Scalars['Boolean']
    name: Scalars['String']
    __typename: 'LockProviderKey'
}

export type LockProviderType = 'BMX' | 'BRIVO' | 'MOCK' | 'OPENPATH' | 'SALTO'

export interface MainApplicantPublicData {
    email: Scalars['String']
    firstName: Scalars['String']
    id: Scalars['ID']
    lastName: Scalars['String']
    middleName: (Scalars['String'] | null)
    __typename: 'MainApplicantPublicData'
}

export type Month = 'APRIL' | 'AUGUST' | 'DECEMBER' | 'FEBRUARY' | 'JANUARY' | 'JULY' | 'JUNE' | 'MARCH' | 'MAY' | 'NOVEMBER' | 'OCTOBER' | 'SEPTEMBER'

export interface MoveInFunnelRecord {
    leaseContract: LeaseContract
    leaseContractId: Scalars['ID']
    __typename: 'MoveInFunnelRecord'
}

export interface MoveInFunnelRecordList {
    pageInfo: PageInfo
    records: (MoveInFunnelRecord[] | null)
    __typename: 'MoveInFunnelRecordList'
}

export type MoveInFunnelStatus = 'APP_DOWNLOADED' | 'CHECKLIST_COMPLETED' | 'CHECKLIST_IN_PROGRESS' | 'DONE' | 'NOT_STARTED'

export interface MoveInSettings {
    additionalInterestedParty: Scalars['Boolean']
    buildingId: (Scalars['String'] | null)
    disableDaysAfterMoveInDate: (Scalars['Int'] | null)
    enableDaysBeforeMoveInDate: (Scalars['Int'] | null)
    enabledLeaseStatuses: (ContractStatus[] | null)
    enabledScreeningStatuses: (Scalars['String'][] | null)
    enabledUsers: (Scalars['String'][] | null)
    isEnabled: Scalars['Boolean']
    openForRoommates: Scalars['Boolean']
    organizationId: Scalars['String']
    useHardCodedTemplate: Scalars['Boolean']
    __typename: 'MoveInSettings'
}

export type MoveOutReason = 'EVICTION' | 'NOTICE'

export interface Mutation {
    ShiftNoteClose: Scalars['Boolean']
    UserTaskListUpdate: (UserTaskListUpdateResponse | null)
    UserTaskUpdate: (UserTaskStatusUpdateResponse | null)
    adapterCreate: Adapter
    adapterUpdate: Adapter
    amenityCalendarCreate: Amenity
    amenityCalendarUnavailabilityDelete: Scalars['Boolean']
    amenityCalendarUnavailabilitySet: Scalars['Boolean']
    amenityCalendarUpdate: AmenityCalendarSettings
    amenityCancellationRequestDeny: AmenityReservation
    amenityCreate: Amenity
    amenityReservationApprove: AmenityReservation
    amenityReservationCancel: AmenityReservation
    amenityReservationCancelAndRefund: AmenityReservation
    amenityReservationCancelMany: Scalars['Int']
    amenityReservationCreate: AmenityReservation
    amenityReservationCustomCreate: AmenityReservation
    /** @deprecated use amenityReservationCancel instead */
    amenityReservationDeny: AmenityReservation
    amenityReservationDepositCharge: AmenityReservation
    amenityReservationDepositCreate: AmenityReservation
    amenityReservationDepositRelease: AmenityReservation
    amenityUpdate: Amenity
    appDashboardLinkCreate: AppDashboardLink
    appDashboardLinkDelete: Scalars['Boolean']
    appDashboardLinkOrder: Scalars['Boolean']
    appDashboardLinkUpdate: AppDashboardLink
    applicantConsentCreate: Scalars['Boolean']
    applicantCreate: ApplicantCreateResponse
    /** generates a lease for the applicant, when sendToApplicant is true, the lease will be sent to the applicant */
    applicantGenerateLease: Scalars['Boolean']
    applicantReject: (Scalars['Boolean'] | null)
    applicantScreeningReRun: Scalars['Boolean']
    applicantStripeCheckoutCreate: StripeCheckoutSession
    /** invite co applicants */
    applicantsInvite: ApplicantsInviteResponse
    applicationSettingsUpdate: ApplicationSettings
    applicationUnitUpdate: Scalars['Boolean']
    authChallengeCreate: AuthChallenge
    authChallengeVerify: AuthChallengeVerifyResponse
    authTokenRefresh: AuthTokenRefreshResponse
    automationRuleCreate: AutomationRule
    automationRuleDelete: Scalars['Boolean']
    automationRuleSet: AutomationRule
    awayNoticeApprove: AwayNotice
    awayNoticeCreate: AwayNotice
    awayNoticeSettingsUpsert: AwayNoticeSettings
    brandCreate: Brand
    brandDelete: Scalars['Boolean']
    brandUpdate: Brand
    broadcastCreate: Broadcast
    broadcastDelete: Scalars['Boolean']
    broadcastSend: Scalars['Boolean']
    broadcastUpdate: Broadcast
    buildingCommunitySet: Building
    buildingSettingsSet: BuildingSettings
    /** Set status to all units of a given building */
    buildingUnitStatusSet: Building
    buildingsAutomationRuleCreate: AutomationRule
    buildingsTaskListSet: Building[]
    chatMessageDelete: Scalars['Boolean']
    coApplicantUpdate: ApplicantUpdateResponse
    communitiesAutomationRuleCreate: AutomationRule
    /** @deprecated use buildingCommunitySet instead */
    communityBuildingsSet: CommunityBuildings
    communityCreate: Community
    communitySettingsSet: CommunitySettings
    communityUpdate: Community
    createRenewalSurveyAssignment: RenewalSurveyAssignment
    deliveryPackageCreate: (DeliveryPackage | null)
    deliveryPackageDelete: Scalars['Boolean']
    deliveryPackagePickupReminderSend: Scalars['Boolean']
    deliveryPackageUpdate: DeliveryPackage
    emailDomainCreate: EmailDomain
    emailDomainSenderCreate: EmailDomainSender
    emailDomainSenderUpdate: EmailDomainSender
    emailDomainVerify: EmailDomain
    emailSetAllowDomainUsersAsSenders: Scalars['Boolean']
    emailSettingsDelete: Scalars['Boolean']
    emailSettingsUpdate: EmailSettings
    emailSystemDefaultSet: Scalars['Boolean']
    embeddedApplicationAdapterCreate: EmbeddedApplicationAdapter
    embeddedApplicationAdapterUpdate: EmbeddedApplicationAdapter
    eventCheckin: Scalars['Boolean']
    eventCreate: Event
    eventDelete: Scalars['Boolean']
    eventUpdate: (Event | null)
    externalLockProviderGroupCreate: externalLockProviderGroup
    externalLockProviderGroupDelete: Scalars['Boolean']
    externalLockProviderGroupUpdate: externalLockProviderGroup
    featuredSlideCreate: FeaturedSlide
    featuredSlideDelete: Scalars['Boolean']
    featuredSlideUpdate: FeaturedSlide
    generalInfoPageCreate: GeneralInfoPage
    generalInfoPageDelete: Scalars['Boolean']
    generalInfoPageUpdate: GeneralInfoPage
    /** Create an interest group */
    interestGroupCreate: InterestGroup
    /** Delete an interest group */
    interestGroupDelete: Scalars['Boolean']
    /** Update an interest group */
    interestGroupUpdate: InterestGroup
    leaseContractCreate: LeaseContract
    leaseContractTerminate: LeaseContract
    me: MutationUserRoot
    /** @deprecated this will happen automatically */
    moveInReconcile: (Scalars['Boolean'] | null)
    moveInSettingsCreate: MoveInSettings
    moveInSettingsUpdate: MoveInSettings
    notificationSnoozeCreate: NotificationSnooze
    notificationSnoozeUpdate: NotificationSnooze
    notificationsUpdateMany: Scalars['Int']
    organizationAutomationRuleCreate: AutomationRule
    organizationCreate: Organization
    organizationMemberInvite: OrganizationMember
    organizationMemberUpdate: OrganizationMember
    organizationUpdate: Organization
    /** Overrides the applicant's identity result and sets a permanent value */
    overrideIdentityResult: Scalars['Boolean']
    paymentReminderDelete: Scalars['Boolean']
    paymentReminderSet: PaymentReminders
    periodicSurveyAssignmentsBatchCreate: SurveyAssignment[]
    periodicSurveySettingsCreate: PeriodicSurveySettings
    periodicSurveySettingsSet: PeriodicSurveySettings
    plaidAccessTokenCreate: Scalars['Boolean']
    plaidIncomeSkip: Scalars['Boolean']
    plaidLinkTokenCreate: PlaidLinkTokenResponse
    pmsAdapterCreate: PmsAdapter
    pmsAdapterTest: PmsAdapterTestResult
    pmsAdapterUpdate: PmsAdapter
    /** Creates a new pms sync job which will execute the sync process asynchronously */
    pmsSyncJobCreate: PmsSyncJob
    reputationCreate: Reputation
    reputationDelete: Scalars['Boolean']
    reputationUpdate: Reputation
    residentCreate: Resident
    scheduledBroadcastCreate: ScheduledBroadcast
    scheduledBroadcastUpdate: ScheduledBroadcast
    serviceRequestCancel: SupportTicket
    serviceRequestCategorySet: SupportCategory
    serviceRequestCreate: ServiceRequestTicket
    serviceRequestSettingsCreate: ServiceRequestsSettings
    serviceRequestSettingsSet: ServiceRequestsSettings
    serviceRequestSettingsSurveyIdSet: ServiceRequestsSettings
    /** @deprecated user serviceRequestSettingsSet */
    serviceRequestSettingsUpdate: ServiceRequestsSettings
    serviceRequestUpdate: SupportTicket
    serviceRequestsAdapterCreate: ServiceRequestsAdapter
    serviceRequestsAdapterDelete: Scalars['Boolean']
    serviceRequestsAdapterSetStatusMapping: ServiceRequestsAdapter
    serviceRequestsAdapterTest: ServiceRequestsAdapterTestResult
    serviceRequestsAdapterUpdate: ServiceRequestsAdapter
    /** @deprecated user serviceRequestSettingsSet */
    serviceRequestsSetIsEnabled: ServiceRequestsSettings
    setApplicantSubmissionStep: Scalars['Boolean']
    setAwayNoticeAsArchived: Scalars['Boolean']
    setShiftNotesAsArchived: Scalars['Boolean']
    setVisitorCheckInStatus: Visitor
    setVisitorCheckOutStatus: Visitor
    shiftNoteCreate: ShiftNote
    shiftNoteUpdate: ShiftNote
    smartLockAdapterCreate: SmartLockAdapter
    smartLockAdapterDelete: Scalars['Boolean']
    smartLockAdapterUpdate: SmartLockAdapter
    socialProfileSuspend: Scalars['Boolean']
    staffTaskCreate: StaffTask
    staffTaskDelete: Scalars['Boolean']
    staffTaskUpdate: StaffTask
    /** @deprecated Used to create existing account for e2e testing purposes only */
    stripeConnectedAccountAddExisting: StripeConnectedAccount
    stripeConnectedAccountCreate: StripeConnectedAccount
    stripeConnectedAccountSessionCreate: StripeConnectedAccountSession
    stripeConnectedAccountUpdate: StripeConnectedAccount
    supportTicketCommentCreate: SupportTicketComment
    supportTicketCreate: SupportTicket
    surveyCreate: Survey
    surveyQuestionUpdate: SurveyQuestion
    /** @deprecated use me.MySurveyResponseSubmit instead */
    surveyResponseSubmit: SurveyAssignment
    surveyUpdate: Survey
    syncJobCreate: SyncJob
    syncJobDownloadExport: SyncJobDownloadExportResult
    syncJobPrepareExport: Scalars['Boolean']
    taskCreate: Task
    taskListCreate: TaskList
    taskListCreateFrom: TaskList
    taskListDelete: Scalars['Boolean']
    taskListTaskCreate: TaskListTask
    taskListTaskDelete: Scalars['Boolean']
    taskListTaskReorder: TaskListTask
    taskListTaskUpdate: TaskListTask
    taskListUpdate: TaskList
    taskUpdate: Task
    teamBuildingPermissionsSet: Scalars['Boolean']
    teamBuildingsPermissionsAdd: Scalars['Boolean']
    teamCreate: Team
    teamDelete: Scalars['Boolean']
    teamUpdate: Team
    /** Set unit accepting applications */
    unitIsAcceptingApplicationsSet: Unit[]
    /** Set unit status */
    unitStatusSet: Unit[]
    userLoginApiKey: UserLoginApiKeyResponse
    visitorCancel: Visitor
    visitorCreate: Visitor
    visitorUpdate: Visitor
    visitorsSettingsUpsert: (VisitorsSettings | null)
    whiteLabelCreate: WhiteLabel
    whiteLabelDelete: Scalars['Boolean']
    whiteLabelUpdate: WhiteLabel
    __typename: 'Mutation'
}

export interface MutationUserRoot {
    ActiveLeaseContracts: (LeaseContract[] | null)
    MyAwayNoticeCreate: AwayNotice
    MyAwayNoticeSetArchived: Scalars['Boolean']
    MyAwayNoticeUpdate: AwayNotice
    MyDeliveryPackageStatusSet: Scalars['Boolean']
    MyEmbeddedAppAuthenticatedURLGenerate: ResidentEmbeddedApplicationDescriptor
    MyEventAttend: EventAttendee
    MyEventCheckIn: EventAttendee
    MyEventCheckInWithScan: EventAttendee
    MyEventCheckinCancel: EventAttendee
    MyEventRatingSet: Event
    MyEventSuggest: Event
    MyEventUnattend: EventAttendee
    MyFirstLoginSet: Scalars['Boolean']
    MyGenerateAttachmentsUploadUrl: AttachmentsUploadUrlResponse
    MyHubMetadataSet: User
    MyLockProvidersInitializeAccess: LockProvider[]
    MyMobileLastVisit: Scalars['Boolean']
    MyNotificationSendYourselfTest: Notification
    MyNotificationUpdate: Notification
    MyPersonalInformationUpdate: User
    MyPushNotificationTokenRegister: Scalars['Boolean']
    MyPushNotificationTokenUnRegister: Scalars['Boolean']
    MyRenewalSurveyAssignmentUpdate: RenewalSurveyAssignment
    MyReservationCancel: AmenityReservation
    /** @deprecated use MyReservationCancel instead */
    MyReservationCancelMany: Scalars['Int']
    /** @deprecated checkout will be part of the my reservation create mutation */
    MyReservationCheckout: (MyReservationCheckoutResponse | null)
    MyReservationCreate: (AmenityReservation | null)
    MyReservationPay: (AmenityReservation | null)
    MyReservationRequestCancel: AmenityReservation
    MyServiceRequestCancel: SupportTicket
    MyServiceRequestCommentCreate: ServiceRequestComment
    MyServiceRequestUpdate: SupportTicket
    MySmartLockGuestKeyGenerate: GuestKey
    MySmartLockOAuthTokenGenerate: InitTokenParams
    MySmartLockOAuthTokenRefresh: InitTokenParams
    MySmartLockRegisterWebhook: Scalars['Boolean']
    MySmartLockUnRegisterWebhook: Scalars['Boolean']
    MySurveyResponseSubmit: SurveyAssignment
    MySurveySnooze: SurveyAssignment
    MyTermsAndConditionsAccept: Scalars['Boolean']
    /** @deprecated use MyThermostatSetState instead */
    MyThermostatSet: Scalars['Boolean']
    MyThermostatSetState: Scalars['Boolean']
    MyUserTaskListSubmit: (UserTaskListSubmitResponse | null)
    MyUserTaskStatusUpdate: (UserTaskStatusUpdateResponse | null)
    MyUserTaskSubmit: (MyUserTaskSubmitResponse | null)
    MyVisitorCancel: Visitor
    MyVisitorCreate: Visitor
    MyVisitorUpdate: Visitor
    UserId: Scalars['ID']
    __typename: 'MutationUserRoot'
}

export interface MyReservationCheckoutResponse {
    checkoutPageUrl: (Scalars['String'] | null)
    __typename: 'MyReservationCheckoutResponse'
}

export interface MyUserTaskSubmitResponse {
    id: Scalars['ID']
    responses: (UserTaskResponse[] | null)
    userTaskListStatus: UserTaskListStatus
    userTaskStatus: UserTaskStatus
    __typename: 'MyUserTaskSubmitResponse'
}

export interface NeighborsList {
    items: SocialProfile[]
    pageInfo: PageInfo
    seed: Scalars['Int']
    __typename: 'NeighborsList'
}

export interface NewDiscussionAddedSubscription {
    hoodId: Scalars['ID']
    __typename: 'NewDiscussionAddedSubscription'
}

export interface Notification {
    broadcast: (Broadcast | null)
    category: NotificationCategory
    clickedOnCta: Scalars['Boolean']
    createdAt: Scalars['Time']
    createdBy: (User | null)
    createdByUserId: (Scalars['ID'] | null)
    cta: (CtaData | null)
    deepLink: (DeepLink | null)
    id: Scalars['ID']
    message: Scalars['String']
    recipientStatus: NotificationRecipientStatus
    sourceType: Scalars['String']
    status: NotificationStatus
    title: (Scalars['String'] | null)
    toUser: User
    toUserId: Scalars['ID']
    /** Indecentest weather the notification is persisted in the user's inbox or just as a push notification */
    visibleToUser: (Scalars['Boolean'] | null)
    __typename: 'Notification'
}

export interface NotificationCategory {
    createdAt: Scalars['Time']
    description: (Scalars['String'] | null)
    id: Scalars['ID']
    isMandatory: (Scalars['Boolean'] | null)
    name: Scalars['String']
    __typename: 'NotificationCategory'
}

export type NotificationMedium = 'EMAIL' | 'MOBILE_APP' | 'SLACK' | 'SMS' | 'VENN_INBOX'

export type NotificationRecipientStatus = 'CLICK' | 'DELIVERED' | 'FAILED' | 'OPEN' | 'SPAM' | 'UNDETERMINED'

export interface NotificationSnooze {
    expiration: (Scalars['Time'] | null)
    id: Scalars['ID']
    notificationCategory: NotificationCategory
    userId: Scalars['ID']
    __typename: 'NotificationSnooze'
}

export interface NotificationSnoozesList {
    items: NotificationSnooze[]
    pageInfo: PageInfo
    __typename: 'NotificationSnoozesList'
}

export type NotificationStatus = 'DENIED' | 'FAILURE' | 'NEW' | 'PROCESSING' | 'RETRY' | 'SUCCESS'

export type NotificationType = 'BILLINGS_AND_PAYMENTS' | 'BILLING_NOTICE' | 'BROADCAST' | 'BROADCAST_TEST' | 'COMMUNITY_CHAT' | 'EVENT_CHECK_IN_NOTIFY' | 'EVENT_NOTIFY' | 'EVENT_SURVEY' | 'MAINTENANCE' | 'MEMBERS_HIGHLIGHT' | 'NEW_DISCUSSION_COMMENT' | 'NEW_FINDS' | 'NEW_MOVE_INS' | 'NEW_SOCIAL_REACTION' | 'NPS' | 'SPECIAL_OFFERS' | 'VENN_HAPPENINGS' | 'VENN_NETWORK' | 'ZD_TICKET_UPDATE'

export interface NotificationsList {
    items: Notification[]
    pageInfo: PageInfo
    __typename: 'NotificationsList'
}

export type NotificationsOrderBy = 'CREATED_AT_ASC' | 'CREATED_AT_DESC'

export interface OAuthParams {
    apiBaseURL: Scalars['String']
    authURL: Scalars['String']
    clientId: Scalars['String']
    tokenURL: Scalars['String']
    __typename: 'OAuthParams'
}

export interface OauthSettings {
    apiBaseURL: Scalars['String']
    authURL: Scalars['String']
    clientId: Scalars['String']
    clientSecret: Scalars['String']
    tokenURL: Scalars['String']
    __typename: 'OauthSettings'
}

export interface OrderedActiveUserTaskLists {
    userTaskList: UserTaskList
    userTasks: ((UserTask | null)[] | null)
    __typename: 'OrderedActiveUserTaskLists'
}

export interface Organization {
    buildings: Building[]
    communities: Community[]
    id: Scalars['ID']
    logoUrl: (Scalars['String'] | null)
    name: Scalars['String']
    subdomain: (Scalars['String'] | null)
    __typename: 'Organization'
}

export interface OrganizationMember {
    id: Scalars['ID']
    inviteDate: Scalars['Time']
    inviteStatus: InviteStatus
    isActive: Scalars['Boolean']
    organizationId: Scalars['ID']
    teams: Team[]
    type: OrganizationMemberType
    user: User
    userId: Scalars['ID']
    __typename: 'OrganizationMember'
}

export type OrganizationMemberType = 'MEMBER' | 'OWNER'

export interface PageInfo {
    totalItems: Scalars['Long']
    __typename: 'PageInfo'
}


/**  Deprecated: Use PaymentOption instead  */
export interface PaymentDetails {
    serviceFee: Scalars['Float']
    totalPrice: Scalars['Float']
    __typename: 'PaymentDetails'
}

export type PaymentMethod = 'ACH' | 'CASH' | 'CHARGE_ON_UNIT' | 'CHECK' | 'CREDIT_CARD'

export interface PaymentOption {
    icon: Scalars['String']
    isEmbeddedPayment: Scalars['Boolean']
    /**  used to determine if the payment option is available to the resident  */
    isSelfService: Scalars['Boolean']
    paymentMethod: PaymentMethod
    serviceFee: Scalars['Float']
    totalPrice: Scalars['Float']
    __typename: 'PaymentOption'
}

export interface PaymentReminders {
    lateReminderSchedule: PaymentRemindersSchedule
    reminderSchedule: PaymentRemindersSchedule
    __typename: 'PaymentReminders'
}

export interface PaymentRemindersSchedule {
    dayOfMonth: Scalars['Int']
    hourOfDay: Scalars['Int']
    __typename: 'PaymentRemindersSchedule'
}

export interface PeriodicSurveyQuestionResponseStats {
    count: Scalars['Int']
    rating: Scalars['Int']
    __typename: 'PeriodicSurveyQuestionResponseStats'
}

export interface PeriodicSurveyQuestionStats {
    averageScore: Scalars['Float']
    questionId: Scalars['ID']
    responsesStats: PeriodicSurveyQuestionResponseStats[]
    title: Scalars['String']
    __typename: 'PeriodicSurveyQuestionStats'
}

export interface PeriodicSurveyReport {
    batchId: Scalars['ID']
    questionsStats: PeriodicSurveyQuestionStats[]
    surveyStats: PeriodicSurveyStats
    title: Scalars['String']
    __typename: 'PeriodicSurveyReport'
}

export interface PeriodicSurveySettings {
    id: Scalars['ID']
    minResidentialDays: Scalars['Int']
    nextRunDate: Scalars['Time']
    organizationId: Scalars['ID']
    runIntervalDays: Scalars['Int']
    surveyId: (Scalars['ID'] | null)
    __typename: 'PeriodicSurveySettings'
}

export interface PeriodicSurveyStats {
    completed: Scalars['Int']
    opened: Scalars['Int']
    sent: Scalars['Int']
    __typename: 'PeriodicSurveyStats'
}

export type Permission = 'BROADCASTER' | 'MANAGER'

export interface PermittedBuilding {
    building: Building
    buildingId: Scalars['ID']
    permissions: Permission[]
    __typename: 'PermittedBuilding'
}

export interface Pet {
    bio: (Scalars['String'] | null)
    breed: (Scalars['String'] | null)
    id: Scalars['ID']
    imgUrl: (Scalars['String'] | null)
    name: Scalars['String']
    tags: Scalars['String'][]
    type: (Scalars['String'] | null)
    __typename: 'Pet'
}

export interface PetDetails {
    age: Scalars['Float']
    breed: Scalars['String']
    color: Scalars['String']
    name: Scalars['String']
    sex: Scalars['String']
    type: Scalars['String']
    weight: Scalars['String']
    __typename: 'PetDetails'
}

export type PetSex = 'FEMALE' | 'MALE'

export type PetType = 'CAT' | 'DOG'

export interface PhoneLinkData {
    phoneNumber: Scalars['String']
    __typename: 'PhoneLinkData'
}

export interface Place {
    address: (Address | null)
    addressId: Scalars['ID']
    building: (Building | null)
    buildingId: (Scalars['ID'] | null)
    communityId: Scalars['ID']
    description: (Scalars['String'] | null)
    id: Scalars['ID']
    name: Scalars['String']
    openingHours: (Scalars['String'] | null)
    __typename: 'Place'
}

export interface PlaidLinkTokenResponse {
    linkToken: Scalars['String']
    __typename: 'PlaidLinkTokenResponse'
}

export type PlaidLinkType = 'ASSET' | 'IDV' | 'INCOME_BANK' | 'INCOME_DOCUMENT' | 'INCOME_EXISTING_ITEM' | 'INCOME_PAYROLL'

export interface PmsAdapter {
    endpoint: Scalars['String']
    id: Scalars['ID']
    lastFailureSync: (PmsSyncJob | null)
    lastFailureSyncJobId: (Scalars['ID'] | null)
    lastSuccessSync: (PmsSyncJob | null)
    lastSuccessSyncJobId: (Scalars['ID'] | null)
    lastSyncJob: (PmsSyncJob | null)
    lastSyncJobId: (Scalars['ID'] | null)
    name: Scalars['String']
    organization: (Organization | null)
    organizationId: Scalars['ID']
    periodicSyncEnabled: Scalars['Boolean']
    settings: SecureKeyValue[]
    __typename: 'PmsAdapter'
}

export interface PmsAdapterTestResult {
    message: (Scalars['String'] | null)
    success: Scalars['Boolean']
    __typename: 'PmsAdapterTestResult'
}

export interface PmsSyncJob {
    adapterId: Scalars['ID']
    forceUpdate: Scalars['Boolean']
    /** @deprecated Use organization id instead */
    hoodId: Scalars['ID']
    id: Scalars['ID']
    items: PmsSyncJobItemList
    organizationId: Scalars['ID']
    results: (PmsSyncJobResult[] | null)
    startedAt: Scalars['Time']
    status: PmsSyncJobStatus
    updatedAt: Scalars['Time']
    __typename: 'PmsSyncJob'
}

export interface PmsSyncJobItem {
    entityData: Scalars['Any']
    entityId: Scalars['ID']
    entityType: JobItemEntityType
    errors: (PmsSyncMessage[] | null)
    id: Scalars['ID']
    jobId: Scalars['ID']
    status: JobItemStatus
    vennId: Scalars['ID']
    warnings: (PmsSyncMessage[] | null)
    __typename: 'PmsSyncJobItem'
}

export interface PmsSyncJobItemList {
    items: PmsSyncJobItem[]
    pageInfo: PageInfo
    __typename: 'PmsSyncJobItemList'
}

export interface PmsSyncJobResult {
    countPerStatus: JobItemStatusCount[]
    entityType: JobItemEntityType
    __typename: 'PmsSyncJobResult'
}

export type PmsSyncJobStatus = 'COMPLETED' | 'COMPLETED_WITH_ERRORS' | 'FAILED' | 'QUEUED' | 'RUNNING'

export interface PmsSyncJobsList {
    PageInfo: PageInfo
    items: PmsSyncJob[]
    __typename: 'PmsSyncJobsList'
}

export interface PmsSyncMessage {
    code: Scalars['String']
    message: Scalars['String']
    __typename: 'PmsSyncMessage'
}

export interface Portfolio {
    id: Scalars['ID']
    name: Scalars['String']
    subdomain: (Scalars['String'] | null)
    __typename: 'Portfolio'
}

export interface PortfolioContextFilter {
    buildingIds: (Scalars['ID'][] | null)
    hoodIds: (Scalars['ID'][] | null)
    portfolioId: (Scalars['ID'] | null)
    __typename: 'PortfolioContextFilter'
}

export interface PortfolioRoot {
    contextFilter: PortfolioContextFilter
    hoods: (HoodRoot[] | null)
    id: (Scalars['ID'] | null)
    leaseContracts: (LeaseContractsList | null)
    pmsSyncJobs: PmsSyncJobsList
    portfolioDetails: Portfolio
    reports: ReportsRoot
    /** @deprecated use root level query instead */
    residents: (ResidentsList | null)
    survey: Survey
    surveys: SurveysList
    __typename: 'PortfolioRoot'
}


/**  Deprecated: Use PaymentOption instead  */
export interface PriceByPaymentMethod {
    ach: PaymentDetails
    creditCard: PaymentDetails
    __typename: 'PriceByPaymentMethod'
}

export interface Query {
    amenities: (AmenitiesList | null)
    amenity: (Amenity | null)
    amenityCalendarUnavailabilities: (AmenityCalendarUnavailability[] | null)
    amenityCalendarUnavailability: (AmenityCalendarUnavailability | null)
    amenityReservation: (AmenityReservation | null)
    amenityReservations: (AmenityReservation[] | null)
    appDashboardLink: AppDashboardLink
    /** app dashboard links */
    appDashboardLinks: AppDashboardLink[]
    applicant: Applicant
    applicantConsentPreview: Scalars['String']
    applicantPublicData: ApplicantPublicData
    applicants: Applicant[]
    applicationSettings: ApplicationSettings
    automationDescriptor: AutomationDescriptor
    automationDescriptors: AutomationDescriptor[]
    automationRules: AutomationRule[]
    automationTriggerHistory: AutomationTriggerHistory[]
    awayNotice: AwayNotice
    awayNoticeSettings: (AwayNoticeSettings | null)
    awayNoticeSettingsList: AwayNoticeSettings[]
    awayNotices: AwayNotice[]
    brand: Brand
    brands: Brand[]
    broadcast: (Broadcast | null)
    broadcasts: (BroadcastsList | null)
    building: Building
    communities: Community[]
    /** Community represents a cluster of buildings that are under the same organization */
    community: Community
    deliveryPackage: (DeliveryPackage | null)
    deliveryPackages: DeliveryPackagesList
    emailDomain: EmailDomain
    emailDomainSender: EmailDomainSender
    emailDomainSenders: EmailDomainSender[]
    emailDomains: EmailDomain[]
    emailSendersForUser: EmailSenderForUser[]
    emailSettings: EmailSettings
    embeddedApplicationAdapter: EmbeddedApplicationAdapter
    embeddedApplicationAdapters: EmbeddedApplicationAdapter[]
    event: Event
    events: (EventsList | null)
    externalLockProviderGroup: externalLockProviderGroup
    externalLockProviderGroups: externalLockProviderGroupList
    featuredSlide: FeaturedSlide
    featuredSlides: (FeaturedSlideList | null)
    /** Get a general info page by id */
    generalInfoPage: (GeneralInfoPage | null)
    /** Get all general info pages */
    generalInfoPages: (GeneralInfoPageList | null)
    /** @deprecated use community instead */
    hood: HoodRoot
    /** Get interest group details */
    interestGroup: InterestGroup
    interestGroupInsightCategories: InterestGroupInsightCategory[]
    interestGroupInsightDiscussion: InterestGroupInsightDiscussion
    interestGroupInsightDiscussions: (InterestGroupInsightDiscussion[] | null)
    interestGroupInsightSummary: (InterestGroupInsightSummary[] | null)
    interestGroupLabels: InterestGroupLabel[]
    /** Get interest group members */
    interestGroupMembers: InterestGroupMembersList
    /** Get interest groups details */
    interestGroups: (InterestGroup[] | null)
    leaseContract: (LeaseContract | null)
    /** Lease contracts, contracts represent the relationship between a user and a unit in an organization. */
    leaseContracts: (LeaseContractsList | null)
    /** User query is a root node for all the current logged in user related queries */
    me: UserRoot
    moveInFunnel: MoveInFunnelRecordList
    moveInSettings: MoveInSettings[]
    notificationCategories: (NotificationCategory | null)[]
    /** @deprecated use residents.taskLists instead */
    orderedActiveUserTaskLists: (OrderedActiveUserTaskLists | null)[]
    /** Organization represents a property management company or a property owner */
    organization: Organization
    organizationLogoUrl: (Scalars['String'] | null)
    organizationMember: OrganizationMember
    organizationMembers: OrganizationMember[]
    /** Organizations represents a property management company or a property owner */
    organizations: Organization[]
    periodicSurveyReport: PeriodicSurveyReport
    periodicSurveySettings: (PeriodicSurveySettings | null)
    plaidVerifiedPreLinkAccountWithIncome: Scalars['Boolean']
    pmsAdapter: PmsAdapter
    pmsAdapters: PmsAdapter[]
    /**
     * @deprecated use syncJob instead
     * PMS Sync Job, job represents the synchronization status from a PMS into an organization.
     */
    pmsSyncJob: PmsSyncJob
    /**
     * @deprecated use syncJobs instead
     * PMS Sync Jobs, jobs represent the synchronization status from a PMS into an organization.
     */
    pmsSyncJobs: PmsSyncJobsList
    /** @deprecated use organization instead */
    portfolio: PortfolioRoot
    /** @deprecated No longer supported */
    portfolios: (Portfolio[] | null)
    reputation: Reputation
    reputations: Reputation[]
    /** @deprecated using via leaseContract instead */
    residentRenewalRiskScore: RenewalRiskScore
    /** Residents are users that hold a lease contract in an organization. */
    residents: ResidentsList
    /** @deprecated using via residents leaseContracts instead */
    residentsUpForRenewal: RenewalRiskScore[]
    /**
     * Search for events using keyword search, this will match events based on their:
     * name, building name and place name
     */
    searchEvents: (EventSearchResult | null)[]
    /** Search for interest groups using keyword search, this will match interest groups based on their name */
    searchInterestGroups: (InterestGroupSearchResult | null)[]
    /**
     * Search for residents using keyword search, this will match residents based on their:
     * First Name, Last Name, Email, Mobile Phone, Unit Name
     */
    searchResidents: (ResidentSearchResult | null)[]
    serviceRequestAdapters: ServiceRequestsAdapter[]
    serviceRequestAttachments: ServiceRequestAttachment[]
    serviceRequestSettings: ServiceRequestsSettings[]
    serviceRequests: ServiceRequestList
    serviceRequestsAdapter: ServiceRequestsAdapter
    /** @deprecated adapter is created with default status mapping */
    serviceRequestsAdapterDefaultStatusMapping: ServiceRequestStatusMapping[]
    serviceRequestsCategories: SupportCategory[]
    shiftNote: ShiftNote
    shiftNotes: ShiftNote[]
    smartLockAdapter: SmartLockAdapter
    smartLockAdapters: SmartLockAdapter[]
    staffTask: (StaffTask | null)
    staffTasks: StaffTask[]
    stripeConnectedAccounts: StripeConnectedAccount[]
    survey: Survey
    surveyQuestionResponseData: LeaseContractsList
    surveys: SurveysList
    surveysBatches: SurveyBatchesList
    /** Sync Job, job represents the synchronization status from a a third-party into an organization. */
    syncJob: SyncJob
    /** Sync Jobs, jobs represent the synchronization status of service requests from a third-party into an organization. */
    syncJobs: SyncJobsList
    task: Task
    taskList: TaskList
    taskListOverview: ((TaskListsOverviewResponse | null)[] | null)
    taskListTask: TaskListTask
    taskListTasks: (TaskListTask[] | null)
    taskLists: (TaskList[] | null)
    tasks: (Task[] | null)
    team: Team
    teamBuildingPermissions: TeamBuildingPermissions
    teamBuildingPermissionsList: TeamBuildingPermissions[]
    teams: Team[]
    unit: Unit
    /**
     * Get all units that are accepting applications
     * PUBLIC API
     */
    unitsAcceptingApplications: UnitPublicData[]
    /**
     * @deprecated invalid by permission model, use me or residents query instead
     * User in the system identified by email
     */
    user: User
    userTask: UserTask
    userTasks: ((UserTask | null)[] | null)
    visitor: Visitor
    visitors: (Visitor[] | null)
    visitorsSettingsList: VisitorsSettings[]
    whiteLabel: WhiteLabel
    whiteLabelConfig: (WhiteLabelConfig | null)
    whiteLabels: WhiteLabel[]
    __typename: 'Query'
}

export interface Rating {
    comment: (Scalars['String'] | null)
    score: (Scalars['Int'] | null)
    __typename: 'Rating'
}

export interface RealEstateAmenity {
    building: (Building | null)
    buildingId: (Scalars['String'] | null)
    category: RealEstateAmenityCategory
    createdAt: Scalars['Time']
    currentStatus: (RealEstateAmenityStatus | null)
    currentStatusDescription: (Scalars['String'] | null)
    deleted: Scalars['Int']
    deletedAt: (Scalars['Time'] | null)
    id: Scalars['ID']
    unit: (Unit | null)
    unitId: (Scalars['String'] | null)
    updatedAt: Scalars['Time']
    __typename: 'RealEstateAmenity'
}

export interface RealEstateAmenityCategory {
    displayName: Scalars['String']
    hood: (Hood | null)
    hoodId: Scalars['String']
    icon: (Scalars['String'] | null)
    id: Scalars['ID']
    isPublic: Scalars['Boolean']
    manageStatus: Scalars['Boolean']
    name: Scalars['String']
    __typename: 'RealEstateAmenityCategory'
}

export type RealEstateAmenityStatus = 'IN_MAINTENANCE' | 'ISSUE_REPORTED' | 'WORKING'

export interface RealEstateListing {
    address: (Address | null)
    amenities: RealEstateAmenity[]
    bedroom: (Bedroom | null)
    bedroomId: (Scalars['String'] | null)
    createdAt: Scalars['Time']
    deleted: Scalars['Int']
    deletedAt: (Scalars['Time'] | null)
    description: (Scalars['String'] | null)
    featuredListing: (Scalars['Boolean'] | null)
    floorPlan: (Scalars['Any'] | null)
    hood: (Hood | null)
    hoodName: (Scalars['String'] | null)
    id: Scalars['ID']
    images: (Scalars['Any'] | null)
    listingPrice: (Scalars['Float'] | null)
    name: Scalars['String']
    nearBySubways: ((SubwayStation | null)[] | null)
    promoBadgeTooltip: (Scalars['String'] | null)
    publishInListing: (Scalars['Boolean'] | null)
    relatedListings: (Scalars['Any'] | null)
    shortDescription: (Scalars['String'] | null)
    slug: Scalars['String']
    unit: (Unit | null)
    unitId: (Scalars['String'] | null)
    updatedAt: Scalars['Time']
    virtualTour: (Scalars['Any'] | null)
    __typename: 'RealEstateListing'
}

export type RecurrenceEndType = 'NEVER' | 'NUMBER_OF_OCCURRENCES' | 'SPECIFIC_DATE'

export type RefundMethod = 'CASH' | 'CHARGE_ON_UNIT' | 'CHECK' | 'ELECTRONIC'

export interface RenewalRiskScore {
    /** @deprecated prevent backward compatibility from residentsUpForRenewal and residentRenewalRiskScore queries using secondaryLeaseContracts instead */
    coOccupantsLeaseIds: (Scalars['ID'][] | null)
    /** @deprecated prevent backward compatibility from residentsUpForRenewal and residentRenewalRiskScore queries using secondaryLeaseContracts instead */
    coOccupantsLeases: (LeaseContract[] | null)
    createdAt: Scalars['Time']
    id: Scalars['ID']
    /** @deprecated prevent backward compatibility from residentsUpForRenewal and residentRenewalRiskScore queries */
    mainLeaseContract: LeaseContract
    /** @deprecated prevent backward compatibility from residentsUpForRenewal and residentRenewalRiskScore queries */
    mainLeaseContractId: Scalars['ID']
    medianResolutionTime: (Scalars['Float'] | null)
    repeatingRequestsCount: Scalars['Int']
    riskCategory: RiskCategory
    riskScore: Scalars['Float']
    secondaryLeaseContracts: (LeaseContract[] | null)
    serviceRequestsCount: Scalars['Int']
    serviceRequestsTopics: (Scalars['String'][] | null)
    updatedAt: Scalars['Time']
    __typename: 'RenewalRiskScore'
}

export interface RenewalSurveyAssignment {
    comment: (Scalars['String'][] | null)
    createdAt: Scalars['Time']
    id: Scalars['String']
    leaseContractEndDate: Scalars['Time']
    leaseContractId: Scalars['String']
    response: (RenewalSurveyAssignmentResponse | null)
    responseDate: (Scalars['Time'] | null)
    status: RenewalSurveyAssignmentStatus
    __typename: 'RenewalSurveyAssignment'
}

export type RenewalSurveyAssignmentResponse = 'INTERESTED_IN_TRANSFER' | 'NO' | 'STILL_DECIDING' | 'YES'

export type RenewalSurveyAssignmentStatus = 'ACTIVE' | 'CANCELLED' | 'COMPLETED' | 'SNOOZED'

export interface RenewalSurveySettings {
    daysBeforeLeaseEnd: Scalars['Int']
    id: Scalars['ID']
    isVisibleToUser: Scalars['Boolean']
    __typename: 'RenewalSurveySettings'
}

export type RentingType = 'BY_BEDROOM' | 'WHOLE_UNIT'

export interface ReportsRoot {
    contextFilter: PortfolioContextFilter
    moveInFunnel: MoveInFunnelRecordList
    __typename: 'ReportsRoot'
}

export interface Reputation {
    buildingsIds: Scalars['ID'][]
    id: Scalars['ID']
    organizationId: Scalars['String']
    reputationLink: Scalars['String']
    __typename: 'Reputation'
}

export interface ReservationCheckoutResponse {
    checkoutPageUrl: Scalars['String']
    __typename: 'ReservationCheckoutResponse'
}

export type ReservationQuotaFrequency = 'DAILY' | 'MONTHLY' | 'NONE' | 'WEEKLY'

export type ReservationQuotaType = 'NONE' | 'UNIT' | 'USER'

export interface Resident {
    amenityReservations: AmenityReservation[]
    email: Scalars['String']
    events: Event[]
    firstName: Scalars['String']
    lastName: Scalars['String']
    leaseContracts: LeaseContract[]
    metadata: (Scalars['Any'] | null)
    middleName: (Scalars['String'] | null)
    mobilePhone: (Scalars['String'] | null)
    /** @deprecated use events instead */
    myEvents: EventsList
    notifications: Notification[]
    residentSince: (Scalars['Time'] | null)
    serviceRequests: SupportTicket[]
    socialProfile: (SocialProfile | null)
    taskLists: UserTaskList[]
    user: User
    userId: Scalars['ID']
    userType: (UserType | null)
    __typename: 'Resident'
}

export interface ResidentEmbeddedApplicationDescriptor {
    authenticationMethod: EmbeddedApplicationAuthenticationMethod
    authenticationSettingsSAML2: (EmbeddedApplicationSaml2Settings | null)
    callbackHandlingMethod: EmbeddedApplicationCallbackHandlingMethod
    callbackHandlingSettingsWindowEvent: (EmbeddedApplicationWindowEventSettings | null)
    iframeTitle: Scalars['String']
    iframeUrl: Scalars['String']
    /** @deprecated Use iframe url instead */
    url: Scalars['String']
    __typename: 'ResidentEmbeddedApplicationDescriptor'
}

export interface ResidentEmbeddedApplications {
    embeddedAppID: Scalars['ID']
    endpoint: Scalars['String']
    leaseContracts: Scalars['ID'][]
    name: Scalars['String']
    type: EmbeddedApplicationType
    __typename: 'ResidentEmbeddedApplications'
}

export type ResidentRiskSortField = 'LEASE_END_DATE_ASC' | 'LEASE_END_DATE_DESC' | 'LEASE_RENT_ASC' | 'LEASE_RENT_DESC' | 'RISK_CATEGORY_ASC' | 'RISK_CATEGORY_DESC' | 'TIME_TO_RESOLVE_ASC' | 'TIME_TO_RESOLVE_DESC'


/**
 * Resident search result will return a user per lease contract with the unit name.
 * If a user has multiple lease contracts, they will be returned multiple times.
 */
export interface ResidentSearchResult {
    email: Scalars['String']
    firstName: Scalars['String']
    lastName: Scalars['String']
    mobilePhone: (Scalars['String'] | null)
    unitName: Scalars['String']
    userId: Scalars['ID']
    __typename: 'ResidentSearchResult'
}

export interface ResidentsList {
    items: Resident[]
    pageInfo: PageInfo
    __typename: 'ResidentsList'
}

export type RiskCategory = 'HIGH' | 'LOW' | 'MEDIUM'

export interface Role {
    hood: (Hood | null)
    id: Scalars['ID']
    name: Scalars['String']
    __typename: 'Role'
}

export type RuleType = 'DATE_REACHED' | 'FIELD_CHANGED' | 'TIME_REACHED'

export type SatisfactionScore = 'BAD' | 'GOOD'

export interface ScheduledBroadcast {
    endDate: (Scalars['Time'] | null)
    id: Scalars['ID']
    nextNotificationDate: (Scalars['Time'] | null)
    scheduledRuleCron: (Scalars['String'] | null)
    startDate: (Scalars['Time'] | null)
    __typename: 'ScheduledBroadcast'
}

export type ScreeningCheckStatus = 'EMPTY' | 'ERRORED' | 'FINISHED' | 'IN_PROGRESS' | 'PENDING' | 'SKIPPED'

export type ScreeningStatus = 'COMPLETED' | 'IN_PROGRESS' | 'PENDING'

export type SectionType = 'FEEDBACK_ITEM' | 'MAIN_UTILITIES' | 'USEFUL_LINKS'


/**
 * Used to pass a key value pair with a flag to indicate if the value is secure or not
 * if the value is secure, it will be encrypted by the BE.
 */
export interface SecureKeyValue {
    isSecure: Scalars['Boolean']
    key: Scalars['String']
    value: Scalars['String']
    __typename: 'SecureKeyValue'
}

export interface Service {
    description: Scalars['String']
    id: Scalars['ID']
    isTermsAndConditionsEnabled: (Scalars['Boolean'] | null)
    name: Scalars['String']
    providerName: Scalars['String']
    serviceProducts: (ServiceProduct[] | null)
    termsAndConditions: (Scalars['String'] | null)
    __typename: 'Service'
}

export interface ServiceProduct {
    actualPrice: (Scalars['Float'] | null)
    calendar: (Calendar | null)
    currentUserSubscriptionContractId: (Scalars['ID'] | null)
    description: (Scalars['String'] | null)
    discountedPrice: (Scalars['Float'] | null)
    id: Scalars['ID']
    imageResourceId: Scalars['String']
    name: Scalars['String']
    pickupLocation: (Place | null)
    price: Scalars['Float']
    priceUnit: (Scalars['String'] | null)
    service: Service
    serviceProductType: ServiceProductType
    subProductId: Scalars['ID']
    taxAmount: (Scalars['Float'] | null)
    taxRate: (TaxRate | null)
    __typename: 'ServiceProduct'
}

export interface ServiceProductPurchaseResult {
    clientSecret: (Scalars['String'] | null)
    isSucceeded: Scalars['Boolean']
    __typename: 'ServiceProductPurchaseResult'
}

export type ServiceProductType = 'ON_DEMAND' | 'RECURRING'

export interface ServiceRequestAttachment {
    URL: Scalars['String']
    contentType: (Scalars['String'] | null)
    fileName: Scalars['String']
    __typename: 'ServiceRequestAttachment'
}

export interface ServiceRequestComment {
    attachments: ServiceRequestAttachment[]
    author: (User | null)
    authorId: (Scalars['ID'] | null)
    content: (Scalars['String'] | null)
    id: Scalars['ID']
    postedAt: Scalars['Time']
    __typename: 'ServiceRequestComment'
}

export interface ServiceRequestList {
    items: ServiceRequestTicket[]
    pageInfo: PageInfo
    __typename: 'ServiceRequestList'
}

export interface ServiceRequestStatusMapping {
    externalStatus: Scalars['String']
    vennStatus: (Scalars['String'] | null)
    __typename: 'ServiceRequestStatusMapping'
}

export interface ServiceRequestTicket {
    building: (Building | null)
    buildingId: Scalars['ID']
    category: SupportCategory
    categoryId: Scalars['ID']
    closedAt: (Scalars['Time'] | null)
    comments: ServiceRequestComment[]
    description: Scalars['String']
    entryNote: (Scalars['String'] | null)
    externalId: (Scalars['ID'] | null)
    externalStatus: (Scalars['String'] | null)
    forLeaseContract: (LeaseContract | null)
    forLeaseContractId: (Scalars['ID'] | null)
    forUnit: (Unit | null)
    forUnitId: (Scalars['ID'] | null)
    hasPet: Scalars['Boolean']
    havesDownloadableAttachments: (Scalars['Boolean'] | null)
    id: Scalars['ID']
    openedAt: (Scalars['Time'] | null)
    openingManager: (ServiceRequestTicketOpeningManagerDetails | null)
    openingManagerId: (Scalars['ID'] | null)
    permissionToEnterRoom: Scalars['Boolean']
    source: SupportTicketSource
    status: SupportTicketStatus
    subject: Scalars['String']
    target: ServiceRequestedTarget
    technicianNotes: (Scalars['String'] | null)
    timeToResolutionInMinutes: (Scalars['Int'] | null)
    __typename: 'ServiceRequestTicket'
}

export interface ServiceRequestTicketOpeningManagerDetails {
    email: Scalars['String']
    fullName: Scalars['String']
    id: Scalars['ID']
    __typename: 'ServiceRequestTicketOpeningManagerDetails'
}

export type ServiceRequestTicketStatus = 'CANCELLED' | 'CLOSED' | 'COMPLETED' | 'ESCALATED' | 'INAPPLICABLE' | 'IN_PROGRESS' | 'ON_HOLD' | 'OPEN' | 'PENDING' | 'SEEN' | 'SOLVED'

export type ServiceRequestedTarget = 'BUILDING' | 'LEASE' | 'UNIT'

export interface ServiceRequestsAdapter {
    endpoint: Scalars['String']
    id: Scalars['ID']
    isPeriodicSyncEnabled: Scalars['Boolean']
    lastFailureSync: (SyncJob | null)
    lastFailureSyncJobId: (Scalars['ID'] | null)
    lastSuccessSync: (SyncJob | null)
    lastSuccessSyncJobId: (Scalars['ID'] | null)
    lastSyncJob: (SyncJob | null)
    lastSyncJobId: (Scalars['ID'] | null)
    name: Scalars['String']
    organization: (Organization | null)
    organizationId: Scalars['ID']
    settings: SecureKeyValue[]
    statusMapping: (ServiceRequestStatusMapping[] | null)
    __typename: 'ServiceRequestsAdapter'
}

export interface ServiceRequestsAdapterTestResult {
    message: (Scalars['String'] | null)
    success: Scalars['Boolean']
    __typename: 'ServiceRequestsAdapterTestResult'
}

export interface ServiceRequestsSettings {
    allowCancellingRequests: Scalars['Boolean']
    allowEditingRequests: Scalars['Boolean']
    allowedCommentingStatuses: (Scalars['String'][] | null)
    areDuplicateRequestsAllowed: Scalars['Boolean']
    areEntryNotesRequired: Scalars['Boolean']
    buildingId: (Scalars['String'] | null)
    emergencyText: (Scalars['String'] | null)
    hideArchivedRequests: Scalars['Boolean']
    isCommentingEnabled: Scalars['Boolean']
    isDescriptionRequired: Scalars['Boolean']
    isEnabled: Scalars['Boolean']
    isHubDashboardEnabled: Scalars['Boolean']
    isSubjectRequired: Scalars['Boolean']
    organizationId: Scalars['String']
    serviceRequestsCategoriesLevelNames: (Scalars['String'][] | null)
    shouldDisplayCommentingChatInput: Scalars['Boolean']
    shouldDisplayCovidQuestions: Scalars['Boolean']
    shouldDisplayEntryNotes: Scalars['Boolean']
    shouldDisplayHubDashboardActions: Scalars['Boolean']
    shouldDisplaySubject: Scalars['Boolean']
    shouldSendEmailOnCreation: Scalars['Boolean']
    supportEmail: (Scalars['String'] | null)
    supportPhoneNumber: (Scalars['String'] | null)
    supportedTicketAttachmentsTypes: (TicketAttachmentType[] | null)
    surveyId: (Scalars['String'] | null)
    ticketStatusesNotificationEnabled: (Scalars['String'][] | null)
    ticketStatusesNotificationTemplateMapping: (ServiceRequestsSettingsTicketStatusesNotificationTemplateMapping[] | null)
    __typename: 'ServiceRequestsSettings'
}

export interface ServiceRequestsSettingsTicketStatusesNotificationTemplateMapping {
    notificationContent: supportTicketNotificationText
    status: SupportTicketStatus
    __typename: 'ServiceRequestsSettingsTicketStatusesNotificationTemplateMapping'
}

export interface ServiceRequestsUploadUrlResponse {
    URL: Scalars['String']
    key: Scalars['String']
    __typename: 'ServiceRequestsUploadUrlResponse'
}

export interface ShiftNote {
    building: Building
    buildingId: Scalars['ID']
    createdAt: Scalars['Time']
    createdBy: User
    createdById: Scalars['ID']
    createdByName: Scalars['String']
    id: Scalars['ID']
    note: Scalars['String']
    status: ShiftNoteStatus
    __typename: 'ShiftNote'
}

export type ShiftNoteOrderBy = 'CREATED_AT_ASC' | 'CREATED_AT_DESC'

export type ShiftNoteStatus = 'CLOSED' | 'OPEN'

export interface SmartLockAdapter {
    AuthenticationMethod: (AuthenticationMethod | null)
    IsGuestAccessEnabled: (Scalars['Boolean'] | null)
    IsMasterKeyEnabled: (Scalars['Boolean'] | null)
    OauthSettings: (OauthSettings | null)
    buildingIds: Scalars['ID'][]
    buildings: Building[]
    endpoint: Scalars['String']
    grantAccessByMoveDates: Scalars['Boolean']
    grantAccessContractStatuses: ContractStatus[]
    id: Scalars['ID']
    name: Scalars['String']
    organizationId: Scalars['ID']
    settings: SecureKeyValue[]
    __typename: 'SmartLockAdapter'
}

export interface SmsLinkData {
    body: Scalars['String']
    phoneNumber: Scalars['String']
    __typename: 'SmsLinkData'
}


/**
 *  This entity represents interaction between users in the app like:
 * sending good vibes
 */
export interface SocialInteraction {
    fromSocialProfile: SocialProfile
    id: Scalars['ID']
    toSocialProfile: SocialProfile
    type: (SocialInteractionType | null)
    viewed: Scalars['Boolean']
    __typename: 'SocialInteraction'
}


/** Social interaction types */
export type SocialInteractionType = 'GOOD_VIBES'

export interface SocialProfile {
    address: (SocialProfileField | null)
    biography: (SocialProfileField | null)
    blockStatus: (SocialProfileBlockStatus | null)
    byline: (Byline | null)
    callToAction: (SocialProfileCallToActionField | null)
    completionPercentage: (Scalars['Int'] | null)
    createdAt: (Scalars['Time'] | null)
    dateOfBirth: (SocialProfileField | null)
    firstName: (Scalars['String'] | null)
    id: Scalars['ID']
    imageId: (Scalars['String'] | null)
    imageUrl: (Scalars['String'] | null)
    interestGroups: (InterestGroupsList | null)
    lastName: (Scalars['String'] | null)
    occupation: (SocialProfileField | null)
    pets: (SocialProfilePets | null)
    pronouns: (SocialProfilePronounsField | null)
    quote: (SocialProfileField | null)
    socialLinks: (SocialProfileSocialLinksField | null)
    socialTags: (SocialProfileArrayField | null)
    superpowers: (SocialProfileField | null)
    userId: Scalars['ID']
    website: (SocialProfileField | null)
    __typename: 'SocialProfile'
}

export interface SocialProfileArrayField {
    privacy: (SocialProfilePrivacy | null)
    value: ((Scalars['String'] | null)[] | null)
    __typename: 'SocialProfileArrayField'
}

export type SocialProfileBlockStatus = 'BLOCKED_BY_CURRENT_USER' | 'BLOCKED_BY_PROFILE_OWNER' | 'MUTUAL_BLOCK' | 'NOT_BLOCKED'

export interface SocialProfileCallToActionField {
    privacy: SocialProfilePrivacy
    target: (Scalars['String'] | null)
    type: (SocialProfileCallToActionType | null)
    __typename: 'SocialProfileCallToActionField'
}

export type SocialProfileCallToActionType = 'SMS'

export interface SocialProfileField {
    privacy: (SocialProfilePrivacy | null)
    value: (Scalars['String'] | null)
    __typename: 'SocialProfileField'
}

export interface SocialProfilePets {
    pets: (Pet[] | null)
    privacy: (SocialProfilePrivacy | null)
    __typename: 'SocialProfilePets'
}

export type SocialProfilePrivacy = 'PRIVATE' | 'PUBLIC'

export type SocialProfilePronouns = 'HE_HIM_HIS' | 'OTHER' | 'SHE_HER_HERS' | 'THEY_THEM_THEIR'

export interface SocialProfilePronounsField {
    customPronouns: (Scalars['String'] | null)
    privacy: (SocialProfilePrivacy | null)
    value: (SocialProfilePronouns | null)
    __typename: 'SocialProfilePronounsField'
}

export interface SocialProfileSocialLinksField {
    facebook: (SocialProfileField | null)
    instagram: (SocialProfileField | null)
    linkedin: (SocialProfileField | null)
    youtube: (SocialProfileField | null)
    __typename: 'SocialProfileSocialLinksField'
}

export interface SocialProfilesList {
    items: SocialProfile[]
    pageInfo: PageInfo
    __typename: 'SocialProfilesList'
}

export interface SpecificUsersAudienceParams {
    userIds: (Scalars['ID'][] | null)
    __typename: 'SpecificUsersAudienceParams'
}

export interface StaffTask {
    assignees: (StaffTaskAssignee[] | null)
    buildingIds: Scalars['ID'][]
    createdAt: Scalars['Time']
    deadline: (Scalars['Time'] | null)
    description: (Scalars['String'] | null)
    id: Scalars['ID']
    name: Scalars['String']
    organizationId: Scalars['ID']
    priority: (Scalars['String'] | null)
    status: (Scalars['String'] | null)
    updatedAt: (Scalars['Time'] | null)
    __typename: 'StaffTask'
}

export interface StaffTaskAssignee {
    user: User
    userId: Scalars['ID']
    __typename: 'StaffTaskAssignee'
}

export interface StripeCheckoutSession {
    url: Scalars['String']
    __typename: 'StripeCheckoutSession'
}

export interface StripeConnectedAccount {
    achFeePercent: (Scalars['Float'] | null)
    chargesEnabled: Scalars['Boolean']
    creditCardFeePercent: (Scalars['Float'] | null)
    email: Scalars['String']
    externalId: Scalars['String']
    id: Scalars['ID']
    name: Scalars['String']
    organizationId: Scalars['ID']
    payoutsEnabled: Scalars['Boolean']
    requirementsMissing: Scalars['Boolean']
    __typename: 'StripeConnectedAccount'
}

export interface StripeConnectedAccountSession {
    clientSecret: Scalars['String']
    __typename: 'StripeConnectedAccountSession'
}

export interface SubscriptionContract {
    id: Scalars['ID']
    __typename: 'SubscriptionContract'
}

export interface SubwayStation {
    line: (Scalars['String'] | null)
    __typename: 'SubwayStation'
}

export type SummaryStatus = 'ERRORED' | 'FAILED' | 'IN_PROGRESS' | 'PASSED' | 'PENDING' | 'SKIPPED'


/** Support ticket category */
export interface SupportCategory {
    buildingId: (Scalars['ID'] | null)
    clientVisibleName: Scalars['String']
    displayHasPet: (Scalars['Boolean'] | null)
    displayPermissionToEnterRoom: (Scalars['Boolean'] | null)
    id: Scalars['ID']
    isArchived: Scalars['Boolean']
    name: (Scalars['String'] | null)
    parent: (SupportCategory | null)
    parentId: (Scalars['ID'] | null)
    problemValues: (Scalars['String'][] | null)
    published: (Scalars['Boolean'] | null)
    requiresScheduling: (Scalars['Boolean'] | null)
    shouldIncludeRoommates: (Scalars['Boolean'] | null)
    type: (Scalars['String'] | null)
    __typename: 'SupportCategory'
}

export type SupportDay = 'FRIDAY' | 'MONDAY' | 'SUNDAY' | 'THURSDAY' | 'TUESDAY' | 'WEDNESDAY'

export interface SupportSatisfactionRating {
    comment: (Scalars['String'] | null)
    id: Scalars['ID']
    score: SatisfactionScore
    __typename: 'SupportSatisfactionRating'
}

export interface SupportTicket {
    category: SupportCategory
    categoryId: Scalars['ID']
    closedAt: (Scalars['Time'] | null)
    comments: ServiceRequestComment[]
    description: Scalars['String']
    entryNote: (Scalars['String'] | null)
    externalId: (Scalars['ID'] | null)
    externalStatus: (Scalars['String'] | null)
    hasAttachments: Scalars['Boolean']
    hasPet: Scalars['Boolean']
    havesDownloadableAttachments: (Scalars['Boolean'] | null)
    id: Scalars['ID']
    lastUpdated: Scalars['Time']
    leaseContract: LeaseContract
    leaseContractId: Scalars['ID']
    openedAt: (Scalars['Time'] | null)
    openingManager: (ServiceRequestTicketOpeningManagerDetails | null)
    openingManagerId: (Scalars['ID'] | null)
    permissionToEnterRoom: Scalars['Boolean']
    source: SupportTicketSource
    status: SupportTicketStatus
    subject: Scalars['String']
    surveyAssignment: (SurveyAssignment | null)
    technicianNotes: (Scalars['String'] | null)
    timeToResolutionInMinutes: (Scalars['Int'] | null)
    __typename: 'SupportTicket'
}

export interface SupportTicketComment {
    attachmentUrls: (Scalars['String'][] | null)
    body: Scalars['String']
    id: Scalars['ID']
    userId: Scalars['ID']
    __typename: 'SupportTicketComment'
}

export type SupportTicketSource = 'APP' | 'HUB' | 'THIRD_PARTY'


/** Support ticket status options */
export type SupportTicketStatus = 'CANCELLED' | 'CLOSED' | 'COMPLETED' | 'ESCALATED' | 'INAPPLICABLE' | 'IN_PROGRESS' | 'ON_HOLD' | 'OPEN' | 'PENDING' | 'SEEN' | 'SOLVED'

export interface SupportTicketUpdatesSubscription {
    status: (SupportTicketStatus | null)
    supportTicketId: Scalars['ID']
    __typename: 'SupportTicketUpdatesSubscription'
}

export type SupportTime = 'AFTERNOON' | 'MORNING'


/**
 *  The Survey entity
 * Used a survey outside the app and when creating a discussion with a poll
 */
export interface Survey {
    assignmentExpirationDays: Scalars['Int']
    description: Scalars['String']
    id: Scalars['ID']
    isEnabled: Scalars['Boolean']
    isPublic: Scalars['Boolean']
    name: Scalars['String']
    priority: Scalars['Int']
    /**  connections  */
    questions: (SurveyQuestion[] | null)
    snoozeMaxAmount: (Scalars['Int'] | null)
    snoozeTTLDays: (Scalars['Int'] | null)
    title: (Scalars['String'] | null)
    type: SurveyType
    __typename: 'Survey'
}

export interface SurveyAssignment {
    batchId: (Scalars['String'] | null)
    expirationDate: Scalars['Time']
    id: Scalars['String']
    relatedEntityId: (Scalars['String'] | null)
    relatedEntityType: (SurveyAssignmentRelatedEntityType | null)
    relatedLeaseContract: (LeaseContract | null)
    relatedSupportTicket: (SupportTicket | null)
    snoozeCount: Scalars['Int']
    snoozeLastDate: (Scalars['Time'] | null)
    status: SurveyAssignmentStatus
    survey: Survey
    surveyId: Scalars['String']
    user: User
    userId: Scalars['String']
    __typename: 'SurveyAssignment'
}

export type SurveyAssignmentRelatedEntityType = 'LEASE_CONTRACT' | 'SUPPORT_TICKET'

export type SurveyAssignmentStatus = 'DONE' | 'PENDING' | 'VIEWED'


/** The survey batch entity */
export interface SurveyBatch {
    id: Scalars['ID']
    name: Scalars['String']
    survey: Survey
    __typename: 'SurveyBatch'
}

export interface SurveyBatchesList {
    items: SurveyBatch[]
    __typename: 'SurveyBatchesList'
}

export interface SurveyChoiceOption {
    Order: Scalars['Int']
    Value: Scalars['String']
    text: Scalars['String']
    __typename: 'SurveyChoiceOption'
}


/** The survey question entity */
export interface SurveyQuestion {
    allowComments: (Scalars['Boolean'] | null)
    choiceOptions: (SurveyChoiceOption[] | null)
    id: Scalars['ID']
    order: Scalars['Int']
    scaleOptions: (SurveyScaleOption | null)
    survey: Survey
    text: Scalars['String']
    type: SurveyQuestionType
    __typename: 'SurveyQuestion'
}

export type SurveyQuestionType = 'CHOICE' | 'FREE_TEXT' | 'SCALE'

export interface SurveyScaleOption {
    max: Scalars['Int']
    maxText: (Scalars['String'] | null)
    min: Scalars['Int']
    minText: Scalars['String']
    steps: Scalars['Int']
    __typename: 'SurveyScaleOption'
}

export type SurveyType = 'CSAT_QUARTERLY' | 'CSAT_SERVICE_REQUEST'

export interface SurveysList {
    items: Survey[]
    pageInfo: PageInfo
    __typename: 'SurveysList'
}

export interface SyncJob {
    adapterId: Scalars['ID']
    exportStatus: SyncJobExportStatus
    forceUpdate: Scalars['Boolean']
    id: Scalars['ID']
    items: SyncJobItemList
    organizationId: Scalars['ID']
    results: (SyncJobResult[] | null)
    startedAt: Scalars['Time']
    status: SyncJobStatus
    type: Scalars['String']
    updatedAt: Scalars['Time']
    __typename: 'SyncJob'
}

export interface SyncJobDownloadExportResult {
    url: Scalars['String']
    __typename: 'SyncJobDownloadExportResult'
}

export type SyncJobExportStatus = 'FAILED' | 'NOT_READY' | 'PROCESSING' | 'READY'

export interface SyncJobItem {
    entityData: Scalars['Any']
    entityId: Scalars['ID']
    entityType: Scalars['String']
    errors: (SyncMessage[] | null)
    /** @deprecated item no long have an id, stop using it, for now we return a hash concat of the entity id, type and job id */
    id: Scalars['ID']
    jobId: Scalars['ID']
    status: SyncJobItemStatus
    vennId: Scalars['ID']
    warnings: (SyncMessage[] | null)
    __typename: 'SyncJobItem'
}

export interface SyncJobItemList {
    items: SyncJobItem[]
    pageInfo: PageInfo
    __typename: 'SyncJobItemList'
}

export type SyncJobItemStatus = 'COMPLETED' | 'DISABLED' | 'FAILED' | 'QUEUED'

export interface SyncJobResult {
    countPerStatus: JobItemStatusCount[]
    entityType: JobItemEntityType
    __typename: 'SyncJobResult'
}

export type SyncJobStatus = 'COMPLETED' | 'COMPLETED_WITH_ERRORS' | 'FAILED' | 'QUEUED' | 'RUNNING'

export type SyncJobType = 'SyncJobTypePMS' | 'SyncJobTypeServiceRequest'

export interface SyncJobsList {
    PageInfo: PageInfo
    items: SyncJob[]
    __typename: 'SyncJobsList'
}

export interface SyncMessage {
    code: Scalars['String']
    message: Scalars['String']
    __typename: 'SyncMessage'
}

export interface Task {
    attachmentsBehaviour: (AttachmentsBehavior | null)
    completedCtaText: Scalars['String']
    createdAt: Scalars['Time']
    displayText: Scalars['String']
    embeddedApp: (EmbeddedApplicationAdapter | null)
    embeddedAppId: (Scalars['String'] | null)
    iconName: Scalars['String']
    id: Scalars['String']
    name: Scalars['String']
    organizationId: Scalars['String']
    requiresApproval: (Scalars['Boolean'] | null)
    template: TaskTemplate
    templateCss: Scalars['String']
    templateHtml: Scalars['String']
    type: (TaskType | null)
    uncompletedCtaText: Scalars['String']
    updatedAt: (Scalars['Time'] | null)
    __typename: 'Task'
}

export interface TaskList {
    buildings: (Building[] | null)
    fullTermsURL: (Scalars['String'] | null)
    hoursUntilDue: (Scalars['Int'] | null)
    id: Scalars['ID']
    isConnectedToUsers: Scalars['Boolean']
    /** @deprecated No longer supported */
    isDefault: (Scalars['Boolean'] | null)
    name: Scalars['String']
    organizationId: Scalars['ID']
    requireApproval: (Scalars['Boolean'] | null)
    shortTerms: (Scalars['String'] | null)
    task: TaskListTask
    tasks: (TaskListTask[] | null)
    type: (TaskListType | null)
    __typename: 'TaskList'
}

export interface TaskListTask {
    id: Scalars['ID']
    isOptional: Scalars['Boolean']
    order: Scalars['Int']
    organizationId: Scalars['ID']
    tMinusDays: (Scalars['Int'] | null)
    task: Task
    taskId: Scalars['ID']
    taskList: TaskList
    taskListId: Scalars['ID']
    __typename: 'TaskListTask'
}

export type TaskListType = 'INSPECTION' | 'MOVE_IN'

export interface TaskListsOverviewResponse {
    inspectionTaskList: (UserTaskList | null)
    leaseContract: LeaseContract
    moveInTaskList: (UserTaskList | null)
    __typename: 'TaskListsOverviewResponse'
}

export type TaskTemplate = 'EMBEDDED_APP' | 'FREE_HTML'

export type TaskType = 'INSPECTION' | 'MOVE_IN'

export interface TaxRate {
    externalTaxRateId: (Scalars['String'] | null)
    id: Scalars['ID']
    value: Scalars['Float']
    __typename: 'TaxRate'
}

export interface Team {
    buildingsCount: Scalars['Int']
    communities: Community[]
    communitiesCount: Scalars['Int']
    id: Scalars['ID']
    members: OrganizationMember[]
    membersCount: Scalars['Int']
    name: Scalars['String']
    organizationId: Scalars['ID']
    __typename: 'Team'
}

export interface TeamBuildingPermissions {
    building: Building
    buildingId: Scalars['ID']
    permissions: Permission[]
    teamId: Scalars['ID']
    __typename: 'TeamBuildingPermissions'
}

export type TermsAndConditionsStatus = 'ACCEPTED' | 'NOT_ACCEPTED'

export interface Thermostat {
    availableDeviceModes: DeviceMode[]
    availableFanModes: FanMode[]
    buildingId: Scalars['String']
    buildingMode: BuildingMode
    currentTemperature: Scalars['Float']
    deviceMode: DeviceMode
    fanMode: FanMode
    id: Scalars['ID']
    targetTemperature: Scalars['Float']
    unit: (Unit | null)
    unitId: Scalars['String']
    __typename: 'Thermostat'
}

export type TicketAttachmentType = 'DOCUMENT' | 'IMAGE' | 'VIDEO'

export type Timeline = 'CURRENT' | 'PAST' | 'UPCOMING'

export interface TriggerTaskListResponse {
    id: Scalars['ID']
    __typename: 'TriggerTaskListResponse'
}

export interface Unit {
    building: Building
    buildingId: Scalars['ID']
    /** The external ID of the unit coming from the PMS */
    externalId: (Scalars['ID'] | null)
    id: Scalars['ID']
    isAcceptingApplications: Scalars['Boolean']
    name: Scalars['String']
    /**
     * Status indicates if the unit is enabled or disabled
     * disabled units will not be available for venn services
     * their residents will not have application access and will not be included in the reports or any other tools
     */
    status: UnitStatus
    /** @deprecated use name instead */
    unitName: Scalars['String']
    updatedAt: (Scalars['Time'] | null)
    __typename: 'Unit'
}

export interface UnitPublicData {
    buildingId: Scalars['ID']
    buildingName: Scalars['String']
    id: Scalars['ID']
    name: Scalars['String']
    __typename: 'UnitPublicData'
}

export type UnitStatus = 'DISABLED' | 'ENABLED'

export type UnitType = 'DUPLEX' | 'STUDIO'

export interface User {
    appOnboardingStatus: AppOnboardingStatus
    /** @deprecated use residents query instead */
    building: (Building | null)
    /** @deprecated use residents query instead */
    buildingId: (Scalars['ID'] | null)
    email: Scalars['String']
    /** @deprecated use residents query instead */
    events: EventsList
    firstName: (Scalars['String'] | null)
    /** @deprecated use managedCommunities instead */
    hoodsAssigned: Hood[]
    hubMetadata: (HubMetadata | null)
    id: Scalars['ID']
    isSystemAdmin: Scalars['Boolean']
    lastName: (Scalars['String'] | null)
    /** @deprecated use residents query instead */
    leaseContracts: (LeaseContract | null)[]
    managedCommunities: Community[]
    metadata: (Scalars['Any'] | null)
    middleName: (Scalars['String'] | null)
    mobileLastVisit: (Scalars['Time'] | null)
    mobilePhone: (Scalars['String'] | null)
    organizationMemberships: OrganizationMember[]
    permittedBuildings: PermittedBuilding[]
    profilePictureUrl: (Scalars['String'] | null)
    /** @deprecated use residents query instead */
    resident: (Resident | null)
    /** @deprecated use residents query instead */
    socialProfile: (SocialProfile | null)
    /** @deprecated use residents query instead */
    unit: (Unit | null)
    /** @deprecated use residents query instead */
    unitId: (Scalars['ID'] | null)
    userType: (UserType | null)
    __typename: 'User'
}

export interface UserAggregations {
    unopenedNotifications: Scalars['Int']
    __typename: 'UserAggregations'
}


/**
 * The token entity
 * Used to return an auth token to a user with an API key
 */
export interface UserLoginApiKeyResponse {
    token: Scalars['String']
    __typename: 'UserLoginApiKeyResponse'
}

export type UserRole = 'OWNER'

export interface UserRoot {
    amenities: (AmenitiesList | null)
    amenity: (Amenity | null)
    appSettings: AppSettings
    awayNotice: AwayNotice
    awayNotices: (AwayNotice[] | null)
    deliveryPackages: DeliveryPackagesList
    event: (Event | null)
    events: (Event[] | null)
    featureFlags: (FeatureFlag[] | null)
    /** @deprecated use user.managedCommunities instead */
    hood: Hood
    /** @deprecated use user.managedCommunities instead */
    hoodsAssigned: Hood[]
    leaseContracts: LeaseContract[]
    myAttendedEvents: (EventAttendance[] | null)
    myEventRating: (Scalars['Int'] | null)
    notification: (Notification | null)
    notificationSnoozes: (NotificationSnooze[] | null)
    notifications: (NotificationsList | null)
    /** @deprecated No longer supported */
    orderedActiveUserTaskLists: (OrderedActiveUserTaskLists | null)[]
    reservation: (AmenityReservation | null)
    reservations: (AmenityReservation[] | null)
    serviceRequest: SupportTicket
    serviceRequestAttachmentUploadUrl: ServiceRequestsUploadUrlResponse
    smartHome: UserSmartHomeRoot
    supportCategories: (SupportCategory[] | null)
    supportTickets: ((SupportTicket | null)[] | null)
    surveyAssignments: (SurveyAssignment[] | null)
    surveyAssignmentsToDo: (SurveyAssignment[] | null)
    thermostats: (Thermostat[] | null)
    user: User
    userId: (Scalars['ID'] | null)
    userTask: UserTask
    visitor: Visitor
    visitors: (Visitor[] | null)
    __typename: 'UserRoot'
}

export interface UserSmartHomeRoot {
    /** @deprecated use MyLockProvidersInitializeAccess instead */
    lockCredentials: (LockCredentials | null)
    lockProviderKeys: LockProviderKey[]
    __typename: 'UserSmartHomeRoot'
}

export interface UserTask {
    dueDate: Scalars['Time']
    id: Scalars['ID']
    pmNotes: (Scalars['String'] | null)
    responses: ((UserTaskResponse | null)[] | null)
    status: UserTaskStatus
    taskListTask: TaskListTask
    taskListTaskId: Scalars['ID']
    user: User
    userId: Scalars['ID']
    userTaskList: UserTaskList
    userTaskListId: Scalars['ID']
    __typename: 'UserTask'
}

export interface UserTaskAttachment {
    URL: Scalars['String']
    contentType: (Scalars['String'] | null)
    key: Scalars['String']
    __typename: 'UserTaskAttachment'
}

export interface UserTaskList {
    approvedAt: (Scalars['Time'] | null)
    approvedBy: (User | null)
    approvedById: (Scalars['String'] | null)
    dueDate: Scalars['Time']
    id: Scalars['ID']
    isActive: Scalars['Boolean']
    leaseContract: (LeaseContract | null)
    leaseContractId: (Scalars['ID'] | null)
    organizationId: Scalars['ID']
    status: UserTaskListStatus
    submittedAt: (Scalars['Time'] | null)
    task: UserTask
    taskList: TaskList
    taskListId: Scalars['ID']
    tasks: UserTask[]
    __typename: 'UserTaskList'
}

export type UserTaskListStatus = 'COMPLETED' | 'IN_PROGRESS' | 'NOT_STARTED' | 'PENDING_REVIEW' | 'PICKUP_KEY'

export interface UserTaskListSubmitResponse {
    id: Scalars['ID']
    userTaskListStatus: UserTaskListStatus
    __typename: 'UserTaskListSubmitResponse'
}

export interface UserTaskListUpdateResponse {
    id: Scalars['ID']
    status: (UserTaskListStatus | null)
    __typename: 'UserTaskListUpdateResponse'
}

export interface UserTaskResponse {
    attachments: (UserTaskAttachment[] | null)
    text: (Scalars['String'] | null)
    __typename: 'UserTaskResponse'
}

export type UserTaskStatus = 'APPROVAL_DENIED' | 'COMPLETED' | 'NOT_COMPLETED' | 'NOT_STARTED' | 'PENDING_APPROVAL'

export interface UserTaskStatusUpdateResponse {
    userTaskListStatus: UserTaskListStatus
    userTaskStatus: UserTaskStatus
    __typename: 'UserTaskStatusUpdateResponse'
}

export type UserType = 'LEGACY_TENANT' | 'MEMBER'

export interface UsersList {
    items: User[]
    pageInfo: PageInfo
    __typename: 'UsersList'
}

export type ValidationConstraint = 'email' | 'max' | 'maxLength' | 'min' | 'minLength' | 'phoneNumber' | 'required' | 'ssn' | 'url'

export type VisitTime = 'CURRENT' | 'PAST' | 'UPCOMING'

export interface Visitor {
    authorizedDeskKey: Scalars['Boolean']
    email: Scalars['String']
    endDate: (Scalars['Time'] | null)
    endTime: Scalars['String']
    firstName: Scalars['String']
    hasKey: Scalars['Boolean']
    id: Scalars['ID']
    isCanceled: Scalars['Boolean']
    isCheckedIn: Scalars['Boolean']
    keyNumber: (Scalars['String'] | null)
    lastName: Scalars['String']
    leaseContract: LeaseContract
    leaseContractId: Scalars['ID']
    lockoutAssistance: Scalars['Boolean']
    notes: (Scalars['String'] | null)
    organizationId: Scalars['ID']
    phoneNumber: Scalars['String']
    recurrenceEndType: (RecurrenceEndType | null)
    recurrenceNumOccurrences: (Scalars['Int'] | null)
    recurrenceWeekdays: (DayOfWeek[] | null)
    startDate: Scalars['Time']
    startTime: Scalars['String']
    status: VisitorStatus
    time: (VisitTime | null)
    __typename: 'Visitor'
}

export type VisitorOrderBy = 'END_DATE_ASC' | 'END_DATE_DESC' | 'START_DATE_ASC' | 'START_DATE_DESC' | 'VISITOR_NAME_ASC' | 'VISITOR_NAME_DESC'

export type VisitorStatus = 'CANCELED' | 'CHECKED_IN' | 'CHECKED_OUT'

export interface VisitorsSettings {
    buildingId: Scalars['ID']
    id: Scalars['ID']
    isAuthorizedDeskKeyEnabled: Scalars['Boolean']
    isLockoutAssistanceEnabled: Scalars['Boolean']
    isPhysicalKeyEnabled: Scalars['Boolean']
    isSmartAccessEnabled: Scalars['Boolean']
    isVisitorsEnabled: Scalars['Boolean']
    __typename: 'VisitorsSettings'
}

export interface WebLinkData {
    openInBrowser: Scalars['Boolean']
    url: Scalars['String']
    __typename: 'WebLinkData'
}

export interface WhiteLabel {
    appIcon: Scalars['String']
    appLink: AppLinkType
    appName: Scalars['String']
    brandId: Scalars['ID']
    id: Scalars['ID']
    organizationId: Scalars['ID']
    privacyPolicyURL: (Scalars['String'] | null)
    requireExplicitTermsAcceptance: Scalars['Boolean']
    termsAndConditionsURL: (Scalars['String'] | null)
    __typename: 'WhiteLabel'
}

export interface WhiteLabelConfig {
    colors: (BrandColors | null)
    emailOTPLogo: (Scalars['String'] | null)
    privacyPolicyURL: (Scalars['String'] | null)
    requireExplicitTermsAcceptance: Scalars['Boolean']
    termsAndConditionsURL: (Scalars['String'] | null)
    whiteLabelId: Scalars['ID']
    __typename: 'WhiteLabelConfig'
}

export type authChallengeType = 'EMAIL' | 'SMS'

export interface externalLockProviderGroup {
    entityId: Scalars['ID']
    entityType: EntityType
    id: Scalars['ID']
    lockProvider: LockProviderType
    lockProviderGroupId: Scalars['String']
    organizationId: Scalars['ID']
    __typename: 'externalLockProviderGroup'
}

export interface externalLockProviderGroupList {
    items: externalLockProviderGroup[]
    pageInfo: PageInfo
    __typename: 'externalLockProviderGroupList'
}

export interface supportTicketNotificationText {
    message: Scalars['String']
    title: Scalars['String']
    __typename: 'supportTicketNotificationText'
}

export interface AdapterGenqlSelection{
    description?: boolean | number
    endpoint?: boolean | number
    id?: boolean | number
    settings?: AdapterSettingGenqlSelection
    type?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AdapterDataInput {description: Scalars['String'],endpoint: Scalars['String'],settings: AdapterSettingInput[],type: AdapterType}

export interface AdapterSettingGenqlSelection{
    IsSecure?: boolean | number
    id?: boolean | number
    key?: boolean | number
    value?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AdapterSettingInput {IsSecure: Scalars['Boolean'],key: Scalars['String'],value: Scalars['String']}

export interface AddressGenqlSelection{
    addressType?: boolean | number
    city?: boolean | number
    coordinate?: CoordinateGenqlSelection
    country?: boolean | number
    entrance?: boolean | number
    googlePlaceID?: boolean | number
    id?: boolean | number
    isDefault?: boolean | number
    state?: boolean | number
    streetName?: boolean | number
    streetNumber?: boolean | number
    zipcode?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AmenitiesListGenqlSelection{
    items?: AmenityGenqlSelection
    pager?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AmenitiesWhereInput {accessibleToBuildingIds: Scalars['ID'][],id?: (Scalars['ID'] | null),isActive?: (Scalars['Boolean'] | null),isReservable?: (Scalars['Boolean'] | null),location?: (Scalars['String'] | null),name?: (Scalars['String'] | null)}

export interface AmenityGenqlSelection{
    accessibleToBuildingIds?: boolean | number
    accessibleToBuildings?: AmenityBuildingGenqlSelection
    amenityCalendarSettings?: AmenityCalendarSettingsGenqlSelection
    availableDates?: { __args: {where: AmenityAvailableDatesWhereInput} }
    bookingOptions?: (BookingOptionGenqlSelection & { __args: {where: BookingOptionsWhereInput} })
    cancellationPolicy?: boolean | number
    customAvailableDates?: { __args: {where: AmenityAvailableDatesWhereInput} }
    customBookingOptions?: (BookingOptionGenqlSelection & { __args: {where: BookingOptionsWhereInput} })
    customPaymentOptions?: (PaymentOptionGenqlSelection & { __args: {data: CustomPaymentOptionsInput} })
    depositAmount?: boolean | number
    description?: boolean | number
    enabledPaymentMethods?: boolean | number
    /** first available date for the amenity based on minReservationDaysInAdvance */
    firstAvailableDate?: boolean | number
    id?: boolean | number
    /** A flag to indicate if the amenity is active or not, if not active, it will not be visible to the users */
    isActive?: boolean | number
    /** A flag to indicate if the amenity is reservable or not, if not reservable, users will not be able to make reservations for it */
    isReservable?: boolean | number
    lastAvailableDate?: boolean | number
    location?: boolean | number
    /** The building in which the amenity is located */
    locationBuilding?: BuildingGenqlSelection
    locationBuildingId?: boolean | number
    managersToNotify?: boolean | number
    media?: AmenityMediaGenqlSelection
    name?: boolean | number
    pmsChargeCode?: boolean | number
    pmsDepositChargeCode?: boolean | number
    pmsDepositGlAccountNumber?: boolean | number
    pmsGlAccountNumber?: boolean | number
    requireAgreement?: boolean | number
    requireApproval?: boolean | number
    stripeAccountId?: boolean | number
    /** @deprecated No longer supported */
    stripeProductId?: boolean | number
    timezone?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AmenityAvailableDatesWhereInput {durationInMinutes: Scalars['Int'],endDate: Scalars['Time'],startDate: Scalars['Time']}

export interface AmenityBuildingGenqlSelection{
    Building?: BuildingGenqlSelection
    BuildingId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AmenityCalendarCreateInput {amenityId: Scalars['ID'],checkIn?: (Scalars['String'] | null),checkOut?: (Scalars['String'] | null),openingHours?: ((AmenityOpeningHoursCreateInput | null)[] | null),reservationSettings?: (AmenityReservationSettingsCreateInput | null),type: AmenityCalendarType}

export interface AmenityCalendarSettingsGenqlSelection{
    amenity?: AmenityGenqlSelection
    amenityId?: boolean | number
    checkIn?: boolean | number
    checkOut?: boolean | number
    id?: boolean | number
    isActive?: boolean | number
    openingHours?: AmenityOpeningHoursGenqlSelection
    reservationSettings?: AmenityReservationSettingsGenqlSelection
    type?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AmenityCalendarUnavailabilitiesWhereInput {accessibleToBuildingIds?: (Scalars['ID'][] | null),amenityIds?: (Scalars['ID'][] | null),endDate?: (Scalars['Time'] | null),startDate?: (Scalars['Time'] | null)}

export interface AmenityCalendarUnavailabilityGenqlSelection{
    amenityCalendar?: AmenityCalendarSettingsGenqlSelection
    amenityCalendarId?: boolean | number
    createdAt?: boolean | number
    endDate?: boolean | number
    id?: boolean | number
    reason?: boolean | number
    startDate?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AmenityCalendarUpdateInput {reservationSettings?: (AmenityReservationSettingsUpdateInput | null)}

export interface AmenityCancelManyWhereInput {idIn?: (Scalars['ID'][] | null)}

export interface AmenityCancellationDenialInput {note?: (Scalars['String'] | null)}

export interface AmenityCreateInput {accessibleToBuildingIds: Scalars['ID'][],cancellationPolicy?: (Scalars['String'] | null),depositAmount?: (Scalars['Float'] | null),description: Scalars['String'],enabledPaymentMethods?: (PaymentMethod[] | null),isActive: Scalars['Boolean'],isReservable: Scalars['Boolean'],location: Scalars['String'],locationBuildingId: Scalars['ID'],managersToNotify?: (Scalars['ID'][] | null),media: AmenityMediaCreateInput[],name: Scalars['String'],pmsChargeCode?: (Scalars['String'] | null),pmsDepositChargeCode?: (Scalars['String'] | null),pmsDepositGlAccountNumber?: (Scalars['String'] | null),pmsGlAccountNumber?: (Scalars['String'] | null),requireAgreement: Scalars['Boolean'],requireApproval: Scalars['Boolean'],stripeAccountId?: (Scalars['String'] | null),stripeProductId?: (Scalars['String'] | null),timezone: Scalars['String']}

export interface AmenityMediaGenqlSelection{
    id?: boolean | number
    type?: boolean | number
    url?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AmenityMediaCreateInput {type: AmenityMediaType,url: Scalars['String']}

export interface AmenityOpeningHoursGenqlSelection{
    closingTime?: boolean | number
    dayOfWeek?: boolean | number
    openingTime?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AmenityOpeningHoursCreateInput {
/** Closing time in HH:mm format */
closingTime: Scalars['String'],dayOfWeek?: (DayOfWeek | null),
/** Opening time in HH:mm format */
openingTime: Scalars['String']}

export interface AmenityReservationGenqlSelection{
    FeeAmount?: boolean | number
    agreementSignedAt?: boolean | number
    amenity?: AmenityGenqlSelection
    amenityId?: boolean | number
    amountPaid?: boolean | number
    /** @deprecated Use statusUpdateNote instead */
    cancellationNote?: boolean | number
    cancellationRequestNote?: boolean | number
    cancellationRequestStatus?: boolean | number
    createdAt?: boolean | number
    /** @deprecated Use statusUpdateNote instead */
    denialReason?: boolean | number
    depositAutoReleaseDate?: boolean | number
    depositChargeDate?: boolean | number
    depositChargeNote?: boolean | number
    depositChargedAmount?: boolean | number
    depositPaymentLink?: boolean | number
    depositStatus?: boolean | number
    depositType?: boolean | number
    endDate?: boolean | number
    externalPaymentId?: boolean | number
    id?: boolean | number
    paidAt?: boolean | number
    paymentLink?: boolean | number
    paymentMethod?: boolean | number
    paymentStatus?: boolean | number
    pmsPaymentId?: boolean | number
    pmsRefundId?: boolean | number
    pmsReversalId?: boolean | number
    refundAmount?: boolean | number
    refundMethod?: boolean | number
    refundedAt?: boolean | number
    reservationId?: boolean | number
    reservationType?: boolean | number
    startDate?: boolean | number
    status?: boolean | number
    statusUpdateNote?: boolean | number
    timeLine?: boolean | number
    unit?: UnitGenqlSelection
    unitId?: boolean | number
    user?: UserGenqlSelection
    userId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AmenityReservationCancelAndRefundInput {cancellationNote?: (Scalars['String'] | null),refundAmount: Scalars['Float'],refundDetails?: (RefundDetailsInput | null)}

export interface AmenityReservationCancelInput {cancellationNote?: (Scalars['String'] | null),refundId?: (Scalars['String'] | null)}

export interface AmenityReservationCreateInput {agreementSigned?: (Scalars['Boolean'] | null),amenityId: Scalars['ID'],endDate: Scalars['Time'],paymentDetails?: (PaymentDetailsInput | null),paymentMethod?: (PaymentMethod | null),requireApproval?: (Scalars['Boolean'] | null),reservationOptionId?: (Scalars['ID'] | null),startDate: Scalars['Time'],unitId?: (Scalars['ID'] | null),userId: Scalars['ID']}

export interface AmenityReservationCustomCreateInput {agreementSigned?: (Scalars['Boolean'] | null),amenityId: Scalars['ID'],endDate: Scalars['Time'],paymentDetails?: (PaymentDetailsInput | null),paymentMethod?: (PaymentMethod | null),priceOverride: Scalars['Float'],startDate: Scalars['Time'],unitId: Scalars['ID'],userId: Scalars['ID']}

export interface AmenityReservationDenialInput {reason?: (Scalars['String'] | null),refundId?: (Scalars['String'] | null)}

export interface AmenityReservationDepositChargeInput {AmenityReservationId: Scalars['ID'],ChargeAmount: Scalars['Float'],ChargeNote?: (Scalars['String'] | null)}

export interface AmenityReservationDepositReleaseInput {AmenityReservationId: Scalars['ID'],ReleaseNote?: (Scalars['String'] | null)}

export interface AmenityReservationDepositTakeInput {AmenityReservationId: Scalars['ID'],depositCashInput?: (DepositCashInput | null),depositCheckInput?: (DepositCheckInput | null),depositType: AmenityDepositType}

export interface AmenityReservationOptionGenqlSelection{
    amenityCalendarId?: boolean | number
    durationInMinutes?: boolean | number
    id?: boolean | number
    label?: boolean | number
    paymentOptions?: PaymentOptionGenqlSelection
    price?: boolean | number
    /** @deprecated Use paymentOptions instead */
    priceByPaymentMethod?: PriceByPaymentMethodGenqlSelection
    productName?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AmenityReservationOptionCreateInput {durationInMinutes: Scalars['Int'],label?: (Scalars['String'] | null),price?: (Scalars['Float'] | null),productName?: (Scalars['String'] | null)}

export interface AmenityReservationOptionUpdateInput {amenityCalendarId: Scalars['ID'],durationInMinutes: Scalars['Int'],label?: (Scalars['String'] | null),price?: (Scalars['Float'] | null),productName?: (Scalars['String'] | null)}

export interface AmenityReservationPayInput {reservationId: Scalars['String'],stripeCheckoutSessionId?: (Scalars['String'] | null)}

export interface AmenityReservationRequestCancelInput {cancellationRequestNote?: (Scalars['String'] | null)}

export interface AmenityReservationSettingsGenqlSelection{
    amenityCalendarId?: boolean | number
    /** A maximum number of days in advance that a reservation can be made */
    maxReservationDaysInAdvance?: boolean | number
    /** A minimum number of days in advance that a reservation can be made */
    minReservationDaysInAdvance?: boolean | number
    postReservationBufferMinutes?: boolean | number
    /**
     * @deprecated Use reservationOptions instead
     * Array of possible durations in minutes for a reservation
     */
    reservationDurationOptions?: boolean | number
    reservationOptions?: AmenityReservationOptionGenqlSelection
    /** A time period for which the reservation quota is applicable */
    reservationQuotaFrequency?: boolean | number
    /** A maximum number of reservations that can be made in a given time period */
    reservationQuotaLimit?: boolean | number
    /** A type of reservation quota, either per user or per unit */
    reservationQuotaType?: boolean | number
    /** A maximum number of users that can reserve a slot at a time */
    slotCapacity?: boolean | number
    slotDurationInMinutes?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AmenityReservationSettingsCreateInput {maxReservationDaysInAdvance: Scalars['Int'],minReservationDaysInAdvance: Scalars['Int'],postReservationBufferMinutes?: (Scalars['Int'] | null),reservationDurationOptions?: (Scalars['Int'][] | null),reservationOptions?: (AmenityReservationOptionCreateInput[] | null),reservationQuotaFrequency?: (ReservationQuotaFrequency | null),reservationQuotaLimit?: (Scalars['Int'] | null),reservationQuotaType?: (ReservationQuotaType | null),slotCapacity: Scalars['Int'],slotDurationInMinutes: Scalars['Int']}

export interface AmenityReservationSettingsUpdateInput {reservationOptions?: ((AmenityReservationOptionUpdateInput | null)[] | null)}

export interface AmenityReservationUserCreateInput {agreementSigned?: (Scalars['Boolean'] | null),amenityId: Scalars['ID'],endDate: Scalars['Time'],notes?: (Scalars['String'] | null),paymentMethod?: (PaymentMethod | null),reservationOptionId?: (Scalars['ID'] | null),startDate: Scalars['Time'],unitId?: (Scalars['ID'] | null)}

export interface AmenityReservationWhereInput {startDateGt?: (Scalars['Time'] | null)}

export interface AmenityReservationsWhereInput {accessibleToBuildingIds?: (Scalars['ID'][] | null),amenityIds?: (Scalars['ID'][] | null),cancellationRequestStatuses?: (CancellationRequestStatus[] | null),endDate?: (Scalars['Time'] | null),reservationType?: (AmenityReservationType | null),startDate?: (Scalars['Time'] | null),status?: (AmenityReservationStatus | null),statuses?: (AmenityReservationStatus[] | null),timeLines?: (AmenityReservationTimeLine[] | null),unitId?: (Scalars['ID'] | null),userId?: (Scalars['ID'] | null)}

export interface AmenityUpdateInput {accessibleToBuildingIds?: (Scalars['ID'][] | null),cancellationPolicy?: (Scalars['String'] | null),depositAmount?: (Scalars['Float'] | null),description?: (Scalars['String'] | null),enabledPaymentMethods?: (PaymentMethod[] | null),isActive?: (Scalars['Boolean'] | null),isReservable?: (Scalars['Boolean'] | null),location?: (Scalars['String'] | null),locationBuildingId?: (Scalars['ID'] | null),managersToNotify?: (Scalars['ID'][] | null),media?: (AmenityMediaCreateInput[] | null),name?: (Scalars['String'] | null),pmsChargeCode?: (Scalars['String'] | null),pmsDepositChargeCode?: (Scalars['String'] | null),pmsDepositGlAccountNumber?: (Scalars['String'] | null),pmsGlAccountNumber?: (Scalars['String'] | null),price?: (Scalars['Float'] | null),requireAgreement?: (Scalars['Boolean'] | null),requireApproval?: (Scalars['Boolean'] | null),stripeAccountId?: (Scalars['String'] | null),stripeProductId?: (Scalars['String'] | null),timezone?: (Scalars['String'] | null)}

export interface AppDashboardLinkGenqlSelection{
    appPageLinkDate?: AppPageLinkDataGenqlSelection
    audienceType?: boolean | number
    building?: BuildingGenqlSelection
    buildingId?: boolean | number
    community?: CommunityGenqlSelection
    communityId?: boolean | number
    disabled?: boolean | number
    displayText?: boolean | number
    emailLinkData?: EmailLinkDataGenqlSelection
    externalAppLinkData?: ExternalAppLinkDataGenqlSelection
    iconResourceKey?: boolean | number
    id?: boolean | number
    linkType?: boolean | number
    ordering?: boolean | number
    organization?: OrganizationGenqlSelection
    organizationId?: boolean | number
    phoneLinkData?: PhoneLinkDataGenqlSelection
    section?: boolean | number
    smsLinkData?: SmsLinkDataGenqlSelection
    webLinkData?: WebLinkDataGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AppDashboardLinkCreateInput {appPageLinkData?: (AppPageLinkDataInput | null),buildingId?: (Scalars['ID'] | null),communityId?: (Scalars['ID'] | null),disabled?: (Scalars['Boolean'] | null),displayText: Scalars['String'],emailLinkData?: (EmailLinkDataInput | null),externalAppLinkData?: (ExternalAppLinkDataInput | null),iconResourceKey?: (Scalars['String'] | null),linkType: AppDashboardLinkType,organizationId: Scalars['ID'],phoneLinkData?: (PhoneLinkDataInput | null),section?: (SectionType | null),smsLinkData?: (SmsLinkDataInput | null),webLinkData?: (WebLinkDataInput | null)}

export interface AppDashboardLinkOrderInput {id: Scalars['String'],ordering: Scalars['Int']}

export interface AppDashboardLinkUpdateInput {appPageLinkData?: (AppPageLinkDataInput | null),buildingId?: (Scalars['ID'] | null),communityId?: (Scalars['ID'] | null),disabled?: (Scalars['Boolean'] | null),displayText?: (Scalars['String'] | null),emailLinkData?: (EmailLinkDataInput | null),externalAppLinkData?: (ExternalAppLinkDataInput | null),iconResourceKey?: (Scalars['String'] | null),ordering?: (Scalars['Int'] | null),phoneLinkData?: (PhoneLinkDataInput | null),section?: (SectionType | null),smsLinkData?: (SmsLinkDataInput | null),webLinkData?: (WebLinkDataInput | null)}

export interface AppLinkTypeGenqlSelection{
    android?: boolean | number
    ios?: boolean | number
    uri_prefix?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AppLinkTypeInput {android: Scalars['String'],ios: Scalars['String'],uri_prefix: Scalars['String']}

export interface AppMoveInSettingsGenqlSelection{
    isVisibleToUser?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AppOnboardingScreenGenqlSelection{
    bullets?: boolean | number
    screenType?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AppOnboardingScreenInput {bullets?: (Scalars['String'][] | null),screenType: Scalars['String']}

export interface AppPageLinkDataGenqlSelection{
    pageName?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AppPageLinkDataInput {pageName: AppPageName}

export interface AppReputationSettingsGenqlSelection{
    reputationLink?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AppSettingsGenqlSelection{
    amenitiesEnabled?: boolean | number
    awayNoticeSettings?: (AwayNoticeSettingsGenqlSelection & { __args?: {where?: (WhereAppSettingsInput | null)} })
    /**
     *  Community Id is required for legacy settings
     * We get it from the hood_id_deprecated on user until we align the frontend to model v2
     */
    communityId?: boolean | number
    dashboardLinks?: AppDashboardLinkGenqlSelection
    embeddedApplications?: ResidentEmbeddedApplicationsGenqlSelection
    legacy?: LegacySettingsGenqlSelection
    moveInSettings?: AppMoveInSettingsGenqlSelection
    renewalSurveySettings?: RenewalSurveySettingsGenqlSelection
    rentPaymentEnabled?: boolean | number
    reputation?: AppReputationSettingsGenqlSelection
    serviceRequestsSettings?: ServiceRequestsSettingsGenqlSelection
    termsAndConditionsStatus?: { __args: {appIdSuffix: Scalars['String']} }
    thermostatsEnabled?: boolean | number
    /** @deprecated Use visitorsSettings instead */
    visitorsEnabled?: { __args: {where?: (WhereAppSettingsInput | null)} } | boolean | number
    visitorsSettings?: (VisitorsSettingsGenqlSelection & { __args?: {where?: (WhereAppSettingsInput | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ApplicantGenqlSelection{
    applicantSubmissionStep?: boolean | number
    applicationStatus?: boolean | number
    assetsReport?: AssetReportResultGenqlSelection
    birthDate?: boolean | number
    buildingId?: boolean | number
    city?: boolean | number
    coApplicants?: ApplicantGenqlSelection
    createdAt?: boolean | number
    creditReport?: CreditReportResultGenqlSelection
    criminalBackgroundCheck?: CriminalBackgroundReportResultGenqlSelection
    decidingPMUser?: UserGenqlSelection
    decidingPMUserId?: boolean | number
    documents?: ApplicantDocumentsGenqlSelection
    email?: boolean | number
    firstName?: boolean | number
    id?: boolean | number
    identityVerification?: IdentityVerificationResultGenqlSelection
    incomeReport?: IncomeReportResultGenqlSelection
    lastName?: boolean | number
    leaseRecommendationStatus?: boolean | number
    /** Data used to generate the lease, dynamic so it's untyped */
    leaseTerms?: ApplicantLeaseTermsGenqlSelection
    mainApplicantId?: boolean | number
    middleName?: boolean | number
    organizationId?: boolean | number
    pets?: PetDetailsGenqlSelection
    phone?: boolean | number
    relation?: boolean | number
    screeningStatus?: boolean | number
    state?: boolean | number
    street?: boolean | number
    summary?: ApplicantSummaryGenqlSelection
    type?: boolean | number
    unit?: UnitGenqlSelection
    unitId?: boolean | number
    zip?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ApplicantCreateResponseGenqlSelection{
    id?: boolean | number
    idvToken?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ApplicantDocumentGenqlSelection{
    availableUntil?: boolean | number
    lastModified?: boolean | number
    /** signed generated s3 url for the document */
    url?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ApplicantDocumentsGenqlSelection{
    applicantConsentDocument?: ApplicantDocumentGenqlSelection
    assetsReportDocument?: ApplicantDocumentGenqlSelection
    creditScoreReportDocument?: ApplicantDocumentGenqlSelection
    identityVerificationImagesDocument?: ApplicantDocumentGenqlSelection
    incomeReportDocument?: ApplicantDocumentGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ApplicantFilter {applicationStatusIn?: (ApplicationStatus[] | null),buildingIdIn: Scalars['ID'][],createdAtGte?: (Scalars['Time'] | null),createdAtLte?: (Scalars['Time'] | null),organizationId: Scalars['ID'],screeningStatusIn?: (ScreeningStatus[] | null),unitIdIn?: (Scalars['ID'][] | null)}

export interface ApplicantGenerateLeaseInput {amenities?: (LeaseAmenityInput | null),concessionOf?: (Scalars['Float'] | null),concessions?: (ConcessionInput[] | null),leaseEndDate: Scalars['Time'],leaseStartDate: Scalars['Time'],mainApplicantId: Scalars['ID'],monthlyRent: Scalars['Float'],monthsOfConcession?: (Scalars['Int'][] | null),pool?: (LeaseAmenityInput | null),securityDeposit?: (Scalars['Float'] | null),
/** whether to send the lease to the applicant- default is false */
sendToApplicant?: (Scalars['Boolean'] | null),thirdPartyGuarantor?: (Scalars['Boolean'] | null)}

export interface ApplicantInvitee {email: Scalars['String'],firstName: Scalars['String'],lastName: Scalars['String'],middleName?: (Scalars['String'] | null),relation?: (Scalars['String'] | null),type: ApplicantType}


/** Lease manager input to generate the lease */
export interface ApplicantLeaseTermsGenqlSelection{
    amenities?: LeaseAmenityGenqlSelection
    /** @deprecated Use concessions instead */
    concessionOf?: boolean | number
    concessions?: ConcessionGenqlSelection
    leaseEndDate?: boolean | number
    leaseStartDate?: boolean | number
    monthlyRent?: boolean | number
    /** @deprecated Use concessions instead */
    monthsOfConcession?: boolean | number
    pool?: LeaseAmenityGenqlSelection
    securityDeposit?: boolean | number
    thirdPartyGuarantor?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ApplicantPet {age: Scalars['Float'],breed: Scalars['String'],color: Scalars['String'],name: Scalars['String'],sex: PetSex,type: PetType,weight: Scalars['Int']}

export interface ApplicantPublicDataGenqlSelection{
    applicantSubmissionStep?: boolean | number
    coApplicants?: CoApplicantPublicDataGenqlSelection
    email?: boolean | number
    firstName?: boolean | number
    id?: boolean | number
    lastName?: boolean | number
    mainApplicant?: MainApplicantPublicDataGenqlSelection
    middleName?: boolean | number
    plaidIdvToken?: boolean | number
    unit?: UnitPublicDataGenqlSelection
    unitId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ApplicantRejectInput {mainApplicantId: Scalars['ID']}

export interface ApplicantSummaryGenqlSelection{
    assets?: AssetReportSummaryGenqlSelection
    credit?: CreditReportSummaryGenqlSelection
    criminal?: CriminalBackgroundReportSummaryGenqlSelection
    identity?: IdentityVerificationSummaryGenqlSelection
    income?: IncomeReportSummaryGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ApplicantUpdateResponseGenqlSelection{
    id?: boolean | number
    idvToken?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ApplicantsInviteInput {invitees: ApplicantInvitee[],mainApplicantId: Scalars['ID']}

export interface ApplicantsInviteResponseGenqlSelection{
    /** list of emails that failed to be invited */
    failed?: boolean | number
    /** list of emails that succeeded to be invited */
    succeeded?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ApplicationSettingsGenqlSelection{
    assetsThreshold?: boolean | number
    buildingId?: boolean | number
    consentHTMLTemplate?: boolean | number
    creditScoreThreshold?: boolean | number
    criminalRecordsThreshold?: boolean | number
    id?: boolean | number
    incomeThresholdNumberOfRentMonths?: boolean | number
    isIdentityVerificationRequired?: boolean | number
    organizationId?: boolean | number
    plaidIDVTemplateId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ApplicationSettingsInput {assetsThreshold?: (Scalars['Float'] | null),consentHTMLTemplate?: (Scalars['String'] | null),creditScoreThreshold?: (Scalars['Int'] | null),criminalRecordsThreshold?: (Scalars['Int'] | null),incomeThresholdNumberOfRentMonths?: (Scalars['Int'] | null),isIdentityVerificationRequired?: (Scalars['Boolean'] | null),plaidIDVTemplateId?: (Scalars['String'] | null)}

export interface ApplicationUnitUpdateInput {mainApplicantId: Scalars['ID'],unitId: Scalars['ID']}

export interface AssetReportResultGenqlSelection{
    details?: boolean | number
    status?: boolean | number
    totalAssets?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AssetReportSummaryGenqlSelection{
    assetsSum?: boolean | number
    minAssetsThreshold?: boolean | number
    status?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AttachmentsUploadUrlResponseGenqlSelection{
    URL?: boolean | number
    key?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AttachmentsUrlCreateInput {category: AttachmentsCategory}

export interface AuthChallengeGenqlSelection{
    authSession?: boolean | number
    type?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AuthChallengeCreateInput {username: Scalars['String']}

export interface AuthChallengeVerifyInput {answer: Scalars['String'],authSession: Scalars['String']}

export interface AuthChallengeVerifyResponseGenqlSelection{
    token?: AuthTokenGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AuthTokenGenqlSelection{
    accessToken?: boolean | number
    refreshToken?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AuthTokenRefreshInput {originalToken: Scalars['String'],refreshToken: Scalars['String']}

export interface AuthTokenRefreshResponseGenqlSelection{
    token?: AuthTokenGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AutomationActionDescriptorGenqlSelection{
    actionParams?: AutomationDescriptorParamGenqlSelection
    displayName?: boolean | number
    id?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AutomationDescriptorGenqlSelection{
    availableActions?: AutomationActionDescriptorGenqlSelection
    displayName?: boolean | number
    id?: boolean | number
    predicateParams?: AutomationDescriptorParamGenqlSelection
    ruleType?: boolean | number
    supportedScopes?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AutomationDescriptorParamGenqlSelection{
    availableValues?: AutomationDescriptorParamOptionGenqlSelection
    displayName?: boolean | number
    id?: boolean | number
    isMandatory?: boolean | number
    maxValue?: boolean | number
    minValue?: boolean | number
    type?: boolean | number
    uiOrder?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AutomationDescriptorParamOptionGenqlSelection{
    key?: boolean | number
    value?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AutomationRuleGenqlSelection{
    action?: AutomationRuleActionGenqlSelection
    automationDescriptorId?: boolean | number
    buildingIds?: boolean | number
    communityIds?: boolean | number
    createdAt?: boolean | number
    createdByUser?: UserGenqlSelection
    createdByUserId?: boolean | number
    description?: boolean | number
    id?: boolean | number
    isActive?: boolean | number
    name?: boolean | number
    organizationId?: boolean | number
    predicateParamValues?: AutomationRuleParamValueGenqlSelection
    scope?: boolean | number
    stats?: AutomationRuleStatsGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AutomationRuleActionGenqlSelection{
    actionDescriptorId?: boolean | number
    actionParams?: AutomationRuleParamValueGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AutomationRuleActionInput {actionDescriptorId: Scalars['ID'],actionParams?: (AutomationRuleParamValueInput[] | null)}

export interface AutomationRuleInput {action: AutomationRuleActionInput,automationDescriptorId: Scalars['ID'],description?: (Scalars['String'] | null),isActive: Scalars['Boolean'],name: Scalars['String'],predicateParamValues: AutomationRuleParamValueInput[]}

export interface AutomationRuleParamValueGenqlSelection{
    paramDescriptorId?: boolean | number
    value?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AutomationRuleParamValueInput {paramDescriptorId: Scalars['ID'],value: Scalars['Any']}

export interface AutomationRuleStatsGenqlSelection{
    lastWeek?: boolean | number
    total?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AutomationTriggerHistoryGenqlSelection{
    actionData?: boolean | number
    createdAt?: boolean | number
    eventData?: boolean | number
    id?: boolean | number
    ruleId?: boolean | number
    status?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AutomationTriggerHistoryWhereInput {createdAtGte?: (Scalars['Time'] | null),ruleId: Scalars['ID'],status?: (Scalars['String'] | null)}

export interface AwayNoticeGenqlSelection{
    awayNoticeServiceReservations?: AwayNoticeServiceReservationGenqlSelection
    createdAt?: boolean | number
    endDate?: boolean | number
    id?: boolean | number
    leaseContract?: LeaseContractGenqlSelection
    leaseContractId?: boolean | number
    notes?: boolean | number
    organizationId?: boolean | number
    startDate?: boolean | number
    status?: boolean | number
    timeline?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AwayNoticeCreateInput {awayNoticeServiceReservations?: (AwayNoticeServiceReservationInput[] | null),endDate: Scalars['Time'],leaseContractId: Scalars['ID'],notes?: (Scalars['String'] | null),startDate: Scalars['Time']}

export interface AwayNoticeServiceReservationGenqlSelection{
    awayNoticeServiceType?: boolean | number
    notes?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AwayNoticeServiceReservationInput {awayNoticeServiceType: Scalars['String'],notes?: (Scalars['String'] | null)}

export interface AwayNoticeSettingsGenqlSelection{
    awayNoticeServiceTypes?: boolean | number
    buildingId?: boolean | number
    id?: boolean | number
    isEnabled?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AwayNoticeSettingsUpsertInput {awayNoticeServiceTypes?: (Scalars['String'][] | null),buildingId: Scalars['ID'],isEnabled: Scalars['Boolean'],organizationId: Scalars['ID']}

export interface AwayNoticeUpdateInput {awayNoticeServiceReservations?: (AwayNoticeServiceReservationInput[] | null),endDate: Scalars['Time'],leaseContractId: Scalars['ID'],notes?: (Scalars['String'] | null),startDate: Scalars['Time']}

export interface AwayNoticeWhereInput {endDateLt?: (Scalars['Time'] | null),startDateGt?: (Scalars['Time'] | null),status?: (AwayNoticeStatus[] | null)}

export interface BedroomGenqlSelection{
    askedRent?: boolean | number
    id?: boolean | number
    leaseContracts?: LeaseContractGenqlSelection
    name?: boolean | number
    realEstateListing?: RealEstateListingGenqlSelection
    squareMeters?: boolean | number
    unit?: UnitGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface BookingOptionGenqlSelection{
    endTime?: boolean | number
    startTime?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface BookingOptionsWhereInput {date: Scalars['Time'],durationInMinutes: Scalars['Int']}

export interface BrandGenqlSelection{
    buildingsIds?: boolean | number
    colors?: BrandColorsGenqlSelection
    emailOTPLogo?: boolean | number
    id?: boolean | number
    landLordLogo?: boolean | number
    organizationId?: boolean | number
    title?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface BrandColorsGenqlSelection{
    primary?: boolean | number
    primaryDark?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface BrandColorsInput {primary: Scalars['String'],primaryDark: Scalars['String']}

export interface BrandCreateInput {buildingsIds?: (Scalars['ID'][] | null),colors: BrandColorsInput,emailOTPLogo?: (Scalars['String'] | null),landLordLogo?: (Scalars['String'] | null),organizationId: Scalars['ID'],title: Scalars['String']}

export interface BrandUpdateInput {buildingsIds?: (Scalars['ID'][] | null),colors?: (BrandColorsInput | null),emailOTPLogo?: (Scalars['String'] | null),landLordLogo?: (Scalars['String'] | null),title?: (Scalars['String'] | null)}

export interface BroadcastGenqlSelection{
    attachments?: BroadcastAttachmentGenqlSelection
    audiences?: BroadcastAudienceGenqlSelection
    /** @deprecated Will be removed in the future */
    category?: boolean | number
    content?: BroadcastContentGenqlSelection
    createdAt?: boolean | number
    createdBy?: UserGenqlSelection
    createdByUserId?: boolean | number
    dateSent?: boolean | number
    emailPayload?: BroadcastEmailPayloadGenqlSelection
    id?: boolean | number
    /** @deprecated Will be replaced with mediums: [BroadcastMedium!] */
    medium?: boolean | number
    mediums?: boolean | number
    notifications?: (NotificationsListGenqlSelection & { __args?: {pager?: (PageInput | null), where?: (NotificationWhereInput | null)} })
    scheduledBroadcast?: ScheduledBroadcastGenqlSelection
    sendStatus?: boolean | number
    stats?: BroadcastStatParamGenqlSelection
    updatedBy?: UserGenqlSelection
    updatedByUserId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface BroadcastAttachmentGenqlSelection{
    id?: boolean | number
    url?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface BroadcastAttachmentCreateInput {type: BroadcastAttachmentType,url: Scalars['String']}

export interface BroadcastAudienceGenqlSelection{
    audienceParams?: BroadcastAudienceParamsGenqlSelection
    type?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface BroadcastAudienceInput {audienceParams: BroadcastAudienceParamsInput,type: BroadcastAudienceType}

export interface BroadcastAudienceParamsGenqlSelection{
    on_BuildingAudienceParams?:BuildingAudienceParamsGenqlSelection,
    on_CommunityAudienceParams?:CommunityAudienceParamsGenqlSelection,
    on_EventAudienceParams?:EventAudienceParamsGenqlSelection,
    on_InterestGroupsAudienceParams?:InterestGroupsAudienceParamsGenqlSelection,
    on_SpecificUsersAudienceParams?:SpecificUsersAudienceParamsGenqlSelection,
    __typename?: boolean | number
}


/** Types of broadcast audiences. Only one field can be non-null. */
export interface BroadcastAudienceParamsInput {buildingAudienceParams?: (BuildingAudienceParamsInput | null),communityAudienceParams?: (CommunityAudienceParamsInput | null),eventAudienceParams?: (EventAudienceParamsInput | null),interestGroupsAudienceParams?: (interestGroupsAudienceParamsInput | null),specificUsersAudienceParams?: (SpecificUsersAudienceParamsInput | null)}

export interface BroadcastContentGenqlSelection{
    cta?: CtaDataGenqlSelection
    deepLink?: DeepLinkGenqlSelection
    message?: boolean | number
    title?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface BroadcastContentInput {cta?: (CtaInput | null),deepLink?: (DeepLinkInput | null),message?: (Scalars['String'] | null),title?: (Scalars['String'] | null)}

export interface BroadcastCreateInput {attachments?: (BroadcastAttachmentCreateInput[] | null),audiences?: (BroadcastAudienceInput[] | null),category?: (BroadcastCategory | null),content: BroadcastContentInput,createdByUserId: Scalars['ID'],emailPayload?: (BroadcastEmailPayloadInput | null),hoodId: Scalars['ID'],medium: NotificationMedium,mediums?: (BroadcastMedium[] | null),organizationId: Scalars['ID'],sendStatus?: (BroadcastSendStatus | null),sourceType?: (BroadcastSourceType | null)}

export interface BroadcastEmailPayloadGenqlSelection{
    fromEmail?: boolean | number
    fromName?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface BroadcastEmailPayloadInput {fromEmail: Scalars['String'],fromName: Scalars['String']}

export interface BroadcastStatParamGenqlSelection{
    count?: boolean | number
    rate?: boolean | number
    status?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}


/** Updating a broadcast is available only before the broadcast is sent */
export interface BroadcastUpdateInput {attachments?: (BroadcastAttachmentCreateInput[] | null),audiences?: (BroadcastAudienceInput[] | null),category?: (BroadcastCategory | null),content?: (BroadcastContentInput | null),dateSent?: (Scalars['Time'] | null),emailPayload?: (BroadcastEmailPayloadInput | null),medium?: (NotificationMedium | null),mediums?: (BroadcastMedium[] | null),scheduledBroadcastId?: (Scalars['ID'] | null),sendStatus?: (BroadcastSendStatus | null),updatedByUserId: Scalars['ID']}

export interface BroadcastWhereInput {communityIds?: (Scalars['ID'][] | null),createdAtBetween?: (DateRangeInput | null),medium?: (NotificationMedium | null),mediums?: (BroadcastMedium[] | null),organizationId?: (Scalars['ID'] | null),statusIn?: (BroadcastSendStatus[] | null)}

export interface BroadcastsListGenqlSelection{
    items?: BroadcastGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface BuildingGenqlSelection{
    address?: AddressGenqlSelection
    buildingNumber?: boolean | number
    /** @deprecated Has only 1 community, use community field instead */
    communities?: CommunityGenqlSelection
    community?: CommunityGenqlSelection
    communityId?: boolean | number
    /** The external ID of the building coming from the PMS */
    externalId?: boolean | number
    id?: boolean | number
    legalName?: boolean | number
    name?: boolean | number
    paymentReminders?: PaymentRemindersGenqlSelection
    pmsAdapterId?: boolean | number
    settings?: BuildingSettingsGenqlSelection
    /**
     * Status indicates if the units in the building are enabled or disabled
     * status will be enabled if ALL units in building are enabled otherwise it will be disabled
     */
    status?: boolean | number
    streetName?: boolean | number
    units?: UnitGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface BuildingAudienceParamsGenqlSelection{
    buildingIds?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface BuildingAudienceParamsInput {buildingIds?: (Scalars['ID'][] | null)}

export interface BuildingSettingsGenqlSelection{
    amenityReservationTemplateName?: boolean | number
    buildingId?: boolean | number
    calcContractStatusByDate?: boolean | number
    isLocksEnabled?: boolean | number
    isPackagesEnabled?: boolean | number
    isRentPaymentMobileEnabled?: boolean | number
    isRentPaymentSupported?: boolean | number
    isShiftNotesEnabled?: boolean | number
    isVisitorsEnabled?: boolean | number
    paymentPolicyFAQLink?: boolean | number
    paymentPortalLink?: boolean | number
    thermostatAdapterId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface BuildingSettingsSetInput {amenityReservationTemplateName?: (Scalars['String'] | null),buildingId: Scalars['String'],calcContractStatusByDate?: (Scalars['Boolean'] | null),isLocksEnabled?: (Scalars['Boolean'] | null),isPackagesEnabled?: (Scalars['Boolean'] | null),isRentPaymentMobileEnabled?: (Scalars['Boolean'] | null),isRentPaymentSupported?: (Scalars['Boolean'] | null),isShiftNotesEnabled?: (Scalars['Boolean'] | null),isVisitorsEnabled?: (Scalars['Boolean'] | null),paymentPolicyFAQLink?: (Scalars['String'] | null),paymentPortalLink?: (Scalars['String'] | null),thermostatAdapterId?: (Scalars['String'] | null)}

export interface BuildingsTaskListSetInput {buildingIds: Scalars['ID'][],taskListId: Scalars['ID']}

export interface BuildingsWhereInput {communityIdsIn?: (Scalars['ID'][] | null),externalIds?: (Scalars['ID'][] | null),ids?: (Scalars['ID'][] | null),name?: (Scalars['String'] | null)}

export interface BylineGenqlSelection{
    count?: boolean | number
    type?: boolean | number
    value?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CalendarGenqlSelection{
    id?: boolean | number
    intervalDuration?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CalendarBookingDetailsInput {slots: CalendarTimeSlotInput[]}

export interface CalendarTimeSlotInput {endTime: Scalars['Time'],startTime: Scalars['Time']}

export interface CashDetailsInput {receiptNumber?: (Scalars['String'] | null),staffMemberId: Scalars['String']}

export interface CheckDetailsInput {bankName: Scalars['String'],checkDate: Scalars['Time'],checkNumber: Scalars['String'],receiptNumber?: (Scalars['String'] | null),staffMemberId: Scalars['String']}

export interface CoApplicantPublicDataGenqlSelection{
    email?: boolean | number
    firstName?: boolean | number
    id?: boolean | number
    lastName?: boolean | number
    middleName?: boolean | number
    type?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CoApplicantUpdateInput {SSN: Scalars['String'],applicantId: Scalars['ID'],birthDate: Scalars['Time'],city: Scalars['String'],firstName: Scalars['String'],lastName: Scalars['String'],middleName?: (Scalars['String'] | null),phone: Scalars['String'],state: Scalars['String'],street: Scalars['String'],zip: Scalars['String']}


/** The Community entity */
export interface CommunityGenqlSelection{
    buildings?: (BuildingGenqlSelection & { __args?: {where?: (CommunityBuildingsWhereInput | null)} })
    coordinates?: CoordinateGenqlSelection
    country?: boolean | number
    displayName?: boolean | number
    id?: boolean | number
    language?: boolean | number
    name?: boolean | number
    organization?: OrganizationGenqlSelection
    organizationId?: boolean | number
    settings?: CommunitySettingsGenqlSelection
    state?: boolean | number
    timezone?: boolean | number
    uiMetadata?: CommunityUiMetadataGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CommunityAudienceParamsGenqlSelection{
    communityIds?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CommunityAudienceParamsInput {communityIds: Scalars['ID'][]}

export interface CommunityBuildingsGenqlSelection{
    buildingsIds?: boolean | number
    communityId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CommunityBuildingsSetInput {buildingsIds: Scalars['ID'][],communityId: Scalars['ID']}

export interface CommunityBuildingsWhereInput {externalIds?: (Scalars['ID'][] | null),ids?: (Scalars['ID'][] | null),name?: (Scalars['String'] | null)}

export interface CommunityCreateInput {coordinates: CoordinateInput,country: CountryCode,displayName: Scalars['String'],language: Language,organizationId: Scalars['ID'],state?: (Scalars['String'] | null),timezone: Scalars['String'],uiMetadata?: (CommunityUiMetadataInput | null)}

export interface CommunitySettingsGenqlSelection{
    communityId?: boolean | number
    moderationSlackChannel?: boolean | number
    socialProfileSuspendDurationInMinutes?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}


/** All settings are optional, any setting that will be set will override, other will be left as-is */
export interface CommunitySettingsSetInput {communityId: Scalars['ID'],emailSender?: (Scalars['String'] | null),emailTemplate?: (Scalars['String'] | null),moderationSlackChannel?: (Scalars['String'] | null),smsPhoneNumberSender?: (Scalars['String'] | null),socialProfileSuspendDurationInMinutes?: (Scalars['Int'] | null)}

export interface CommunityUiMetadataGenqlSelection{
    appOnboardingScreens?: AppOnboardingScreenGenqlSelection
    collateralUrl?: boolean | number
    communityLogoUrl?: boolean | number
    dashboardLogoUrl?: boolean | number
    hideVennLogo?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CommunityUiMetadataInput {appOnboardingScreens?: (AppOnboardingScreenInput[] | null),collateralUrl?: (Scalars['String'] | null),communityLogoUrl?: (Scalars['String'] | null),dashboardLogoUrl?: (Scalars['String'] | null),hideVennLogo?: (Scalars['Boolean'] | null)}

export interface CommunityUpdateInput {coordinates?: (CoordinateInput | null),country?: (CountryCode | null),displayName?: (Scalars['String'] | null),language?: (Language | null),state?: (Scalars['String'] | null),timezone?: (Scalars['String'] | null),uiMetadata?: (CommunityUiMetadataInput | null)}

export interface CommunityWhereInput {name?: (Scalars['String'] | null)}

export interface ConcessionGenqlSelection{
    amount?: boolean | number
    month?: boolean | number
    year?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ConcessionInput {amount: Scalars['Float'],month: Month,year: Scalars['String']}


/** Coordinates */
export interface CoordinateGenqlSelection{
    latitude?: boolean | number
    latitudeDelta?: boolean | number
    longitude?: boolean | number
    longitudeDelta?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CoordinateInput {latitude?: (Scalars['Float'] | null),longitude?: (Scalars['Float'] | null)}

export interface CreditReportResultGenqlSelection{
    creditScore?: boolean | number
    details?: boolean | number
    status?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CreditReportSummaryGenqlSelection{
    averageCreditScore?: boolean | number
    minCreditThreshold?: boolean | number
    status?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CriminalBackgroundReportResultGenqlSelection{
    details?: boolean | number
    numberOfOffenses?: boolean | number
    status?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CriminalBackgroundReportSummaryGenqlSelection{
    status?: boolean | number
    sumOfCriminalRecords?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CtaDataGenqlSelection{
    applicationPage?: boolean | number
    ctaText?: boolean | number
    description?: boolean | number
    entityId?: boolean | number
    params?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CtaInput {applicationPage: ApplicationPage,ctaText?: (Scalars['String'] | null),description?: (Scalars['String'] | null),entityId?: (Scalars['ID'] | null)}

export interface CustomPaymentOptionsInput {price: Scalars['Float']}

export interface DateRangeInput {end: Scalars['Time'],start: Scalars['Time']}


/** Deep Link can either be a list of entities, or a page of a specific entity */
export interface DeepLinkGenqlSelection{
    applicationPage?: boolean | number
    description?: boolean | number
    entityId?: boolean | number
    params?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DeepLinkInput {applicationPage: ApplicationPage,description?: (Scalars['String'] | null),entityId?: (Scalars['ID'] | null)}

export interface DeliveryPackageGenqlSelection{
    additionalInfo?: boolean | number
    arrivedOnDate?: boolean | number
    building?: BuildingGenqlSelection
    buildingId?: boolean | number
    courier?: boolean | number
    id?: boolean | number
    lastReminderDate?: boolean | number
    packageNumber?: boolean | number
    pickUpDate?: boolean | number
    pickUpLocation?: boolean | number
    status?: boolean | number
    unit?: UnitGenqlSelection
    unitId?: boolean | number
    user?: UserGenqlSelection
    userId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DeliveryPackageCreateInput {additionalInfo?: (Scalars['String'] | null),arrivedOnDate: Scalars['Time'],courier: Scalars['String'],packageNumber: Scalars['String'],pickUpLocation: Scalars['String'],status: DeliveryPackageStatus,unitId: Scalars['String'],userId: Scalars['String']}

export interface DeliveryPackageUpdateInput {additionalInfo?: (Scalars['String'] | null),arrivedOnDate?: (Scalars['Time'] | null),buildingId?: (Scalars['String'] | null),courier?: (Scalars['String'] | null),packageNumber?: (Scalars['String'] | null),pickUpDate?: (Scalars['Time'] | null),pickUpLocation?: (Scalars['String'] | null),status?: (DeliveryPackageStatus | null),unitId?: (Scalars['String'] | null),userId?: (Scalars['String'] | null)}

export interface DeliveryPackageWhereInput {id: Scalars['ID']}

export interface DeliveryPackagesListGenqlSelection{
    items?: DeliveryPackageGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DeliveryPackagesWhereInput {arrivedOnDateBetween?: (DateRangeInput | null),packageNumberEq?: (Scalars['String'] | null),statuses?: (DeliveryPackageStatus[] | null),statusesIn?: (DeliveryPackageStatus[] | null),unitIdIn?: (Scalars['String'][] | null)}

export interface DepositCashInput {receiptNumber?: (Scalars['String'] | null),staffMemberId: Scalars['ID']}

export interface DepositCheckInput {bankName: Scalars['String'],checkDate: Scalars['String'],checkNumber: Scalars['String'],receiptNumber?: (Scalars['String'] | null),staffMemberId: Scalars['ID']}


/**
 *  The discussion entity
 * (known as social moment in the old API)
 */
export interface DiscussionGenqlSelection{
    audienceBuildings?: BuildingGenqlSelection
    authorUser?: UserGenqlSelection
    commentsCount?: boolean | number
    id?: boolean | number
    image?: boolean | number
    imageAspectRatio?: boolean | number
    interactingSocialProfiles?: SocialProfileGenqlSelection
    isLikedByCurrentUser?: boolean | number
    latestActivity?: boolean | number
    likesCount?: boolean | number
    locationLabel?: boolean | number
    publishedAt?: boolean | number
    status?: boolean | number
    survey?: SurveyGenqlSelection
    text?: boolean | number
    type?: boolean | number
    updatedAt?: boolean | number
    wasViewedByCurrentUser?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}


/** Comments on discussions */
export interface DiscussionCommentGenqlSelection{
    authorSocialProfile?: SocialProfileGenqlSelection
    createdAt?: boolean | number
    id?: boolean | number
    message?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}


/** Comments with paging */
export interface DiscussionCommentsListGenqlSelection{
    items?: DiscussionCommentGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiscussionCommentsWhereInput {discussionId: Scalars['ID']}


/** Input args when creating a discussion */
export interface DiscussionCreateInput {audienceBuildingIds?: (Scalars['ID'][] | null),hoodName: Scalars['String'],image?: (Scalars['String'] | null),ownerUserId: Scalars['ID'],status: DiscussionPublishStatus,text: Scalars['String'],type: DiscussionType}


/** Input args when updating a discussion */
export interface DiscussionUpdateInput {audienceBuildingIds?: (Scalars['ID'][] | null),image?: (Scalars['String'] | null),status?: (DiscussionPublishStatus | null),text?: (Scalars['String'] | null),type?: (DiscussionType | null)}


/** Discussions with paging */
export interface DiscussionsListGenqlSelection{
    items?: DiscussionGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EmailDomainGenqlSelection{
    allowDomainUsersAsSenders?: boolean | number
    createdAt?: boolean | number
    dkimHost?: boolean | number
    dkimValue?: boolean | number
    domain?: boolean | number
    externalId?: boolean | number
    id?: boolean | number
    organizationId?: boolean | number
    returnPath?: boolean | number
    returnPathCname?: boolean | number
    verifcationStatus?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EmailDomainCreateInput {allowDomainUsersAsSenders: Scalars['Boolean'],domain: Scalars['String']}

export interface EmailDomainInput {domain: Scalars['String'],isVerified: Scalars['Boolean']}

export interface EmailDomainSenderGenqlSelection{
    allowedCommunities?: CommunityGenqlSelection
    allowedCommunityIds?: boolean | number
    allowedUserIds?: boolean | number
    allowedUsers?: UserGenqlSelection
    createdAt?: boolean | number
    domainId?: boolean | number
    fromEmail?: boolean | number
    fromName?: boolean | number
    id?: boolean | number
    isSystemDefault?: boolean | number
    organizationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EmailDomainSenderCreateInput {allowedCommunityIds?: (Scalars['ID'][] | null),allowedUserIds?: (Scalars['ID'][] | null),fromEmail: Scalars['String'],fromName: Scalars['String']}

export interface EmailDomainSenderUpdateInput {allowedCommunityIds?: (Scalars['ID'][] | null),allowedUserIds?: (Scalars['ID'][] | null),fromName?: (Scalars['String'] | null)}

export interface EmailLinkDataGenqlSelection{
    emailAddress?: boolean | number
    subject?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EmailLinkDataInput {emailAddress: Scalars['String'],subject?: (Scalars['String'] | null)}

export interface EmailSenderDomainGenqlSelection{
    domain?: boolean | number
    isVerified?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EmailSenderForUserGenqlSelection{
    fromEmail?: boolean | number
    fromName?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EmailSettingsGenqlSelection{
    defaultSystemEmail?: boolean | number
    domains?: EmailSenderDomainGenqlSelection
    endpoint?: boolean | number
    fromEmails?: boolean | number
    id?: boolean | number
    organizationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EmailSettingsUpdateInput {domains?: (EmailDomainInput[] | null),fromEmails?: (Scalars['String'][] | null)}

export interface EmbeddedApplicationAdapterGenqlSelection{
    buildingIds?: boolean | number
    endpoint?: boolean | number
    id?: boolean | number
    isEnabled?: boolean | number
    name?: boolean | number
    organizationId?: boolean | number
    settings?: SecureKeyValueGenqlSelection
    type?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EmbeddedApplicationAdapterCreateInput {buildingIDs?: (Scalars['ID'][] | null),endpoint: Scalars['String'],isEnabled: Scalars['Boolean'],name: Scalars['String'],organizationId: Scalars['ID'],settings: SecureKeyValueInput[],type: EmbeddedApplicationType}

export interface EmbeddedApplicationAdapterUpdateInput {buildingIds?: (Scalars['ID'][] | null),isEnabled?: (Scalars['Boolean'] | null),name?: (Scalars['String'] | null),settings?: (SecureKeyValueInput[] | null)}

export interface EmbeddedApplicationAdapterWhereInput {isEnabled?: (Scalars['Boolean'] | null),typeIn?: (EmbeddedApplicationType[] | null)}

export interface EmbeddedApplicationSaml2SettingsGenqlSelection{
    acsURL?: boolean | number
    metadataURL?: boolean | number
    relayState?: boolean | number
    userCustomAttributes?: KeyValueParamGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EmbeddedApplicationWindowEventSettingsGenqlSelection{
    errorMessageJsonPath?: boolean | number
    failureJsonPath?: boolean | number
    origin?: boolean | number
    successJsonPath?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EventGenqlSelection{
    RVSPs?: boolean | number
    admissionFeeType?: boolean | number
    attendanceCancellable?: boolean | number
    attendees?: (EventAttendeeGenqlSelection & { __args?: {where?: (EventAttendeeWhereInput | null)} })
    audienceBuildingIds?: boolean | number
    audienceBuildings?: BuildingGenqlSelection
    availableTickets?: boolean | number
    category?: boolean | number
    checkIns?: boolean | number
    communities?: CommunityGenqlSelection
    communityIds?: boolean | number
    description?: boolean | number
    endDate?: boolean | number
    hoodId?: boolean | number
    id?: boolean | number
    imageResourceId?: boolean | number
    locationBuilding?: BuildingGenqlSelection
    locationBuildingId?: boolean | number
    maxTickets?: boolean | number
    name?: boolean | number
    ownershipType?: boolean | number
    place?: PlaceGenqlSelection
    placeId?: boolean | number
    publish?: boolean | number
    residentHostIds?: boolean | number
    residentHosts?: UserGenqlSelection
    shouldGenerateQRCode?: boolean | number
    startDate?: boolean | number
    statistics?: EventStatisticsGenqlSelection
    status?: boolean | number
    /** @deprecated use MyEventRating instead or point to the new resolver for getting event rating */
    surveyId?: boolean | number
    ticketPrice?: boolean | number
    virtualLink?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EventAttendInput {eventId: Scalars['ID']}

export interface EventAttendanceGenqlSelection{
    attendanceStatus?: boolean | number
    eventDetails?: EventGenqlSelection
    eventId?: boolean | number
    rating?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EventAttendeeGenqlSelection{
    attendanceStatus?: boolean | number
    eventId?: boolean | number
    id?: boolean | number
    rating?: RatingGenqlSelection
    user?: UserGenqlSelection
    userId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EventAttendeeWhereInput {attendanceStatus?: (AttendanceStatus | null),attendanceStatusNot?: (AttendanceStatus | null)}

export interface EventAudienceParamsGenqlSelection{
    eventId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EventAudienceParamsInput {eventId: Scalars['ID']}

export interface EventCancelAttendanceInput {eventAttendeeId: Scalars['ID']}

export interface EventCheckInWithScanInput {eventId: Scalars['ID'],userId: Scalars['ID']}


/** Create event input args */
export interface EventCreateInput {admissionFeeType?: (AdmissionFeeType | null),audienceBuildingIds?: (Scalars['ID'][] | null),category?: (Scalars['String'] | null),communityIds?: (Scalars['ID'][] | null),description?: (Scalars['String'] | null),endDate?: (Scalars['Time'] | null),hoodId?: (Scalars['String'] | null),imageResourceId?: (Scalars['String'] | null),maxTickets?: (Scalars['Int'] | null),name: Scalars['String'],ownershipType?: (EventOwnershipType | null),placeId?: (Scalars['ID'] | null),publish: Scalars['Boolean'],residentHostIds?: (Scalars['ID'][] | null),shouldGenerateQRCode?: (Scalars['Boolean'] | null),startDate?: (Scalars['Time'] | null),ticketPrice?: (Scalars['Float'] | null),virtualLink?: (Scalars['String'] | null)}

export interface EventSearchResultGenqlSelection{
    buildingName?: boolean | number
    communityId?: boolean | number
    endDate?: boolean | number
    id?: boolean | number
    name?: boolean | number
    organizationId?: boolean | number
    placeName?: boolean | number
    startDate?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EventStatisticsGenqlSelection{
    ratingAverage?: boolean | number
    ratingCount?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EventSuggestionImageInput {format?: (Scalars['String'] | null),publicId: Scalars['String'],resourceType?: (Scalars['String'] | null)}

export interface EventSuggestionInput {communityId: Scalars['ID'],date: Scalars['Time'],description: Scalars['String'],helpDetails?: (Scalars['String'] | null),imageResource: EventSuggestionImageInput,location?: (Scalars['String'] | null),locationDetails?: (Scalars['String'] | null),name: Scalars['String'],organizers: OrganizerDetails,timeframe: EventSuggestionTimeframe}


/** Update event input args */
export interface EventUpdateInput {admissionFeeType?: (AdmissionFeeType | null),audienceBuildingIds?: (Scalars['ID'][] | null),category?: (Scalars['String'] | null),communityIds?: (Scalars['ID'][] | null),description?: (Scalars['String'] | null),endDate?: (Scalars['Time'] | null),hoodId?: (Scalars['String'] | null),imageResourceId?: (Scalars['String'] | null),maxTickets?: (Scalars['Int'] | null),name?: (Scalars['String'] | null),ownershipType?: (EventOwnershipType | null),placeId?: (Scalars['String'] | null),publish?: (Scalars['Boolean'] | null),residentHostIds?: (Scalars['ID'][] | null),shouldGenerateQRCode?: (Scalars['Boolean'] | null),startDate?: (Scalars['Time'] | null),ticketPrice?: (Scalars['Float'] | null),virtualLink?: (Scalars['String'] | null)}

export interface EventWhereInput {buildingIds?: (Scalars['ID'][] | null),communityId?: (Scalars['ID'] | null),communityIds?: (Scalars['ID'][] | null),hoodId?: (Scalars['ID'] | null),ids?: (Scalars['ID'][] | null),searchName?: (Scalars['String'] | null),startDateGt?: (Scalars['Time'] | null),startDateLt?: (Scalars['Time'] | null)}


/** Events with paging */
export interface EventsListGenqlSelection{
    items?: EventGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ExternalAppLinkDataGenqlSelection{
    androidFallbackLink?: boolean | number
    androidLink?: boolean | number
    iosFallbackLink?: boolean | number
    iosLink?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ExternalAppLinkDataInput {androidFallbackLink: Scalars['String'],androidLink: Scalars['String'],iosFallbackLink: Scalars['String'],iosLink: Scalars['String']}

export interface FeatureFlagGenqlSelection{
    id?: boolean | number
    key?: boolean | number
    name?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface FeatureFlagWhereInput {searchKey?: (Scalars['String'] | null)}


/** The featured slide entity - featured content on the feed */
export interface FeaturedSlideGenqlSelection{
    audienceBuildings?: FeaturedSlideBuildingsGenqlSelection
    callToAction?: FeaturedSlideCallToActionGenqlSelection
    communityId?: boolean | number
    createdBy?: UserGenqlSelection
    createdById?: boolean | number
    description?: boolean | number
    id?: boolean | number
    imageResourceId?: boolean | number
    linkType?: boolean | number
    name?: boolean | number
    priority?: boolean | number
    publishEndDate?: boolean | number
    publishStartDate?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface FeaturedSlideBuildingsGenqlSelection{
    building?: BuildingGenqlSelection
    id?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}


/** call to action on featured slide */
export interface FeaturedSlideCallToActionGenqlSelection{
    label?: boolean | number
    link?: FeaturedSlideLinkGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}


/** call to action on featured slide input */
export interface FeaturedSlideCallToActionInput {label: Scalars['String'],link: FeaturedSlideLinkInput}


/** Input args when creating a featured slide */
export interface FeaturedSlideCreateInput {audienceBuildingIds?: (Scalars['ID'][] | null),callToAction: FeaturedSlideCallToActionInput,communityId: Scalars['ID'],description: Scalars['String'],image: ImageInput,linkType: LinkType,name: Scalars['String'],priority?: (Scalars['Int'] | null),publishEndDate: Scalars['Time'],publishStartDate: Scalars['Time'],template?: (Scalars['String'] | null)}


/** link in call to action */
export interface FeaturedSlideLinkGenqlSelection{
    name?: boolean | number
    type?: boolean | number
    value?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}


/** link in call to action input */
export interface FeaturedSlideLinkInput {name: Scalars['String'],type: LinkType,value: Scalars['String']}

export interface FeaturedSlideListGenqlSelection{
    items?: FeaturedSlideGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}


/** Input args when updating a featured slide */
export interface FeaturedSlideUpdateInput {audienceBuildingIds?: (Scalars['ID'][] | null),callToAction?: (FeaturedSlideCallToActionInput | null),description?: (Scalars['String'] | null),image?: (ImageInput | null),linkType?: (LinkType | null),name?: (Scalars['String'] | null),publishEndDate?: (Scalars['Time'] | null),publishStartDate?: (Scalars['Time'] | null),template?: (Scalars['String'] | null)}

export interface FeaturedSlideWhereInput {communityIds: Scalars['ID'][],isActive?: (Scalars['Boolean'] | null),publishBetween?: (DateRangeInput | null)}

export interface GeneralInfoPageGenqlSelection{
    cards?: GeneralInfoPageCardsGenqlSelection
    communityId?: boolean | number
    createdAt?: boolean | number
    createdBy?: UserGenqlSelection
    createdById?: boolean | number
    description?: boolean | number
    id?: boolean | number
    image?: ImageGenqlSelection
    publish?: boolean | number
    subtitle?: boolean | number
    title?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}


/** Entities connected to general info pages as cards */
export interface GeneralInfoPageCardsGenqlSelection{
    eventIds?: boolean | number
    events?: EventGenqlSelection
    placeIds?: boolean | number
    places?: PlaceGenqlSelection
    serviceIds?: boolean | number
    serviceProductIds?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}


/** Input args for connecting entities to general info pages as cards */
export interface GeneralInfoPageCardsInput {eventIds?: (Scalars['ID'][] | null),placeIds?: (Scalars['ID'][] | null),serviceIds?: (Scalars['ID'][] | null),serviceProductIds?: (Scalars['ID'][] | null)}


/** Input args when creating a general info page */
export interface GeneralInfoPageCreateInput {cards?: (GeneralInfoPageCardsInput | null),description: Scalars['String'],image: ImageInput,publish: Scalars['Boolean'],subtitle: Scalars['String'],title: Scalars['String']}

export interface GeneralInfoPageListGenqlSelection{
    items?: GeneralInfoPageGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}


/** Input args when updating a general info page */
export interface GeneralInfoPageUpdateInput {cards?: (GeneralInfoPageCardsInput | null),description?: (Scalars['String'] | null),image?: (ImageInput | null),publish?: (Scalars['Boolean'] | null),subtitle?: (Scalars['String'] | null),title?: (Scalars['String'] | null)}

export interface GeneralInfoPageWhereInput {communityId: Scalars['ID'][],freeText?: (Scalars['String'] | null),publish?: (Scalars['Boolean'] | null)}

export interface GuestKeyGenqlSelection{
    key?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface GuestKeyEntry {id: Scalars['String'],name: Scalars['String']}


/** The hood entity - deprecated! use Community instead */
export interface HoodGenqlSelection{
    coordinates?: boolean | number
    country?: boolean | number
    displayName?: boolean | number
    id?: boolean | number
    language?: boolean | number
    name?: boolean | number
    portfolio?: PortfolioGenqlSelection
    /** @deprecated use portfolio connection instead */
    portfolioId?: boolean | number
    state?: boolean | number
    timezone?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface HoodAggregationsGenqlSelection{
    activeSocialProfilesCount?: boolean | number
    newSocialProfilesCount?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface HoodRootGenqlSelection{
    aggregations?: HoodAggregationsGenqlSelection
    broadcast?: (BroadcastGenqlSelection & { __args: {id: Scalars['ID']} })
    broadcasts?: (BroadcastsListGenqlSelection & { __args?: {orderBy?: (BroadcastsOrderBy[] | null), pager?: (PageInput | null), where?: (BroadcastWhereInput | null)} })
    discussion?: (DiscussionGenqlSelection & { __args: {id: Scalars['ID']} })
    discussionComments?: (DiscussionCommentsListGenqlSelection & { __args: {orderBy?: (DiscussionCommentsOrderBy | null), pager?: (PageInput | null), where: DiscussionCommentsWhereInput} })
    discussions?: (DiscussionsListGenqlSelection & { __args?: {orderBy?: (DiscussionsOrderBy[] | null), pager?: (PageInput | null)} })
    discussionsSearchSuggestions?: { __args: {searchTerm: Scalars['String']} }
    event?: (EventGenqlSelection & { __args: {id: Scalars['ID']} })
    events?: (EventsListGenqlSelection & { __args: {orderBy?: (EventsOrderBy[] | null), pager: PageInput, where?: (EventWhereInput | null)} })
    featuredSlidesSearchSuggestions?: { __args: {searchTerm: Scalars['String']} }
    /** @deprecated use community instead */
    hoodDetails?: HoodGenqlSelection
    hoodId?: boolean | number
    hoodSettings?: (HoodSettingGenqlSelection & { __args: {where: HoodSettingWhereInput} })
    interestGroup?: (InterestGroupGenqlSelection & { __args: {id: Scalars['ID']} })
    interestGroups?: (InterestGroupsListGenqlSelection & { __args?: {pager?: (PageInput | null), where?: (InterestGroupWhereInput | null)} })
    managersSocialProfiles?: SocialProfileGenqlSelection
    place?: (PlaceGenqlSelection & { __args: {id: Scalars['ID']} })
    service?: (ServiceGenqlSelection & { __args: {id: Scalars['ID']} })
    serviceProduct?: (ServiceProductGenqlSelection & { __args: {id: Scalars['ID']} })
    socialProfiles?: (SocialProfilesListGenqlSelection & { __args?: {pager?: (PageInput | null), where?: (SocialProfileWhereInput | null)} })
    user?: (UserGenqlSelection & { __args: {id: Scalars['ID']} })
    users?: (UsersListGenqlSelection & { __args?: {pager?: (PageInput | null), where?: (UserWhereInput | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface HoodSettingGenqlSelection{
    hoodId?: boolean | number
    key?: boolean | number
    /** The key format will be hoodSettingGroup.hoodSetting.value */
    value?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface HoodSettingWhereInput {key: Scalars['String']}

export interface HubMetadataGenqlSelection{
    background?: boolean | number
    firstTimeWalkMe?: boolean | number
    nickName?: boolean | number
    onboarded?: boolean | number
    picture?: boolean | number
    promoAmenities?: boolean | number
    promoMoveIn?: boolean | number
    renewals?: boolean | number
    roles?: boolean | number
    tShirtSize?: boolean | number
    tasks?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface HubMetadataSetInput {background?: (Scalars['String'] | null),firstTimeWalkMe: Scalars['Boolean'],nickName?: (Scalars['String'] | null),onboarded: Scalars['Boolean'],picture?: (Scalars['String'] | null),promoAmenities: Scalars['Boolean'],promoMoveIn: Scalars['Boolean'],renewals: Scalars['Boolean'],roles?: (Scalars['String'][] | null),tShirtSize?: (Scalars['String'] | null),tasks?: (Scalars['String'][] | null)}

export interface IdentityVerificationResultGenqlSelection{
    details?: boolean | number
    identityOverrideTimestamp?: boolean | number
    isIdentityVerified?: boolean | number
    status?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface IdentityVerificationSummaryGenqlSelection{
    areAllIdentitiesVerified?: boolean | number
    status?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ImageGenqlSelection{
    format?: boolean | number
    resourceId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ImageInput {resourceId: Scalars['ID']}

export interface IncomeReportResultGenqlSelection{
    annualIncome?: boolean | number
    details?: boolean | number
    reportType?: boolean | number
    status?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface IncomeReportSummaryGenqlSelection{
    incomeSum?: boolean | number
    minIncomeThreshold?: boolean | number
    numOfSkipped?: boolean | number
    status?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface InitTokenParamsGenqlSelection{
    expiresAt?: boolean | number
    refreshToken?: boolean | number
    token?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}


/**
 * The interest group entity
 * This entity doesn't exist on the DB but is pulled from chat_client
 */
export interface InterestGroupGenqlSelection{
    createdAt?: boolean | number
    createdByUser?: UserGenqlSelection
    createdByUserId?: boolean | number
    description?: boolean | number
    disabled?: boolean | number
    groupSuggestedByNeighbor?: boolean | number
    id?: boolean | number
    imageUrl?: boolean | number
    isPublic?: boolean | number
    memberCount?: boolean | number
    name?: boolean | number
    socialProfilesPreview?: SocialProfileGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface InterestGroupCreateInput {description: Scalars['String'],imageUrl: Scalars['String'],isPublic: Scalars['Boolean'],name: Scalars['String']}

export interface InterestGroupInsightCategoryGenqlSelection{
    description?: boolean | number
    id?: boolean | number
    name?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface InterestGroupInsightCategoryDiscussionWhereInput {discussionStartedBetween?: (DateRangeInput | null),interestGroupIdsIn?: (Scalars['ID'][] | null),labels?: (Scalars['ID'][] | null),sentiment?: (InterestGroupSentiment | null)}

export interface InterestGroupInsightDiscussionGenqlSelection{
    InterestGroup?: InterestGroupGenqlSelection
    category?: InterestGroupInsightCategoryGenqlSelection
    categoryId?: boolean | number
    endAt?: boolean | number
    id?: boolean | number
    interestGroupId?: boolean | number
    labels?: InterestGroupLabelGenqlSelection
    members?: InterestGroupMemberGenqlSelection
    messageIds?: boolean | number
    messages?: InterestGroupMessageGenqlSelection
    sentiment?: boolean | number
    shortDescription?: boolean | number
    startAt?: boolean | number
    topic?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface InterestGroupInsightDiscussionWhereInput {categoryIdsIn?: (Scalars['ID'][] | null),discussionStartedBetween?: (DateRangeInput | null),interestGroupIdsIn?: (Scalars['ID'][] | null),labels?: (Scalars['ID'][] | null),sentiment?: (InterestGroupSentiment | null)}

export interface InterestGroupInsightSummaryGenqlSelection{
    dateFrom?: boolean | number
    dateTo?: boolean | number
    interestGroupId?: boolean | number
    members?: InterestGroupMemberGenqlSelection
    messageIds?: boolean | number
    summary?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface InterestGroupLabelGenqlSelection{
    color?: boolean | number
    icon?: boolean | number
    id?: boolean | number
    name?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface InterestGroupMemberGenqlSelection{
    user?: UserGenqlSelection
    user_id?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface InterestGroupMembersListGenqlSelection{
    members?: InterestGroupMemberGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface InterestGroupMessageGenqlSelection{
    communityId?: boolean | number
    id?: boolean | number
    interestGroupId?: boolean | number
    organizationId?: boolean | number
    streamCreatedAt?: boolean | number
    streamUpdatedAt?: boolean | number
    text?: boolean | number
    user?: UserGenqlSelection
    userId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface InterestGroupSearchResultGenqlSelection{
    communityId?: boolean | number
    id?: boolean | number
    name?: boolean | number
    organizationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface InterestGroupUpdateInput {description?: (Scalars['String'] | null),disabled?: (Scalars['Boolean'] | null),imageUrl?: (Scalars['String'] | null),isPublic?: (Scalars['Boolean'] | null),name?: (Scalars['String'] | null)}

export interface InterestGroupWhereInput {includePrivate?: (Scalars['Boolean'] | null)}

export interface InterestGroupsAudienceParamsGenqlSelection{
    interestGroupIds?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}


/** Interest groups with paging */
export interface InterestGroupsListGenqlSelection{
    items?: InterestGroupGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface JobItemStatusCountGenqlSelection{
    count?: boolean | number
    status?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface KeyValueParamGenqlSelection{
    key?: boolean | number
    value?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface LeaseAmenityGenqlSelection{
    access?: boolean | number
    amount?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface LeaseAmenityInput {access: Scalars['Boolean'],amount: Scalars['Float']}

export interface LeaseContractGenqlSelection{
    activeTaskLists?: (UserTaskListGenqlSelection & { __args: {where: LeaseContractTaskListWhereInput} })
    bedroom?: BedroomGenqlSelection
    bedroomId?: boolean | number
    contractTimeline?: boolean | number
    endDate?: boolean | number
    /** The external ID of the building coming from the PMS */
    externalId?: boolean | number
    hood?: HoodGenqlSelection
    hoodId?: boolean | number
    id?: boolean | number
    isDraft?: boolean | number
    isTerminatedEarly?: boolean | number
    lateFeeApplicable?: boolean | number
    lateFeeGracePeriod?: boolean | number
    ledger?: LeaseLedgerGenqlSelection
    metadata?: boolean | number
    moveInDate?: boolean | number
    moveOutDate?: boolean | number
    moveOutReason?: boolean | number
    originalEndDate?: boolean | number
    pmsStatus?: boolean | number
    renewalScore?: RenewalRiskScoreGenqlSelection
    renewalSurveyAssignment?: RenewalSurveyAssignmentGenqlSelection
    rent?: boolean | number
    securityDepositAmount?: boolean | number
    startDate?: boolean | number
    status?: boolean | number
    unit?: UnitGenqlSelection
    unitId?: boolean | number
    user?: UserGenqlSelection
    userId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface LeaseContractCreateInput {endDate: Scalars['Time'],organizationId: Scalars['ID'],startDate: Scalars['Time'],unitId: Scalars['ID'],userId: Scalars['ID']}

export interface LeaseContractTaskListWhereInput {type: TaskListType}

export interface LeaseContractWhereInput {buildingIds?: (Scalars['ID'][] | null),contractTimeline?: (ContractTimeline | null),isDraft?: (Scalars['Boolean'] | null),isPrimary?: (Scalars['Boolean'] | null),statusIn?: (ContractStatus[] | null),userIds?: (Scalars['ID'][] | null)}

export interface LeaseContractsListGenqlSelection{
    items?: LeaseContractGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface LeaseEndRange {end: Scalars['Time'],start: Scalars['Time']}

export interface LeaseLedgerGenqlSelection{
    BalanceDue?: boolean | number
    charges?: LeaseLedgerChargeGenqlSelection
    payments?: LeaseLedgerPaymentGenqlSelection
    /** @deprecated Use BalanceDue instead */
    totalUnpaidCharges?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface LeaseLedgerChargeGenqlSelection{
    amount?: boolean | number
    amountPaid?: boolean | number
    code?: boolean | number
    date?: boolean | number
    description?: boolean | number
    id?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface LeaseLedgerPaymentGenqlSelection{
    amount?: boolean | number
    id?: boolean | number
    paidAt?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface LeaseRentRange {end: Scalars['Float'],start: Scalars['Float']}


/**
 * HERE THERE BE DRAGONS!
 * This is a legacy settings struct, the naming is unclear, the existance of
 * the fields is illogical in some cases but for backward compatibility
 * it must be here.
 * 
 * no new fields should be added here ever.
 * we should only remove them one by one managing breaking changes in mobile app.
 */
export interface LegacySettingsGenqlSelection{
    cancelOrderEnabled?: boolean | number
    cancelOrderUserId?: boolean | number
    customCommunityGuidelinesObjectsArray?: boolean | number
    dashboardAdditionalOptions?: boolean | number
    enableMaintenanceChat?: boolean | number
    hasCustomCommunityGuidelines?: boolean | number
    immediatePaymentEnabled?: boolean | number
    landlordImage?: boolean | number
    locksEnabled?: boolean | number
    locksProvider?: boolean | number
    locksSharingEnabled?: boolean | number
    locksSupportPhone?: boolean | number
    maintenanceUserID?: boolean | number
    mobileSettingsIsDemoHood?: boolean | number
    mobileSettingsNewAppDisplayName?: boolean | number
    mobileSettingsNewAppLink?: boolean | number
    mobileSettingsNewAppModalEnabled?: boolean | number
    moveInSettingsAdditionalInterestedParty?: boolean | number
    moveInSettingsEnabled?: boolean | number
    moveInSettingsEnabledUsers?: boolean | number
    moveInSettingsUseHardCodedTemplate?: boolean | number
    packagesEnabled?: boolean | number
    paymentInvoicesEnabled?: boolean | number
    paymentProviderUrl?: boolean | number
    preventUsersFromPostingInFeed?: boolean | number
    supportDescriptionOptionalEnabled?: boolean | number
    supportEntryNotesMandatoryEnabled?: boolean | number
    supportProvider?: boolean | number
    supportSubjectOptionalEnabled?: boolean | number
    supportTicketCovidQuestionsEnabled?: boolean | number
    supportTicketEnabled?: boolean | number
    supportTicketEntryNoteEnabled?: boolean | number
    supportUserId?: boolean | number
    walletEnabled?: boolean | number
    yardiCommentsEmailAddress?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface LockCredentialsGenqlSelection{
    credentials?: boolean | number
    provider?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface LockProviderGenqlSelection{
    adapterId?: boolean | number
    authenticationMethod?: boolean | number
    initTokenParams?: InitTokenParamsGenqlSelection
    isBluetoothEnabled?: boolean | number
    isGuestAccessEnabled?: boolean | number
    oAuthParams?: OAuthParamsGenqlSelection
    provider?: boolean | number
    providerSpecificParams?: KeyValueParamGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface LockProviderInput {accessToken?: (Scalars['String'] | null),adapterId: Scalars['String']}

export interface LockProviderKeyGenqlSelection{
    adapterId?: boolean | number
    id?: boolean | number
    isSharable?: boolean | number
    name?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface LockProviderKeysInput {lockProvidersInput: LockProviderInput[]}

export interface MainApplicantCreateInput {SSN: Scalars['String'],birthDate: Scalars['Time'],buildingId: Scalars['ID'],city: Scalars['String'],email: Scalars['String'],firstName: Scalars['String'],lastName: Scalars['String'],middleName?: (Scalars['String'] | null),organizationId: Scalars['ID'],pets?: (ApplicantPet[] | null),phone: Scalars['String'],state: Scalars['String'],street: Scalars['String'],unitId: Scalars['ID'],zip: Scalars['String']}

export interface MainApplicantPublicDataGenqlSelection{
    email?: boolean | number
    firstName?: boolean | number
    id?: boolean | number
    lastName?: boolean | number
    middleName?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ManagedCommunitiesWhereInput {organizationId: Scalars['ID']}

export interface MoveInFunnelRecordGenqlSelection{
    leaseContract?: LeaseContractGenqlSelection
    leaseContractId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface MoveInFunnelRecordListGenqlSelection{
    pageInfo?: PageInfoGenqlSelection
    records?: MoveInFunnelRecordGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface MoveInFunnelWhereInput {buildingIds?: (Scalars['ID'][] | null),communityIds?: (Scalars['ID'][] | null),leaseStartDateBetween: DateRangeInput,organizationId: Scalars['ID'],status: MoveInFunnelStatus}

export interface MoveInReconcileInput {communityId: Scalars['ID']}

export interface MoveInSettingsGenqlSelection{
    additionalInterestedParty?: boolean | number
    buildingId?: boolean | number
    disableDaysAfterMoveInDate?: boolean | number
    enableDaysBeforeMoveInDate?: boolean | number
    enabledLeaseStatuses?: boolean | number
    enabledScreeningStatuses?: boolean | number
    enabledUsers?: boolean | number
    isEnabled?: boolean | number
    openForRoommates?: boolean | number
    organizationId?: boolean | number
    useHardCodedTemplate?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface MoveInSettingsCreateInput {additionalInterestedParty: Scalars['Boolean'],disableDaysAfterMoveInDate?: (Scalars['Int'] | null),enableDaysBeforeMoveInDate?: (Scalars['Int'] | null),enabledLeaseStatuses?: (ContractStatus[] | null),enabledScreeningStatuses?: (Scalars['String'][] | null),enabledUsers?: (Scalars['String'][] | null),isEnabled: Scalars['Boolean'],openForRoommates: Scalars['Boolean'],useHardCodedTemplate: Scalars['Boolean']}

export interface MoveInSettingsUpdateInput {additionalInterestedParty?: (Scalars['Boolean'] | null),disableDaysAfterMoveInDate?: (Scalars['Int'] | null),enableDaysBeforeMoveInDate?: (Scalars['Int'] | null),enabledLeaseStatuses?: (ContractStatus[] | null),enabledScreeningStatuses?: (Scalars['String'][] | null),enabledUsers?: (Scalars['String'][] | null),isEnabled?: (Scalars['Boolean'] | null),openForRoommates?: (Scalars['Boolean'] | null),useHardCodedTemplate?: (Scalars['Boolean'] | null)}

export interface MutationGenqlSelection{
    ShiftNoteClose?: { __args: {buildingId: Scalars['ID'], id: Scalars['ID'], organizationId: Scalars['ID']} }
    UserTaskListUpdate?: (UserTaskListUpdateResponseGenqlSelection & { __args: {data: UserTaskListUpdateInput, id: Scalars['ID'], organizationId: Scalars['ID']} })
    UserTaskUpdate?: (UserTaskStatusUpdateResponseGenqlSelection & { __args: {data: UserTaskUpdateInput, id: Scalars['ID'], organizationId: Scalars['ID']} })
    adapterCreate?: (AdapterGenqlSelection & { __args: {data: AdapterDataInput} })
    adapterUpdate?: (AdapterGenqlSelection & { __args: {data: AdapterDataInput, id: Scalars['ID']} })
    amenityCalendarCreate?: (AmenityGenqlSelection & { __args: {data: AmenityCalendarCreateInput} })
    amenityCalendarUnavailabilityDelete?: { __args: {id: Scalars['ID']} }
    amenityCalendarUnavailabilitySet?: { __args: {data: amenityCalendarUnavailabilitySetInput} }
    amenityCalendarUpdate?: (AmenityCalendarSettingsGenqlSelection & { __args: {data: AmenityCalendarUpdateInput, id: Scalars['ID']} })
    amenityCancellationRequestDeny?: (AmenityReservationGenqlSelection & { __args: {data: AmenityCancellationDenialInput, id: Scalars['ID']} })
    amenityCreate?: (AmenityGenqlSelection & { __args: {data: AmenityCreateInput} })
    amenityReservationApprove?: (AmenityReservationGenqlSelection & { __args: {id: Scalars['ID']} })
    amenityReservationCancel?: (AmenityReservationGenqlSelection & { __args: {data: AmenityReservationCancelInput, id: Scalars['ID']} })
    amenityReservationCancelAndRefund?: (AmenityReservationGenqlSelection & { __args: {data: AmenityReservationCancelAndRefundInput, id: Scalars['ID']} })
    amenityReservationCancelMany?: { __args: {data: AmenityReservationCancelInput, where: AmenityCancelManyWhereInput} }
    amenityReservationCreate?: (AmenityReservationGenqlSelection & { __args: {data: AmenityReservationCreateInput} })
    amenityReservationCustomCreate?: (AmenityReservationGenqlSelection & { __args: {data: AmenityReservationCustomCreateInput} })
    /** @deprecated use amenityReservationCancel instead */
    amenityReservationDeny?: (AmenityReservationGenqlSelection & { __args: {data: AmenityReservationDenialInput, id: Scalars['ID']} })
    amenityReservationDepositCharge?: (AmenityReservationGenqlSelection & { __args: {data: AmenityReservationDepositChargeInput} })
    amenityReservationDepositCreate?: (AmenityReservationGenqlSelection & { __args: {data: AmenityReservationDepositTakeInput} })
    amenityReservationDepositRelease?: (AmenityReservationGenqlSelection & { __args: {data: AmenityReservationDepositReleaseInput} })
    amenityUpdate?: (AmenityGenqlSelection & { __args: {data: AmenityUpdateInput, id: Scalars['ID']} })
    appDashboardLinkCreate?: (AppDashboardLinkGenqlSelection & { __args: {data: AppDashboardLinkCreateInput} })
    appDashboardLinkDelete?: { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} }
    appDashboardLinkOrder?: { __args: {data: AppDashboardLinkOrderInput[], organizationId: Scalars['ID']} }
    appDashboardLinkUpdate?: (AppDashboardLinkGenqlSelection & { __args: {data: AppDashboardLinkUpdateInput, id: Scalars['ID'], organizationId: Scalars['ID']} })
    applicantConsentCreate?: { __args: {applicantId: Scalars['ID'], buildingId: Scalars['ID'], organizationId: Scalars['ID'], signature: Scalars['String']} }
    applicantCreate?: (ApplicantCreateResponseGenqlSelection & { __args: {input: MainApplicantCreateInput} })
    /** generates a lease for the applicant, when sendToApplicant is true, the lease will be sent to the applicant */
    applicantGenerateLease?: { __args: {buildingId: Scalars['ID'], input: ApplicantGenerateLeaseInput, organizationId: Scalars['ID']} }
    applicantReject?: { __args: {buildingId: Scalars['ID'], input: ApplicantRejectInput, organizationId: Scalars['ID']} }
    applicantScreeningReRun?: { __args: {applicantId: Scalars['ID'], buildingId: Scalars['ID'], organizationId: Scalars['ID']} }
    applicantStripeCheckoutCreate?: (StripeCheckoutSessionGenqlSelection & { __args: {applicantId: Scalars['ID'], buildingId: Scalars['ID'], organizationId: Scalars['ID']} })
    /** invite co applicants */
    applicantsInvite?: (ApplicantsInviteResponseGenqlSelection & { __args: {buildingId: Scalars['ID'], input: ApplicantsInviteInput, organizationId: Scalars['ID']} })
    applicationSettingsUpdate?: (ApplicationSettingsGenqlSelection & { __args: {buildingId: Scalars['ID'], input: ApplicationSettingsInput, organizationId: Scalars['ID']} })
    applicationUnitUpdate?: { __args: {buildingId: Scalars['ID'], input: ApplicationUnitUpdateInput, organizationId: Scalars['ID']} }
    authChallengeCreate?: (AuthChallengeGenqlSelection & { __args: {data: AuthChallengeCreateInput} })
    authChallengeVerify?: (AuthChallengeVerifyResponseGenqlSelection & { __args: {data: AuthChallengeVerifyInput} })
    authTokenRefresh?: (AuthTokenRefreshResponseGenqlSelection & { __args: {data: AuthTokenRefreshInput} })
    automationRuleCreate?: (AutomationRuleGenqlSelection & { __args: {data: AutomationRuleInput, organizationId: Scalars['ID']} })
    automationRuleDelete?: { __args: {organizationID: Scalars['ID'], ruleId: Scalars['ID']} }
    automationRuleSet?: (AutomationRuleGenqlSelection & { __args: {data: AutomationRuleInput, organizationId: Scalars['ID'], ruleId: Scalars['ID']} })
    awayNoticeApprove?: (AwayNoticeGenqlSelection & { __args: {buildingId: Scalars['ID'], id: Scalars['ID'], organizationId: Scalars['ID']} })
    awayNoticeCreate?: (AwayNoticeGenqlSelection & { __args: {buildingId: Scalars['ID'], data: AwayNoticeCreateInput, organizationId: Scalars['ID']} })
    awayNoticeSettingsUpsert?: (AwayNoticeSettingsGenqlSelection & { __args: {data: AwayNoticeSettingsUpsertInput} })
    brandCreate?: (BrandGenqlSelection & { __args: {data: BrandCreateInput} })
    brandDelete?: { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} }
    brandUpdate?: (BrandGenqlSelection & { __args: {data: BrandUpdateInput, id: Scalars['ID'], organizationId: Scalars['ID']} })
    broadcastCreate?: (BroadcastGenqlSelection & { __args: {data: BroadcastCreateInput} })
    broadcastDelete?: { __args: {id: Scalars['ID']} }
    broadcastSend?: { __args: {id: Scalars['ID']} }
    broadcastUpdate?: (BroadcastGenqlSelection & { __args: {data: BroadcastUpdateInput, id: Scalars['ID']} })
    buildingCommunitySet?: (BuildingGenqlSelection & { __args: {buildingId: Scalars['ID'], communityId: Scalars['ID'], organizationId: Scalars['ID']} })
    buildingSettingsSet?: (BuildingSettingsGenqlSelection & { __args: {data: BuildingSettingsSetInput} })
    /** Set status to all units of a given building */
    buildingUnitStatusSet?: (BuildingGenqlSelection & { __args: {buildingId: Scalars['ID'], organizationId: Scalars['ID'], status: UnitStatus} })
    buildingsAutomationRuleCreate?: (AutomationRuleGenqlSelection & { __args: {buildingIds: Scalars['ID'][], data: AutomationRuleInput, organizationId: Scalars['ID']} })
    buildingsTaskListSet?: (BuildingGenqlSelection & { __args: {data: BuildingsTaskListSetInput, organizationId: Scalars['ID']} })
    chatMessageDelete?: { __args: {communityId: Scalars['ID'], messageId: Scalars['ID']} }
    coApplicantUpdate?: (ApplicantUpdateResponseGenqlSelection & { __args: {buildingId: Scalars['ID'], input: CoApplicantUpdateInput, organizationId: Scalars['ID']} })
    communitiesAutomationRuleCreate?: (AutomationRuleGenqlSelection & { __args: {communityIds: Scalars['ID'][], data: AutomationRuleInput, organizationId: Scalars['ID']} })
    /** @deprecated use buildingCommunitySet instead */
    communityBuildingsSet?: (CommunityBuildingsGenqlSelection & { __args: {data: CommunityBuildingsSetInput} })
    communityCreate?: (CommunityGenqlSelection & { __args: {data: CommunityCreateInput} })
    communitySettingsSet?: (CommunitySettingsGenqlSelection & { __args: {data: CommunitySettingsSetInput} })
    communityUpdate?: (CommunityGenqlSelection & { __args: {data: CommunityUpdateInput, id: Scalars['ID']} })
    createRenewalSurveyAssignment?: (RenewalSurveyAssignmentGenqlSelection & { __args: {input: RenewalSurveyAssignmentCreateInput} })
    deliveryPackageCreate?: (DeliveryPackageGenqlSelection & { __args: {buildingId: Scalars['ID'], data: DeliveryPackageCreateInput, organizationId: Scalars['ID']} })
    deliveryPackageDelete?: { __args: {buildingId: Scalars['ID'], organizationId: Scalars['ID'], where: DeliveryPackageWhereInput} }
    deliveryPackagePickupReminderSend?: { __args: {buildingId: Scalars['ID'], id: Scalars['ID'], organizationId: Scalars['ID']} }
    deliveryPackageUpdate?: (DeliveryPackageGenqlSelection & { __args: {buildingId: Scalars['ID'], data: DeliveryPackageUpdateInput, organizationId: Scalars['ID'], where: DeliveryPackageWhereInput} })
    emailDomainCreate?: (EmailDomainGenqlSelection & { __args: {data: EmailDomainCreateInput, organizationId: Scalars['ID']} })
    emailDomainSenderCreate?: (EmailDomainSenderGenqlSelection & { __args: {domainId: Scalars['ID'], input: EmailDomainSenderCreateInput, organizationId: Scalars['ID']} })
    emailDomainSenderUpdate?: (EmailDomainSenderGenqlSelection & { __args: {id: Scalars['ID'], input: EmailDomainSenderUpdateInput, organizationId: Scalars['ID']} })
    emailDomainVerify?: (EmailDomainGenqlSelection & { __args: {domainId: Scalars['ID'], organizationId: Scalars['ID']} })
    emailSetAllowDomainUsersAsSenders?: { __args: {allowDomainUsersAsSenders: Scalars['Boolean'], domainId: Scalars['ID'], organizationId: Scalars['ID']} }
    emailSettingsDelete?: { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} }
    emailSettingsUpdate?: (EmailSettingsGenqlSelection & { __args: {data: EmailSettingsUpdateInput, id: Scalars['ID'], organizationId: Scalars['ID']} })
    emailSystemDefaultSet?: { __args: {domainSenderId: Scalars['ID'], organizationId: Scalars['ID']} }
    embeddedApplicationAdapterCreate?: (EmbeddedApplicationAdapterGenqlSelection & { __args: {data: EmbeddedApplicationAdapterCreateInput} })
    embeddedApplicationAdapterUpdate?: (EmbeddedApplicationAdapterGenqlSelection & { __args: {adapterId: Scalars['ID'], data: EmbeddedApplicationAdapterUpdateInput, organizationId: Scalars['ID']} })
    eventCheckin?: { __args: {communityId?: (Scalars['ID'] | null), id: Scalars['ID'], userIds: Scalars['ID'][]} }
    eventCreate?: (EventGenqlSelection & { __args: {data: EventCreateInput} })
    eventDelete?: { __args: {id: Scalars['ID']} }
    eventUpdate?: (EventGenqlSelection & { __args: {data: EventUpdateInput, id: Scalars['ID']} })
    externalLockProviderGroupCreate?: (externalLockProviderGroupGenqlSelection & { __args: {data: externalLockProviderGroupCreateInput} })
    externalLockProviderGroupDelete?: { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} }
    externalLockProviderGroupUpdate?: (externalLockProviderGroupGenqlSelection & { __args: {data: externalLockProviderGroupUpdateInput, id: Scalars['ID'], organizationId: Scalars['ID']} })
    featuredSlideCreate?: (FeaturedSlideGenqlSelection & { __args: {data: FeaturedSlideCreateInput} })
    featuredSlideDelete?: { __args: {communityId: Scalars['ID'], id: Scalars['ID']} }
    featuredSlideUpdate?: (FeaturedSlideGenqlSelection & { __args: {communityId: Scalars['ID'], data: FeaturedSlideUpdateInput, id: Scalars['ID']} })
    generalInfoPageCreate?: (GeneralInfoPageGenqlSelection & { __args: {communityId: Scalars['ID'], data: GeneralInfoPageCreateInput} })
    generalInfoPageDelete?: { __args: {communityId: Scalars['ID'], id: Scalars['ID']} }
    generalInfoPageUpdate?: (GeneralInfoPageGenqlSelection & { __args: {communityId: Scalars['ID'], data: GeneralInfoPageUpdateInput, id: Scalars['ID']} })
    /** Create an interest group */
    interestGroupCreate?: (InterestGroupGenqlSelection & { __args: {communityId: Scalars['ID'], data: InterestGroupCreateInput, organizationId: Scalars['ID']} })
    /** Delete an interest group */
    interestGroupDelete?: { __args: {communityId: Scalars['ID'], id: Scalars['ID'], organizationId: Scalars['ID']} }
    /** Update an interest group */
    interestGroupUpdate?: (InterestGroupGenqlSelection & { __args: {communityId: Scalars['ID'], data: InterestGroupUpdateInput, id: Scalars['ID'], organizationId: Scalars['ID']} })
    leaseContractCreate?: (LeaseContractGenqlSelection & { __args: {data: LeaseContractCreateInput} })
    leaseContractTerminate?: (LeaseContractGenqlSelection & { __args: {endDate: Scalars['Time'], id: Scalars['ID'], organizationId: Scalars['ID']} })
    me?: MutationUserRootGenqlSelection
    /** @deprecated this will happen automatically */
    moveInReconcile?: { __args: {data: MoveInReconcileInput, organizationId: Scalars['ID']} }
    moveInSettingsCreate?: (MoveInSettingsGenqlSelection & { __args: {buildingId?: (Scalars['ID'] | null), data: MoveInSettingsCreateInput, organizationId: Scalars['ID']} })
    moveInSettingsUpdate?: (MoveInSettingsGenqlSelection & { __args: {buildingId?: (Scalars['ID'] | null), data: MoveInSettingsUpdateInput, organizationId: Scalars['ID']} })
    notificationSnoozeCreate?: (NotificationSnoozeGenqlSelection & { __args: {data: NotificationSnoozeCreateInput} })
    notificationSnoozeUpdate?: (NotificationSnoozeGenqlSelection & { __args: {data: NotificationSnoozeUpdateInput, id: Scalars['ID']} })
    notificationsUpdateMany?: { __args: {data: NotificationUpdateInput, where: NotificationWhereInput} }
    organizationAutomationRuleCreate?: (AutomationRuleGenqlSelection & { __args: {data: AutomationRuleInput, organizationId: Scalars['ID']} })
    organizationCreate?: (OrganizationGenqlSelection & { __args: {data: OrganizationCreateInput} })
    organizationMemberInvite?: (OrganizationMemberGenqlSelection & { __args: {data: OrganizationMemberInviteInput} })
    organizationMemberUpdate?: (OrganizationMemberGenqlSelection & { __args: {data: OrganizationMemberUpdateInput, id: Scalars['ID'], organizationId: Scalars['ID']} })
    organizationUpdate?: (OrganizationGenqlSelection & { __args: {data: OrganizationUpdateInput} })
    /** Overrides the applicant's identity result and sets a permanent value */
    overrideIdentityResult?: { __args: {applicantId: Scalars['ID'], buildingId: Scalars['ID'], organizationId: Scalars['ID'], verified: Scalars['Boolean']} }
    paymentReminderDelete?: { __args: {buildingId: Scalars['ID']} }
    paymentReminderSet?: (PaymentRemindersGenqlSelection & { __args: {data: PaymentRemindersSetInput} })
    periodicSurveyAssignmentsBatchCreate?: (SurveyAssignmentGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    periodicSurveySettingsCreate?: (PeriodicSurveySettingsGenqlSelection & { __args: {data: PeriodicSurveySettingsCreateInput} })
    periodicSurveySettingsSet?: (PeriodicSurveySettingsGenqlSelection & { __args: {data: PeriodicSurveySettingsSetInput, organizationId: Scalars['ID']} })
    plaidAccessTokenCreate?: { __args: {input: PlaidAccessTokenCreateInput} }
    plaidIncomeSkip?: { __args: {applicantId: Scalars['ID'], applicantSubmissionStep: ApplicantSubmissionStep, buildingId: Scalars['ID'], organizationId: Scalars['ID']} }
    plaidLinkTokenCreate?: (PlaidLinkTokenResponseGenqlSelection & { __args: {input: PlaidLinkTokenCreateInput} })
    pmsAdapterCreate?: (PmsAdapterGenqlSelection & { __args: {data: PmsAdapterCreateInput} })
    pmsAdapterTest?: (PmsAdapterTestResultGenqlSelection & { __args: {data: PmsAdapterCreateInput} })
    pmsAdapterUpdate?: (PmsAdapterGenqlSelection & { __args: {adapterId: Scalars['ID'], data: PmsAdapterUpdateInput, organizationId: Scalars['ID']} })
    /** Creates a new pms sync job which will execute the sync process asynchronously */
    pmsSyncJobCreate?: (PmsSyncJobGenqlSelection & { __args: {data: PmsSyncJobCreateInput} })
    reputationCreate?: (ReputationGenqlSelection & { __args: {data: ReputationCreateInput, organizationId: Scalars['ID']} })
    reputationDelete?: { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} }
    reputationUpdate?: (ReputationGenqlSelection & { __args: {data: ReputationUpdateInput, id: Scalars['ID'], organizationId: Scalars['ID']} })
    residentCreate?: (ResidentGenqlSelection & { __args: {data: ResidentCreateInput} })
    scheduledBroadcastCreate?: (ScheduledBroadcastGenqlSelection & { __args: {data: ScheduledBroadcastCreateInput} })
    scheduledBroadcastUpdate?: (ScheduledBroadcastGenqlSelection & { __args: {data: ScheduledBroadcastUpdateInput, id: Scalars['ID']} })
    serviceRequestCancel?: (SupportTicketGenqlSelection & { __args: {buildingId: Scalars['ID'], id: Scalars['ID'], organizationId: Scalars['ID']} })
    serviceRequestCategorySet?: (SupportCategoryGenqlSelection & { __args: {categoryId: Scalars['ID'], data: ServiceRequestCategorySetInput, organizationId: Scalars['ID']} })
    serviceRequestCreate?: (ServiceRequestTicketGenqlSelection & { __args: {buildingId: Scalars['ID'], data: ServiceRequestCreateInput, organizationId: Scalars['ID']} })
    serviceRequestSettingsCreate?: (ServiceRequestsSettingsGenqlSelection & { __args: {buildingId?: (Scalars['ID'] | null), data: ServiceRequestsSettingsInput, organizationId: Scalars['ID']} })
    serviceRequestSettingsSet?: (ServiceRequestsSettingsGenqlSelection & { __args: {buildingId?: (Scalars['ID'] | null), data: ServiceRequestsSettingsSetInput, organizationId: Scalars['ID']} })
    serviceRequestSettingsSurveyIdSet?: (ServiceRequestsSettingsGenqlSelection & { __args: {organizationId: Scalars['ID'], surveyId?: (Scalars['String'] | null)} })
    /** @deprecated user serviceRequestSettingsSet */
    serviceRequestSettingsUpdate?: (ServiceRequestsSettingsGenqlSelection & { __args: {buildingId?: (Scalars['ID'] | null), data: ServiceRequestsSettingsInput, organizationId: Scalars['ID']} })
    serviceRequestUpdate?: (SupportTicketGenqlSelection & { __args: {buildingId: Scalars['ID'], data: ServiceRequestUpdateInput, id: Scalars['ID'], organizationId: Scalars['ID']} })
    serviceRequestsAdapterCreate?: (ServiceRequestsAdapterGenqlSelection & { __args: {data: ServiceRequestsAdapterCreateInput} })
    serviceRequestsAdapterDelete?: { __args: {adapterId: Scalars['ID'], organizationId: Scalars['ID']} }
    serviceRequestsAdapterSetStatusMapping?: (ServiceRequestsAdapterGenqlSelection & { __args: {adapterId: Scalars['ID'], organizationId: Scalars['ID'], statusMapping: StatusMappings} })
    serviceRequestsAdapterTest?: (ServiceRequestsAdapterTestResultGenqlSelection & { __args: {data: ServiceRequestsAdapterCreateInput} })
    serviceRequestsAdapterUpdate?: (ServiceRequestsAdapterGenqlSelection & { __args: {adapterId: Scalars['ID'], data: ServiceRequestsAdapterUpdateInput, organizationId: Scalars['ID']} })
    /** @deprecated user serviceRequestSettingsSet */
    serviceRequestsSetIsEnabled?: (ServiceRequestsSettingsGenqlSelection & { __args: {buildingId?: (Scalars['ID'] | null), isEnabled: Scalars['Boolean'], organizationId: Scalars['ID']} })
    setApplicantSubmissionStep?: { __args: {applicantId: Scalars['ID'], applicantSubmissionStep: ApplicantSubmissionStep, buildingId: Scalars['ID'], organizationId: Scalars['ID']} }
    setAwayNoticeAsArchived?: { __args: {buildingId: Scalars['ID'], id: Scalars['ID'], organizationId: Scalars['ID']} }
    setShiftNotesAsArchived?: { __args: {buildingId: Scalars['ID'], id: Scalars['ID'], organizationId: Scalars['ID']} }
    setVisitorCheckInStatus?: (VisitorGenqlSelection & { __args: {buildingId: Scalars['ID'], id: Scalars['ID'], keyNumber?: (Scalars['String'] | null), organizationId: Scalars['ID']} })
    setVisitorCheckOutStatus?: (VisitorGenqlSelection & { __args: {buildingId: Scalars['ID'], id: Scalars['ID'], organizationId: Scalars['ID']} })
    shiftNoteCreate?: (ShiftNoteGenqlSelection & { __args: {data: ShiftNoteCreateInput} })
    shiftNoteUpdate?: (ShiftNoteGenqlSelection & { __args: {data: ShiftNoteUpdateInput} })
    smartLockAdapterCreate?: (SmartLockAdapterGenqlSelection & { __args: {data: SmartLockAdapterCreateInput} })
    smartLockAdapterDelete?: { __args: {adapterId: Scalars['ID'], organizationId: Scalars['ID']} }
    smartLockAdapterUpdate?: (SmartLockAdapterGenqlSelection & { __args: {adapterId: Scalars['ID'], data: SmartLockAdapterUpdateInput, organizationId: Scalars['ID']} })
    socialProfileSuspend?: { __args: {communityId: Scalars['ID'], suspendDurationInMinutes?: (Scalars['Int'] | null), userId: Scalars['ID']} }
    staffTaskCreate?: (StaffTaskGenqlSelection & { __args: {data: StaffTaskCreateInput} })
    staffTaskDelete?: { __args: {where: StaffTaskWhereUniqueInput} }
    staffTaskUpdate?: (StaffTaskGenqlSelection & { __args: {data: StaffTaskUpdateInput, where: StaffTaskWhereUniqueInput} })
    /** @deprecated Used to create existing account for e2e testing purposes only */
    stripeConnectedAccountAddExisting?: (StripeConnectedAccountGenqlSelection & { __args: {data: StripeConnectedAccountAddExistingInput, organizationId: Scalars['ID']} })
    stripeConnectedAccountCreate?: (StripeConnectedAccountGenqlSelection & { __args: {data: StripeConnectedAccountCreateInput, organizationId: Scalars['ID']} })
    stripeConnectedAccountSessionCreate?: (StripeConnectedAccountSessionGenqlSelection & { __args: {accountId: Scalars['ID'], organizationId: Scalars['ID']} })
    stripeConnectedAccountUpdate?: (StripeConnectedAccountGenqlSelection & { __args: {data: StripeConnectedAccountUpdateInput, id: Scalars['ID'], organizationId: Scalars['ID']} })
    supportTicketCommentCreate?: (SupportTicketCommentGenqlSelection & { __args: {data: SupportTicketCommentCreateInput} })
    supportTicketCreate?: (SupportTicketGenqlSelection & { __args: {data: SupportTicketCreateInput} })
    surveyCreate?: (SurveyGenqlSelection & { __args: {data: SurveyCreateInput} })
    surveyQuestionUpdate?: (SurveyQuestionGenqlSelection & { __args: {data: SurveyQuestionUpdateInput, id: Scalars['ID']} })
    /** @deprecated use me.MySurveyResponseSubmit instead */
    surveyResponseSubmit?: (SurveyAssignmentGenqlSelection & { __args: {data: SurveyResponseSubmitInput} })
    surveyUpdate?: (SurveyGenqlSelection & { __args: {data: SurveyUpdateInput, id: Scalars['ID']} })
    syncJobCreate?: (SyncJobGenqlSelection & { __args: {data: SyncJobCreateInput} })
    syncJobDownloadExport?: (SyncJobDownloadExportResultGenqlSelection & { __args: {data: SyncJobDownloadExportInput} })
    syncJobPrepareExport?: { __args: {data: SyncJobPrepareExportInput} }
    taskCreate?: (TaskGenqlSelection & { __args: {data: TaskCreateInput} })
    taskListCreate?: (TaskListGenqlSelection & { __args: {data: TaskListCreateInput} })
    taskListCreateFrom?: (TaskListGenqlSelection & { __args: {id: Scalars['ID'], input: TaskListCreateFromInput, organizationId: Scalars['ID']} })
    taskListDelete?: { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} }
    taskListTaskCreate?: (TaskListTaskGenqlSelection & { __args: {data: TaskListTaskCreateInput} })
    taskListTaskDelete?: { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} }
    taskListTaskReorder?: (TaskListTaskGenqlSelection & { __args: {data: TaskListTaskReorderInput, organizationId: Scalars['ID']} })
    taskListTaskUpdate?: (TaskListTaskGenqlSelection & { __args: {data: TaskListTaskUpdateInput, id: Scalars['ID'], organizationId: Scalars['ID']} })
    taskListUpdate?: (TaskListGenqlSelection & { __args: {id: Scalars['ID'], input: TaskListUpdateInput, organizationId: Scalars['ID']} })
    taskUpdate?: (TaskGenqlSelection & { __args: {id: Scalars['ID'], input: TaskUpdateInput, organizationId: Scalars['ID']} })
    teamBuildingPermissionsSet?: { __args: {data: TeamBuildingPermissionsSetInput, organizationId: Scalars['ID']} }
    teamBuildingsPermissionsAdd?: { __args: {data: TeamBuildingsPermissionsAddInput, organizationId: Scalars['ID']} }
    teamCreate?: (TeamGenqlSelection & { __args: {data: TeamCreateInput} })
    teamDelete?: { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} }
    teamUpdate?: (TeamGenqlSelection & { __args: {data: TeamUpdateInput, id: Scalars['ID'], organizationId: Scalars['ID']} })
    /** Set unit accepting applications */
    unitIsAcceptingApplicationsSet?: (UnitGenqlSelection & { __args: {buildingId: Scalars['ID'], isAcceptingApplications: Scalars['Boolean'], organizationId: Scalars['ID'], unitIds: Scalars['ID'][]} })
    /** Set unit status */
    unitStatusSet?: (UnitGenqlSelection & { __args: {organizationId: Scalars['ID'], status: UnitStatus, unitIds: Scalars['ID'][]} })
    userLoginApiKey?: (UserLoginApiKeyResponseGenqlSelection & { __args: {data: UserLoginApiKeyInput} })
    visitorCancel?: (VisitorGenqlSelection & { __args: {buildingId: Scalars['ID'], id: Scalars['ID'], organizationId: Scalars['ID']} })
    visitorCreate?: (VisitorGenqlSelection & { __args: {buildingId: Scalars['ID'], data: VisitorUpsertInput, organizationId: Scalars['ID']} })
    visitorUpdate?: (VisitorGenqlSelection & { __args: {buildingId: Scalars['ID'], data: VisitorUpsertInput, id: Scalars['ID'], organizationId: Scalars['ID']} })
    visitorsSettingsUpsert?: (VisitorsSettingsGenqlSelection & { __args: {data: VisitorsSettingsUpsertInput} })
    whiteLabelCreate?: (WhiteLabelGenqlSelection & { __args: {data: WhiteLabelCreateInput} })
    whiteLabelDelete?: { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} }
    whiteLabelUpdate?: (WhiteLabelGenqlSelection & { __args: {data: WhiteLabelUpdateInput, id: Scalars['ID'], organizationId: Scalars['ID']} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface MutationUserRootGenqlSelection{
    ActiveLeaseContracts?: LeaseContractGenqlSelection
    MyAwayNoticeCreate?: (AwayNoticeGenqlSelection & { __args: {data: AwayNoticeCreateInput} })
    MyAwayNoticeSetArchived?: { __args: {id: Scalars['ID']} }
    MyAwayNoticeUpdate?: (AwayNoticeGenqlSelection & { __args: {data: AwayNoticeUpdateInput, id: Scalars['ID']} })
    MyDeliveryPackageStatusSet?: { __args: {packageId: Scalars['String'], pickUpDate?: (Scalars['Time'] | null), status: DeliveryPackageStatus} }
    MyEmbeddedAppAuthenticatedURLGenerate?: (ResidentEmbeddedApplicationDescriptorGenqlSelection & { __args: {EmbeddedAppID: Scalars['ID'], embeddedAppRoute?: (Scalars['String'] | null), leaseContractID: Scalars['ID']} })
    MyEventAttend?: (EventAttendeeGenqlSelection & { __args: {eventId: Scalars['ID']} })
    MyEventCheckIn?: (EventAttendeeGenqlSelection & { __args: {eventId: Scalars['ID']} })
    MyEventCheckInWithScan?: (EventAttendeeGenqlSelection & { __args: {data: EventCheckInWithScanInput} })
    MyEventCheckinCancel?: (EventAttendeeGenqlSelection & { __args: {eventId: Scalars['ID']} })
    MyEventRatingSet?: (EventGenqlSelection & { __args: {eventId: Scalars['ID'], rating: Scalars['Int'], review?: (Scalars['String'] | null)} })
    MyEventSuggest?: (EventGenqlSelection & { __args: {data: EventSuggestionInput} })
    MyEventUnattend?: (EventAttendeeGenqlSelection & { __args: {eventId: Scalars['ID']} })
    MyFirstLoginSet?: boolean | number
    MyGenerateAttachmentsUploadUrl?: (AttachmentsUploadUrlResponseGenqlSelection & { __args: {data: AttachmentsUrlCreateInput} })
    MyHubMetadataSet?: (UserGenqlSelection & { __args: {data: HubMetadataSetInput} })
    MyLockProvidersInitializeAccess?: LockProviderGenqlSelection
    MyMobileLastVisit?: boolean | number
    MyNotificationSendYourselfTest?: (NotificationGenqlSelection & { __args: {data: BroadcastCreateInput} })
    MyNotificationUpdate?: (NotificationGenqlSelection & { __args: {NotificationData: NotificationUpdateInput, id: Scalars['ID']} })
    MyPersonalInformationUpdate?: (UserGenqlSelection & { __args: {data: PersonalInformationUpdateInput} })
    MyPushNotificationTokenRegister?: { __args: {data: RegisterUserDeviceInput} }
    MyPushNotificationTokenUnRegister?: { __args: {data: UnregisterUserDeviceInput} }
    MyRenewalSurveyAssignmentUpdate?: (RenewalSurveyAssignmentGenqlSelection & { __args: {id: Scalars['ID'], input: MyRenewalSurveyAssignmentUpdateInput} })
    MyReservationCancel?: (AmenityReservationGenqlSelection & { __args: {data: AmenityReservationCancelInput, id: Scalars['ID']} })
    /** @deprecated use MyReservationCancel instead */
    MyReservationCancelMany?: { __args: {data: AmenityReservationCancelInput, where: AmenityCancelManyWhereInput} }
    /** @deprecated checkout will be part of the my reservation create mutation */
    MyReservationCheckout?: (MyReservationCheckoutResponseGenqlSelection & { __args: {data: MyReservationCheckoutInput} })
    MyReservationCreate?: (AmenityReservationGenqlSelection & { __args: {data: AmenityReservationUserCreateInput} })
    MyReservationPay?: (AmenityReservationGenqlSelection & { __args: {data: AmenityReservationPayInput} })
    MyReservationRequestCancel?: (AmenityReservationGenqlSelection & { __args: {data: AmenityReservationRequestCancelInput, id: Scalars['ID']} })
    MyServiceRequestCancel?: (SupportTicketGenqlSelection & { __args: {id: Scalars['ID']} })
    MyServiceRequestCommentCreate?: (ServiceRequestCommentGenqlSelection & { __args: {data: MyServiceRequestCommentCreateInput} })
    MyServiceRequestUpdate?: (SupportTicketGenqlSelection & { __args: {id: Scalars['ID'], input: MyServiceRequestUpdateInput} })
    MySmartLockGuestKeyGenerate?: (GuestKeyGenqlSelection & { __args: {data: SmartLockGuestKeyGenerateInput} })
    MySmartLockOAuthTokenGenerate?: (InitTokenParamsGenqlSelection & { __args: {data: SmartLockOAuthTokenGenerateInput} })
    MySmartLockOAuthTokenRefresh?: (InitTokenParamsGenqlSelection & { __args: {data: SmartLockOAuthTokenRefreshInput} })
    MySmartLockRegisterWebhook?: { __args: {data: SmartLockWebhookRegisterInput} }
    MySmartLockUnRegisterWebhook?: { __args: {data: SmartLockWebhookUnregisterInput} }
    MySurveyResponseSubmit?: (SurveyAssignmentGenqlSelection & { __args: {data: SurveyResponseUserSubmitInput} })
    MySurveySnooze?: (SurveyAssignmentGenqlSelection & { __args: {assignmentId: Scalars['ID']} })
    MyTermsAndConditionsAccept?: { __args: {whiteLabelId: Scalars['String']} }
    /** @deprecated use MyThermostatSetState instead */
    MyThermostatSet?: { __args: {data?: (ThermostatTemperatureUpdateInput | null)} } | boolean | number
    MyThermostatSetState?: { __args: {data?: (ThermostatUpdateInput | null)} } | boolean | number
    MyUserTaskListSubmit?: (UserTaskListSubmitResponseGenqlSelection & { __args: {id: Scalars['ID']} })
    MyUserTaskStatusUpdate?: (UserTaskStatusUpdateResponseGenqlSelection & { __args: {id: Scalars['ID'], status: UserTaskStatus} })
    MyUserTaskSubmit?: (MyUserTaskSubmitResponseGenqlSelection & { __args: {data: MyUserTaskSubmitInput, id: Scalars['ID']} })
    MyVisitorCancel?: (VisitorGenqlSelection & { __args: {id: Scalars['ID']} })
    MyVisitorCreate?: (VisitorGenqlSelection & { __args: {data: VisitorUpsertInput} })
    MyVisitorUpdate?: (VisitorGenqlSelection & { __args: {data: VisitorUpsertInput, id: Scalars['ID']} })
    UserId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface MyLeaseContractWhereInput {statusIn?: (ContractStatus[] | null)}

export interface MyRenewalSurveyAssignmentUpdateInput {comment?: (Scalars['String'][] | null),response?: (RenewalSurveyAssignmentResponse | null)}

export interface MyReservationCheckoutInput {amenityId: Scalars['String'],cancelUrl: Scalars['String'],paymentMethod?: (PaymentMethod | null),reservationId: Scalars['String'],successUrl: Scalars['String']}

export interface MyReservationCheckoutResponseGenqlSelection{
    checkoutPageUrl?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface MyServiceRequestCommentCreateInput {body: Scalars['String'],fileKeys?: (Scalars['String'][] | null),ticketId: Scalars['ID']}

export interface MyServiceRequestUpdateInput {ccRoommatesToTicket?: (Scalars['Boolean'] | null),description?: (Scalars['String'] | null),entryNote?: (Scalars['String'] | null),hasPet?: (Scalars['Boolean'] | null),permissionToEnterRoom?: (Scalars['Boolean'] | null),problemValue?: (Scalars['String'] | null),subject?: (Scalars['String'] | null),supportCategoryId?: (Scalars['ID'] | null)}

export interface MyUserTaskSubmitInput {responses?: (UserTaskResponseInput[] | null),status?: (AllowUserTaskStatus | null)}

export interface MyUserTaskSubmitResponseGenqlSelection{
    id?: boolean | number
    responses?: UserTaskResponseGenqlSelection
    userTaskListStatus?: boolean | number
    userTaskStatus?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface NeighborsListGenqlSelection{
    items?: SocialProfileGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    seed?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface NewDiscussionAddedSubscriptionGenqlSelection{
    hoodId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface NotificationGenqlSelection{
    broadcast?: BroadcastGenqlSelection
    category?: NotificationCategoryGenqlSelection
    clickedOnCta?: boolean | number
    createdAt?: boolean | number
    createdBy?: UserGenqlSelection
    createdByUserId?: boolean | number
    cta?: CtaDataGenqlSelection
    deepLink?: DeepLinkGenqlSelection
    id?: boolean | number
    message?: boolean | number
    recipientStatus?: boolean | number
    sourceType?: boolean | number
    status?: boolean | number
    title?: boolean | number
    toUser?: UserGenqlSelection
    toUserId?: boolean | number
    /** Indecentest weather the notification is persisted in the user's inbox or just as a push notification */
    visibleToUser?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface NotificationCategoryGenqlSelection{
    createdAt?: boolean | number
    description?: boolean | number
    id?: boolean | number
    isMandatory?: boolean | number
    name?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface NotificationCategoryWhereInput {isMandatory?: (Scalars['Boolean'] | null)}

export interface NotificationSnoozeGenqlSelection{
    expiration?: boolean | number
    id?: boolean | number
    notificationCategory?: NotificationCategoryGenqlSelection
    userId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface NotificationSnoozeCreateInput {enabled: Scalars['Boolean'],expiration: Scalars['Time'],medium: NotificationMedium,notificationCategoryId: Scalars['ID'],userId: Scalars['ID']}

export interface NotificationSnoozeUpdateInput {enabled: Scalars['Boolean']}

export interface NotificationSnoozeWhereInput {enabled?: (Scalars['Boolean'] | null)}

export interface NotificationSnoozesListGenqlSelection{
    items?: NotificationSnoozeGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface NotificationUpdateInput {clickedOnCta?: (Scalars['Boolean'] | null),recipientStatus?: (NotificationRecipientStatus | null)}

export interface NotificationWhereInput {createdAtGt?: (Scalars['Time'] | null),medium?: (NotificationMedium | null),recipientStatusIn?: ((NotificationRecipientStatus | null)[] | null),recipientStatusNotIn?: ((NotificationRecipientStatus | null)[] | null),sourceTypeIn?: (Scalars['String'][] | null),status?: (NotificationStatus | null),toUserId?: (Scalars['ID'] | null),toUserSearchKeys?: (Scalars['String'][] | null),type?: (NotificationType | null),visibleToUser?: (Scalars['Boolean'] | null)}

export interface NotificationsListGenqlSelection{
    items?: NotificationGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface OAuthParamsGenqlSelection{
    apiBaseURL?: boolean | number
    authURL?: boolean | number
    clientId?: boolean | number
    tokenURL?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface OauthSettingsGenqlSelection{
    apiBaseURL?: boolean | number
    authURL?: boolean | number
    clientId?: boolean | number
    clientSecret?: boolean | number
    tokenURL?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface OauthSettingsInput {apiBaseURL: Scalars['String'],authURL: Scalars['String'],clientId: Scalars['String'],clientSecret: Scalars['String'],tokenURL: Scalars['String']}

export interface OrderedActiveUserTaskListsGenqlSelection{
    userTaskList?: UserTaskListGenqlSelection
    userTasks?: UserTaskGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface OrderedActiveUserTaskListsWhereInput {communityId: Scalars['ID'],type: TaskListType,userId: Scalars['ID']}

export interface OrganizationGenqlSelection{
    buildings?: (BuildingGenqlSelection & { __args?: {where?: (BuildingsWhereInput | null)} })
    communities?: CommunityGenqlSelection
    id?: boolean | number
    logoUrl?: boolean | number
    name?: boolean | number
    subdomain?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface OrganizationCreateInput {organizationName: Scalars['String'],user: UserInviteInput}

export interface OrganizationMemberGenqlSelection{
    id?: boolean | number
    inviteDate?: boolean | number
    inviteStatus?: boolean | number
    isActive?: boolean | number
    organizationId?: boolean | number
    teams?: TeamGenqlSelection
    type?: boolean | number
    user?: UserGenqlSelection
    userId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface OrganizationMemberInviteInput {email: Scalars['String'],firstName?: (Scalars['String'] | null),isActive: Scalars['Boolean'],lastName?: (Scalars['String'] | null),mobilePhone?: (Scalars['String'] | null),organizationId: Scalars['ID'],teamIds?: (Scalars['ID'][] | null),type: OrganizationMemberType}

export interface OrganizationMemberUpdateInput {isActive?: (Scalars['Boolean'] | null),teamIdsSet?: (Scalars['ID'][] | null),type?: (OrganizationMemberType | null)}

export interface OrganizationMembersWhereInput {
/** searchKey used to search by connected user's email, mobile phone and name */
searchKey?: (Scalars['String'] | null)}

export interface OrganizationUpdateInput {logoUrl: Scalars['String'],organizationId: Scalars['ID']}

export interface OrganizerDetails {mobilePhone?: (Scalars['String'] | null),name: Scalars['String']}

export interface PageInfoGenqlSelection{
    totalItems?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PageInput {offset?: Scalars['Int'],size?: Scalars['Int']}


/**  Deprecated: Use PaymentOption instead  */
export interface PaymentDetailsGenqlSelection{
    serviceFee?: boolean | number
    totalPrice?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PaymentDetailsInput {cashDetails?: (CashDetailsInput | null),checkDetails?: (CheckDetailsInput | null),paymentMethod: PaymentMethod}

export interface PaymentOptionGenqlSelection{
    icon?: boolean | number
    isEmbeddedPayment?: boolean | number
    /**  used to determine if the payment option is available to the resident  */
    isSelfService?: boolean | number
    paymentMethod?: boolean | number
    serviceFee?: boolean | number
    totalPrice?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PaymentRemindersGenqlSelection{
    lateReminderSchedule?: PaymentRemindersScheduleGenqlSelection
    reminderSchedule?: PaymentRemindersScheduleGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PaymentRemindersScheduleGenqlSelection{
    dayOfMonth?: boolean | number
    hourOfDay?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PaymentRemindersScheduleInput {dayOfMonth: Scalars['Int'],hourOfDay: Scalars['Int']}

export interface PaymentRemindersSetInput {buildingId: Scalars['ID'],lateReminderSchedule: PaymentRemindersScheduleInput,reminderSchedule: PaymentRemindersScheduleInput}

export interface PeriodicSurveyQuestionResponseStatsGenqlSelection{
    count?: boolean | number
    rating?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PeriodicSurveyQuestionStatsGenqlSelection{
    averageScore?: boolean | number
    questionId?: boolean | number
    responsesStats?: PeriodicSurveyQuestionResponseStatsGenqlSelection
    title?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PeriodicSurveyReportGenqlSelection{
    batchId?: boolean | number
    questionsStats?: PeriodicSurveyQuestionStatsGenqlSelection
    surveyStats?: PeriodicSurveyStatsGenqlSelection
    title?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PeriodicSurveyReportWhereInput {surveyBatchId: Scalars['ID']}

export interface PeriodicSurveySettingsGenqlSelection{
    id?: boolean | number
    minResidentialDays?: boolean | number
    nextRunDate?: boolean | number
    organizationId?: boolean | number
    runIntervalDays?: boolean | number
    surveyId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PeriodicSurveySettingsCreateInput {firstRunDate: Scalars['Time'],minResidentialDays: Scalars['Int'],organizationId: Scalars['ID'],runIntervalDays: Scalars['Int'],surveyId?: (Scalars['ID'] | null)}

export interface PeriodicSurveySettingsSetInput {id: Scalars['ID'],minResidentialDays: Scalars['Int'],runIntervalDays: Scalars['Int'],surveyId?: (Scalars['ID'] | null)}

export interface PeriodicSurveyStatsGenqlSelection{
    completed?: boolean | number
    opened?: boolean | number
    sent?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PermittedBuildingGenqlSelection{
    building?: BuildingGenqlSelection
    buildingId?: boolean | number
    permissions?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PermittedBuildingsWhereInput {organizationId: Scalars['ID']}

export interface PersonalInformationUpdateInput {firstName?: (Scalars['String'] | null),lastName?: (Scalars['String'] | null),profilePictureUrl?: (Scalars['String'] | null)}

export interface PetGenqlSelection{
    bio?: boolean | number
    breed?: boolean | number
    id?: boolean | number
    imgUrl?: boolean | number
    name?: boolean | number
    tags?: boolean | number
    type?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PetCreateInput {bio?: (Scalars['String'] | null),breed?: (Scalars['String'] | null),imgUrl?: (Scalars['String'] | null),name: Scalars['String'],socialProfileId: Scalars['ID'],tags?: ((Scalars['String'] | null)[] | null),type?: (Scalars['String'] | null)}

export interface PetDetailsGenqlSelection{
    age?: boolean | number
    breed?: boolean | number
    color?: boolean | number
    name?: boolean | number
    sex?: boolean | number
    type?: boolean | number
    weight?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PetUpdateInput {bio?: (Scalars['String'] | null),breed?: (Scalars['String'] | null),imgUrl?: (Scalars['String'] | null),name?: (Scalars['String'] | null),tags?: ((Scalars['String'] | null)[] | null),type?: (Scalars['String'] | null)}

export interface PhoneLinkDataGenqlSelection{
    phoneNumber?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PhoneLinkDataInput {phoneNumber: Scalars['String']}

export interface PlaceGenqlSelection{
    address?: AddressGenqlSelection
    addressId?: boolean | number
    building?: BuildingGenqlSelection
    buildingId?: boolean | number
    communityId?: boolean | number
    description?: boolean | number
    id?: boolean | number
    name?: boolean | number
    openingHours?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PlaidAccessTokenCreateInput {applicantId: Scalars['ID'],buildingId: Scalars['ID'],organizationId: Scalars['ID'],publicToken: Scalars['String']}

export interface PlaidLinkTokenCreateInput {applicantId: Scalars['ID'],buildingId: Scalars['ID'],linkType: PlaidLinkType,organizationId: Scalars['ID']}

export interface PlaidLinkTokenResponseGenqlSelection{
    linkToken?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PmsAdapterGenqlSelection{
    endpoint?: boolean | number
    id?: boolean | number
    lastFailureSync?: PmsSyncJobGenqlSelection
    lastFailureSyncJobId?: boolean | number
    lastSuccessSync?: PmsSyncJobGenqlSelection
    lastSuccessSyncJobId?: boolean | number
    lastSyncJob?: PmsSyncJobGenqlSelection
    lastSyncJobId?: boolean | number
    name?: boolean | number
    organization?: OrganizationGenqlSelection
    organizationId?: boolean | number
    periodicSyncEnabled?: boolean | number
    settings?: SecureKeyValueGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PmsAdapterCreateInput {endpoint: Scalars['String'],name: Scalars['String'],organizationId: Scalars['ID'],periodicSyncEnabled: Scalars['Boolean'],settings: SecureKeyValueInput[]}

export interface PmsAdapterTestResultGenqlSelection{
    message?: boolean | number
    success?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PmsAdapterUpdateInput {endpoint?: (Scalars['String'] | null),name?: (Scalars['String'] | null),periodicSyncEnabled?: (Scalars['Boolean'] | null),settings?: (SecureKeyValueInput[] | null)}

export interface PmsSyncJobGenqlSelection{
    adapterId?: boolean | number
    forceUpdate?: boolean | number
    /** @deprecated Use organization id instead */
    hoodId?: boolean | number
    id?: boolean | number
    items?: (PmsSyncJobItemListGenqlSelection & { __args?: {pager?: (PageInput | null), where?: (PmsSyncJobItemsWhereInput | null)} })
    organizationId?: boolean | number
    results?: PmsSyncJobResultGenqlSelection
    startedAt?: boolean | number
    status?: boolean | number
    updatedAt?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PmsSyncJobCreateInput {adapterId: Scalars['ID'],forceUpdate: Scalars['Boolean'],hoodId?: (Scalars['ID'] | null),organizationId: Scalars['ID']}

export interface PmsSyncJobItemGenqlSelection{
    entityData?: boolean | number
    entityId?: boolean | number
    entityType?: boolean | number
    errors?: PmsSyncMessageGenqlSelection
    id?: boolean | number
    jobId?: boolean | number
    status?: boolean | number
    vennId?: boolean | number
    warnings?: PmsSyncMessageGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PmsSyncJobItemListGenqlSelection{
    items?: PmsSyncJobItemGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PmsSyncJobItemsWhereInput {statusIn?: (JobItemStatus[] | null)}

export interface PmsSyncJobResultGenqlSelection{
    countPerStatus?: JobItemStatusCountGenqlSelection
    entityType?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PmsSyncJobWhereInput {adapterId?: (Scalars['ID'] | null),idIn?: (Scalars['ID'][] | null),startedBetween?: (DateRangeInput | null),statusIn?: (PmsSyncJobStatus[] | null),updatedBetween?: (DateRangeInput | null)}

export interface PmsSyncJobsListGenqlSelection{
    PageInfo?: PageInfoGenqlSelection
    items?: PmsSyncJobGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PmsSyncMessageGenqlSelection{
    code?: boolean | number
    message?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PortfolioGenqlSelection{
    id?: boolean | number
    name?: boolean | number
    subdomain?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PortfolioContextFilterGenqlSelection{
    buildingIds?: boolean | number
    hoodIds?: boolean | number
    portfolioId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PortfolioContextFilterInput {buildingIds?: (Scalars['ID'][] | null),hoodIds?: (Scalars['ID'][] | null),portfolioId?: (Scalars['ID'] | null)}

export interface PortfolioRootGenqlSelection{
    contextFilter?: PortfolioContextFilterGenqlSelection
    hoods?: (HoodRootGenqlSelection & { __args?: {ids?: (Scalars['ID'][] | null)} })
    id?: boolean | number
    leaseContracts?: (LeaseContractsListGenqlSelection & { __args?: {orderBy?: (LeaseContractOrderBy[] | null), pager?: (PageInput | null), where?: (LeaseContractWhereInput | null)} })
    pmsSyncJobs?: (PmsSyncJobsListGenqlSelection & { __args?: {where?: (PmsSyncJobWhereInput | null)} })
    portfolioDetails?: PortfolioGenqlSelection
    reports?: ReportsRootGenqlSelection
    /** @deprecated use root level query instead */
    residents?: (ResidentsListGenqlSelection & { __args?: {pager?: (PageInput | null), where?: (ResidentWhereInput | null)} })
    survey?: (SurveyGenqlSelection & { __args: {id: Scalars['ID']} })
    surveys?: SurveysListGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}


/**  Deprecated: Use PaymentOption instead  */
export interface PriceByPaymentMethodGenqlSelection{
    ach?: PaymentDetailsGenqlSelection
    creditCard?: PaymentDetailsGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface QueryGenqlSelection{
    amenities?: (AmenitiesListGenqlSelection & { __args?: {orderBy?: ((AmenitiesOrderBy | null)[] | null), pager?: (PageInput | null), where?: (AmenitiesWhereInput | null)} })
    amenity?: (AmenityGenqlSelection & { __args: {id: Scalars['ID']} })
    amenityCalendarUnavailabilities?: (AmenityCalendarUnavailabilityGenqlSelection & { __args?: {orderBy?: ((AmenityCalendarUnavailabilityOrderBy | null)[] | null), where?: (AmenityCalendarUnavailabilitiesWhereInput | null)} })
    amenityCalendarUnavailability?: (AmenityCalendarUnavailabilityGenqlSelection & { __args: {id: Scalars['ID']} })
    amenityReservation?: (AmenityReservationGenqlSelection & { __args: {id: Scalars['ID']} })
    amenityReservations?: (AmenityReservationGenqlSelection & { __args?: {orderBy?: ((AmenityReservationOrderBy | null)[] | null), where?: (AmenityReservationsWhereInput | null)} })
    appDashboardLink?: (AppDashboardLinkGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    /** app dashboard links */
    appDashboardLinks?: (AppDashboardLinkGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    applicant?: (ApplicantGenqlSelection & { __args: {applicantId: Scalars['ID'], buildingId: Scalars['ID'], organizationId: Scalars['ID']} })
    applicantConsentPreview?: { __args: {applicantId: Scalars['ID'], buildingId: Scalars['ID'], organizationId: Scalars['ID']} }
    applicantPublicData?: (ApplicantPublicDataGenqlSelection & { __args: {applicantId: Scalars['ID']} })
    applicants?: (ApplicantGenqlSelection & { __args: {orderBy?: (ApplicantOrderBy[] | null), where: ApplicantFilter} })
    applicationSettings?: (ApplicationSettingsGenqlSelection & { __args: {buildingId: Scalars['ID'], organizationId: Scalars['ID']} })
    automationDescriptor?: (AutomationDescriptorGenqlSelection & { __args: {automationDescriptorId: Scalars['ID'], organizationId: Scalars['ID']} })
    automationDescriptors?: (AutomationDescriptorGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    automationRules?: (AutomationRuleGenqlSelection & { __args: {communityIds?: (Scalars['ID'][] | null), organizationId: Scalars['ID']} })
    automationTriggerHistory?: (AutomationTriggerHistoryGenqlSelection & { __args: {organizationId: Scalars['ID'], where?: (AutomationTriggerHistoryWhereInput | null)} })
    awayNotice?: (AwayNoticeGenqlSelection & { __args: {buildingId: Scalars['ID'], id: Scalars['ID'], organizationId: Scalars['ID']} })
    awayNoticeSettings?: (AwayNoticeSettingsGenqlSelection & { __args: {buildingId: Scalars['ID'], organizationId: Scalars['ID']} })
    awayNoticeSettingsList?: (AwayNoticeSettingsGenqlSelection & { __args: {buildingsIds: Scalars['ID'][], organizationId: Scalars['ID']} })
    awayNotices?: (AwayNoticeGenqlSelection & { __args: {buildingIds: Scalars['ID'][], orderBy?: (AwayNoticeOrderBy | null), organizationId: Scalars['ID'], where?: (AwayNoticeWhereInput | null)} })
    brand?: (BrandGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    brands?: (BrandGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    broadcast?: (BroadcastGenqlSelection & { __args: {id: Scalars['ID']} })
    broadcasts?: (BroadcastsListGenqlSelection & { __args: {orderBy?: (BroadcastsOrderBy[] | null), organizationId: Scalars['ID'], pager?: (PageInput | null), where: BroadcastWhereInput} })
    building?: (BuildingGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    communities?: (CommunityGenqlSelection & { __args: {organizationId: Scalars['ID'], where?: (CommunityWhereInput | null)} })
    /** Community represents a cluster of buildings that are under the same organization */
    community?: (CommunityGenqlSelection & { __args: {id: Scalars['ID']} })
    deliveryPackage?: (DeliveryPackageGenqlSelection & { __args: {buildingId: Scalars['ID'], organizationId: Scalars['ID'], where: DeliveryPackageWhereInput} })
    deliveryPackages?: (DeliveryPackagesListGenqlSelection & { __args: {buildingIds: Scalars['ID'][], orderBy?: (DeliveryPackageOrderBy | null), organizationId: Scalars['ID'], where?: (DeliveryPackagesWhereInput | null)} })
    emailDomain?: (EmailDomainGenqlSelection & { __args: {domainId: Scalars['ID'], organizationId: Scalars['ID']} })
    emailDomainSender?: (EmailDomainSenderGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    emailDomainSenders?: (EmailDomainSenderGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    emailDomains?: (EmailDomainGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    emailSendersForUser?: (EmailSenderForUserGenqlSelection & { __args: {organizationId: Scalars['ID'], userId: Scalars['ID']} })
    emailSettings?: (EmailSettingsGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    embeddedApplicationAdapter?: (EmbeddedApplicationAdapterGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    embeddedApplicationAdapters?: (EmbeddedApplicationAdapterGenqlSelection & { __args: {organizationId: Scalars['ID'], where?: (EmbeddedApplicationAdapterWhereInput | null)} })
    event?: (EventGenqlSelection & { __args: {id: Scalars['ID']} })
    events?: (EventsListGenqlSelection & { __args: {orderBy?: (EventsOrderBy[] | null), pager?: (PageInput | null), where: EventWhereInput} })
    externalLockProviderGroup?: (externalLockProviderGroupGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    externalLockProviderGroups?: (externalLockProviderGroupListGenqlSelection & { __args: {organizationId: Scalars['ID'], pager?: (PageInput | null)} })
    featuredSlide?: (FeaturedSlideGenqlSelection & { __args: {communityId: Scalars['ID'], id: Scalars['ID']} })
    featuredSlides?: (FeaturedSlideListGenqlSelection & { __args: {orderBy: FeaturedSlidesOrderBy, pager?: (PageInput | null), where?: (FeaturedSlideWhereInput | null)} })
    /** Get a general info page by id */
    generalInfoPage?: (GeneralInfoPageGenqlSelection & { __args: {communityId: Scalars['ID'], id: Scalars['ID']} })
    /** Get all general info pages */
    generalInfoPages?: (GeneralInfoPageListGenqlSelection & { __args: {orderBy: GeneralInfoPagesOrderBy, pager?: (PageInput | null), where: GeneralInfoPageWhereInput} })
    /** @deprecated use community instead */
    hood?: (HoodRootGenqlSelection & { __args: {id: Scalars['ID']} })
    /** Get interest group details */
    interestGroup?: (InterestGroupGenqlSelection & { __args: {communityId: Scalars['ID'], id: Scalars['ID'], organizationId: Scalars['ID']} })
    interestGroupInsightCategories?: (InterestGroupInsightCategoryGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    interestGroupInsightDiscussion?: (InterestGroupInsightDiscussionGenqlSelection & { __args: {communityId: Scalars['ID'], id: Scalars['ID'], organizationId: Scalars['ID']} })
    interestGroupInsightDiscussions?: (InterestGroupInsightDiscussionGenqlSelection & { __args: {communityId: Scalars['ID'], organizationId: Scalars['ID'], where: InterestGroupInsightDiscussionWhereInput} })
    interestGroupInsightSummary?: (InterestGroupInsightSummaryGenqlSelection & { __args: {communityId: Scalars['ID'], id: Scalars['ID'], organizationId: Scalars['ID'], startedBetween?: (DateRangeInput | null)} })
    interestGroupLabels?: (InterestGroupLabelGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    /** Get interest group members */
    interestGroupMembers?: (InterestGroupMembersListGenqlSelection & { __args: {interestGroupId: Scalars['ID']} })
    /** Get interest groups details */
    interestGroups?: (InterestGroupGenqlSelection & { __args: {communityIds?: (Scalars['ID'][] | null), organizationId: Scalars['ID']} })
    leaseContract?: (LeaseContractGenqlSelection & { __args: {id: Scalars['ID']} })
    /** Lease contracts, contracts represent the relationship between a user and a unit in an organization. */
    leaseContracts?: (LeaseContractsListGenqlSelection & { __args: {orderBy?: (LeaseContractOrderBy[] | null), pager?: (PageInput | null), where: LeaseContractWhereInput} })
    /** User query is a root node for all the current logged in user related queries */
    me?: UserRootGenqlSelection
    moveInFunnel?: (MoveInFunnelRecordListGenqlSelection & { __args: {pager?: (PageInput | null), where: MoveInFunnelWhereInput} })
    moveInSettings?: (MoveInSettingsGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    notificationCategories?: (NotificationCategoryGenqlSelection & { __args?: {where?: (NotificationCategoryWhereInput | null)} })
    /** @deprecated use residents.taskLists instead */
    orderedActiveUserTaskLists?: (OrderedActiveUserTaskListsGenqlSelection & { __args: {where: OrderedActiveUserTaskListsWhereInput} })
    /** Organization represents a property management company or a property owner */
    organization?: (OrganizationGenqlSelection & { __args: {id: Scalars['ID']} })
    organizationLogoUrl?: { __args: {id: Scalars['ID']} }
    organizationMember?: (OrganizationMemberGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    organizationMembers?: (OrganizationMemberGenqlSelection & { __args: {organizationId: Scalars['ID'], where?: (OrganizationMembersWhereInput | null)} })
    /** Organizations represents a property management company or a property owner */
    organizations?: OrganizationGenqlSelection
    periodicSurveyReport?: (PeriodicSurveyReportGenqlSelection & { __args: {buildingIds: Scalars['ID'][], organizationId: Scalars['ID'], where: PeriodicSurveyReportWhereInput} })
    periodicSurveySettings?: (PeriodicSurveySettingsGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    plaidVerifiedPreLinkAccountWithIncome?: { __args: {linkToken: Scalars['String']} }
    pmsAdapter?: (PmsAdapterGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    pmsAdapters?: (PmsAdapterGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    /**
     * @deprecated use syncJob instead
     * PMS Sync Job, job represents the synchronization status from a PMS into an organization.
     */
    pmsSyncJob?: (PmsSyncJobGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    /**
     * @deprecated use syncJobs instead
     * PMS Sync Jobs, jobs represent the synchronization status from a PMS into an organization.
     */
    pmsSyncJobs?: (PmsSyncJobsListGenqlSelection & { __args: {organizationId: Scalars['ID'], where: PmsSyncJobWhereInput} })
    /** @deprecated use organization instead */
    portfolio?: (PortfolioRootGenqlSelection & { __args: {contextFilter: PortfolioContextFilterInput} })
    /** @deprecated No longer supported */
    portfolios?: PortfolioGenqlSelection
    reputation?: (ReputationGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    reputations?: (ReputationGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    /** @deprecated using via leaseContract instead */
    residentRenewalRiskScore?: (RenewalRiskScoreGenqlSelection & { __args: {buildingId: Scalars['ID'], id: Scalars['ID'], organizationId: Scalars['ID']} })
    /** Residents are users that hold a lease contract in an organization. */
    residents?: (ResidentsListGenqlSelection & { __args: {pager?: (PageInput | null), where: ResidentWhereInput} })
    /** @deprecated using via residents leaseContracts instead */
    residentsUpForRenewal?: (RenewalRiskScoreGenqlSelection & { __args: {buildingIds: Scalars['ID'][], filters?: (ResidentsUpForRenewalFilter | null), orderBy?: (ResidentRiskSortField | null), organizationId: Scalars['ID']} })
    /**
     * Search for events using keyword search, this will match events based on their:
     * name, building name and place name
     */
    searchEvents?: (EventSearchResultGenqlSelection & { __args: {communityIds: Scalars['ID'][], limit?: Scalars['Int'], organizationId: Scalars['ID'], searchKey: Scalars['String']} })
    /** Search for interest groups using keyword search, this will match interest groups based on their name */
    searchInterestGroups?: (InterestGroupSearchResultGenqlSelection & { __args: {communityIds: Scalars['ID'][], limit?: Scalars['Int'], organizationId: Scalars['ID'], searchKey: Scalars['String']} })
    /**
     * Search for residents using keyword search, this will match residents based on their:
     * First Name, Last Name, Email, Mobile Phone, Unit Name
     */
    searchResidents?: (ResidentSearchResultGenqlSelection & { __args: {communityIds: Scalars['ID'][], limit?: Scalars['Int'], organizationId: Scalars['ID'], searchKey: Scalars['String']} })
    serviceRequestAdapters?: (ServiceRequestsAdapterGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    serviceRequestAttachments?: (ServiceRequestAttachmentGenqlSelection & { __args: {buildingId: Scalars['ID'], serviceRequestTicketId: Scalars['ID']} })
    serviceRequestSettings?: (ServiceRequestsSettingsGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    serviceRequests?: (ServiceRequestListGenqlSelection & { __args: {buildingIds?: (Scalars['ID'][] | null), organizationId: Scalars['ID'], where: ServiceRequestsWhereInput} })
    serviceRequestsAdapter?: (ServiceRequestsAdapterGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    /** @deprecated adapter is created with default status mapping */
    serviceRequestsAdapterDefaultStatusMapping?: (ServiceRequestStatusMappingGenqlSelection & { __args: {adapterId: Scalars['ID'], organizationId: Scalars['ID']} })
    serviceRequestsCategories?: (SupportCategoryGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    shiftNote?: (ShiftNoteGenqlSelection & { __args: {buildingId: Scalars['ID'], id: Scalars['ID'], organizationId: Scalars['ID']} })
    shiftNotes?: (ShiftNoteGenqlSelection & { __args: {orderBy?: (ShiftNoteOrderBy | null), where: ShiftNoteWhereInput} })
    smartLockAdapter?: (SmartLockAdapterGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    smartLockAdapters?: (SmartLockAdapterGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    staffTask?: (StaffTaskGenqlSelection & { __args: {where: StaffTaskWhereUniqueInput} })
    staffTasks?: (StaffTaskGenqlSelection & { __args?: {where?: (StaffTaskWhereInput | null)} })
    stripeConnectedAccounts?: (StripeConnectedAccountGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    survey?: (SurveyGenqlSelection & { __args: {id: Scalars['ID']} })
    surveyQuestionResponseData?: (LeaseContractsListGenqlSelection & { __args: {buildingsIds: Scalars['ID'][], organizationId: Scalars['ID'], pager?: (PageInput | null), where?: (SurveyQuestionResponseWhereInput | null)} })
    surveys?: (SurveysListGenqlSelection & { __args: {where: SurveyWhereInput} })
    surveysBatches?: (SurveyBatchesListGenqlSelection & { __args: {buildingIds: Scalars['ID'][], organizationId: Scalars['ID'], where?: (SurveyBatchWhereInput | null)} })
    /** Sync Job, job represents the synchronization status from a a third-party into an organization. */
    syncJob?: (SyncJobGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    /** Sync Jobs, jobs represent the synchronization status of service requests from a third-party into an organization. */
    syncJobs?: (SyncJobsListGenqlSelection & { __args: {organizationId: Scalars['ID'], where: SyncJobWhereInput} })
    task?: (TaskGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    taskList?: (TaskListGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    taskListOverview?: (TaskListsOverviewResponseGenqlSelection & { __args: {organizationId: Scalars['ID'], where: TaskListsOverviewWhereInput} })
    taskListTask?: (TaskListTaskGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    taskListTasks?: (TaskListTaskGenqlSelection & { __args: {organizationId: Scalars['ID'], where: TaskListTaskWhereInput} })
    taskLists?: (TaskListGenqlSelection & { __args: {organizationId: Scalars['ID'], where: TaskListWhereInput} })
    tasks?: (TaskGenqlSelection & { __args: {organizationId: Scalars['ID'], where: TaskWhereInput} })
    team?: (TeamGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    teamBuildingPermissions?: (TeamBuildingPermissionsGenqlSelection & { __args: {buildingId: Scalars['ID'], organizationId: Scalars['ID'], teamId: Scalars['ID']} })
    teamBuildingPermissionsList?: (TeamBuildingPermissionsGenqlSelection & { __args: {organizationId: Scalars['ID'], teamId: Scalars['ID']} })
    teams?: (TeamGenqlSelection & { __args: {organizationId: Scalars['ID'], where?: (TeamsWhereInput | null)} })
    unit?: (UnitGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    /**
     * Get all units that are accepting applications
     * PUBLIC API
     */
    unitsAcceptingApplications?: (UnitPublicDataGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    /**
     * @deprecated invalid by permission model, use me or residents query instead
     * User in the system identified by email
     */
    user?: (UserGenqlSelection & { __args: {id: Scalars['ID']} })
    userTask?: (UserTaskGenqlSelection & { __args: {id: Scalars['ID']} })
    userTasks?: (UserTaskGenqlSelection & { __args: {where: UserTasksWhereInput} })
    visitor?: (VisitorGenqlSelection & { __args: {buildingId: Scalars['ID'], id: Scalars['ID'], organizationId: Scalars['ID']} })
    visitors?: (VisitorGenqlSelection & { __args: {buildingIds: Scalars['ID'][], orderBy?: (VisitorOrderBy | null), organizationId: Scalars['ID'], where?: (VisitorWhereInput | null)} })
    visitorsSettingsList?: (VisitorsSettingsGenqlSelection & { __args: {buildingIds: Scalars['ID'][], organizationId: Scalars['ID']} })
    whiteLabel?: (WhiteLabelGenqlSelection & { __args: {id: Scalars['ID'], organizationId: Scalars['ID']} })
    whiteLabelConfig?: (WhiteLabelConfigGenqlSelection & { __args: {appIdSuffix: Scalars['String']} })
    whiteLabels?: (WhiteLabelGenqlSelection & { __args: {organizationId: Scalars['ID']} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface QueryParam {key: Scalars['String'],value: Scalars['String']}

export interface RatingGenqlSelection{
    comment?: boolean | number
    score?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface RealEstateAmenityGenqlSelection{
    building?: BuildingGenqlSelection
    buildingId?: boolean | number
    category?: RealEstateAmenityCategoryGenqlSelection
    createdAt?: boolean | number
    currentStatus?: boolean | number
    currentStatusDescription?: boolean | number
    deleted?: boolean | number
    deletedAt?: boolean | number
    id?: boolean | number
    unit?: UnitGenqlSelection
    unitId?: boolean | number
    updatedAt?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface RealEstateAmenityCategoryGenqlSelection{
    displayName?: boolean | number
    hood?: HoodGenqlSelection
    hoodId?: boolean | number
    icon?: boolean | number
    id?: boolean | number
    isPublic?: boolean | number
    manageStatus?: boolean | number
    name?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface RealEstateListingGenqlSelection{
    address?: AddressGenqlSelection
    amenities?: RealEstateAmenityGenqlSelection
    bedroom?: BedroomGenqlSelection
    bedroomId?: boolean | number
    createdAt?: boolean | number
    deleted?: boolean | number
    deletedAt?: boolean | number
    description?: boolean | number
    featuredListing?: boolean | number
    floorPlan?: boolean | number
    hood?: HoodGenqlSelection
    hoodName?: boolean | number
    id?: boolean | number
    images?: boolean | number
    listingPrice?: boolean | number
    name?: boolean | number
    nearBySubways?: SubwayStationGenqlSelection
    promoBadgeTooltip?: boolean | number
    publishInListing?: boolean | number
    relatedListings?: boolean | number
    shortDescription?: boolean | number
    slug?: boolean | number
    unit?: UnitGenqlSelection
    unitId?: boolean | number
    updatedAt?: boolean | number
    virtualTour?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface RefundDetailsInput {cashDetails?: (CashDetailsInput | null),checkDetails?: (CheckDetailsInput | null),refundMethod: RefundMethod}

export interface RegisterUserDeviceInput {deviceId: Scalars['String'],deviceName: Scalars['String'],devicePlatform: DevicePlatform,deviceToken: Scalars['String']}

export interface RenewalRiskScoreGenqlSelection{
    /** @deprecated prevent backward compatibility from residentsUpForRenewal and residentRenewalRiskScore queries using secondaryLeaseContracts instead */
    coOccupantsLeaseIds?: boolean | number
    /** @deprecated prevent backward compatibility from residentsUpForRenewal and residentRenewalRiskScore queries using secondaryLeaseContracts instead */
    coOccupantsLeases?: LeaseContractGenqlSelection
    createdAt?: boolean | number
    id?: boolean | number
    /** @deprecated prevent backward compatibility from residentsUpForRenewal and residentRenewalRiskScore queries */
    mainLeaseContract?: LeaseContractGenqlSelection
    /** @deprecated prevent backward compatibility from residentsUpForRenewal and residentRenewalRiskScore queries */
    mainLeaseContractId?: boolean | number
    medianResolutionTime?: boolean | number
    repeatingRequestsCount?: boolean | number
    riskCategory?: boolean | number
    riskScore?: boolean | number
    secondaryLeaseContracts?: LeaseContractGenqlSelection
    serviceRequestsCount?: boolean | number
    serviceRequestsTopics?: boolean | number
    updatedAt?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface RenewalSurveyAssignmentGenqlSelection{
    comment?: boolean | number
    createdAt?: boolean | number
    id?: boolean | number
    leaseContractEndDate?: boolean | number
    leaseContractId?: boolean | number
    response?: boolean | number
    responseDate?: boolean | number
    status?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface RenewalSurveyAssignmentCreateInput {AutomationRuleId?: (Scalars['String'] | null),LeaseContractEndDate: Scalars['Time'],LeaseContractId: Scalars['String'],ReminderFrequencyInDays: Scalars['Int'],Status: RenewalSurveyAssignmentStatus}

export interface RenewalSurveySettingsGenqlSelection{
    daysBeforeLeaseEnd?: boolean | number
    id?: boolean | number
    isVisibleToUser?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ReportsRootGenqlSelection{
    contextFilter?: PortfolioContextFilterGenqlSelection
    moveInFunnel?: (MoveInFunnelRecordListGenqlSelection & { __args: {pager: PageInput, where: MoveInFunnelWhereInput} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ReputationGenqlSelection{
    buildingsIds?: boolean | number
    id?: boolean | number
    organizationId?: boolean | number
    reputationLink?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ReputationCreateInput {buildingsIds: Scalars['ID'][],reputationLink: Scalars['String']}

export interface ReputationUpdateInput {buildingsIds?: (Scalars['ID'][] | null),reputationLink?: (Scalars['String'] | null)}

export interface ReservationCheckoutResponseGenqlSelection{
    checkoutPageUrl?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ResidentGenqlSelection{
    amenityReservations?: (AmenityReservationGenqlSelection & { __args: {where: AmenityReservationWhereInput} })
    email?: boolean | number
    events?: (EventGenqlSelection & { __args?: {where?: (ResidentEventsWhereInput | null)} })
    firstName?: boolean | number
    lastName?: boolean | number
    leaseContracts?: LeaseContractGenqlSelection
    metadata?: boolean | number
    middleName?: boolean | number
    mobilePhone?: boolean | number
    /** @deprecated use events instead */
    myEvents?: (EventsListGenqlSelection & { __args?: {orderBy?: (EventsOrderBy[] | null), where?: (EventWhereInput | null)} })
    notifications?: NotificationGenqlSelection
    residentSince?: { __args: {organizationId: Scalars['ID']} }
    serviceRequests?: (SupportTicketGenqlSelection & { __args: {where: ServiceRequestsWhereInput} })
    socialProfile?: SocialProfileGenqlSelection
    taskLists?: (UserTaskListGenqlSelection & { __args: {where: ResidentTaskListsWhereInput} })
    user?: UserGenqlSelection
    userId?: boolean | number
    userType?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ResidentCreateInput {leaseContractEndDate: Scalars['Time'],leaseContractStartDate: Scalars['Time'],leaseContractUnitId: Scalars['ID'],organizationId: Scalars['ID'],userEmail: Scalars['String'],userFirstName: Scalars['String'],userLastName: Scalars['String'],userMobilePhone?: (Scalars['String'] | null)}

export interface ResidentEmbeddedApplicationDescriptorGenqlSelection{
    authenticationMethod?: boolean | number
    authenticationSettingsSAML2?: EmbeddedApplicationSaml2SettingsGenqlSelection
    callbackHandlingMethod?: boolean | number
    callbackHandlingSettingsWindowEvent?: EmbeddedApplicationWindowEventSettingsGenqlSelection
    iframeTitle?: boolean | number
    iframeUrl?: boolean | number
    /** @deprecated Use iframe url instead */
    url?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ResidentEmbeddedApplicationsGenqlSelection{
    embeddedAppID?: boolean | number
    endpoint?: boolean | number
    leaseContracts?: boolean | number
    name?: boolean | number
    type?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ResidentEventsWhereInput {communityIdIn?: (Scalars['ID'][] | null),startDateBetween?: (DateRangeInput | null)}


/**
 * Resident search result will return a user per lease contract with the unit name.
 * If a user has multiple lease contracts, they will be returned multiple times.
 */
export interface ResidentSearchResultGenqlSelection{
    email?: boolean | number
    firstName?: boolean | number
    lastName?: boolean | number
    mobilePhone?: boolean | number
    unitName?: boolean | number
    userId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ResidentTaskListsWhereInput {idIn?: (Scalars['ID'][] | null),isActive?: (Scalars['Boolean'] | null),type: TaskListType}

export interface ResidentWhereInput {buildingIdIn?: (Scalars['ID'][] | null),communityIdIn?: (Scalars['ID'][] | null),completedAppOnboarding?: (Scalars['Boolean'] | null),contractEndDateBetween?: (DateRangeInput | null),contractStartDateBetween?: (DateRangeInput | null),contractStatusIn?: (ContractStatus[] | null),contractTimelineIn?: (ContractTimeline[] | null),isContractPrimary?: (Scalars['Boolean'] | null),leaseRentRange?: (LeaseRentRange | null),leaseStatusIn?: (ContractStatus[] | null),
/** searchKey used to search by name, email, and phone */
organizationId?: (Scalars['ID'] | null),renewalSurveyResponseIn?: (RenewalSurveyAssignmentResponse[] | null),riskCategoryIn?: (RiskCategory[] | null),searchKey?: (Scalars['String'] | null),upForRenewal?: (Scalars['Boolean'] | null),userIdIn?: (Scalars['ID'][] | null)}

export interface ResidentsListGenqlSelection{
    items?: ResidentGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ResidentsUpForRenewalFilter {leaseEndRange?: (LeaseEndRange | null),leaseRentRange?: (LeaseRentRange | null),riskCategoryIn?: (RiskCategory[] | null)}

export interface RoleGenqlSelection{
    hood?: HoodGenqlSelection
    id?: boolean | number
    name?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ScheduledBroadcastGenqlSelection{
    endDate?: boolean | number
    id?: boolean | number
    nextNotificationDate?: boolean | number
    scheduledRuleCron?: boolean | number
    startDate?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ScheduledBroadcastCreateInput {broadcastTemplateId: Scalars['ID'],scheduledRuleCron?: (Scalars['String'] | null),startDate?: (Scalars['Time'] | null)}

export interface ScheduledBroadcastUpdateInput {endDate?: (Scalars['Time'] | null),scheduledRuleCron?: (Scalars['String'] | null),startDate?: (Scalars['Time'] | null)}


/**
 * Used to pass a key value pair with a flag to indicate if the value is secure or not
 * if the value is secure, it will be encrypted by the BE.
 */
export interface SecureKeyValueGenqlSelection{
    isSecure?: boolean | number
    key?: boolean | number
    value?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}


/**
 * Used to pass a key value pair with a flag to indicate if the value is secure or not
 * if the value is secure, it will be encrypted by the BE.
 */
export interface SecureKeyValueInput {isSecure: Scalars['Boolean'],key: Scalars['String'],value: Scalars['String']}

export interface ServiceGenqlSelection{
    description?: boolean | number
    id?: boolean | number
    isTermsAndConditionsEnabled?: boolean | number
    name?: boolean | number
    providerName?: boolean | number
    serviceProducts?: (ServiceProductGenqlSelection & { __args?: {where?: (ServiceProductWhereInput | null)} })
    termsAndConditions?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ServiceProductGenqlSelection{
    actualPrice?: boolean | number
    calendar?: CalendarGenqlSelection
    currentUserSubscriptionContractId?: boolean | number
    description?: boolean | number
    discountedPrice?: boolean | number
    id?: boolean | number
    imageResourceId?: boolean | number
    name?: boolean | number
    pickupLocation?: PlaceGenqlSelection
    price?: boolean | number
    priceUnit?: boolean | number
    service?: ServiceGenqlSelection
    serviceProductType?: boolean | number
    subProductId?: boolean | number
    taxAmount?: boolean | number
    taxRate?: TaxRateGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ServiceProductPurchaseInput {calendarBookingDetails?: (CalendarBookingDetailsInput | null),paymentMethodId?: (Scalars['ID'] | null),serviceProductId: Scalars['ID']}

export interface ServiceProductPurchaseResultGenqlSelection{
    clientSecret?: boolean | number
    isSucceeded?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ServiceProductSubscribeInput {externalSubscriptionId: Scalars['ID'],serviceProductId: Scalars['ID']}

export interface ServiceProductWhereInput {published?: (Scalars['Boolean'] | null),serviceId?: (Scalars['ID'] | null)}

export interface ServiceRequestAttachmentGenqlSelection{
    URL?: boolean | number
    contentType?: boolean | number
    fileName?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ServiceRequestCategorySetInput {displayHasPet?: (Scalars['Boolean'] | null),displayPermissionToEnterRoom?: (Scalars['Boolean'] | null),published?: (Scalars['Boolean'] | null),shouldIncludeRoommates?: (Scalars['Boolean'] | null)}

export interface ServiceRequestCommentGenqlSelection{
    attachments?: ServiceRequestAttachmentGenqlSelection
    author?: UserGenqlSelection
    authorId?: boolean | number
    content?: boolean | number
    id?: boolean | number
    postedAt?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ServiceRequestCreateInput {categoryId: Scalars['ID'],ccRoommatesToTicket?: (Scalars['Boolean'] | null),description: Scalars['String'],entryNote?: (Scalars['String'] | null),forLeaseContractId?: (Scalars['ID'] | null),forUnitId?: (Scalars['ID'] | null),hasPet?: (Scalars['Boolean'] | null),metadata?: (Scalars['Any'] | null),permissionToEnterRoom?: (Scalars['Boolean'] | null),subject?: (Scalars['String'] | null),target: ServiceRequestedTarget}

export interface ServiceRequestListGenqlSelection{
    items?: ServiceRequestTicketGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ServiceRequestStatusMappingGenqlSelection{
    externalStatus?: boolean | number
    vennStatus?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ServiceRequestStatusMappingInput {externalStatus: Scalars['String'],vennStatus?: (Scalars['String'] | null)}

export interface ServiceRequestTicketGenqlSelection{
    building?: BuildingGenqlSelection
    buildingId?: boolean | number
    category?: SupportCategoryGenqlSelection
    categoryId?: boolean | number
    closedAt?: boolean | number
    comments?: ServiceRequestCommentGenqlSelection
    description?: boolean | number
    entryNote?: boolean | number
    externalId?: boolean | number
    externalStatus?: boolean | number
    forLeaseContract?: LeaseContractGenqlSelection
    forLeaseContractId?: boolean | number
    forUnit?: UnitGenqlSelection
    forUnitId?: boolean | number
    hasPet?: boolean | number
    havesDownloadableAttachments?: boolean | number
    id?: boolean | number
    openedAt?: boolean | number
    openingManager?: ServiceRequestTicketOpeningManagerDetailsGenqlSelection
    openingManagerId?: boolean | number
    permissionToEnterRoom?: boolean | number
    source?: boolean | number
    status?: boolean | number
    subject?: boolean | number
    target?: boolean | number
    technicianNotes?: boolean | number
    timeToResolutionInMinutes?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ServiceRequestTicketOpeningManagerDetailsGenqlSelection{
    email?: boolean | number
    fullName?: boolean | number
    id?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ServiceRequestUpdateInput {description: Scalars['String'],entryNote: Scalars['String'],externalStatus?: (Scalars['String'] | null),hasPet?: (Scalars['Boolean'] | null),permissionToEnterRoom: Scalars['Boolean'],problemValue?: (Scalars['String'] | null),subject: Scalars['String'],supportCategoryId: Scalars['ID'],technicianNotes?: (Scalars['String'] | null)}

export interface ServiceRequestsAdapterGenqlSelection{
    endpoint?: boolean | number
    id?: boolean | number
    isPeriodicSyncEnabled?: boolean | number
    lastFailureSync?: SyncJobGenqlSelection
    lastFailureSyncJobId?: boolean | number
    lastSuccessSync?: SyncJobGenqlSelection
    lastSuccessSyncJobId?: boolean | number
    lastSyncJob?: SyncJobGenqlSelection
    lastSyncJobId?: boolean | number
    name?: boolean | number
    organization?: OrganizationGenqlSelection
    organizationId?: boolean | number
    settings?: SecureKeyValueGenqlSelection
    statusMapping?: ServiceRequestStatusMappingGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ServiceRequestsAdapterCreateInput {endpoint: Scalars['String'],isPeriodicSyncEnabled: Scalars['Boolean'],name: Scalars['String'],organizationId: Scalars['ID'],settings: SecureKeyValueInput[]}

export interface ServiceRequestsAdapterTestResultGenqlSelection{
    message?: boolean | number
    success?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ServiceRequestsAdapterUpdateInput {endpoint?: (Scalars['String'] | null),isPeriodicSyncEnabled?: (Scalars['Boolean'] | null),name?: (Scalars['String'] | null),settings?: (SecureKeyValueInput[] | null)}

export interface ServiceRequestsSettingsGenqlSelection{
    allowCancellingRequests?: boolean | number
    allowEditingRequests?: boolean | number
    allowedCommentingStatuses?: boolean | number
    areDuplicateRequestsAllowed?: boolean | number
    areEntryNotesRequired?: boolean | number
    buildingId?: boolean | number
    emergencyText?: boolean | number
    hideArchivedRequests?: boolean | number
    isCommentingEnabled?: boolean | number
    isDescriptionRequired?: boolean | number
    isEnabled?: boolean | number
    isHubDashboardEnabled?: boolean | number
    isSubjectRequired?: boolean | number
    organizationId?: boolean | number
    serviceRequestsCategoriesLevelNames?: boolean | number
    shouldDisplayCommentingChatInput?: boolean | number
    shouldDisplayCovidQuestions?: boolean | number
    shouldDisplayEntryNotes?: boolean | number
    shouldDisplayHubDashboardActions?: boolean | number
    shouldDisplaySubject?: boolean | number
    shouldSendEmailOnCreation?: boolean | number
    supportEmail?: boolean | number
    supportPhoneNumber?: boolean | number
    supportedTicketAttachmentsTypes?: boolean | number
    surveyId?: boolean | number
    ticketStatusesNotificationEnabled?: boolean | number
    ticketStatusesNotificationTemplateMapping?: ServiceRequestsSettingsTicketStatusesNotificationTemplateMappingGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ServiceRequestsSettingsInput {allowCancellingRequests?: (Scalars['Boolean'] | null),allowEditingRequests?: (Scalars['Boolean'] | null),allowedCommentingStatuses?: (Scalars['String'][] | null),areDuplicateRequestsAllowed?: (Scalars['Boolean'] | null),areEntryNotesRequired: Scalars['Boolean'],emergencyText?: (Scalars['String'] | null),hideArchivedRequests?: (Scalars['Boolean'] | null),isCommentingEnabled: Scalars['Boolean'],isDescriptionRequired: Scalars['Boolean'],isSubjectRequired: Scalars['Boolean'],shouldDisplayCovidQuestions: Scalars['Boolean'],shouldDisplayEntryNotes: Scalars['Boolean'],shouldSendEmailOnCreation: Scalars['Boolean'],supportEmail?: (Scalars['String'] | null),supportPhoneNumber?: (Scalars['String'] | null),surveyId?: (Scalars['String'] | null),ticketStatusesNotificationEnabled?: (Scalars['String'][] | null),ticketStatusesNotificationTemplateMapping?: (ServiceRequestsSettingsTicketStatusesNotificationTemplateMappingInput[] | null)}

export interface ServiceRequestsSettingsSetInput {ShouldDisplayHubDashboardActions?: (Scalars['Boolean'] | null),allowCancellingRequests?: (Scalars['Boolean'] | null),allowEditingRequests?: (Scalars['Boolean'] | null),allowedCommentingStatuses?: (Scalars['String'][] | null),areDuplicateRequestsAllowed?: (Scalars['Boolean'] | null),areEntryNotesRequired?: (Scalars['Boolean'] | null),emergencyText?: (Scalars['String'] | null),hideArchivedRequests?: (Scalars['Boolean'] | null),isCommentingEnabled?: (Scalars['Boolean'] | null),isDescriptionRequired?: (Scalars['Boolean'] | null),isEnabled?: (Scalars['Boolean'] | null),isHubDashboardEnabled?: (Scalars['Boolean'] | null),isSubjectRequired?: (Scalars['Boolean'] | null),shouldDisplayCovidQuestions?: (Scalars['Boolean'] | null),supportPhoneNumber?: (Scalars['String'] | null),surveyId?: (Scalars['String'] | null),ticketStatusesNotificationEnabled?: (Scalars['String'][] | null),ticketStatusesNotificationTemplateMapping?: (ServiceRequestsSettingsTicketStatusesNotificationTemplateMappingInput[] | null)}

export interface ServiceRequestsSettingsTicketStatusesNotificationTemplateMappingGenqlSelection{
    notificationContent?: supportTicketNotificationTextGenqlSelection
    status?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ServiceRequestsSettingsTicketStatusesNotificationTemplateMappingInput {notificationContent: supportTicketNotificationTextInput,status: SupportTicketStatus}

export interface ServiceRequestsUploadUrlResponseGenqlSelection{
    URL?: boolean | number
    key?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ServiceRequestsWhereInput {categoryIds?: (Scalars['ID'][] | null),ids?: (Scalars['ID'][] | null),startDateGt?: (Scalars['Time'] | null),statusIn?: (SupportTicketStatus[] | null),userIdIn?: (Scalars['ID'][] | null)}

export interface ShiftNoteGenqlSelection{
    building?: BuildingGenqlSelection
    buildingId?: boolean | number
    createdAt?: boolean | number
    createdBy?: UserGenqlSelection
    createdById?: boolean | number
    createdByName?: boolean | number
    id?: boolean | number
    note?: boolean | number
    status?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ShiftNoteCreateInput {buildingId: Scalars['ID'],note: Scalars['String'],organizationId: Scalars['ID']}

export interface ShiftNoteUpdateInput {buildingId: Scalars['ID'],id: Scalars['ID'],note: Scalars['String'],organizationId: Scalars['ID'],status: ShiftNoteStatus}

export interface ShiftNoteWhereInput {buildingIds: Scalars['ID'][],createdAtBT?: (Scalars['Time'] | null),createdAtLT?: (Scalars['Time'] | null),organizationId: Scalars['ID'],search?: (Scalars['String'] | null),statuses?: (ShiftNoteStatus[] | null)}

export interface SmartLockAccessKeyGenerateInput {lockProvider: Scalars['String']}

export interface SmartLockAdapterGenqlSelection{
    AuthenticationMethod?: boolean | number
    IsGuestAccessEnabled?: boolean | number
    IsMasterKeyEnabled?: boolean | number
    OauthSettings?: OauthSettingsGenqlSelection
    buildingIds?: boolean | number
    buildings?: BuildingGenqlSelection
    endpoint?: boolean | number
    grantAccessByMoveDates?: boolean | number
    grantAccessContractStatuses?: boolean | number
    id?: boolean | number
    name?: boolean | number
    organizationId?: boolean | number
    settings?: SecureKeyValueGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SmartLockAdapterCreateInput {AuthenticationMethod?: (AuthenticationMethod | null),IsGuestAccessEnabled?: (Scalars['Boolean'] | null),IsMasterKeyEnabled?: (Scalars['Boolean'] | null),OauthSettings?: (OauthSettingsInput | null),buildingIds: Scalars['ID'][],endpoint: Scalars['String'],grantAccessByMoveDates: Scalars['Boolean'],grantAccessContractStatuses: ContractStatus[],name: Scalars['String'],organizationId: Scalars['ID'],settings: SecureKeyValueInput[]}

export interface SmartLockAdapterUpdateInput {AuthenticationMethod?: (AuthenticationMethod | null),IsGuestAccessEnabled?: (Scalars['Boolean'] | null),IsMasterKeyEnabled?: (Scalars['Boolean'] | null),OauthSettings?: (OauthSettingsInput | null),buildingIds?: (Scalars['ID'][] | null),endpoint?: (Scalars['String'] | null),grantAccessByMoveDates?: (Scalars['Boolean'] | null),grantAccessContractStatuses?: (ContractStatus[] | null),name?: (Scalars['String'] | null),settings?: (SecureKeyValueInput[] | null)}

export interface SmartLockGuestKeyGenerateInput {locks: GuestKeyEntry[]}

export interface SmartLockOAuthTokenGenerateInput {adapterId: Scalars['String'],additionalQueryParams?: (QueryParam[] | null),authorizationCode: Scalars['String'],codeVerifier: Scalars['String'],redirectURL: Scalars['String']}

export interface SmartLockOAuthTokenRefreshInput {adapterId: Scalars['String'],additionalQueryParams?: (QueryParam[] | null),refreshToken: Scalars['String']}

export interface SmartLockWebhookRegisterInput {adapterId: Scalars['String'],deviceToken: Scalars['String'],metadata: Scalars['JSONW']}

export interface SmartLockWebhookUnregisterInput {adapterId: Scalars['String'],deviceToken: Scalars['String'],metadata: Scalars['JSONW']}

export interface SmsLinkDataGenqlSelection{
    body?: boolean | number
    phoneNumber?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SmsLinkDataInput {body: Scalars['String'],phoneNumber: Scalars['String']}


/**
 *  This entity represents interaction between users in the app like:
 * sending good vibes
 */
export interface SocialInteractionGenqlSelection{
    fromSocialProfile?: SocialProfileGenqlSelection
    id?: boolean | number
    toSocialProfile?: SocialProfileGenqlSelection
    type?: boolean | number
    viewed?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}


/** SocialInteraction create inputs args */
export interface SocialInteractionCreateInput {fromUserId: Scalars['ID'],toUserId: Scalars['ID'],type: SocialInteractionType,viewed: Scalars['Boolean']}

export interface SocialInteractionUpdateInput {viewed: Scalars['Boolean']}

export interface SocialInteractionWhereInput {fromUserId: Scalars['ID'],toUserId: Scalars['ID']}

export interface SocialProfileGenqlSelection{
    address?: SocialProfileFieldGenqlSelection
    biography?: SocialProfileFieldGenqlSelection
    blockStatus?: boolean | number
    byline?: BylineGenqlSelection
    callToAction?: SocialProfileCallToActionFieldGenqlSelection
    completionPercentage?: boolean | number
    createdAt?: boolean | number
    dateOfBirth?: SocialProfileFieldGenqlSelection
    firstName?: boolean | number
    id?: boolean | number
    imageId?: boolean | number
    imageUrl?: boolean | number
    interestGroups?: (InterestGroupsListGenqlSelection & { __args?: {pager?: (PageInput | null)} })
    lastName?: boolean | number
    occupation?: SocialProfileFieldGenqlSelection
    pets?: SocialProfilePetsGenqlSelection
    pronouns?: SocialProfilePronounsFieldGenqlSelection
    quote?: SocialProfileFieldGenqlSelection
    socialLinks?: SocialProfileSocialLinksFieldGenqlSelection
    socialTags?: SocialProfileArrayFieldGenqlSelection
    superpowers?: SocialProfileFieldGenqlSelection
    userId?: boolean | number
    website?: SocialProfileFieldGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SocialProfileArrayFieldGenqlSelection{
    privacy?: boolean | number
    value?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SocialProfileCallToActionFieldGenqlSelection{
    privacy?: boolean | number
    target?: boolean | number
    type?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SocialProfileFieldGenqlSelection{
    privacy?: boolean | number
    value?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SocialProfileImageCreateInput {fileName: Scalars['String']}

export interface SocialProfilePetsGenqlSelection{
    pets?: PetGenqlSelection
    privacy?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SocialProfilePronounsFieldGenqlSelection{
    customPronouns?: boolean | number
    privacy?: boolean | number
    value?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SocialProfileSocialLinksFieldGenqlSelection{
    facebook?: SocialProfileFieldGenqlSelection
    instagram?: SocialProfileFieldGenqlSelection
    linkedin?: SocialProfileFieldGenqlSelection
    youtube?: SocialProfileFieldGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SocialProfileUpdateInput {image?: (SocialProfileImageCreateInput | null)}

export interface SocialProfileWhereInput {userId_not: Scalars['ID']}

export interface SocialProfilesListGenqlSelection{
    items?: SocialProfileGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SpecificUsersAudienceParamsGenqlSelection{
    userIds?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SpecificUsersAudienceParamsInput {userIds?: (Scalars['ID'][] | null)}

export interface StaffTaskGenqlSelection{
    assignees?: StaffTaskAssigneeGenqlSelection
    buildingIds?: boolean | number
    createdAt?: boolean | number
    deadline?: boolean | number
    description?: boolean | number
    id?: boolean | number
    name?: boolean | number
    organizationId?: boolean | number
    priority?: boolean | number
    status?: boolean | number
    updatedAt?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface StaffTaskAssigneeGenqlSelection{
    user?: UserGenqlSelection
    userId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface StaffTaskCreateInput {assigneeIds?: (Scalars['ID'][] | null),buildingIds: Scalars['ID'][],deadline?: (Scalars['Time'] | null),description?: (Scalars['String'] | null),name: Scalars['String'],organizationId: Scalars['ID'],priority: Scalars['String'],status: Scalars['String']}

export interface StaffTaskUpdateInput {assigneeIds?: (Scalars['ID'][] | null),buildingIds: Scalars['ID'][],deadline?: (Scalars['Time'] | null),description?: (Scalars['String'] | null),name?: (Scalars['String'] | null),priority?: (Scalars['String'] | null),status?: (Scalars['String'] | null)}

export interface StaffTaskWhereInput {assigneeIds?: (Scalars['ID'][] | null),buildingIds: Scalars['ID'][],name?: (Scalars['String'] | null),organizationId: Scalars['ID'],priority?: (Scalars['String'] | null),searchKey?: (Scalars['String'] | null),status?: (Scalars['String'] | null)}

export interface StaffTaskWhereUniqueInput {buildingIds: Scalars['ID'][],id: Scalars['ID'],organizationId: Scalars['ID']}

export interface StatusMappings {mapping: ServiceRequestStatusMappingInput[]}

export interface StripeCheckoutSessionGenqlSelection{
    url?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface StripeConnectedAccountGenqlSelection{
    achFeePercent?: boolean | number
    chargesEnabled?: boolean | number
    creditCardFeePercent?: boolean | number
    email?: boolean | number
    externalId?: boolean | number
    id?: boolean | number
    name?: boolean | number
    organizationId?: boolean | number
    payoutsEnabled?: boolean | number
    requirementsMissing?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface StripeConnectedAccountAddExistingInput {email: Scalars['String'],externalId: Scalars['ID'],name: Scalars['String']}

export interface StripeConnectedAccountCreateInput {achFeePercent?: (Scalars['Float'] | null),creditCardFeePercent?: (Scalars['Float'] | null),email: Scalars['String'],name: Scalars['String']}

export interface StripeConnectedAccountSessionGenqlSelection{
    clientSecret?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface StripeConnectedAccountUpdateInput {achFeePercent?: (Scalars['Float'] | null),creditCardFeePercent?: (Scalars['Float'] | null)}

export interface SubscriptionContractGenqlSelection{
    id?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SubwayStationGenqlSelection{
    line?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SupportAvailabilityInput {day: SupportDay[],time: SupportTime[]}


/** Support ticket category */
export interface SupportCategoryGenqlSelection{
    buildingId?: boolean | number
    clientVisibleName?: boolean | number
    displayHasPet?: boolean | number
    displayPermissionToEnterRoom?: boolean | number
    id?: boolean | number
    isArchived?: boolean | number
    name?: boolean | number
    parent?: SupportCategoryGenqlSelection
    parentId?: boolean | number
    problemValues?: boolean | number
    published?: boolean | number
    requiresScheduling?: boolean | number
    shouldIncludeRoommates?: boolean | number
    type?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SupportCategoryWhereInput {parentId?: (Scalars['ID'] | null)}

export interface SupportSatisfactionRatingGenqlSelection{
    comment?: boolean | number
    id?: boolean | number
    score?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SupportSurveyResponseInput {comment?: (Scalars['String'] | null),score: SatisfactionScore,supportTicketId: Scalars['ID']}

export interface SupportTicketGenqlSelection{
    category?: SupportCategoryGenqlSelection
    categoryId?: boolean | number
    closedAt?: boolean | number
    comments?: ServiceRequestCommentGenqlSelection
    description?: boolean | number
    entryNote?: boolean | number
    externalId?: boolean | number
    externalStatus?: boolean | number
    hasAttachments?: boolean | number
    hasPet?: boolean | number
    havesDownloadableAttachments?: boolean | number
    id?: boolean | number
    lastUpdated?: boolean | number
    leaseContract?: LeaseContractGenqlSelection
    leaseContractId?: boolean | number
    openedAt?: boolean | number
    openingManager?: ServiceRequestTicketOpeningManagerDetailsGenqlSelection
    openingManagerId?: boolean | number
    permissionToEnterRoom?: boolean | number
    source?: boolean | number
    status?: boolean | number
    subject?: boolean | number
    surveyAssignment?: SurveyAssignmentGenqlSelection
    technicianNotes?: boolean | number
    timeToResolutionInMinutes?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SupportTicketCommentGenqlSelection{
    attachmentUrls?: boolean | number
    body?: boolean | number
    id?: boolean | number
    userId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SupportTicketCommentCreateInput {attachmentUrls?: (Scalars['String'][] | null),body: Scalars['String'],ticketId: Scalars['ID'],userId: Scalars['ID']}

export interface SupportTicketCreateInput {attachmentUrls?: (Scalars['String'][] | null),attachments?: (Scalars['Upload'][] | null),availability?: (SupportAvailabilityInput | null),body: Scalars['String'],ccRoommatesToTicket?: (Scalars['Boolean'] | null),entryNote?: (Scalars['String'] | null),fileKeys?: (Scalars['String'][] | null),hasPet?: (Scalars['Boolean'] | null),hoodId: Scalars['ID'],metadata?: (Scalars['Any'] | null),permissionToEnterRoom?: (Scalars['Boolean'] | null),problemValue?: (Scalars['String'] | null),reporterUserId: Scalars['ID'],subject?: (Scalars['String'] | null),supportCategoryId: Scalars['ID']}

export interface SupportTicketUpdatesSubscriptionGenqlSelection{
    status?: boolean | number
    supportTicketId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SupportTicketWhereInput {categoryId: Scalars['ID'],problemValue?: (Scalars['String'] | null),statusNotIn?: (SupportTicketStatus[] | null)}


/**
 *  The Survey entity
 * Used a survey outside the app and when creating a discussion with a poll
 */
export interface SurveyGenqlSelection{
    assignmentExpirationDays?: boolean | number
    description?: boolean | number
    id?: boolean | number
    isEnabled?: boolean | number
    isPublic?: boolean | number
    name?: boolean | number
    priority?: boolean | number
    /**  connections  */
    questions?: SurveyQuestionGenqlSelection
    snoozeMaxAmount?: boolean | number
    snoozeTTLDays?: boolean | number
    title?: boolean | number
    type?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SurveyAssignmentGenqlSelection{
    batchId?: boolean | number
    expirationDate?: boolean | number
    id?: boolean | number
    relatedEntityId?: boolean | number
    relatedEntityType?: boolean | number
    relatedLeaseContract?: LeaseContractGenqlSelection
    relatedSupportTicket?: SupportTicketGenqlSelection
    snoozeCount?: boolean | number
    snoozeLastDate?: boolean | number
    status?: boolean | number
    survey?: SurveyGenqlSelection
    surveyId?: boolean | number
    user?: UserGenqlSelection
    userId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SurveyAssignmentUserWhereInput {batchIdIn?: (Scalars['String'][] | null),relatedEntityIdIn?: (Scalars['String'][] | null),relatedEntityTypeIn?: (SurveyAssignmentRelatedEntityType[] | null),statusIn?: (SurveyAssignmentStatus[] | null),surveyIdIn?: (Scalars['String'][] | null),surveyTypeIn?: (SurveyType[] | null)}


/** The survey batch entity */
export interface SurveyBatchGenqlSelection{
    id?: boolean | number
    name?: boolean | number
    survey?: SurveyGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SurveyBatchWhereInput {surveyType?: (SurveyType | null)}

export interface SurveyBatchesListGenqlSelection{
    items?: SurveyBatchGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SurveyChoiceOptionGenqlSelection{
    Order?: boolean | number
    Value?: boolean | number
    text?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SurveyCreateInput {assignmentExpirationDays: Scalars['Int'],description: Scalars['String'],hoodId?: (Scalars['ID'] | null),isEnabled?: (Scalars['Boolean'] | null),isPublic: Scalars['Boolean'],name: Scalars['String'],organizationId: Scalars['ID'],priority: Scalars['Int'],questions: SurveyQuestionCreateInput[],snoozeMaxAmount?: (Scalars['Int'] | null),snoozeTTLDays?: (Scalars['Int'] | null),title?: (Scalars['String'] | null),type: SurveyType}


/** The survey question entity */
export interface SurveyQuestionGenqlSelection{
    allowComments?: boolean | number
    choiceOptions?: SurveyChoiceOptionGenqlSelection
    id?: boolean | number
    order?: boolean | number
    scaleOptions?: SurveyScaleOptionGenqlSelection
    survey?: SurveyGenqlSelection
    text?: boolean | number
    type?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SurveyQuestionChoiceOptionCreateInput {order: Scalars['Int'],text: Scalars['String'],value: Scalars['String']}

export interface SurveyQuestionCreateInput {allowComments: Scalars['Boolean'],choiceOptions?: (SurveyQuestionChoiceOptionCreateInput[] | null),order: Scalars['Int'],scaleOptions?: (SurveyQuestionScaleOptionCreateInput | null),text: Scalars['String'],type: SurveyQuestionType}

export interface SurveyQuestionResponseInput {comment?: (Scalars['String'] | null),questionId: Scalars['ID'],value: Scalars['String']}

export interface SurveyQuestionResponseWhereInput {batchId: Scalars['ID'],questionId: Scalars['ID'],rating: Scalars['String']}

export interface SurveyQuestionScaleOptionCreateInput {max: Scalars['Int'],maxText?: (Scalars['String'] | null),min: Scalars['Int'],minText: Scalars['String'],steps: Scalars['Int']}

export interface SurveyQuestionUpdateInput {allowComments?: (Scalars['Boolean'] | null),choiceOptions?: (SurveyQuestionChoiceOptionCreateInput[] | null),id: Scalars['ID'],order?: (Scalars['Int'] | null),scaleOptions?: (SurveyQuestionScaleOptionCreateInput | null),text?: (Scalars['String'] | null),type?: (SurveyQuestionType | null)}

export interface SurveyResponseSubmitInput {responses: SurveyQuestionResponseInput[],surveyAssignmentId: Scalars['ID'],userId: Scalars['ID']}

export interface SurveyResponseUserSubmitInput {isCompleted?: (Scalars['Boolean'] | null),responses: SurveyQuestionResponseInput[],surveyAssignmentId: Scalars['ID']}

export interface SurveyScaleOptionGenqlSelection{
    max?: boolean | number
    maxText?: boolean | number
    min?: boolean | number
    minText?: boolean | number
    steps?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SurveyUpdateInput {description?: (Scalars['String'] | null),isEnabled?: (Scalars['Boolean'] | null),isPublic?: (Scalars['Boolean'] | null),name?: (Scalars['String'] | null),priority?: (Scalars['Int'] | null),questions?: (SurveyQuestionUpdateInput[] | null),snoozeMaxAmount?: (Scalars['Int'] | null),snoozeTTLDays?: (Scalars['Int'] | null),title?: (Scalars['String'] | null)}

export interface SurveyWhereInput {communityIds?: (Scalars['ID'][] | null),organizationId: Scalars['ID']}

export interface SurveysListGenqlSelection{
    items?: SurveyGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SyncJobGenqlSelection{
    adapterId?: boolean | number
    exportStatus?: boolean | number
    forceUpdate?: boolean | number
    id?: boolean | number
    items?: (SyncJobItemListGenqlSelection & { __args?: {pager?: (PageInput | null), where?: (SyncJobItemsWhereInput | null)} })
    organizationId?: boolean | number
    results?: SyncJobResultGenqlSelection
    startedAt?: boolean | number
    status?: boolean | number
    type?: boolean | number
    updatedAt?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SyncJobCreateInput {adapterId: Scalars['ID'],forceUpdate: Scalars['Boolean'],organizationId: Scalars['ID'],type: SyncJobType}

export interface SyncJobDownloadExportInput {organizationId: Scalars['ID'],syncJobId: Scalars['ID']}

export interface SyncJobDownloadExportResultGenqlSelection{
    url?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SyncJobItemGenqlSelection{
    entityData?: boolean | number
    entityId?: boolean | number
    entityType?: boolean | number
    errors?: SyncMessageGenqlSelection
    /** @deprecated item no long have an id, stop using it, for now we return a hash concat of the entity id, type and job id */
    id?: boolean | number
    jobId?: boolean | number
    status?: boolean | number
    vennId?: boolean | number
    warnings?: SyncMessageGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SyncJobItemListGenqlSelection{
    items?: SyncJobItemGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SyncJobItemsWhereInput {entityIdSearchKey?: (Scalars['String'] | null),entityTypeIn?: (JobItemEntityType[] | null),statusIn?: (JobItemStatus[] | null)}

export interface SyncJobPrepareExportInput {organizationId: Scalars['ID'],syncJobId: Scalars['ID']}

export interface SyncJobResultGenqlSelection{
    countPerStatus?: JobItemStatusCountGenqlSelection
    entityType?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SyncJobWhereInput {adapterId?: (Scalars['ID'] | null),idIn?: (Scalars['ID'][] | null),startedBetween?: (DateRangeInput | null),statusIn?: (SyncJobStatus[] | null),updatedBetween?: (DateRangeInput | null)}

export interface SyncJobsListGenqlSelection{
    PageInfo?: PageInfoGenqlSelection
    items?: SyncJobGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SyncMessageGenqlSelection{
    code?: boolean | number
    message?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface TaskGenqlSelection{
    attachmentsBehaviour?: boolean | number
    completedCtaText?: boolean | number
    createdAt?: boolean | number
    displayText?: boolean | number
    embeddedApp?: EmbeddedApplicationAdapterGenqlSelection
    embeddedAppId?: boolean | number
    iconName?: boolean | number
    id?: boolean | number
    name?: boolean | number
    organizationId?: boolean | number
    requiresApproval?: boolean | number
    template?: boolean | number
    templateCss?: boolean | number
    templateHtml?: boolean | number
    type?: boolean | number
    uncompletedCtaText?: boolean | number
    updatedAt?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface TaskCreateInput {attachmentsBehaviour?: (AttachmentsBehavior | null),completedCtaText: Scalars['String'],displayText: Scalars['String'],embeddedAppId?: (Scalars['String'] | null),iconName: Scalars['String'],name: Scalars['String'],organizationId: Scalars['ID'],requiresApproval?: (Scalars['Boolean'] | null),template?: (TaskTemplate | null),templateCss: Scalars['String'],templateHtml: Scalars['String'],type?: (TaskType | null),uncompletedCtaText: Scalars['String']}

export interface TaskListGenqlSelection{
    buildings?: BuildingGenqlSelection
    fullTermsURL?: boolean | number
    hoursUntilDue?: boolean | number
    id?: boolean | number
    isConnectedToUsers?: boolean | number
    /** @deprecated No longer supported */
    isDefault?: boolean | number
    name?: boolean | number
    organizationId?: boolean | number
    requireApproval?: boolean | number
    shortTerms?: boolean | number
    task?: (TaskListTaskGenqlSelection & { __args: {id: Scalars['ID']} })
    tasks?: TaskListTaskGenqlSelection
    type?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface TaskListCreateFromInput {name: Scalars['String'],type: TaskListType}

export interface TaskListCreateInput {fullTermsURL?: (Scalars['String'] | null),hoursUntilDue?: (Scalars['Int'] | null),isDefault?: (Scalars['Boolean'] | null),name: Scalars['String'],organizationId: Scalars['ID'],requireApproval?: (Scalars['Boolean'] | null),shortTerms?: (Scalars['String'] | null),type: TaskListType}

export interface TaskListTaskGenqlSelection{
    id?: boolean | number
    isOptional?: boolean | number
    order?: boolean | number
    organizationId?: boolean | number
    tMinusDays?: boolean | number
    task?: TaskGenqlSelection
    taskId?: boolean | number
    taskList?: TaskListGenqlSelection
    taskListId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface TaskListTaskCreateInput {isOptional: Scalars['Boolean'],order: Scalars['Int'],organizationId: Scalars['ID'],tMinusInDays: Scalars['Int'],taskId: Scalars['ID'],taskListId: Scalars['ID']}

export interface TaskListTaskReorderInput {order: Scalars['Int'],taskListTaskId: Scalars['ID']}

export interface TaskListTaskUpdateInput {isOptional?: (Scalars['Boolean'] | null),tMinusInDays?: (Scalars['Int'] | null),taskId: Scalars['ID']}

export interface TaskListTaskWhereInput {taskIdsIn?: ((Scalars['ID'] | null)[] | null),taskListIdsIn?: ((Scalars['ID'] | null)[] | null)}

export interface TaskListUpdateInput {fullTermsURL?: (Scalars['String'] | null),hoursUntilDue?: (Scalars['Int'] | null),name?: (Scalars['String'] | null),requireApproval?: (Scalars['Boolean'] | null),shortTerms?: (Scalars['String'] | null),type?: (TaskListType | null)}

export interface TaskListWhereInput {Ids?: (Scalars['ID'][] | null),buildingIds?: (Scalars['ID'][] | null)}

export interface TaskListsOverviewResponseGenqlSelection{
    inspectionTaskList?: UserTaskListGenqlSelection
    leaseContract?: LeaseContractGenqlSelection
    moveInTaskList?: UserTaskListGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface TaskListsOverviewWhereInput {buildingIds: Scalars['ID'][],communityIds?: (Scalars['ID'][] | null),leaseContractIdIn?: (Scalars['ID'][] | null),leaseStartDateBetween?: (DateRangeInput | null)}

export interface TaskUpdateInput {attachmentsBehavior?: (AttachmentsBehavior | null),completedCtaText?: (Scalars['String'] | null),displayText?: (Scalars['String'] | null),embeddedAppId?: (Scalars['String'] | null),iconName?: (Scalars['String'] | null),name?: (Scalars['String'] | null),requireApproval?: (Scalars['Boolean'] | null),template?: (TaskTemplate | null),templateCss?: (Scalars['String'] | null),templateHtml?: (Scalars['String'] | null),type?: (TaskType | null),uncompletedCtaText?: (Scalars['String'] | null)}

export interface TaskWhereInput {Ids?: (Scalars['ID'][] | null),searchKey?: (Scalars['String'] | null)}

export interface TaxRateGenqlSelection{
    externalTaxRateId?: boolean | number
    id?: boolean | number
    value?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface TeamGenqlSelection{
    buildingsCount?: boolean | number
    communities?: CommunityGenqlSelection
    communitiesCount?: boolean | number
    id?: boolean | number
    members?: OrganizationMemberGenqlSelection
    membersCount?: boolean | number
    name?: boolean | number
    organizationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface TeamBuildingPermissionsGenqlSelection{
    building?: BuildingGenqlSelection
    buildingId?: boolean | number
    permissions?: boolean | number
    teamId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface TeamBuildingPermissionsSetInput {buildingId: Scalars['ID'],permissionsSet: Permission[],teamId: Scalars['ID']}

export interface TeamBuildingsPermissionsAddInput {buildingIds: Scalars['ID'][],permissions: Permission[],teamId: Scalars['ID']}

export interface TeamCreateInput {communityIds?: (Scalars['ID'][] | null),memberIds?: (Scalars['ID'][] | null),name: Scalars['String'],organizationId: Scalars['ID']}

export interface TeamDeleteInput {id: Scalars['ID']}

export interface TeamUpdateInput {communityIds?: (Scalars['ID'][] | null),memberIds?: (Scalars['ID'][] | null),name?: (Scalars['String'] | null)}

export interface TeamsWhereInput {searchKey?: (Scalars['String'] | null)}

export interface ThermostatGenqlSelection{
    availableDeviceModes?: boolean | number
    availableFanModes?: boolean | number
    buildingId?: boolean | number
    buildingMode?: boolean | number
    currentTemperature?: boolean | number
    deviceMode?: boolean | number
    fanMode?: boolean | number
    id?: boolean | number
    targetTemperature?: boolean | number
    unit?: UnitGenqlSelection
    unitId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ThermostatTemperatureUpdateInput {newTemperature: Scalars['Float'],thermostatId: Scalars['String']}

export interface ThermostatUpdateInput {DeviceMode?: (DeviceMode | null),FanMode?: (FanMode | null),newTemperature?: (Scalars['Float'] | null),thermostatId: Scalars['String']}

export interface TriggerTaskListResponseGenqlSelection{
    id?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UnitGenqlSelection{
    building?: BuildingGenqlSelection
    buildingId?: boolean | number
    /** The external ID of the unit coming from the PMS */
    externalId?: boolean | number
    id?: boolean | number
    isAcceptingApplications?: boolean | number
    name?: boolean | number
    /**
     * Status indicates if the unit is enabled or disabled
     * disabled units will not be available for venn services
     * their residents will not have application access and will not be included in the reports or any other tools
     */
    status?: boolean | number
    /** @deprecated use name instead */
    unitName?: boolean | number
    updatedAt?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UnitPublicDataGenqlSelection{
    buildingId?: boolean | number
    buildingName?: boolean | number
    id?: boolean | number
    name?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UnregisterUserDeviceInput {deviceId: Scalars['String']}

export interface UserGenqlSelection{
    appOnboardingStatus?: boolean | number
    /** @deprecated use residents query instead */
    building?: BuildingGenqlSelection
    /** @deprecated use residents query instead */
    buildingId?: boolean | number
    email?: boolean | number
    /** @deprecated use residents query instead */
    events?: (EventsListGenqlSelection & { __args: {orderBy?: (EventsOrderBy[] | null), pager: PageInput, where?: (EventWhereInput | null)} })
    firstName?: boolean | number
    /** @deprecated use managedCommunities instead */
    hoodsAssigned?: HoodGenqlSelection
    hubMetadata?: HubMetadataGenqlSelection
    id?: boolean | number
    isSystemAdmin?: boolean | number
    lastName?: boolean | number
    /** @deprecated use residents query instead */
    leaseContracts?: (LeaseContractGenqlSelection & { __args?: {where?: (LeaseContractWhereInput | null)} })
    managedCommunities?: (CommunityGenqlSelection & { __args?: {where?: (ManagedCommunitiesWhereInput | null)} })
    metadata?: boolean | number
    middleName?: boolean | number
    mobileLastVisit?: boolean | number
    mobilePhone?: boolean | number
    organizationMemberships?: OrganizationMemberGenqlSelection
    permittedBuildings?: (PermittedBuildingGenqlSelection & { __args: {where: PermittedBuildingsWhereInput} })
    profilePictureUrl?: boolean | number
    /** @deprecated use residents query instead */
    resident?: ResidentGenqlSelection
    /** @deprecated use residents query instead */
    socialProfile?: SocialProfileGenqlSelection
    /** @deprecated use residents query instead */
    unit?: UnitGenqlSelection
    /** @deprecated use residents query instead */
    unitId?: boolean | number
    userType?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UserAggregationsGenqlSelection{
    unopenedNotifications?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UserInviteInput {email: Scalars['String'],firstName: Scalars['String'],lastName: Scalars['String'],mobilePhone?: (Scalars['String'] | null),role?: (UserRole | null)}

export interface UserLoginApiKeyInput {apiKey: Scalars['String'],userId: Scalars['ID']}


/**
 * The token entity
 * Used to return an auth token to a user with an API key
 */
export interface UserLoginApiKeyResponseGenqlSelection{
    token?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UserRootGenqlSelection{
    amenities?: (AmenitiesListGenqlSelection & { __args?: {orderBy?: ((AmenitiesOrderBy | null)[] | null), pager?: (PageInput | null)} })
    amenity?: (AmenityGenqlSelection & { __args: {id: Scalars['ID']} })
    appSettings?: AppSettingsGenqlSelection
    awayNotice?: (AwayNoticeGenqlSelection & { __args: {id: Scalars['ID']} })
    awayNotices?: (AwayNoticeGenqlSelection & { __args: {leaseContractId: Scalars['ID'], orderBy?: (AwayNoticeOrderBy | null), where?: (AwayNoticeWhereInput | null)} })
    deliveryPackages?: (DeliveryPackagesListGenqlSelection & { __args?: {orderBy?: (DeliveryPackageOrderBy | null), statuses?: (DeliveryPackageStatus[] | null)} })
    event?: (EventGenqlSelection & { __args: {id: Scalars['ID']} })
    events?: (EventGenqlSelection & { __args?: {where?: (EventWhereInput | null)} })
    featureFlags?: (FeatureFlagGenqlSelection & { __args?: {where?: (FeatureFlagWhereInput | null)} })
    /** @deprecated use user.managedCommunities instead */
    hood?: HoodGenqlSelection
    /** @deprecated use user.managedCommunities instead */
    hoodsAssigned?: HoodGenqlSelection
    leaseContracts?: (LeaseContractGenqlSelection & { __args?: {where?: (MyLeaseContractWhereInput | null)} })
    myAttendedEvents?: (EventAttendanceGenqlSelection & { __args?: {communityId?: (Scalars['ID'] | null)} })
    myEventRating?: { __args: {eventId: Scalars['ID']} }
    notification?: (NotificationGenqlSelection & { __args: {id: Scalars['ID']} })
    notificationSnoozes?: (NotificationSnoozeGenqlSelection & { __args?: {where?: (NotificationSnoozeWhereInput | null)} })
    notifications?: (NotificationsListGenqlSelection & { __args?: {orderBy?: (NotificationsOrderBy[] | null), pager?: (PageInput | null), where?: (NotificationWhereInput | null)} })
    /** @deprecated No longer supported */
    orderedActiveUserTaskLists?: (OrderedActiveUserTaskListsGenqlSelection & { __args: {where: OrderedActiveUserTaskListsWhereInput} })
    reservation?: (AmenityReservationGenqlSelection & { __args: {id: Scalars['ID']} })
    reservations?: (AmenityReservationGenqlSelection & { __args?: {orderBy?: ((AmenityReservationOrderBy | null)[] | null), where?: (AmenityReservationsWhereInput | null)} })
    serviceRequest?: (SupportTicketGenqlSelection & { __args: {id: Scalars['ID']} })
    serviceRequestAttachmentUploadUrl?: ServiceRequestsUploadUrlResponseGenqlSelection
    smartHome?: UserSmartHomeRootGenqlSelection
    supportCategories?: (SupportCategoryGenqlSelection & { __args: {where: SupportCategoryWhereInput} })
    supportTickets?: (SupportTicketGenqlSelection & { __args?: {pager?: (PageInput | null), where?: (SupportTicketWhereInput | null)} })
    surveyAssignments?: (SurveyAssignmentGenqlSelection & { __args?: {pager?: (PageInput | null), where?: (SurveyAssignmentUserWhereInput | null)} })
    surveyAssignmentsToDo?: SurveyAssignmentGenqlSelection
    thermostats?: ThermostatGenqlSelection
    user?: UserGenqlSelection
    userId?: boolean | number
    userTask?: (UserTaskGenqlSelection & { __args: {id: Scalars['ID']} })
    visitor?: (VisitorGenqlSelection & { __args: {id: Scalars['ID']} })
    visitors?: (VisitorGenqlSelection & { __args: {orderBy: VisitorOrderBy, where?: (VisitorWhereInput | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UserSmartHomeRootGenqlSelection{
    /** @deprecated use MyLockProvidersInitializeAccess instead */
    lockCredentials?: LockCredentialsGenqlSelection
    lockProviderKeys?: (LockProviderKeyGenqlSelection & { __args?: {data?: (LockProviderKeysInput | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UserTaskGenqlSelection{
    dueDate?: boolean | number
    id?: boolean | number
    pmNotes?: boolean | number
    responses?: UserTaskResponseGenqlSelection
    status?: boolean | number
    taskListTask?: TaskListTaskGenqlSelection
    taskListTaskId?: boolean | number
    user?: UserGenqlSelection
    userId?: boolean | number
    userTaskList?: UserTaskListGenqlSelection
    userTaskListId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UserTaskAttachmentGenqlSelection{
    URL?: boolean | number
    contentType?: boolean | number
    key?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UserTaskAttachmentInput {contentType: Scalars['String'],key: Scalars['String']}

export interface UserTaskListGenqlSelection{
    approvedAt?: boolean | number
    approvedBy?: UserGenqlSelection
    approvedById?: boolean | number
    dueDate?: boolean | number
    id?: boolean | number
    isActive?: boolean | number
    leaseContract?: LeaseContractGenqlSelection
    leaseContractId?: boolean | number
    organizationId?: boolean | number
    status?: boolean | number
    submittedAt?: boolean | number
    task?: (UserTaskGenqlSelection & { __args: {id: Scalars['ID']} })
    taskList?: TaskListGenqlSelection
    taskListId?: boolean | number
    tasks?: UserTaskGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UserTaskListSubmitResponseGenqlSelection{
    id?: boolean | number
    userTaskListStatus?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UserTaskListUpdateInput {status?: (UserTaskListStatus | null)}

export interface UserTaskListUpdateResponseGenqlSelection{
    id?: boolean | number
    status?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UserTaskResponseGenqlSelection{
    attachments?: UserTaskAttachmentGenqlSelection
    text?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UserTaskResponseInput {attachments?: (UserTaskAttachmentInput[] | null),text?: (Scalars['String'] | null)}

export interface UserTaskStatusUpdateResponseGenqlSelection{
    userTaskListStatus?: boolean | number
    userTaskStatus?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UserTaskUpdateInput {Responses?: (UserTaskResponseInput[] | null),pmNotes?: (Scalars['String'] | null),status?: (UserTaskStatus | null)}

export interface UserTasksWhereInput {userTaskListId: Scalars['ID']}

export interface UserWhereInput {unitIn?: (Scalars['String'][] | null)}

export interface UsersListGenqlSelection{
    items?: UserGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ValidatorConstraint {name: ValidationConstraint,param?: (Scalars['String'] | null)}

export interface VisitorGenqlSelection{
    authorizedDeskKey?: boolean | number
    email?: boolean | number
    endDate?: boolean | number
    endTime?: boolean | number
    firstName?: boolean | number
    hasKey?: boolean | number
    id?: boolean | number
    isCanceled?: boolean | number
    isCheckedIn?: boolean | number
    keyNumber?: boolean | number
    lastName?: boolean | number
    leaseContract?: LeaseContractGenqlSelection
    leaseContractId?: boolean | number
    lockoutAssistance?: boolean | number
    notes?: boolean | number
    organizationId?: boolean | number
    phoneNumber?: boolean | number
    recurrenceEndType?: boolean | number
    recurrenceNumOccurrences?: boolean | number
    recurrenceWeekdays?: boolean | number
    startDate?: boolean | number
    startTime?: boolean | number
    status?: boolean | number
    time?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface VisitorUpsertInput {authorizedDeskKey: Scalars['Boolean'],email?: (Scalars['String'] | null),endDate?: (Scalars['Time'] | null),endTime: Scalars['String'],firstName: Scalars['String'],hasKey: Scalars['Boolean'],lastName: Scalars['String'],leaseContractId: Scalars['ID'],lockoutAssistance: Scalars['Boolean'],notes?: (Scalars['String'] | null),phoneNumber?: (Scalars['String'] | null),recurrenceEndType?: (RecurrenceEndType | null),recurrenceNumOccurrences?: (Scalars['Int'] | null),recurrenceWeekdays?: (DayOfWeek[] | null),startDate: Scalars['Time'],startTime: Scalars['String']}

export interface VisitorWhereInput {endDateLt?: (Scalars['Time'] | null),leaseContractIdsIn?: (Scalars['ID'][] | null),searchName?: (Scalars['String'] | null),startDateGt?: (Scalars['Time'] | null),statuses?: (VisitorStatus[] | null),timeIn?: (VisitTime[] | null)}

export interface VisitorsSettingsGenqlSelection{
    buildingId?: boolean | number
    id?: boolean | number
    isAuthorizedDeskKeyEnabled?: boolean | number
    isLockoutAssistanceEnabled?: boolean | number
    isPhysicalKeyEnabled?: boolean | number
    isSmartAccessEnabled?: boolean | number
    isVisitorsEnabled?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface VisitorsSettingsUpsertInput {buildingId: Scalars['ID'],isAuthorizedDeskKeyEnabled?: (Scalars['Boolean'] | null),isLockoutAssistanceEnabled?: (Scalars['Boolean'] | null),isPhysicalKeyEnabled?: (Scalars['Boolean'] | null),isSmartAccessEnabled?: (Scalars['Boolean'] | null),isVisitorsEnabled?: (Scalars['Boolean'] | null),organizationId: Scalars['ID']}

export interface WebLinkDataGenqlSelection{
    openInBrowser?: boolean | number
    url?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface WebLinkDataInput {openInBrowser: Scalars['Boolean'],url: Scalars['String']}

export interface WhereAppSettingsInput {leaseContractId: Scalars['ID']}

export interface WhiteLabelGenqlSelection{
    appIcon?: boolean | number
    appLink?: AppLinkTypeGenqlSelection
    appName?: boolean | number
    brandId?: boolean | number
    id?: boolean | number
    organizationId?: boolean | number
    privacyPolicyURL?: boolean | number
    requireExplicitTermsAcceptance?: boolean | number
    termsAndConditionsURL?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface WhiteLabelConfigGenqlSelection{
    colors?: BrandColorsGenqlSelection
    emailOTPLogo?: boolean | number
    privacyPolicyURL?: boolean | number
    requireExplicitTermsAcceptance?: boolean | number
    termsAndConditionsURL?: boolean | number
    whiteLabelId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface WhiteLabelCreateInput {appIcon: Scalars['String'],appLink: AppLinkTypeInput,appName: Scalars['String'],brandId: Scalars['ID'],organizationId: Scalars['ID'],privacyPolicyURL?: (Scalars['String'] | null),requireExplicitTermsAcceptance?: (Scalars['Boolean'] | null),termsAndConditionsURL?: (Scalars['String'] | null)}

export interface WhiteLabelUpdateInput {appIcon?: (Scalars['String'] | null),appLink?: (AppLinkTypeInput | null),appName?: (Scalars['String'] | null),privacyPolicyURL?: (Scalars['String'] | null),requireExplicitTermsAcceptance?: (Scalars['Boolean'] | null),termsAndConditionsURL?: (Scalars['String'] | null)}

export interface amenityCalendarUnavailabilitySetInput {AmenityIds: Scalars['ID'][],endDate: Scalars['Time'],reason?: (Scalars['String'] | null),startDate: Scalars['Time']}

export interface externalLockProviderGroupGenqlSelection{
    entityId?: boolean | number
    entityType?: boolean | number
    id?: boolean | number
    lockProvider?: boolean | number
    lockProviderGroupId?: boolean | number
    organizationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface externalLockProviderGroupCreateInput {entityId: Scalars['ID'],entityType: EntityType,lockProvider: LockProviderType,lockProviderGroupId: Scalars['String'],organizationId: Scalars['ID']}

export interface externalLockProviderGroupListGenqlSelection{
    items?: externalLockProviderGroupGenqlSelection
    pageInfo?: PageInfoGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface externalLockProviderGroupUpdateInput {entityId: Scalars['ID'],entityType: EntityType,lockProvider: LockProviderType,lockProviderGroupId: Scalars['String']}

export interface interestGroupsAudienceParamsInput {interestGroupIds?: (Scalars['ID'][] | null)}

export interface supportTicketNotificationTextGenqlSelection{
    message?: boolean | number
    title?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface supportTicketNotificationTextInput {message: Scalars['String'],title: Scalars['String']}


    const Adapter_possibleTypes: string[] = ['Adapter']
    export const isAdapter = (obj?: { __typename?: any } | null): obj is Adapter => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAdapter"')
      return Adapter_possibleTypes.includes(obj.__typename)
    }
    


    const AdapterSetting_possibleTypes: string[] = ['AdapterSetting']
    export const isAdapterSetting = (obj?: { __typename?: any } | null): obj is AdapterSetting => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAdapterSetting"')
      return AdapterSetting_possibleTypes.includes(obj.__typename)
    }
    


    const Address_possibleTypes: string[] = ['Address']
    export const isAddress = (obj?: { __typename?: any } | null): obj is Address => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAddress"')
      return Address_possibleTypes.includes(obj.__typename)
    }
    


    const AmenitiesList_possibleTypes: string[] = ['AmenitiesList']
    export const isAmenitiesList = (obj?: { __typename?: any } | null): obj is AmenitiesList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAmenitiesList"')
      return AmenitiesList_possibleTypes.includes(obj.__typename)
    }
    


    const Amenity_possibleTypes: string[] = ['Amenity']
    export const isAmenity = (obj?: { __typename?: any } | null): obj is Amenity => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAmenity"')
      return Amenity_possibleTypes.includes(obj.__typename)
    }
    


    const AmenityBuilding_possibleTypes: string[] = ['AmenityBuilding']
    export const isAmenityBuilding = (obj?: { __typename?: any } | null): obj is AmenityBuilding => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAmenityBuilding"')
      return AmenityBuilding_possibleTypes.includes(obj.__typename)
    }
    


    const AmenityCalendarSettings_possibleTypes: string[] = ['AmenityCalendarSettings']
    export const isAmenityCalendarSettings = (obj?: { __typename?: any } | null): obj is AmenityCalendarSettings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAmenityCalendarSettings"')
      return AmenityCalendarSettings_possibleTypes.includes(obj.__typename)
    }
    


    const AmenityCalendarUnavailability_possibleTypes: string[] = ['AmenityCalendarUnavailability']
    export const isAmenityCalendarUnavailability = (obj?: { __typename?: any } | null): obj is AmenityCalendarUnavailability => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAmenityCalendarUnavailability"')
      return AmenityCalendarUnavailability_possibleTypes.includes(obj.__typename)
    }
    


    const AmenityMedia_possibleTypes: string[] = ['AmenityMedia']
    export const isAmenityMedia = (obj?: { __typename?: any } | null): obj is AmenityMedia => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAmenityMedia"')
      return AmenityMedia_possibleTypes.includes(obj.__typename)
    }
    


    const AmenityOpeningHours_possibleTypes: string[] = ['AmenityOpeningHours']
    export const isAmenityOpeningHours = (obj?: { __typename?: any } | null): obj is AmenityOpeningHours => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAmenityOpeningHours"')
      return AmenityOpeningHours_possibleTypes.includes(obj.__typename)
    }
    


    const AmenityReservation_possibleTypes: string[] = ['AmenityReservation']
    export const isAmenityReservation = (obj?: { __typename?: any } | null): obj is AmenityReservation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAmenityReservation"')
      return AmenityReservation_possibleTypes.includes(obj.__typename)
    }
    


    const AmenityReservationOption_possibleTypes: string[] = ['AmenityReservationOption']
    export const isAmenityReservationOption = (obj?: { __typename?: any } | null): obj is AmenityReservationOption => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAmenityReservationOption"')
      return AmenityReservationOption_possibleTypes.includes(obj.__typename)
    }
    


    const AmenityReservationSettings_possibleTypes: string[] = ['AmenityReservationSettings']
    export const isAmenityReservationSettings = (obj?: { __typename?: any } | null): obj is AmenityReservationSettings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAmenityReservationSettings"')
      return AmenityReservationSettings_possibleTypes.includes(obj.__typename)
    }
    


    const AppDashboardLink_possibleTypes: string[] = ['AppDashboardLink']
    export const isAppDashboardLink = (obj?: { __typename?: any } | null): obj is AppDashboardLink => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAppDashboardLink"')
      return AppDashboardLink_possibleTypes.includes(obj.__typename)
    }
    


    const AppLinkType_possibleTypes: string[] = ['AppLinkType']
    export const isAppLinkType = (obj?: { __typename?: any } | null): obj is AppLinkType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAppLinkType"')
      return AppLinkType_possibleTypes.includes(obj.__typename)
    }
    


    const AppMoveInSettings_possibleTypes: string[] = ['AppMoveInSettings']
    export const isAppMoveInSettings = (obj?: { __typename?: any } | null): obj is AppMoveInSettings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAppMoveInSettings"')
      return AppMoveInSettings_possibleTypes.includes(obj.__typename)
    }
    


    const AppOnboardingScreen_possibleTypes: string[] = ['AppOnboardingScreen']
    export const isAppOnboardingScreen = (obj?: { __typename?: any } | null): obj is AppOnboardingScreen => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAppOnboardingScreen"')
      return AppOnboardingScreen_possibleTypes.includes(obj.__typename)
    }
    


    const AppPageLinkData_possibleTypes: string[] = ['AppPageLinkData']
    export const isAppPageLinkData = (obj?: { __typename?: any } | null): obj is AppPageLinkData => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAppPageLinkData"')
      return AppPageLinkData_possibleTypes.includes(obj.__typename)
    }
    


    const AppReputationSettings_possibleTypes: string[] = ['AppReputationSettings']
    export const isAppReputationSettings = (obj?: { __typename?: any } | null): obj is AppReputationSettings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAppReputationSettings"')
      return AppReputationSettings_possibleTypes.includes(obj.__typename)
    }
    


    const AppSettings_possibleTypes: string[] = ['AppSettings']
    export const isAppSettings = (obj?: { __typename?: any } | null): obj is AppSettings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAppSettings"')
      return AppSettings_possibleTypes.includes(obj.__typename)
    }
    


    const Applicant_possibleTypes: string[] = ['Applicant']
    export const isApplicant = (obj?: { __typename?: any } | null): obj is Applicant => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isApplicant"')
      return Applicant_possibleTypes.includes(obj.__typename)
    }
    


    const ApplicantCreateResponse_possibleTypes: string[] = ['ApplicantCreateResponse']
    export const isApplicantCreateResponse = (obj?: { __typename?: any } | null): obj is ApplicantCreateResponse => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isApplicantCreateResponse"')
      return ApplicantCreateResponse_possibleTypes.includes(obj.__typename)
    }
    


    const ApplicantDocument_possibleTypes: string[] = ['ApplicantDocument']
    export const isApplicantDocument = (obj?: { __typename?: any } | null): obj is ApplicantDocument => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isApplicantDocument"')
      return ApplicantDocument_possibleTypes.includes(obj.__typename)
    }
    


    const ApplicantDocuments_possibleTypes: string[] = ['ApplicantDocuments']
    export const isApplicantDocuments = (obj?: { __typename?: any } | null): obj is ApplicantDocuments => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isApplicantDocuments"')
      return ApplicantDocuments_possibleTypes.includes(obj.__typename)
    }
    


    const ApplicantLeaseTerms_possibleTypes: string[] = ['ApplicantLeaseTerms']
    export const isApplicantLeaseTerms = (obj?: { __typename?: any } | null): obj is ApplicantLeaseTerms => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isApplicantLeaseTerms"')
      return ApplicantLeaseTerms_possibleTypes.includes(obj.__typename)
    }
    


    const ApplicantPublicData_possibleTypes: string[] = ['ApplicantPublicData']
    export const isApplicantPublicData = (obj?: { __typename?: any } | null): obj is ApplicantPublicData => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isApplicantPublicData"')
      return ApplicantPublicData_possibleTypes.includes(obj.__typename)
    }
    


    const ApplicantSummary_possibleTypes: string[] = ['ApplicantSummary']
    export const isApplicantSummary = (obj?: { __typename?: any } | null): obj is ApplicantSummary => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isApplicantSummary"')
      return ApplicantSummary_possibleTypes.includes(obj.__typename)
    }
    


    const ApplicantUpdateResponse_possibleTypes: string[] = ['ApplicantUpdateResponse']
    export const isApplicantUpdateResponse = (obj?: { __typename?: any } | null): obj is ApplicantUpdateResponse => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isApplicantUpdateResponse"')
      return ApplicantUpdateResponse_possibleTypes.includes(obj.__typename)
    }
    


    const ApplicantsInviteResponse_possibleTypes: string[] = ['ApplicantsInviteResponse']
    export const isApplicantsInviteResponse = (obj?: { __typename?: any } | null): obj is ApplicantsInviteResponse => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isApplicantsInviteResponse"')
      return ApplicantsInviteResponse_possibleTypes.includes(obj.__typename)
    }
    


    const ApplicationSettings_possibleTypes: string[] = ['ApplicationSettings']
    export const isApplicationSettings = (obj?: { __typename?: any } | null): obj is ApplicationSettings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isApplicationSettings"')
      return ApplicationSettings_possibleTypes.includes(obj.__typename)
    }
    


    const AssetReportResult_possibleTypes: string[] = ['AssetReportResult']
    export const isAssetReportResult = (obj?: { __typename?: any } | null): obj is AssetReportResult => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAssetReportResult"')
      return AssetReportResult_possibleTypes.includes(obj.__typename)
    }
    


    const AssetReportSummary_possibleTypes: string[] = ['AssetReportSummary']
    export const isAssetReportSummary = (obj?: { __typename?: any } | null): obj is AssetReportSummary => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAssetReportSummary"')
      return AssetReportSummary_possibleTypes.includes(obj.__typename)
    }
    


    const AttachmentsUploadUrlResponse_possibleTypes: string[] = ['AttachmentsUploadUrlResponse']
    export const isAttachmentsUploadUrlResponse = (obj?: { __typename?: any } | null): obj is AttachmentsUploadUrlResponse => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAttachmentsUploadUrlResponse"')
      return AttachmentsUploadUrlResponse_possibleTypes.includes(obj.__typename)
    }
    


    const AuthChallenge_possibleTypes: string[] = ['AuthChallenge']
    export const isAuthChallenge = (obj?: { __typename?: any } | null): obj is AuthChallenge => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAuthChallenge"')
      return AuthChallenge_possibleTypes.includes(obj.__typename)
    }
    


    const AuthChallengeVerifyResponse_possibleTypes: string[] = ['AuthChallengeVerifyResponse']
    export const isAuthChallengeVerifyResponse = (obj?: { __typename?: any } | null): obj is AuthChallengeVerifyResponse => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAuthChallengeVerifyResponse"')
      return AuthChallengeVerifyResponse_possibleTypes.includes(obj.__typename)
    }
    


    const AuthToken_possibleTypes: string[] = ['AuthToken']
    export const isAuthToken = (obj?: { __typename?: any } | null): obj is AuthToken => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAuthToken"')
      return AuthToken_possibleTypes.includes(obj.__typename)
    }
    


    const AuthTokenRefreshResponse_possibleTypes: string[] = ['AuthTokenRefreshResponse']
    export const isAuthTokenRefreshResponse = (obj?: { __typename?: any } | null): obj is AuthTokenRefreshResponse => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAuthTokenRefreshResponse"')
      return AuthTokenRefreshResponse_possibleTypes.includes(obj.__typename)
    }
    


    const AutomationActionDescriptor_possibleTypes: string[] = ['AutomationActionDescriptor']
    export const isAutomationActionDescriptor = (obj?: { __typename?: any } | null): obj is AutomationActionDescriptor => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAutomationActionDescriptor"')
      return AutomationActionDescriptor_possibleTypes.includes(obj.__typename)
    }
    


    const AutomationDescriptor_possibleTypes: string[] = ['AutomationDescriptor']
    export const isAutomationDescriptor = (obj?: { __typename?: any } | null): obj is AutomationDescriptor => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAutomationDescriptor"')
      return AutomationDescriptor_possibleTypes.includes(obj.__typename)
    }
    


    const AutomationDescriptorParam_possibleTypes: string[] = ['AutomationDescriptorParam']
    export const isAutomationDescriptorParam = (obj?: { __typename?: any } | null): obj is AutomationDescriptorParam => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAutomationDescriptorParam"')
      return AutomationDescriptorParam_possibleTypes.includes(obj.__typename)
    }
    


    const AutomationDescriptorParamOption_possibleTypes: string[] = ['AutomationDescriptorParamOption']
    export const isAutomationDescriptorParamOption = (obj?: { __typename?: any } | null): obj is AutomationDescriptorParamOption => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAutomationDescriptorParamOption"')
      return AutomationDescriptorParamOption_possibleTypes.includes(obj.__typename)
    }
    


    const AutomationRule_possibleTypes: string[] = ['AutomationRule']
    export const isAutomationRule = (obj?: { __typename?: any } | null): obj is AutomationRule => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAutomationRule"')
      return AutomationRule_possibleTypes.includes(obj.__typename)
    }
    


    const AutomationRuleAction_possibleTypes: string[] = ['AutomationRuleAction']
    export const isAutomationRuleAction = (obj?: { __typename?: any } | null): obj is AutomationRuleAction => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAutomationRuleAction"')
      return AutomationRuleAction_possibleTypes.includes(obj.__typename)
    }
    


    const AutomationRuleParamValue_possibleTypes: string[] = ['AutomationRuleParamValue']
    export const isAutomationRuleParamValue = (obj?: { __typename?: any } | null): obj is AutomationRuleParamValue => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAutomationRuleParamValue"')
      return AutomationRuleParamValue_possibleTypes.includes(obj.__typename)
    }
    


    const AutomationRuleStats_possibleTypes: string[] = ['AutomationRuleStats']
    export const isAutomationRuleStats = (obj?: { __typename?: any } | null): obj is AutomationRuleStats => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAutomationRuleStats"')
      return AutomationRuleStats_possibleTypes.includes(obj.__typename)
    }
    


    const AutomationTriggerHistory_possibleTypes: string[] = ['AutomationTriggerHistory']
    export const isAutomationTriggerHistory = (obj?: { __typename?: any } | null): obj is AutomationTriggerHistory => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAutomationTriggerHistory"')
      return AutomationTriggerHistory_possibleTypes.includes(obj.__typename)
    }
    


    const AwayNotice_possibleTypes: string[] = ['AwayNotice']
    export const isAwayNotice = (obj?: { __typename?: any } | null): obj is AwayNotice => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAwayNotice"')
      return AwayNotice_possibleTypes.includes(obj.__typename)
    }
    


    const AwayNoticeServiceReservation_possibleTypes: string[] = ['AwayNoticeServiceReservation']
    export const isAwayNoticeServiceReservation = (obj?: { __typename?: any } | null): obj is AwayNoticeServiceReservation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAwayNoticeServiceReservation"')
      return AwayNoticeServiceReservation_possibleTypes.includes(obj.__typename)
    }
    


    const AwayNoticeSettings_possibleTypes: string[] = ['AwayNoticeSettings']
    export const isAwayNoticeSettings = (obj?: { __typename?: any } | null): obj is AwayNoticeSettings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAwayNoticeSettings"')
      return AwayNoticeSettings_possibleTypes.includes(obj.__typename)
    }
    


    const Bedroom_possibleTypes: string[] = ['Bedroom']
    export const isBedroom = (obj?: { __typename?: any } | null): obj is Bedroom => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isBedroom"')
      return Bedroom_possibleTypes.includes(obj.__typename)
    }
    


    const BookingOption_possibleTypes: string[] = ['BookingOption']
    export const isBookingOption = (obj?: { __typename?: any } | null): obj is BookingOption => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isBookingOption"')
      return BookingOption_possibleTypes.includes(obj.__typename)
    }
    


    const Brand_possibleTypes: string[] = ['Brand']
    export const isBrand = (obj?: { __typename?: any } | null): obj is Brand => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isBrand"')
      return Brand_possibleTypes.includes(obj.__typename)
    }
    


    const BrandColors_possibleTypes: string[] = ['BrandColors']
    export const isBrandColors = (obj?: { __typename?: any } | null): obj is BrandColors => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isBrandColors"')
      return BrandColors_possibleTypes.includes(obj.__typename)
    }
    


    const Broadcast_possibleTypes: string[] = ['Broadcast']
    export const isBroadcast = (obj?: { __typename?: any } | null): obj is Broadcast => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isBroadcast"')
      return Broadcast_possibleTypes.includes(obj.__typename)
    }
    


    const BroadcastAttachment_possibleTypes: string[] = ['BroadcastAttachment']
    export const isBroadcastAttachment = (obj?: { __typename?: any } | null): obj is BroadcastAttachment => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isBroadcastAttachment"')
      return BroadcastAttachment_possibleTypes.includes(obj.__typename)
    }
    


    const BroadcastAudience_possibleTypes: string[] = ['BroadcastAudience']
    export const isBroadcastAudience = (obj?: { __typename?: any } | null): obj is BroadcastAudience => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isBroadcastAudience"')
      return BroadcastAudience_possibleTypes.includes(obj.__typename)
    }
    


    const BroadcastAudienceParams_possibleTypes: string[] = ['BuildingAudienceParams','CommunityAudienceParams','EventAudienceParams','InterestGroupsAudienceParams','SpecificUsersAudienceParams']
    export const isBroadcastAudienceParams = (obj?: { __typename?: any } | null): obj is BroadcastAudienceParams => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isBroadcastAudienceParams"')
      return BroadcastAudienceParams_possibleTypes.includes(obj.__typename)
    }
    


    const BroadcastContent_possibleTypes: string[] = ['BroadcastContent']
    export const isBroadcastContent = (obj?: { __typename?: any } | null): obj is BroadcastContent => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isBroadcastContent"')
      return BroadcastContent_possibleTypes.includes(obj.__typename)
    }
    


    const BroadcastEmailPayload_possibleTypes: string[] = ['BroadcastEmailPayload']
    export const isBroadcastEmailPayload = (obj?: { __typename?: any } | null): obj is BroadcastEmailPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isBroadcastEmailPayload"')
      return BroadcastEmailPayload_possibleTypes.includes(obj.__typename)
    }
    


    const BroadcastStatParam_possibleTypes: string[] = ['BroadcastStatParam']
    export const isBroadcastStatParam = (obj?: { __typename?: any } | null): obj is BroadcastStatParam => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isBroadcastStatParam"')
      return BroadcastStatParam_possibleTypes.includes(obj.__typename)
    }
    


    const BroadcastsList_possibleTypes: string[] = ['BroadcastsList']
    export const isBroadcastsList = (obj?: { __typename?: any } | null): obj is BroadcastsList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isBroadcastsList"')
      return BroadcastsList_possibleTypes.includes(obj.__typename)
    }
    


    const Building_possibleTypes: string[] = ['Building']
    export const isBuilding = (obj?: { __typename?: any } | null): obj is Building => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isBuilding"')
      return Building_possibleTypes.includes(obj.__typename)
    }
    


    const BuildingAudienceParams_possibleTypes: string[] = ['BuildingAudienceParams']
    export const isBuildingAudienceParams = (obj?: { __typename?: any } | null): obj is BuildingAudienceParams => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isBuildingAudienceParams"')
      return BuildingAudienceParams_possibleTypes.includes(obj.__typename)
    }
    


    const BuildingSettings_possibleTypes: string[] = ['BuildingSettings']
    export const isBuildingSettings = (obj?: { __typename?: any } | null): obj is BuildingSettings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isBuildingSettings"')
      return BuildingSettings_possibleTypes.includes(obj.__typename)
    }
    


    const Byline_possibleTypes: string[] = ['Byline']
    export const isByline = (obj?: { __typename?: any } | null): obj is Byline => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isByline"')
      return Byline_possibleTypes.includes(obj.__typename)
    }
    


    const Calendar_possibleTypes: string[] = ['Calendar']
    export const isCalendar = (obj?: { __typename?: any } | null): obj is Calendar => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCalendar"')
      return Calendar_possibleTypes.includes(obj.__typename)
    }
    


    const CoApplicantPublicData_possibleTypes: string[] = ['CoApplicantPublicData']
    export const isCoApplicantPublicData = (obj?: { __typename?: any } | null): obj is CoApplicantPublicData => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCoApplicantPublicData"')
      return CoApplicantPublicData_possibleTypes.includes(obj.__typename)
    }
    


    const Community_possibleTypes: string[] = ['Community']
    export const isCommunity = (obj?: { __typename?: any } | null): obj is Community => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCommunity"')
      return Community_possibleTypes.includes(obj.__typename)
    }
    


    const CommunityAudienceParams_possibleTypes: string[] = ['CommunityAudienceParams']
    export const isCommunityAudienceParams = (obj?: { __typename?: any } | null): obj is CommunityAudienceParams => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCommunityAudienceParams"')
      return CommunityAudienceParams_possibleTypes.includes(obj.__typename)
    }
    


    const CommunityBuildings_possibleTypes: string[] = ['CommunityBuildings']
    export const isCommunityBuildings = (obj?: { __typename?: any } | null): obj is CommunityBuildings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCommunityBuildings"')
      return CommunityBuildings_possibleTypes.includes(obj.__typename)
    }
    


    const CommunitySettings_possibleTypes: string[] = ['CommunitySettings']
    export const isCommunitySettings = (obj?: { __typename?: any } | null): obj is CommunitySettings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCommunitySettings"')
      return CommunitySettings_possibleTypes.includes(obj.__typename)
    }
    


    const CommunityUiMetadata_possibleTypes: string[] = ['CommunityUiMetadata']
    export const isCommunityUiMetadata = (obj?: { __typename?: any } | null): obj is CommunityUiMetadata => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCommunityUiMetadata"')
      return CommunityUiMetadata_possibleTypes.includes(obj.__typename)
    }
    


    const Concession_possibleTypes: string[] = ['Concession']
    export const isConcession = (obj?: { __typename?: any } | null): obj is Concession => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isConcession"')
      return Concession_possibleTypes.includes(obj.__typename)
    }
    


    const Coordinate_possibleTypes: string[] = ['Coordinate']
    export const isCoordinate = (obj?: { __typename?: any } | null): obj is Coordinate => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCoordinate"')
      return Coordinate_possibleTypes.includes(obj.__typename)
    }
    


    const CreditReportResult_possibleTypes: string[] = ['CreditReportResult']
    export const isCreditReportResult = (obj?: { __typename?: any } | null): obj is CreditReportResult => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCreditReportResult"')
      return CreditReportResult_possibleTypes.includes(obj.__typename)
    }
    


    const CreditReportSummary_possibleTypes: string[] = ['CreditReportSummary']
    export const isCreditReportSummary = (obj?: { __typename?: any } | null): obj is CreditReportSummary => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCreditReportSummary"')
      return CreditReportSummary_possibleTypes.includes(obj.__typename)
    }
    


    const CriminalBackgroundReportResult_possibleTypes: string[] = ['CriminalBackgroundReportResult']
    export const isCriminalBackgroundReportResult = (obj?: { __typename?: any } | null): obj is CriminalBackgroundReportResult => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCriminalBackgroundReportResult"')
      return CriminalBackgroundReportResult_possibleTypes.includes(obj.__typename)
    }
    


    const CriminalBackgroundReportSummary_possibleTypes: string[] = ['CriminalBackgroundReportSummary']
    export const isCriminalBackgroundReportSummary = (obj?: { __typename?: any } | null): obj is CriminalBackgroundReportSummary => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCriminalBackgroundReportSummary"')
      return CriminalBackgroundReportSummary_possibleTypes.includes(obj.__typename)
    }
    


    const CtaData_possibleTypes: string[] = ['CtaData']
    export const isCtaData = (obj?: { __typename?: any } | null): obj is CtaData => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCtaData"')
      return CtaData_possibleTypes.includes(obj.__typename)
    }
    


    const DeepLink_possibleTypes: string[] = ['DeepLink']
    export const isDeepLink = (obj?: { __typename?: any } | null): obj is DeepLink => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDeepLink"')
      return DeepLink_possibleTypes.includes(obj.__typename)
    }
    


    const DeliveryPackage_possibleTypes: string[] = ['DeliveryPackage']
    export const isDeliveryPackage = (obj?: { __typename?: any } | null): obj is DeliveryPackage => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDeliveryPackage"')
      return DeliveryPackage_possibleTypes.includes(obj.__typename)
    }
    


    const DeliveryPackagesList_possibleTypes: string[] = ['DeliveryPackagesList']
    export const isDeliveryPackagesList = (obj?: { __typename?: any } | null): obj is DeliveryPackagesList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDeliveryPackagesList"')
      return DeliveryPackagesList_possibleTypes.includes(obj.__typename)
    }
    


    const Discussion_possibleTypes: string[] = ['Discussion']
    export const isDiscussion = (obj?: { __typename?: any } | null): obj is Discussion => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiscussion"')
      return Discussion_possibleTypes.includes(obj.__typename)
    }
    


    const DiscussionComment_possibleTypes: string[] = ['DiscussionComment']
    export const isDiscussionComment = (obj?: { __typename?: any } | null): obj is DiscussionComment => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiscussionComment"')
      return DiscussionComment_possibleTypes.includes(obj.__typename)
    }
    


    const DiscussionCommentsList_possibleTypes: string[] = ['DiscussionCommentsList']
    export const isDiscussionCommentsList = (obj?: { __typename?: any } | null): obj is DiscussionCommentsList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiscussionCommentsList"')
      return DiscussionCommentsList_possibleTypes.includes(obj.__typename)
    }
    


    const DiscussionsList_possibleTypes: string[] = ['DiscussionsList']
    export const isDiscussionsList = (obj?: { __typename?: any } | null): obj is DiscussionsList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiscussionsList"')
      return DiscussionsList_possibleTypes.includes(obj.__typename)
    }
    


    const EmailDomain_possibleTypes: string[] = ['EmailDomain']
    export const isEmailDomain = (obj?: { __typename?: any } | null): obj is EmailDomain => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEmailDomain"')
      return EmailDomain_possibleTypes.includes(obj.__typename)
    }
    


    const EmailDomainSender_possibleTypes: string[] = ['EmailDomainSender']
    export const isEmailDomainSender = (obj?: { __typename?: any } | null): obj is EmailDomainSender => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEmailDomainSender"')
      return EmailDomainSender_possibleTypes.includes(obj.__typename)
    }
    


    const EmailLinkData_possibleTypes: string[] = ['EmailLinkData']
    export const isEmailLinkData = (obj?: { __typename?: any } | null): obj is EmailLinkData => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEmailLinkData"')
      return EmailLinkData_possibleTypes.includes(obj.__typename)
    }
    


    const EmailSenderDomain_possibleTypes: string[] = ['EmailSenderDomain']
    export const isEmailSenderDomain = (obj?: { __typename?: any } | null): obj is EmailSenderDomain => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEmailSenderDomain"')
      return EmailSenderDomain_possibleTypes.includes(obj.__typename)
    }
    


    const EmailSenderForUser_possibleTypes: string[] = ['EmailSenderForUser']
    export const isEmailSenderForUser = (obj?: { __typename?: any } | null): obj is EmailSenderForUser => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEmailSenderForUser"')
      return EmailSenderForUser_possibleTypes.includes(obj.__typename)
    }
    


    const EmailSettings_possibleTypes: string[] = ['EmailSettings']
    export const isEmailSettings = (obj?: { __typename?: any } | null): obj is EmailSettings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEmailSettings"')
      return EmailSettings_possibleTypes.includes(obj.__typename)
    }
    


    const EmbeddedApplicationAdapter_possibleTypes: string[] = ['EmbeddedApplicationAdapter']
    export const isEmbeddedApplicationAdapter = (obj?: { __typename?: any } | null): obj is EmbeddedApplicationAdapter => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEmbeddedApplicationAdapter"')
      return EmbeddedApplicationAdapter_possibleTypes.includes(obj.__typename)
    }
    


    const EmbeddedApplicationSaml2Settings_possibleTypes: string[] = ['EmbeddedApplicationSaml2Settings']
    export const isEmbeddedApplicationSaml2Settings = (obj?: { __typename?: any } | null): obj is EmbeddedApplicationSaml2Settings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEmbeddedApplicationSaml2Settings"')
      return EmbeddedApplicationSaml2Settings_possibleTypes.includes(obj.__typename)
    }
    


    const EmbeddedApplicationWindowEventSettings_possibleTypes: string[] = ['EmbeddedApplicationWindowEventSettings']
    export const isEmbeddedApplicationWindowEventSettings = (obj?: { __typename?: any } | null): obj is EmbeddedApplicationWindowEventSettings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEmbeddedApplicationWindowEventSettings"')
      return EmbeddedApplicationWindowEventSettings_possibleTypes.includes(obj.__typename)
    }
    


    const Event_possibleTypes: string[] = ['Event']
    export const isEvent = (obj?: { __typename?: any } | null): obj is Event => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEvent"')
      return Event_possibleTypes.includes(obj.__typename)
    }
    


    const EventAttendance_possibleTypes: string[] = ['EventAttendance']
    export const isEventAttendance = (obj?: { __typename?: any } | null): obj is EventAttendance => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEventAttendance"')
      return EventAttendance_possibleTypes.includes(obj.__typename)
    }
    


    const EventAttendee_possibleTypes: string[] = ['EventAttendee']
    export const isEventAttendee = (obj?: { __typename?: any } | null): obj is EventAttendee => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEventAttendee"')
      return EventAttendee_possibleTypes.includes(obj.__typename)
    }
    


    const EventAudienceParams_possibleTypes: string[] = ['EventAudienceParams']
    export const isEventAudienceParams = (obj?: { __typename?: any } | null): obj is EventAudienceParams => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEventAudienceParams"')
      return EventAudienceParams_possibleTypes.includes(obj.__typename)
    }
    


    const EventSearchResult_possibleTypes: string[] = ['EventSearchResult']
    export const isEventSearchResult = (obj?: { __typename?: any } | null): obj is EventSearchResult => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEventSearchResult"')
      return EventSearchResult_possibleTypes.includes(obj.__typename)
    }
    


    const EventStatistics_possibleTypes: string[] = ['EventStatistics']
    export const isEventStatistics = (obj?: { __typename?: any } | null): obj is EventStatistics => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEventStatistics"')
      return EventStatistics_possibleTypes.includes(obj.__typename)
    }
    


    const EventsList_possibleTypes: string[] = ['EventsList']
    export const isEventsList = (obj?: { __typename?: any } | null): obj is EventsList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEventsList"')
      return EventsList_possibleTypes.includes(obj.__typename)
    }
    


    const ExternalAppLinkData_possibleTypes: string[] = ['ExternalAppLinkData']
    export const isExternalAppLinkData = (obj?: { __typename?: any } | null): obj is ExternalAppLinkData => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isExternalAppLinkData"')
      return ExternalAppLinkData_possibleTypes.includes(obj.__typename)
    }
    


    const FeatureFlag_possibleTypes: string[] = ['FeatureFlag']
    export const isFeatureFlag = (obj?: { __typename?: any } | null): obj is FeatureFlag => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFeatureFlag"')
      return FeatureFlag_possibleTypes.includes(obj.__typename)
    }
    


    const FeaturedSlide_possibleTypes: string[] = ['FeaturedSlide']
    export const isFeaturedSlide = (obj?: { __typename?: any } | null): obj is FeaturedSlide => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFeaturedSlide"')
      return FeaturedSlide_possibleTypes.includes(obj.__typename)
    }
    


    const FeaturedSlideBuildings_possibleTypes: string[] = ['FeaturedSlideBuildings']
    export const isFeaturedSlideBuildings = (obj?: { __typename?: any } | null): obj is FeaturedSlideBuildings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFeaturedSlideBuildings"')
      return FeaturedSlideBuildings_possibleTypes.includes(obj.__typename)
    }
    


    const FeaturedSlideCallToAction_possibleTypes: string[] = ['FeaturedSlideCallToAction']
    export const isFeaturedSlideCallToAction = (obj?: { __typename?: any } | null): obj is FeaturedSlideCallToAction => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFeaturedSlideCallToAction"')
      return FeaturedSlideCallToAction_possibleTypes.includes(obj.__typename)
    }
    


    const FeaturedSlideLink_possibleTypes: string[] = ['FeaturedSlideLink']
    export const isFeaturedSlideLink = (obj?: { __typename?: any } | null): obj is FeaturedSlideLink => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFeaturedSlideLink"')
      return FeaturedSlideLink_possibleTypes.includes(obj.__typename)
    }
    


    const FeaturedSlideList_possibleTypes: string[] = ['FeaturedSlideList']
    export const isFeaturedSlideList = (obj?: { __typename?: any } | null): obj is FeaturedSlideList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFeaturedSlideList"')
      return FeaturedSlideList_possibleTypes.includes(obj.__typename)
    }
    


    const GeneralInfoPage_possibleTypes: string[] = ['GeneralInfoPage']
    export const isGeneralInfoPage = (obj?: { __typename?: any } | null): obj is GeneralInfoPage => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isGeneralInfoPage"')
      return GeneralInfoPage_possibleTypes.includes(obj.__typename)
    }
    


    const GeneralInfoPageCards_possibleTypes: string[] = ['GeneralInfoPageCards']
    export const isGeneralInfoPageCards = (obj?: { __typename?: any } | null): obj is GeneralInfoPageCards => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isGeneralInfoPageCards"')
      return GeneralInfoPageCards_possibleTypes.includes(obj.__typename)
    }
    


    const GeneralInfoPageList_possibleTypes: string[] = ['GeneralInfoPageList']
    export const isGeneralInfoPageList = (obj?: { __typename?: any } | null): obj is GeneralInfoPageList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isGeneralInfoPageList"')
      return GeneralInfoPageList_possibleTypes.includes(obj.__typename)
    }
    


    const GuestKey_possibleTypes: string[] = ['GuestKey']
    export const isGuestKey = (obj?: { __typename?: any } | null): obj is GuestKey => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isGuestKey"')
      return GuestKey_possibleTypes.includes(obj.__typename)
    }
    


    const Hood_possibleTypes: string[] = ['Hood']
    export const isHood = (obj?: { __typename?: any } | null): obj is Hood => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isHood"')
      return Hood_possibleTypes.includes(obj.__typename)
    }
    


    const HoodAggregations_possibleTypes: string[] = ['HoodAggregations']
    export const isHoodAggregations = (obj?: { __typename?: any } | null): obj is HoodAggregations => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isHoodAggregations"')
      return HoodAggregations_possibleTypes.includes(obj.__typename)
    }
    


    const HoodRoot_possibleTypes: string[] = ['HoodRoot']
    export const isHoodRoot = (obj?: { __typename?: any } | null): obj is HoodRoot => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isHoodRoot"')
      return HoodRoot_possibleTypes.includes(obj.__typename)
    }
    


    const HoodSetting_possibleTypes: string[] = ['HoodSetting']
    export const isHoodSetting = (obj?: { __typename?: any } | null): obj is HoodSetting => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isHoodSetting"')
      return HoodSetting_possibleTypes.includes(obj.__typename)
    }
    


    const HubMetadata_possibleTypes: string[] = ['HubMetadata']
    export const isHubMetadata = (obj?: { __typename?: any } | null): obj is HubMetadata => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isHubMetadata"')
      return HubMetadata_possibleTypes.includes(obj.__typename)
    }
    


    const IdentityVerificationResult_possibleTypes: string[] = ['IdentityVerificationResult']
    export const isIdentityVerificationResult = (obj?: { __typename?: any } | null): obj is IdentityVerificationResult => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isIdentityVerificationResult"')
      return IdentityVerificationResult_possibleTypes.includes(obj.__typename)
    }
    


    const IdentityVerificationSummary_possibleTypes: string[] = ['IdentityVerificationSummary']
    export const isIdentityVerificationSummary = (obj?: { __typename?: any } | null): obj is IdentityVerificationSummary => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isIdentityVerificationSummary"')
      return IdentityVerificationSummary_possibleTypes.includes(obj.__typename)
    }
    


    const Image_possibleTypes: string[] = ['Image']
    export const isImage = (obj?: { __typename?: any } | null): obj is Image => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isImage"')
      return Image_possibleTypes.includes(obj.__typename)
    }
    


    const IncomeReportResult_possibleTypes: string[] = ['IncomeReportResult']
    export const isIncomeReportResult = (obj?: { __typename?: any } | null): obj is IncomeReportResult => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isIncomeReportResult"')
      return IncomeReportResult_possibleTypes.includes(obj.__typename)
    }
    


    const IncomeReportSummary_possibleTypes: string[] = ['IncomeReportSummary']
    export const isIncomeReportSummary = (obj?: { __typename?: any } | null): obj is IncomeReportSummary => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isIncomeReportSummary"')
      return IncomeReportSummary_possibleTypes.includes(obj.__typename)
    }
    


    const InitTokenParams_possibleTypes: string[] = ['InitTokenParams']
    export const isInitTokenParams = (obj?: { __typename?: any } | null): obj is InitTokenParams => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isInitTokenParams"')
      return InitTokenParams_possibleTypes.includes(obj.__typename)
    }
    


    const InterestGroup_possibleTypes: string[] = ['InterestGroup']
    export const isInterestGroup = (obj?: { __typename?: any } | null): obj is InterestGroup => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isInterestGroup"')
      return InterestGroup_possibleTypes.includes(obj.__typename)
    }
    


    const InterestGroupInsightCategory_possibleTypes: string[] = ['InterestGroupInsightCategory']
    export const isInterestGroupInsightCategory = (obj?: { __typename?: any } | null): obj is InterestGroupInsightCategory => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isInterestGroupInsightCategory"')
      return InterestGroupInsightCategory_possibleTypes.includes(obj.__typename)
    }
    


    const InterestGroupInsightDiscussion_possibleTypes: string[] = ['InterestGroupInsightDiscussion']
    export const isInterestGroupInsightDiscussion = (obj?: { __typename?: any } | null): obj is InterestGroupInsightDiscussion => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isInterestGroupInsightDiscussion"')
      return InterestGroupInsightDiscussion_possibleTypes.includes(obj.__typename)
    }
    


    const InterestGroupInsightSummary_possibleTypes: string[] = ['InterestGroupInsightSummary']
    export const isInterestGroupInsightSummary = (obj?: { __typename?: any } | null): obj is InterestGroupInsightSummary => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isInterestGroupInsightSummary"')
      return InterestGroupInsightSummary_possibleTypes.includes(obj.__typename)
    }
    


    const InterestGroupLabel_possibleTypes: string[] = ['InterestGroupLabel']
    export const isInterestGroupLabel = (obj?: { __typename?: any } | null): obj is InterestGroupLabel => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isInterestGroupLabel"')
      return InterestGroupLabel_possibleTypes.includes(obj.__typename)
    }
    


    const InterestGroupMember_possibleTypes: string[] = ['InterestGroupMember']
    export const isInterestGroupMember = (obj?: { __typename?: any } | null): obj is InterestGroupMember => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isInterestGroupMember"')
      return InterestGroupMember_possibleTypes.includes(obj.__typename)
    }
    


    const InterestGroupMembersList_possibleTypes: string[] = ['InterestGroupMembersList']
    export const isInterestGroupMembersList = (obj?: { __typename?: any } | null): obj is InterestGroupMembersList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isInterestGroupMembersList"')
      return InterestGroupMembersList_possibleTypes.includes(obj.__typename)
    }
    


    const InterestGroupMessage_possibleTypes: string[] = ['InterestGroupMessage']
    export const isInterestGroupMessage = (obj?: { __typename?: any } | null): obj is InterestGroupMessage => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isInterestGroupMessage"')
      return InterestGroupMessage_possibleTypes.includes(obj.__typename)
    }
    


    const InterestGroupSearchResult_possibleTypes: string[] = ['InterestGroupSearchResult']
    export const isInterestGroupSearchResult = (obj?: { __typename?: any } | null): obj is InterestGroupSearchResult => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isInterestGroupSearchResult"')
      return InterestGroupSearchResult_possibleTypes.includes(obj.__typename)
    }
    


    const InterestGroupsAudienceParams_possibleTypes: string[] = ['InterestGroupsAudienceParams']
    export const isInterestGroupsAudienceParams = (obj?: { __typename?: any } | null): obj is InterestGroupsAudienceParams => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isInterestGroupsAudienceParams"')
      return InterestGroupsAudienceParams_possibleTypes.includes(obj.__typename)
    }
    


    const InterestGroupsList_possibleTypes: string[] = ['InterestGroupsList']
    export const isInterestGroupsList = (obj?: { __typename?: any } | null): obj is InterestGroupsList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isInterestGroupsList"')
      return InterestGroupsList_possibleTypes.includes(obj.__typename)
    }
    


    const JobItemStatusCount_possibleTypes: string[] = ['JobItemStatusCount']
    export const isJobItemStatusCount = (obj?: { __typename?: any } | null): obj is JobItemStatusCount => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isJobItemStatusCount"')
      return JobItemStatusCount_possibleTypes.includes(obj.__typename)
    }
    


    const KeyValueParam_possibleTypes: string[] = ['KeyValueParam']
    export const isKeyValueParam = (obj?: { __typename?: any } | null): obj is KeyValueParam => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isKeyValueParam"')
      return KeyValueParam_possibleTypes.includes(obj.__typename)
    }
    


    const LeaseAmenity_possibleTypes: string[] = ['LeaseAmenity']
    export const isLeaseAmenity = (obj?: { __typename?: any } | null): obj is LeaseAmenity => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLeaseAmenity"')
      return LeaseAmenity_possibleTypes.includes(obj.__typename)
    }
    


    const LeaseContract_possibleTypes: string[] = ['LeaseContract']
    export const isLeaseContract = (obj?: { __typename?: any } | null): obj is LeaseContract => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLeaseContract"')
      return LeaseContract_possibleTypes.includes(obj.__typename)
    }
    


    const LeaseContractsList_possibleTypes: string[] = ['LeaseContractsList']
    export const isLeaseContractsList = (obj?: { __typename?: any } | null): obj is LeaseContractsList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLeaseContractsList"')
      return LeaseContractsList_possibleTypes.includes(obj.__typename)
    }
    


    const LeaseLedger_possibleTypes: string[] = ['LeaseLedger']
    export const isLeaseLedger = (obj?: { __typename?: any } | null): obj is LeaseLedger => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLeaseLedger"')
      return LeaseLedger_possibleTypes.includes(obj.__typename)
    }
    


    const LeaseLedgerCharge_possibleTypes: string[] = ['LeaseLedgerCharge']
    export const isLeaseLedgerCharge = (obj?: { __typename?: any } | null): obj is LeaseLedgerCharge => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLeaseLedgerCharge"')
      return LeaseLedgerCharge_possibleTypes.includes(obj.__typename)
    }
    


    const LeaseLedgerPayment_possibleTypes: string[] = ['LeaseLedgerPayment']
    export const isLeaseLedgerPayment = (obj?: { __typename?: any } | null): obj is LeaseLedgerPayment => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLeaseLedgerPayment"')
      return LeaseLedgerPayment_possibleTypes.includes(obj.__typename)
    }
    


    const LegacySettings_possibleTypes: string[] = ['LegacySettings']
    export const isLegacySettings = (obj?: { __typename?: any } | null): obj is LegacySettings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLegacySettings"')
      return LegacySettings_possibleTypes.includes(obj.__typename)
    }
    


    const LockCredentials_possibleTypes: string[] = ['LockCredentials']
    export const isLockCredentials = (obj?: { __typename?: any } | null): obj is LockCredentials => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLockCredentials"')
      return LockCredentials_possibleTypes.includes(obj.__typename)
    }
    


    const LockProvider_possibleTypes: string[] = ['LockProvider']
    export const isLockProvider = (obj?: { __typename?: any } | null): obj is LockProvider => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLockProvider"')
      return LockProvider_possibleTypes.includes(obj.__typename)
    }
    


    const LockProviderKey_possibleTypes: string[] = ['LockProviderKey']
    export const isLockProviderKey = (obj?: { __typename?: any } | null): obj is LockProviderKey => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLockProviderKey"')
      return LockProviderKey_possibleTypes.includes(obj.__typename)
    }
    


    const MainApplicantPublicData_possibleTypes: string[] = ['MainApplicantPublicData']
    export const isMainApplicantPublicData = (obj?: { __typename?: any } | null): obj is MainApplicantPublicData => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isMainApplicantPublicData"')
      return MainApplicantPublicData_possibleTypes.includes(obj.__typename)
    }
    


    const MoveInFunnelRecord_possibleTypes: string[] = ['MoveInFunnelRecord']
    export const isMoveInFunnelRecord = (obj?: { __typename?: any } | null): obj is MoveInFunnelRecord => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isMoveInFunnelRecord"')
      return MoveInFunnelRecord_possibleTypes.includes(obj.__typename)
    }
    


    const MoveInFunnelRecordList_possibleTypes: string[] = ['MoveInFunnelRecordList']
    export const isMoveInFunnelRecordList = (obj?: { __typename?: any } | null): obj is MoveInFunnelRecordList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isMoveInFunnelRecordList"')
      return MoveInFunnelRecordList_possibleTypes.includes(obj.__typename)
    }
    


    const MoveInSettings_possibleTypes: string[] = ['MoveInSettings']
    export const isMoveInSettings = (obj?: { __typename?: any } | null): obj is MoveInSettings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isMoveInSettings"')
      return MoveInSettings_possibleTypes.includes(obj.__typename)
    }
    


    const Mutation_possibleTypes: string[] = ['Mutation']
    export const isMutation = (obj?: { __typename?: any } | null): obj is Mutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isMutation"')
      return Mutation_possibleTypes.includes(obj.__typename)
    }
    


    const MutationUserRoot_possibleTypes: string[] = ['MutationUserRoot']
    export const isMutationUserRoot = (obj?: { __typename?: any } | null): obj is MutationUserRoot => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isMutationUserRoot"')
      return MutationUserRoot_possibleTypes.includes(obj.__typename)
    }
    


    const MyReservationCheckoutResponse_possibleTypes: string[] = ['MyReservationCheckoutResponse']
    export const isMyReservationCheckoutResponse = (obj?: { __typename?: any } | null): obj is MyReservationCheckoutResponse => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isMyReservationCheckoutResponse"')
      return MyReservationCheckoutResponse_possibleTypes.includes(obj.__typename)
    }
    


    const MyUserTaskSubmitResponse_possibleTypes: string[] = ['MyUserTaskSubmitResponse']
    export const isMyUserTaskSubmitResponse = (obj?: { __typename?: any } | null): obj is MyUserTaskSubmitResponse => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isMyUserTaskSubmitResponse"')
      return MyUserTaskSubmitResponse_possibleTypes.includes(obj.__typename)
    }
    


    const NeighborsList_possibleTypes: string[] = ['NeighborsList']
    export const isNeighborsList = (obj?: { __typename?: any } | null): obj is NeighborsList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isNeighborsList"')
      return NeighborsList_possibleTypes.includes(obj.__typename)
    }
    


    const NewDiscussionAddedSubscription_possibleTypes: string[] = ['NewDiscussionAddedSubscription']
    export const isNewDiscussionAddedSubscription = (obj?: { __typename?: any } | null): obj is NewDiscussionAddedSubscription => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isNewDiscussionAddedSubscription"')
      return NewDiscussionAddedSubscription_possibleTypes.includes(obj.__typename)
    }
    


    const Notification_possibleTypes: string[] = ['Notification']
    export const isNotification = (obj?: { __typename?: any } | null): obj is Notification => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isNotification"')
      return Notification_possibleTypes.includes(obj.__typename)
    }
    


    const NotificationCategory_possibleTypes: string[] = ['NotificationCategory']
    export const isNotificationCategory = (obj?: { __typename?: any } | null): obj is NotificationCategory => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isNotificationCategory"')
      return NotificationCategory_possibleTypes.includes(obj.__typename)
    }
    


    const NotificationSnooze_possibleTypes: string[] = ['NotificationSnooze']
    export const isNotificationSnooze = (obj?: { __typename?: any } | null): obj is NotificationSnooze => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isNotificationSnooze"')
      return NotificationSnooze_possibleTypes.includes(obj.__typename)
    }
    


    const NotificationSnoozesList_possibleTypes: string[] = ['NotificationSnoozesList']
    export const isNotificationSnoozesList = (obj?: { __typename?: any } | null): obj is NotificationSnoozesList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isNotificationSnoozesList"')
      return NotificationSnoozesList_possibleTypes.includes(obj.__typename)
    }
    


    const NotificationsList_possibleTypes: string[] = ['NotificationsList']
    export const isNotificationsList = (obj?: { __typename?: any } | null): obj is NotificationsList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isNotificationsList"')
      return NotificationsList_possibleTypes.includes(obj.__typename)
    }
    


    const OAuthParams_possibleTypes: string[] = ['OAuthParams']
    export const isOAuthParams = (obj?: { __typename?: any } | null): obj is OAuthParams => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isOAuthParams"')
      return OAuthParams_possibleTypes.includes(obj.__typename)
    }
    


    const OauthSettings_possibleTypes: string[] = ['OauthSettings']
    export const isOauthSettings = (obj?: { __typename?: any } | null): obj is OauthSettings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isOauthSettings"')
      return OauthSettings_possibleTypes.includes(obj.__typename)
    }
    


    const OrderedActiveUserTaskLists_possibleTypes: string[] = ['OrderedActiveUserTaskLists']
    export const isOrderedActiveUserTaskLists = (obj?: { __typename?: any } | null): obj is OrderedActiveUserTaskLists => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isOrderedActiveUserTaskLists"')
      return OrderedActiveUserTaskLists_possibleTypes.includes(obj.__typename)
    }
    


    const Organization_possibleTypes: string[] = ['Organization']
    export const isOrganization = (obj?: { __typename?: any } | null): obj is Organization => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isOrganization"')
      return Organization_possibleTypes.includes(obj.__typename)
    }
    


    const OrganizationMember_possibleTypes: string[] = ['OrganizationMember']
    export const isOrganizationMember = (obj?: { __typename?: any } | null): obj is OrganizationMember => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isOrganizationMember"')
      return OrganizationMember_possibleTypes.includes(obj.__typename)
    }
    


    const PageInfo_possibleTypes: string[] = ['PageInfo']
    export const isPageInfo = (obj?: { __typename?: any } | null): obj is PageInfo => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPageInfo"')
      return PageInfo_possibleTypes.includes(obj.__typename)
    }
    


    const PaymentDetails_possibleTypes: string[] = ['PaymentDetails']
    export const isPaymentDetails = (obj?: { __typename?: any } | null): obj is PaymentDetails => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPaymentDetails"')
      return PaymentDetails_possibleTypes.includes(obj.__typename)
    }
    


    const PaymentOption_possibleTypes: string[] = ['PaymentOption']
    export const isPaymentOption = (obj?: { __typename?: any } | null): obj is PaymentOption => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPaymentOption"')
      return PaymentOption_possibleTypes.includes(obj.__typename)
    }
    


    const PaymentReminders_possibleTypes: string[] = ['PaymentReminders']
    export const isPaymentReminders = (obj?: { __typename?: any } | null): obj is PaymentReminders => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPaymentReminders"')
      return PaymentReminders_possibleTypes.includes(obj.__typename)
    }
    


    const PaymentRemindersSchedule_possibleTypes: string[] = ['PaymentRemindersSchedule']
    export const isPaymentRemindersSchedule = (obj?: { __typename?: any } | null): obj is PaymentRemindersSchedule => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPaymentRemindersSchedule"')
      return PaymentRemindersSchedule_possibleTypes.includes(obj.__typename)
    }
    


    const PeriodicSurveyQuestionResponseStats_possibleTypes: string[] = ['PeriodicSurveyQuestionResponseStats']
    export const isPeriodicSurveyQuestionResponseStats = (obj?: { __typename?: any } | null): obj is PeriodicSurveyQuestionResponseStats => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPeriodicSurveyQuestionResponseStats"')
      return PeriodicSurveyQuestionResponseStats_possibleTypes.includes(obj.__typename)
    }
    


    const PeriodicSurveyQuestionStats_possibleTypes: string[] = ['PeriodicSurveyQuestionStats']
    export const isPeriodicSurveyQuestionStats = (obj?: { __typename?: any } | null): obj is PeriodicSurveyQuestionStats => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPeriodicSurveyQuestionStats"')
      return PeriodicSurveyQuestionStats_possibleTypes.includes(obj.__typename)
    }
    


    const PeriodicSurveyReport_possibleTypes: string[] = ['PeriodicSurveyReport']
    export const isPeriodicSurveyReport = (obj?: { __typename?: any } | null): obj is PeriodicSurveyReport => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPeriodicSurveyReport"')
      return PeriodicSurveyReport_possibleTypes.includes(obj.__typename)
    }
    


    const PeriodicSurveySettings_possibleTypes: string[] = ['PeriodicSurveySettings']
    export const isPeriodicSurveySettings = (obj?: { __typename?: any } | null): obj is PeriodicSurveySettings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPeriodicSurveySettings"')
      return PeriodicSurveySettings_possibleTypes.includes(obj.__typename)
    }
    


    const PeriodicSurveyStats_possibleTypes: string[] = ['PeriodicSurveyStats']
    export const isPeriodicSurveyStats = (obj?: { __typename?: any } | null): obj is PeriodicSurveyStats => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPeriodicSurveyStats"')
      return PeriodicSurveyStats_possibleTypes.includes(obj.__typename)
    }
    


    const PermittedBuilding_possibleTypes: string[] = ['PermittedBuilding']
    export const isPermittedBuilding = (obj?: { __typename?: any } | null): obj is PermittedBuilding => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPermittedBuilding"')
      return PermittedBuilding_possibleTypes.includes(obj.__typename)
    }
    


    const Pet_possibleTypes: string[] = ['Pet']
    export const isPet = (obj?: { __typename?: any } | null): obj is Pet => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPet"')
      return Pet_possibleTypes.includes(obj.__typename)
    }
    


    const PetDetails_possibleTypes: string[] = ['PetDetails']
    export const isPetDetails = (obj?: { __typename?: any } | null): obj is PetDetails => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPetDetails"')
      return PetDetails_possibleTypes.includes(obj.__typename)
    }
    


    const PhoneLinkData_possibleTypes: string[] = ['PhoneLinkData']
    export const isPhoneLinkData = (obj?: { __typename?: any } | null): obj is PhoneLinkData => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPhoneLinkData"')
      return PhoneLinkData_possibleTypes.includes(obj.__typename)
    }
    


    const Place_possibleTypes: string[] = ['Place']
    export const isPlace = (obj?: { __typename?: any } | null): obj is Place => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPlace"')
      return Place_possibleTypes.includes(obj.__typename)
    }
    


    const PlaidLinkTokenResponse_possibleTypes: string[] = ['PlaidLinkTokenResponse']
    export const isPlaidLinkTokenResponse = (obj?: { __typename?: any } | null): obj is PlaidLinkTokenResponse => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPlaidLinkTokenResponse"')
      return PlaidLinkTokenResponse_possibleTypes.includes(obj.__typename)
    }
    


    const PmsAdapter_possibleTypes: string[] = ['PmsAdapter']
    export const isPmsAdapter = (obj?: { __typename?: any } | null): obj is PmsAdapter => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPmsAdapter"')
      return PmsAdapter_possibleTypes.includes(obj.__typename)
    }
    


    const PmsAdapterTestResult_possibleTypes: string[] = ['PmsAdapterTestResult']
    export const isPmsAdapterTestResult = (obj?: { __typename?: any } | null): obj is PmsAdapterTestResult => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPmsAdapterTestResult"')
      return PmsAdapterTestResult_possibleTypes.includes(obj.__typename)
    }
    


    const PmsSyncJob_possibleTypes: string[] = ['PmsSyncJob']
    export const isPmsSyncJob = (obj?: { __typename?: any } | null): obj is PmsSyncJob => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPmsSyncJob"')
      return PmsSyncJob_possibleTypes.includes(obj.__typename)
    }
    


    const PmsSyncJobItem_possibleTypes: string[] = ['PmsSyncJobItem']
    export const isPmsSyncJobItem = (obj?: { __typename?: any } | null): obj is PmsSyncJobItem => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPmsSyncJobItem"')
      return PmsSyncJobItem_possibleTypes.includes(obj.__typename)
    }
    


    const PmsSyncJobItemList_possibleTypes: string[] = ['PmsSyncJobItemList']
    export const isPmsSyncJobItemList = (obj?: { __typename?: any } | null): obj is PmsSyncJobItemList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPmsSyncJobItemList"')
      return PmsSyncJobItemList_possibleTypes.includes(obj.__typename)
    }
    


    const PmsSyncJobResult_possibleTypes: string[] = ['PmsSyncJobResult']
    export const isPmsSyncJobResult = (obj?: { __typename?: any } | null): obj is PmsSyncJobResult => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPmsSyncJobResult"')
      return PmsSyncJobResult_possibleTypes.includes(obj.__typename)
    }
    


    const PmsSyncJobsList_possibleTypes: string[] = ['PmsSyncJobsList']
    export const isPmsSyncJobsList = (obj?: { __typename?: any } | null): obj is PmsSyncJobsList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPmsSyncJobsList"')
      return PmsSyncJobsList_possibleTypes.includes(obj.__typename)
    }
    


    const PmsSyncMessage_possibleTypes: string[] = ['PmsSyncMessage']
    export const isPmsSyncMessage = (obj?: { __typename?: any } | null): obj is PmsSyncMessage => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPmsSyncMessage"')
      return PmsSyncMessage_possibleTypes.includes(obj.__typename)
    }
    


    const Portfolio_possibleTypes: string[] = ['Portfolio']
    export const isPortfolio = (obj?: { __typename?: any } | null): obj is Portfolio => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPortfolio"')
      return Portfolio_possibleTypes.includes(obj.__typename)
    }
    


    const PortfolioContextFilter_possibleTypes: string[] = ['PortfolioContextFilter']
    export const isPortfolioContextFilter = (obj?: { __typename?: any } | null): obj is PortfolioContextFilter => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPortfolioContextFilter"')
      return PortfolioContextFilter_possibleTypes.includes(obj.__typename)
    }
    


    const PortfolioRoot_possibleTypes: string[] = ['PortfolioRoot']
    export const isPortfolioRoot = (obj?: { __typename?: any } | null): obj is PortfolioRoot => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPortfolioRoot"')
      return PortfolioRoot_possibleTypes.includes(obj.__typename)
    }
    


    const PriceByPaymentMethod_possibleTypes: string[] = ['PriceByPaymentMethod']
    export const isPriceByPaymentMethod = (obj?: { __typename?: any } | null): obj is PriceByPaymentMethod => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPriceByPaymentMethod"')
      return PriceByPaymentMethod_possibleTypes.includes(obj.__typename)
    }
    


    const Query_possibleTypes: string[] = ['Query']
    export const isQuery = (obj?: { __typename?: any } | null): obj is Query => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isQuery"')
      return Query_possibleTypes.includes(obj.__typename)
    }
    


    const Rating_possibleTypes: string[] = ['Rating']
    export const isRating = (obj?: { __typename?: any } | null): obj is Rating => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isRating"')
      return Rating_possibleTypes.includes(obj.__typename)
    }
    


    const RealEstateAmenity_possibleTypes: string[] = ['RealEstateAmenity']
    export const isRealEstateAmenity = (obj?: { __typename?: any } | null): obj is RealEstateAmenity => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isRealEstateAmenity"')
      return RealEstateAmenity_possibleTypes.includes(obj.__typename)
    }
    


    const RealEstateAmenityCategory_possibleTypes: string[] = ['RealEstateAmenityCategory']
    export const isRealEstateAmenityCategory = (obj?: { __typename?: any } | null): obj is RealEstateAmenityCategory => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isRealEstateAmenityCategory"')
      return RealEstateAmenityCategory_possibleTypes.includes(obj.__typename)
    }
    


    const RealEstateListing_possibleTypes: string[] = ['RealEstateListing']
    export const isRealEstateListing = (obj?: { __typename?: any } | null): obj is RealEstateListing => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isRealEstateListing"')
      return RealEstateListing_possibleTypes.includes(obj.__typename)
    }
    


    const RenewalRiskScore_possibleTypes: string[] = ['RenewalRiskScore']
    export const isRenewalRiskScore = (obj?: { __typename?: any } | null): obj is RenewalRiskScore => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isRenewalRiskScore"')
      return RenewalRiskScore_possibleTypes.includes(obj.__typename)
    }
    


    const RenewalSurveyAssignment_possibleTypes: string[] = ['RenewalSurveyAssignment']
    export const isRenewalSurveyAssignment = (obj?: { __typename?: any } | null): obj is RenewalSurveyAssignment => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isRenewalSurveyAssignment"')
      return RenewalSurveyAssignment_possibleTypes.includes(obj.__typename)
    }
    


    const RenewalSurveySettings_possibleTypes: string[] = ['RenewalSurveySettings']
    export const isRenewalSurveySettings = (obj?: { __typename?: any } | null): obj is RenewalSurveySettings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isRenewalSurveySettings"')
      return RenewalSurveySettings_possibleTypes.includes(obj.__typename)
    }
    


    const ReportsRoot_possibleTypes: string[] = ['ReportsRoot']
    export const isReportsRoot = (obj?: { __typename?: any } | null): obj is ReportsRoot => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isReportsRoot"')
      return ReportsRoot_possibleTypes.includes(obj.__typename)
    }
    


    const Reputation_possibleTypes: string[] = ['Reputation']
    export const isReputation = (obj?: { __typename?: any } | null): obj is Reputation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isReputation"')
      return Reputation_possibleTypes.includes(obj.__typename)
    }
    


    const ReservationCheckoutResponse_possibleTypes: string[] = ['ReservationCheckoutResponse']
    export const isReservationCheckoutResponse = (obj?: { __typename?: any } | null): obj is ReservationCheckoutResponse => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isReservationCheckoutResponse"')
      return ReservationCheckoutResponse_possibleTypes.includes(obj.__typename)
    }
    


    const Resident_possibleTypes: string[] = ['Resident']
    export const isResident = (obj?: { __typename?: any } | null): obj is Resident => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isResident"')
      return Resident_possibleTypes.includes(obj.__typename)
    }
    


    const ResidentEmbeddedApplicationDescriptor_possibleTypes: string[] = ['ResidentEmbeddedApplicationDescriptor']
    export const isResidentEmbeddedApplicationDescriptor = (obj?: { __typename?: any } | null): obj is ResidentEmbeddedApplicationDescriptor => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isResidentEmbeddedApplicationDescriptor"')
      return ResidentEmbeddedApplicationDescriptor_possibleTypes.includes(obj.__typename)
    }
    


    const ResidentEmbeddedApplications_possibleTypes: string[] = ['ResidentEmbeddedApplications']
    export const isResidentEmbeddedApplications = (obj?: { __typename?: any } | null): obj is ResidentEmbeddedApplications => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isResidentEmbeddedApplications"')
      return ResidentEmbeddedApplications_possibleTypes.includes(obj.__typename)
    }
    


    const ResidentSearchResult_possibleTypes: string[] = ['ResidentSearchResult']
    export const isResidentSearchResult = (obj?: { __typename?: any } | null): obj is ResidentSearchResult => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isResidentSearchResult"')
      return ResidentSearchResult_possibleTypes.includes(obj.__typename)
    }
    


    const ResidentsList_possibleTypes: string[] = ['ResidentsList']
    export const isResidentsList = (obj?: { __typename?: any } | null): obj is ResidentsList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isResidentsList"')
      return ResidentsList_possibleTypes.includes(obj.__typename)
    }
    


    const Role_possibleTypes: string[] = ['Role']
    export const isRole = (obj?: { __typename?: any } | null): obj is Role => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isRole"')
      return Role_possibleTypes.includes(obj.__typename)
    }
    


    const ScheduledBroadcast_possibleTypes: string[] = ['ScheduledBroadcast']
    export const isScheduledBroadcast = (obj?: { __typename?: any } | null): obj is ScheduledBroadcast => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isScheduledBroadcast"')
      return ScheduledBroadcast_possibleTypes.includes(obj.__typename)
    }
    


    const SecureKeyValue_possibleTypes: string[] = ['SecureKeyValue']
    export const isSecureKeyValue = (obj?: { __typename?: any } | null): obj is SecureKeyValue => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSecureKeyValue"')
      return SecureKeyValue_possibleTypes.includes(obj.__typename)
    }
    


    const Service_possibleTypes: string[] = ['Service']
    export const isService = (obj?: { __typename?: any } | null): obj is Service => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isService"')
      return Service_possibleTypes.includes(obj.__typename)
    }
    


    const ServiceProduct_possibleTypes: string[] = ['ServiceProduct']
    export const isServiceProduct = (obj?: { __typename?: any } | null): obj is ServiceProduct => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isServiceProduct"')
      return ServiceProduct_possibleTypes.includes(obj.__typename)
    }
    


    const ServiceProductPurchaseResult_possibleTypes: string[] = ['ServiceProductPurchaseResult']
    export const isServiceProductPurchaseResult = (obj?: { __typename?: any } | null): obj is ServiceProductPurchaseResult => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isServiceProductPurchaseResult"')
      return ServiceProductPurchaseResult_possibleTypes.includes(obj.__typename)
    }
    


    const ServiceRequestAttachment_possibleTypes: string[] = ['ServiceRequestAttachment']
    export const isServiceRequestAttachment = (obj?: { __typename?: any } | null): obj is ServiceRequestAttachment => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isServiceRequestAttachment"')
      return ServiceRequestAttachment_possibleTypes.includes(obj.__typename)
    }
    


    const ServiceRequestComment_possibleTypes: string[] = ['ServiceRequestComment']
    export const isServiceRequestComment = (obj?: { __typename?: any } | null): obj is ServiceRequestComment => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isServiceRequestComment"')
      return ServiceRequestComment_possibleTypes.includes(obj.__typename)
    }
    


    const ServiceRequestList_possibleTypes: string[] = ['ServiceRequestList']
    export const isServiceRequestList = (obj?: { __typename?: any } | null): obj is ServiceRequestList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isServiceRequestList"')
      return ServiceRequestList_possibleTypes.includes(obj.__typename)
    }
    


    const ServiceRequestStatusMapping_possibleTypes: string[] = ['ServiceRequestStatusMapping']
    export const isServiceRequestStatusMapping = (obj?: { __typename?: any } | null): obj is ServiceRequestStatusMapping => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isServiceRequestStatusMapping"')
      return ServiceRequestStatusMapping_possibleTypes.includes(obj.__typename)
    }
    


    const ServiceRequestTicket_possibleTypes: string[] = ['ServiceRequestTicket']
    export const isServiceRequestTicket = (obj?: { __typename?: any } | null): obj is ServiceRequestTicket => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isServiceRequestTicket"')
      return ServiceRequestTicket_possibleTypes.includes(obj.__typename)
    }
    


    const ServiceRequestTicketOpeningManagerDetails_possibleTypes: string[] = ['ServiceRequestTicketOpeningManagerDetails']
    export const isServiceRequestTicketOpeningManagerDetails = (obj?: { __typename?: any } | null): obj is ServiceRequestTicketOpeningManagerDetails => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isServiceRequestTicketOpeningManagerDetails"')
      return ServiceRequestTicketOpeningManagerDetails_possibleTypes.includes(obj.__typename)
    }
    


    const ServiceRequestsAdapter_possibleTypes: string[] = ['ServiceRequestsAdapter']
    export const isServiceRequestsAdapter = (obj?: { __typename?: any } | null): obj is ServiceRequestsAdapter => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isServiceRequestsAdapter"')
      return ServiceRequestsAdapter_possibleTypes.includes(obj.__typename)
    }
    


    const ServiceRequestsAdapterTestResult_possibleTypes: string[] = ['ServiceRequestsAdapterTestResult']
    export const isServiceRequestsAdapterTestResult = (obj?: { __typename?: any } | null): obj is ServiceRequestsAdapterTestResult => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isServiceRequestsAdapterTestResult"')
      return ServiceRequestsAdapterTestResult_possibleTypes.includes(obj.__typename)
    }
    


    const ServiceRequestsSettings_possibleTypes: string[] = ['ServiceRequestsSettings']
    export const isServiceRequestsSettings = (obj?: { __typename?: any } | null): obj is ServiceRequestsSettings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isServiceRequestsSettings"')
      return ServiceRequestsSettings_possibleTypes.includes(obj.__typename)
    }
    


    const ServiceRequestsSettingsTicketStatusesNotificationTemplateMapping_possibleTypes: string[] = ['ServiceRequestsSettingsTicketStatusesNotificationTemplateMapping']
    export const isServiceRequestsSettingsTicketStatusesNotificationTemplateMapping = (obj?: { __typename?: any } | null): obj is ServiceRequestsSettingsTicketStatusesNotificationTemplateMapping => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isServiceRequestsSettingsTicketStatusesNotificationTemplateMapping"')
      return ServiceRequestsSettingsTicketStatusesNotificationTemplateMapping_possibleTypes.includes(obj.__typename)
    }
    


    const ServiceRequestsUploadUrlResponse_possibleTypes: string[] = ['ServiceRequestsUploadUrlResponse']
    export const isServiceRequestsUploadUrlResponse = (obj?: { __typename?: any } | null): obj is ServiceRequestsUploadUrlResponse => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isServiceRequestsUploadUrlResponse"')
      return ServiceRequestsUploadUrlResponse_possibleTypes.includes(obj.__typename)
    }
    


    const ShiftNote_possibleTypes: string[] = ['ShiftNote']
    export const isShiftNote = (obj?: { __typename?: any } | null): obj is ShiftNote => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isShiftNote"')
      return ShiftNote_possibleTypes.includes(obj.__typename)
    }
    


    const SmartLockAdapter_possibleTypes: string[] = ['SmartLockAdapter']
    export const isSmartLockAdapter = (obj?: { __typename?: any } | null): obj is SmartLockAdapter => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSmartLockAdapter"')
      return SmartLockAdapter_possibleTypes.includes(obj.__typename)
    }
    


    const SmsLinkData_possibleTypes: string[] = ['SmsLinkData']
    export const isSmsLinkData = (obj?: { __typename?: any } | null): obj is SmsLinkData => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSmsLinkData"')
      return SmsLinkData_possibleTypes.includes(obj.__typename)
    }
    


    const SocialInteraction_possibleTypes: string[] = ['SocialInteraction']
    export const isSocialInteraction = (obj?: { __typename?: any } | null): obj is SocialInteraction => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSocialInteraction"')
      return SocialInteraction_possibleTypes.includes(obj.__typename)
    }
    


    const SocialProfile_possibleTypes: string[] = ['SocialProfile']
    export const isSocialProfile = (obj?: { __typename?: any } | null): obj is SocialProfile => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSocialProfile"')
      return SocialProfile_possibleTypes.includes(obj.__typename)
    }
    


    const SocialProfileArrayField_possibleTypes: string[] = ['SocialProfileArrayField']
    export const isSocialProfileArrayField = (obj?: { __typename?: any } | null): obj is SocialProfileArrayField => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSocialProfileArrayField"')
      return SocialProfileArrayField_possibleTypes.includes(obj.__typename)
    }
    


    const SocialProfileCallToActionField_possibleTypes: string[] = ['SocialProfileCallToActionField']
    export const isSocialProfileCallToActionField = (obj?: { __typename?: any } | null): obj is SocialProfileCallToActionField => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSocialProfileCallToActionField"')
      return SocialProfileCallToActionField_possibleTypes.includes(obj.__typename)
    }
    


    const SocialProfileField_possibleTypes: string[] = ['SocialProfileField']
    export const isSocialProfileField = (obj?: { __typename?: any } | null): obj is SocialProfileField => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSocialProfileField"')
      return SocialProfileField_possibleTypes.includes(obj.__typename)
    }
    


    const SocialProfilePets_possibleTypes: string[] = ['SocialProfilePets']
    export const isSocialProfilePets = (obj?: { __typename?: any } | null): obj is SocialProfilePets => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSocialProfilePets"')
      return SocialProfilePets_possibleTypes.includes(obj.__typename)
    }
    


    const SocialProfilePronounsField_possibleTypes: string[] = ['SocialProfilePronounsField']
    export const isSocialProfilePronounsField = (obj?: { __typename?: any } | null): obj is SocialProfilePronounsField => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSocialProfilePronounsField"')
      return SocialProfilePronounsField_possibleTypes.includes(obj.__typename)
    }
    


    const SocialProfileSocialLinksField_possibleTypes: string[] = ['SocialProfileSocialLinksField']
    export const isSocialProfileSocialLinksField = (obj?: { __typename?: any } | null): obj is SocialProfileSocialLinksField => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSocialProfileSocialLinksField"')
      return SocialProfileSocialLinksField_possibleTypes.includes(obj.__typename)
    }
    


    const SocialProfilesList_possibleTypes: string[] = ['SocialProfilesList']
    export const isSocialProfilesList = (obj?: { __typename?: any } | null): obj is SocialProfilesList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSocialProfilesList"')
      return SocialProfilesList_possibleTypes.includes(obj.__typename)
    }
    


    const SpecificUsersAudienceParams_possibleTypes: string[] = ['SpecificUsersAudienceParams']
    export const isSpecificUsersAudienceParams = (obj?: { __typename?: any } | null): obj is SpecificUsersAudienceParams => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSpecificUsersAudienceParams"')
      return SpecificUsersAudienceParams_possibleTypes.includes(obj.__typename)
    }
    


    const StaffTask_possibleTypes: string[] = ['StaffTask']
    export const isStaffTask = (obj?: { __typename?: any } | null): obj is StaffTask => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isStaffTask"')
      return StaffTask_possibleTypes.includes(obj.__typename)
    }
    


    const StaffTaskAssignee_possibleTypes: string[] = ['StaffTaskAssignee']
    export const isStaffTaskAssignee = (obj?: { __typename?: any } | null): obj is StaffTaskAssignee => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isStaffTaskAssignee"')
      return StaffTaskAssignee_possibleTypes.includes(obj.__typename)
    }
    


    const StripeCheckoutSession_possibleTypes: string[] = ['StripeCheckoutSession']
    export const isStripeCheckoutSession = (obj?: { __typename?: any } | null): obj is StripeCheckoutSession => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isStripeCheckoutSession"')
      return StripeCheckoutSession_possibleTypes.includes(obj.__typename)
    }
    


    const StripeConnectedAccount_possibleTypes: string[] = ['StripeConnectedAccount']
    export const isStripeConnectedAccount = (obj?: { __typename?: any } | null): obj is StripeConnectedAccount => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isStripeConnectedAccount"')
      return StripeConnectedAccount_possibleTypes.includes(obj.__typename)
    }
    


    const StripeConnectedAccountSession_possibleTypes: string[] = ['StripeConnectedAccountSession']
    export const isStripeConnectedAccountSession = (obj?: { __typename?: any } | null): obj is StripeConnectedAccountSession => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isStripeConnectedAccountSession"')
      return StripeConnectedAccountSession_possibleTypes.includes(obj.__typename)
    }
    


    const SubscriptionContract_possibleTypes: string[] = ['SubscriptionContract']
    export const isSubscriptionContract = (obj?: { __typename?: any } | null): obj is SubscriptionContract => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSubscriptionContract"')
      return SubscriptionContract_possibleTypes.includes(obj.__typename)
    }
    


    const SubwayStation_possibleTypes: string[] = ['SubwayStation']
    export const isSubwayStation = (obj?: { __typename?: any } | null): obj is SubwayStation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSubwayStation"')
      return SubwayStation_possibleTypes.includes(obj.__typename)
    }
    


    const SupportCategory_possibleTypes: string[] = ['SupportCategory']
    export const isSupportCategory = (obj?: { __typename?: any } | null): obj is SupportCategory => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSupportCategory"')
      return SupportCategory_possibleTypes.includes(obj.__typename)
    }
    


    const SupportSatisfactionRating_possibleTypes: string[] = ['SupportSatisfactionRating']
    export const isSupportSatisfactionRating = (obj?: { __typename?: any } | null): obj is SupportSatisfactionRating => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSupportSatisfactionRating"')
      return SupportSatisfactionRating_possibleTypes.includes(obj.__typename)
    }
    


    const SupportTicket_possibleTypes: string[] = ['SupportTicket']
    export const isSupportTicket = (obj?: { __typename?: any } | null): obj is SupportTicket => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSupportTicket"')
      return SupportTicket_possibleTypes.includes(obj.__typename)
    }
    


    const SupportTicketComment_possibleTypes: string[] = ['SupportTicketComment']
    export const isSupportTicketComment = (obj?: { __typename?: any } | null): obj is SupportTicketComment => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSupportTicketComment"')
      return SupportTicketComment_possibleTypes.includes(obj.__typename)
    }
    


    const SupportTicketUpdatesSubscription_possibleTypes: string[] = ['SupportTicketUpdatesSubscription']
    export const isSupportTicketUpdatesSubscription = (obj?: { __typename?: any } | null): obj is SupportTicketUpdatesSubscription => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSupportTicketUpdatesSubscription"')
      return SupportTicketUpdatesSubscription_possibleTypes.includes(obj.__typename)
    }
    


    const Survey_possibleTypes: string[] = ['Survey']
    export const isSurvey = (obj?: { __typename?: any } | null): obj is Survey => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSurvey"')
      return Survey_possibleTypes.includes(obj.__typename)
    }
    


    const SurveyAssignment_possibleTypes: string[] = ['SurveyAssignment']
    export const isSurveyAssignment = (obj?: { __typename?: any } | null): obj is SurveyAssignment => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSurveyAssignment"')
      return SurveyAssignment_possibleTypes.includes(obj.__typename)
    }
    


    const SurveyBatch_possibleTypes: string[] = ['SurveyBatch']
    export const isSurveyBatch = (obj?: { __typename?: any } | null): obj is SurveyBatch => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSurveyBatch"')
      return SurveyBatch_possibleTypes.includes(obj.__typename)
    }
    


    const SurveyBatchesList_possibleTypes: string[] = ['SurveyBatchesList']
    export const isSurveyBatchesList = (obj?: { __typename?: any } | null): obj is SurveyBatchesList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSurveyBatchesList"')
      return SurveyBatchesList_possibleTypes.includes(obj.__typename)
    }
    


    const SurveyChoiceOption_possibleTypes: string[] = ['SurveyChoiceOption']
    export const isSurveyChoiceOption = (obj?: { __typename?: any } | null): obj is SurveyChoiceOption => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSurveyChoiceOption"')
      return SurveyChoiceOption_possibleTypes.includes(obj.__typename)
    }
    


    const SurveyQuestion_possibleTypes: string[] = ['SurveyQuestion']
    export const isSurveyQuestion = (obj?: { __typename?: any } | null): obj is SurveyQuestion => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSurveyQuestion"')
      return SurveyQuestion_possibleTypes.includes(obj.__typename)
    }
    


    const SurveyScaleOption_possibleTypes: string[] = ['SurveyScaleOption']
    export const isSurveyScaleOption = (obj?: { __typename?: any } | null): obj is SurveyScaleOption => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSurveyScaleOption"')
      return SurveyScaleOption_possibleTypes.includes(obj.__typename)
    }
    


    const SurveysList_possibleTypes: string[] = ['SurveysList']
    export const isSurveysList = (obj?: { __typename?: any } | null): obj is SurveysList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSurveysList"')
      return SurveysList_possibleTypes.includes(obj.__typename)
    }
    


    const SyncJob_possibleTypes: string[] = ['SyncJob']
    export const isSyncJob = (obj?: { __typename?: any } | null): obj is SyncJob => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSyncJob"')
      return SyncJob_possibleTypes.includes(obj.__typename)
    }
    


    const SyncJobDownloadExportResult_possibleTypes: string[] = ['SyncJobDownloadExportResult']
    export const isSyncJobDownloadExportResult = (obj?: { __typename?: any } | null): obj is SyncJobDownloadExportResult => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSyncJobDownloadExportResult"')
      return SyncJobDownloadExportResult_possibleTypes.includes(obj.__typename)
    }
    


    const SyncJobItem_possibleTypes: string[] = ['SyncJobItem']
    export const isSyncJobItem = (obj?: { __typename?: any } | null): obj is SyncJobItem => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSyncJobItem"')
      return SyncJobItem_possibleTypes.includes(obj.__typename)
    }
    


    const SyncJobItemList_possibleTypes: string[] = ['SyncJobItemList']
    export const isSyncJobItemList = (obj?: { __typename?: any } | null): obj is SyncJobItemList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSyncJobItemList"')
      return SyncJobItemList_possibleTypes.includes(obj.__typename)
    }
    


    const SyncJobResult_possibleTypes: string[] = ['SyncJobResult']
    export const isSyncJobResult = (obj?: { __typename?: any } | null): obj is SyncJobResult => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSyncJobResult"')
      return SyncJobResult_possibleTypes.includes(obj.__typename)
    }
    


    const SyncJobsList_possibleTypes: string[] = ['SyncJobsList']
    export const isSyncJobsList = (obj?: { __typename?: any } | null): obj is SyncJobsList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSyncJobsList"')
      return SyncJobsList_possibleTypes.includes(obj.__typename)
    }
    


    const SyncMessage_possibleTypes: string[] = ['SyncMessage']
    export const isSyncMessage = (obj?: { __typename?: any } | null): obj is SyncMessage => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSyncMessage"')
      return SyncMessage_possibleTypes.includes(obj.__typename)
    }
    


    const Task_possibleTypes: string[] = ['Task']
    export const isTask = (obj?: { __typename?: any } | null): obj is Task => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isTask"')
      return Task_possibleTypes.includes(obj.__typename)
    }
    


    const TaskList_possibleTypes: string[] = ['TaskList']
    export const isTaskList = (obj?: { __typename?: any } | null): obj is TaskList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isTaskList"')
      return TaskList_possibleTypes.includes(obj.__typename)
    }
    


    const TaskListTask_possibleTypes: string[] = ['TaskListTask']
    export const isTaskListTask = (obj?: { __typename?: any } | null): obj is TaskListTask => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isTaskListTask"')
      return TaskListTask_possibleTypes.includes(obj.__typename)
    }
    


    const TaskListsOverviewResponse_possibleTypes: string[] = ['TaskListsOverviewResponse']
    export const isTaskListsOverviewResponse = (obj?: { __typename?: any } | null): obj is TaskListsOverviewResponse => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isTaskListsOverviewResponse"')
      return TaskListsOverviewResponse_possibleTypes.includes(obj.__typename)
    }
    


    const TaxRate_possibleTypes: string[] = ['TaxRate']
    export const isTaxRate = (obj?: { __typename?: any } | null): obj is TaxRate => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isTaxRate"')
      return TaxRate_possibleTypes.includes(obj.__typename)
    }
    


    const Team_possibleTypes: string[] = ['Team']
    export const isTeam = (obj?: { __typename?: any } | null): obj is Team => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isTeam"')
      return Team_possibleTypes.includes(obj.__typename)
    }
    


    const TeamBuildingPermissions_possibleTypes: string[] = ['TeamBuildingPermissions']
    export const isTeamBuildingPermissions = (obj?: { __typename?: any } | null): obj is TeamBuildingPermissions => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isTeamBuildingPermissions"')
      return TeamBuildingPermissions_possibleTypes.includes(obj.__typename)
    }
    


    const Thermostat_possibleTypes: string[] = ['Thermostat']
    export const isThermostat = (obj?: { __typename?: any } | null): obj is Thermostat => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isThermostat"')
      return Thermostat_possibleTypes.includes(obj.__typename)
    }
    


    const TriggerTaskListResponse_possibleTypes: string[] = ['TriggerTaskListResponse']
    export const isTriggerTaskListResponse = (obj?: { __typename?: any } | null): obj is TriggerTaskListResponse => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isTriggerTaskListResponse"')
      return TriggerTaskListResponse_possibleTypes.includes(obj.__typename)
    }
    


    const Unit_possibleTypes: string[] = ['Unit']
    export const isUnit = (obj?: { __typename?: any } | null): obj is Unit => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUnit"')
      return Unit_possibleTypes.includes(obj.__typename)
    }
    


    const UnitPublicData_possibleTypes: string[] = ['UnitPublicData']
    export const isUnitPublicData = (obj?: { __typename?: any } | null): obj is UnitPublicData => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUnitPublicData"')
      return UnitPublicData_possibleTypes.includes(obj.__typename)
    }
    


    const User_possibleTypes: string[] = ['User']
    export const isUser = (obj?: { __typename?: any } | null): obj is User => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUser"')
      return User_possibleTypes.includes(obj.__typename)
    }
    


    const UserAggregations_possibleTypes: string[] = ['UserAggregations']
    export const isUserAggregations = (obj?: { __typename?: any } | null): obj is UserAggregations => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUserAggregations"')
      return UserAggregations_possibleTypes.includes(obj.__typename)
    }
    


    const UserLoginApiKeyResponse_possibleTypes: string[] = ['UserLoginApiKeyResponse']
    export const isUserLoginApiKeyResponse = (obj?: { __typename?: any } | null): obj is UserLoginApiKeyResponse => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUserLoginApiKeyResponse"')
      return UserLoginApiKeyResponse_possibleTypes.includes(obj.__typename)
    }
    


    const UserRoot_possibleTypes: string[] = ['UserRoot']
    export const isUserRoot = (obj?: { __typename?: any } | null): obj is UserRoot => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUserRoot"')
      return UserRoot_possibleTypes.includes(obj.__typename)
    }
    


    const UserSmartHomeRoot_possibleTypes: string[] = ['UserSmartHomeRoot']
    export const isUserSmartHomeRoot = (obj?: { __typename?: any } | null): obj is UserSmartHomeRoot => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUserSmartHomeRoot"')
      return UserSmartHomeRoot_possibleTypes.includes(obj.__typename)
    }
    


    const UserTask_possibleTypes: string[] = ['UserTask']
    export const isUserTask = (obj?: { __typename?: any } | null): obj is UserTask => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUserTask"')
      return UserTask_possibleTypes.includes(obj.__typename)
    }
    


    const UserTaskAttachment_possibleTypes: string[] = ['UserTaskAttachment']
    export const isUserTaskAttachment = (obj?: { __typename?: any } | null): obj is UserTaskAttachment => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUserTaskAttachment"')
      return UserTaskAttachment_possibleTypes.includes(obj.__typename)
    }
    


    const UserTaskList_possibleTypes: string[] = ['UserTaskList']
    export const isUserTaskList = (obj?: { __typename?: any } | null): obj is UserTaskList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUserTaskList"')
      return UserTaskList_possibleTypes.includes(obj.__typename)
    }
    


    const UserTaskListSubmitResponse_possibleTypes: string[] = ['UserTaskListSubmitResponse']
    export const isUserTaskListSubmitResponse = (obj?: { __typename?: any } | null): obj is UserTaskListSubmitResponse => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUserTaskListSubmitResponse"')
      return UserTaskListSubmitResponse_possibleTypes.includes(obj.__typename)
    }
    


    const UserTaskListUpdateResponse_possibleTypes: string[] = ['UserTaskListUpdateResponse']
    export const isUserTaskListUpdateResponse = (obj?: { __typename?: any } | null): obj is UserTaskListUpdateResponse => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUserTaskListUpdateResponse"')
      return UserTaskListUpdateResponse_possibleTypes.includes(obj.__typename)
    }
    


    const UserTaskResponse_possibleTypes: string[] = ['UserTaskResponse']
    export const isUserTaskResponse = (obj?: { __typename?: any } | null): obj is UserTaskResponse => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUserTaskResponse"')
      return UserTaskResponse_possibleTypes.includes(obj.__typename)
    }
    


    const UserTaskStatusUpdateResponse_possibleTypes: string[] = ['UserTaskStatusUpdateResponse']
    export const isUserTaskStatusUpdateResponse = (obj?: { __typename?: any } | null): obj is UserTaskStatusUpdateResponse => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUserTaskStatusUpdateResponse"')
      return UserTaskStatusUpdateResponse_possibleTypes.includes(obj.__typename)
    }
    


    const UsersList_possibleTypes: string[] = ['UsersList']
    export const isUsersList = (obj?: { __typename?: any } | null): obj is UsersList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUsersList"')
      return UsersList_possibleTypes.includes(obj.__typename)
    }
    


    const Visitor_possibleTypes: string[] = ['Visitor']
    export const isVisitor = (obj?: { __typename?: any } | null): obj is Visitor => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isVisitor"')
      return Visitor_possibleTypes.includes(obj.__typename)
    }
    


    const VisitorsSettings_possibleTypes: string[] = ['VisitorsSettings']
    export const isVisitorsSettings = (obj?: { __typename?: any } | null): obj is VisitorsSettings => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isVisitorsSettings"')
      return VisitorsSettings_possibleTypes.includes(obj.__typename)
    }
    


    const WebLinkData_possibleTypes: string[] = ['WebLinkData']
    export const isWebLinkData = (obj?: { __typename?: any } | null): obj is WebLinkData => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isWebLinkData"')
      return WebLinkData_possibleTypes.includes(obj.__typename)
    }
    


    const WhiteLabel_possibleTypes: string[] = ['WhiteLabel']
    export const isWhiteLabel = (obj?: { __typename?: any } | null): obj is WhiteLabel => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isWhiteLabel"')
      return WhiteLabel_possibleTypes.includes(obj.__typename)
    }
    


    const WhiteLabelConfig_possibleTypes: string[] = ['WhiteLabelConfig']
    export const isWhiteLabelConfig = (obj?: { __typename?: any } | null): obj is WhiteLabelConfig => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isWhiteLabelConfig"')
      return WhiteLabelConfig_possibleTypes.includes(obj.__typename)
    }
    


    const externalLockProviderGroup_possibleTypes: string[] = ['externalLockProviderGroup']
    export const isexternalLockProviderGroup = (obj?: { __typename?: any } | null): obj is externalLockProviderGroup => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isexternalLockProviderGroup"')
      return externalLockProviderGroup_possibleTypes.includes(obj.__typename)
    }
    


    const externalLockProviderGroupList_possibleTypes: string[] = ['externalLockProviderGroupList']
    export const isexternalLockProviderGroupList = (obj?: { __typename?: any } | null): obj is externalLockProviderGroupList => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isexternalLockProviderGroupList"')
      return externalLockProviderGroupList_possibleTypes.includes(obj.__typename)
    }
    


    const supportTicketNotificationText_possibleTypes: string[] = ['supportTicketNotificationText']
    export const issupportTicketNotificationText = (obj?: { __typename?: any } | null): obj is supportTicketNotificationText => {
      if (!obj?.__typename) throw new Error('__typename is missing in "issupportTicketNotificationText"')
      return supportTicketNotificationText_possibleTypes.includes(obj.__typename)
    }
    

export const enumAdapterType = {
   PMS: 'PMS' as const,
   SMART_LOCK: 'SMART_LOCK' as const,
   THERMOSTAT: 'THERMOSTAT' as const
}

export const enumAddressType = {
   BILLING_ADDRESS: 'BILLING_ADDRESS' as const
}

export const enumAdmissionFeeType = {
   COMPLIMENTARY: 'COMPLIMENTARY' as const,
   FREE: 'FREE' as const,
   NONE: 'NONE' as const
}

export const enumAllowUserTaskStatus = {
   COMPLETED: 'COMPLETED' as const,
   NOT_COMPLETED: 'NOT_COMPLETED' as const
}

export const enumAmenitiesOrderBy = {
   LOCATION_ASC: 'LOCATION_ASC' as const,
   LOCATION_DESC: 'LOCATION_DESC' as const,
   NAME_ASC: 'NAME_ASC' as const,
   NAME_DESC: 'NAME_DESC' as const
}

export const enumAmenityCalendarType = {
   ALWAYS_OPEN: 'ALWAYS_OPEN' as const,
   CUSTOM: 'CUSTOM' as const,
   DAILY: 'DAILY' as const,
   OVER_NIGHT: 'OVER_NIGHT' as const
}

export const enumAmenityCalendarUnavailabilityOrderBy = {
   END_DATE_ASC: 'END_DATE_ASC' as const,
   END_DATE_DESC: 'END_DATE_DESC' as const,
   START_DATE_ASC: 'START_DATE_ASC' as const,
   START_DATE_DESC: 'START_DATE_DESC' as const
}

export const enumAmenityDepositStatus = {
   DEPOSIT_CHARGED: 'DEPOSIT_CHARGED' as const,
   DEPOSIT_PAID: 'DEPOSIT_PAID' as const,
   DEPOSIT_RELEASED: 'DEPOSIT_RELEASED' as const,
   PENDING_DEPOSIT: 'PENDING_DEPOSIT' as const
}

export const enumAmenityDepositType = {
   CARD: 'CARD' as const,
   CASH: 'CASH' as const,
   CHECK: 'CHECK' as const
}

export const enumAmenityMediaType = {
   GIF: 'GIF' as const,
   IMAGE: 'IMAGE' as const,
   VIDEO: 'VIDEO' as const
}

export const enumAmenityReservationOrderBy = {
   END_DATE_ASC: 'END_DATE_ASC' as const,
   END_DATE_DESC: 'END_DATE_DESC' as const,
   START_DATE_ASC: 'START_DATE_ASC' as const,
   START_DATE_DESC: 'START_DATE_DESC' as const
}

export const enumAmenityReservationPaymentStatus = {
   FAILED: 'FAILED' as const,
   PAID: 'PAID' as const,
   PENDING: 'PENDING' as const,
   REFUNDED: 'REFUNDED' as const
}

export const enumAmenityReservationStatus = {
   APPROVED: 'APPROVED' as const,
   CANCELLED: 'CANCELLED' as const,
   DENIED: 'DENIED' as const,
   PENDING_APPROVAL: 'PENDING_APPROVAL' as const,
   PENDING_PAYMENT: 'PENDING_PAYMENT' as const
}

export const enumAmenityReservationTimeLine = {
   CURRENT: 'CURRENT' as const,
   FUTURE: 'FUTURE' as const,
   PAST: 'PAST' as const
}

export const enumAmenityReservationType = {
   FREE: 'FREE' as const,
   PAID: 'PAID' as const
}

export const enumAppDashboardLinkAudienceType = {
   BUILDING: 'BUILDING' as const,
   COMMUNITY: 'COMMUNITY' as const,
   ORGANIZATION: 'ORGANIZATION' as const
}

export const enumAppDashboardLinkType = {
   APP_PAGE: 'APP_PAGE' as const,
   EMAIL: 'EMAIL' as const,
   EXTERNAL_APP: 'EXTERNAL_APP' as const,
   PHONE: 'PHONE' as const,
   SMS: 'SMS' as const,
   WEB: 'WEB' as const
}

export const enumAppOnboardingStatus = {
   COMPLETE: 'COMPLETE' as const,
   IN_COMPLETE: 'IN_COMPLETE' as const
}

export const enumAppPageName = {
   EVENTS: 'EVENTS' as const,
   GIVE_US_FEEDBACK: 'GIVE_US_FEEDBACK' as const,
   GROUPS: 'GROUPS' as const,
   INBOX: 'INBOX' as const,
   MEET_YOUR_NEIGHBORS: 'MEET_YOUR_NEIGHBORS' as const,
   MY_WALLET: 'MY_WALLET' as const,
   NOTIFICATIONS: 'NOTIFICATIONS' as const
}

export const enumApplicantOrderBy = {
   CREATED_AT_ASC: 'CREATED_AT_ASC' as const,
   CREATED_AT_DESC: 'CREATED_AT_DESC' as const
}

export const enumApplicantSubmissionStep = {
   ASSET: 'ASSET' as const,
   COMPLETED: 'COMPLETED' as const,
   CONSENT: 'CONSENT' as const,
   IDENTITY_VERIFICATION: 'IDENTITY_VERIFICATION' as const,
   INCOME: 'INCOME' as const,
   INVITE_CO_APPLICANT: 'INVITE_CO_APPLICANT' as const,
   PAYMENT: 'PAYMENT' as const,
   START: 'START' as const
}

export const enumApplicantType = {
   CO_OCCUPANT: 'CO_OCCUPANT' as const,
   CO_SIGNER: 'CO_SIGNER' as const,
   GUARANTOR: 'GUARANTOR' as const,
   MAIN: 'MAIN' as const,
   ROOMMATE: 'ROOMMATE' as const
}

export const enumApplicationPage = {
   EVENT: 'EVENT' as const,
   EVENTS_PAGE: 'EVENTS_PAGE' as const,
   HOME_UPDATE: 'HOME_UPDATE' as const,
   INVOICES: 'INVOICES' as const,
   MOVE_IN_CHECKLIST: 'MOVE_IN_CHECKLIST' as const,
   MY_TICKETS: 'MY_TICKETS' as const,
   PLACE: 'PLACE' as const,
   PROFILE: 'PROFILE' as const,
   SERVICE: 'SERVICE' as const,
   USER: 'USER' as const
}

export const enumApplicationStatus = {
   AWAITING_COUNTERSIGN: 'AWAITING_COUNTERSIGN' as const,
   AWAITING_REVIEW: 'AWAITING_REVIEW' as const,
   LEASE_SENT: 'LEASE_SENT' as const,
   LEASE_SIGNED: 'LEASE_SIGNED' as const,
   REJECTED: 'REJECTED' as const,
   STARTED: 'STARTED' as const
}

export const enumAttachmentsBehavior = {
   NOT_AVAILABLE: 'NOT_AVAILABLE' as const,
   OPTIONAL: 'OPTIONAL' as const,
   REQUIRED: 'REQUIRED' as const
}

export const enumAttachmentsCategory = {
   INSPECTION: 'INSPECTION' as const,
   MOVE_IN: 'MOVE_IN' as const,
   SERVICE_REQUEST: 'SERVICE_REQUEST' as const
}

export const enumAttendanceStatus = {
   CANCELLED: 'CANCELLED' as const,
   CHECKED_IN: 'CHECKED_IN' as const,
   CHECKED_IN_WO_RSVP: 'CHECKED_IN_WO_RSVP' as const,
   GOING: 'GOING' as const
}

export const enumAuthenticationMethod = {
   INIT_TOKEN: 'INIT_TOKEN' as const,
   OAUTH: 'OAUTH' as const
}

export const enumAutomationDescriptorParamType = {
   DATE: 'DATE' as const,
   INT: 'INT' as const,
   MULTI_SELECT: 'MULTI_SELECT' as const,
   SINGLE_SELECT: 'SINGLE_SELECT' as const,
   STRING: 'STRING' as const
}

export const enumAutomationRuleScope = {
   BUILDINGS: 'BUILDINGS' as const,
   COMMUNITIES: 'COMMUNITIES' as const,
   ORGANIZATION: 'ORGANIZATION' as const
}

export const enumAwayNoticeOrderBy = {
   START_DATE_ASC: 'START_DATE_ASC' as const,
   START_DATE_DESC: 'START_DATE_DESC' as const
}

export const enumAwayNoticeStatus = {
   APPROVED: 'APPROVED' as const,
   PENDING: 'PENDING' as const
}

export const enumBroadcastAttachmentType = {
   IMAGE: 'IMAGE' as const
}

export const enumBroadcastAudienceType = {
   BUILDING: 'BUILDING' as const,
   COMMUNITY: 'COMMUNITY' as const,
   EVENT_TYPE: 'EVENT_TYPE' as const,
   INTEREST_GROUP: 'INTEREST_GROUP' as const,
   SPECIFIC_MEMBERS: 'SPECIFIC_MEMBERS' as const
}

export const enumBroadcastCategory = {
   BILLING_AND_PAYMENTS: 'BILLING_AND_PAYMENTS' as const,
   EVENTS: 'EVENTS' as const,
   GROUPS: 'GROUPS' as const,
   MAINTENANCE: 'MAINTENANCE' as const,
   MOVE_IN: 'MOVE_IN' as const,
   PAYMENT_REMINDERS: 'PAYMENT_REMINDERS' as const,
   SPECIAL_OFFERS: 'SPECIAL_OFFERS' as const
}

export const enumBroadcastMedium = {
   EMAIL: 'EMAIL' as const,
   MOBILE_APP: 'MOBILE_APP' as const
}

export const enumBroadcastSendStatus = {
   DRAFT: 'DRAFT' as const,
   FAILED: 'FAILED' as const,
   IN_PROCESS: 'IN_PROCESS' as const,
   NOTIFICATIONS_CREATED: 'NOTIFICATIONS_CREATED' as const,
   TEMPLATE: 'TEMPLATE' as const
}

export const enumBroadcastSourceType = {
   PROPERTY_MANAGER: 'PROPERTY_MANAGER' as const,
   SEND_BROADCAST: 'SEND_BROADCAST' as const
}

export const enumBroadcastStatName = {
   AUDIENCE_WITHOUT_APP: 'AUDIENCE_WITHOUT_APP' as const,
   BOUNCED: 'BOUNCED' as const,
   CLICKED_ON_CTA: 'CLICKED_ON_CTA' as const,
   DELIVERABLE_AUDIENCE: 'DELIVERABLE_AUDIENCE' as const,
   DELIVERED: 'DELIVERED' as const,
   OPENED: 'OPENED' as const,
   SENT: 'SENT' as const,
   TOTAL_AUDIENCE: 'TOTAL_AUDIENCE' as const
}

export const enumBroadcastsOrderBy = {
   AUDIENCE_ASC: 'AUDIENCE_ASC' as const,
   AUDIENCE_DESC: 'AUDIENCE_DESC' as const,
   CATEGORY_ASC: 'CATEGORY_ASC' as const,
   CATEGORY_DESC: 'CATEGORY_DESC' as const,
   CREATED_AT_ASC: 'CREATED_AT_ASC' as const,
   CREATED_AT_DESC: 'CREATED_AT_DESC' as const,
   DATE_SENT_ASC: 'DATE_SENT_ASC' as const,
   DATE_SENT_DESC: 'DATE_SENT_DESC' as const,
   STATUS_ASC: 'STATUS_ASC' as const,
   STATUS_DESC: 'STATUS_DESC' as const,
   TITLE_ASC: 'TITLE_ASC' as const,
   TITLE_DESC: 'TITLE_DESC' as const,
   UPDATED_AT_ASC: 'UPDATED_AT_ASC' as const,
   UPDATED_AT_DESC: 'UPDATED_AT_DESC' as const
}

export const enumBuildingMode = {
   COOL: 'COOL' as const,
   HEAT: 'HEAT' as const
}

export const enumBuildingStatus = {
   DISABLED: 'DISABLED' as const,
   ENABLED: 'ENABLED' as const
}

export const enumBylineType = {
   COMMENT: 'COMMENT' as const,
   DISCUSSION: 'DISCUSSION' as const,
   EVENT_CHECKED_IN: 'EVENT_CHECKED_IN' as const,
   GOOD_VIBES: 'GOOD_VIBES' as const,
   HAVE_PET_BOTH: 'HAVE_PET_BOTH' as const,
   LIKE: 'LIKE' as const,
   NEW_NEIGHBOR: 'NEW_NEIGHBOR' as const,
   NEW_NEIGHBOR_BOTH: 'NEW_NEIGHBOR_BOTH' as const,
   SAME_BUILDING: 'SAME_BUILDING' as const,
   SAME_GROUP: 'SAME_GROUP' as const,
   SAME_INDUSTRY_OCCUPATION: 'SAME_INDUSTRY_OCCUPATION' as const,
   SAME_MOVE_IN_DAY: 'SAME_MOVE_IN_DAY' as const,
   VETERAN_NEIGHBOR: 'VETERAN_NEIGHBOR' as const,
   VETERAN_NEIGHBOR_BOTH: 'VETERAN_NEIGHBOR_BOTH' as const
}

export const enumCancellationRequestStatus = {
   APPROVED: 'APPROVED' as const,
   DENIED: 'DENIED' as const,
   PENDING: 'PENDING' as const
}

export const enumCheckInStatus = {
   CHECKED_IN: 'CHECKED_IN' as const,
   CHECKED_OUT: 'CHECKED_OUT' as const
}

export const enumContractStatus = {
   ACTIVE: 'ACTIVE' as const,
   APPLICANT: 'APPLICANT' as const,
   CANCELLED: 'CANCELLED' as const,
   EVICTED: 'EVICTED' as const,
   FUTURE: 'FUTURE' as const,
   INACTIVE: 'INACTIVE' as const
}

export const enumContractTimeline = {
   CURRENT: 'CURRENT' as const,
   FUTURE: 'FUTURE' as const,
   PAST: 'PAST' as const
}

export const enumCountryCode = {
   CA: 'CA' as const,
   DE: 'DE' as const,
   IL: 'IL' as const,
   US: 'US' as const
}

export const enumDayOfWeek = {
   FRIDAY: 'FRIDAY' as const,
   MONDAY: 'MONDAY' as const,
   SATURDAY: 'SATURDAY' as const,
   SUNDAY: 'SUNDAY' as const,
   THURSDAY: 'THURSDAY' as const,
   TUESDAY: 'TUESDAY' as const,
   WEDNESDAY: 'WEDNESDAY' as const
}

export const enumDeliveryPackageOrderBy = {
   ARRIVED_ON_DATE_AT_ASC: 'ARRIVED_ON_DATE_AT_ASC' as const,
   ARRIVED_ON_DATE_AT_DESC: 'ARRIVED_ON_DATE_AT_DESC' as const
}

export const enumDeliveryPackageStatus = {
   ARRIVED: 'ARRIVED' as const,
   PICKED_UP: 'PICKED_UP' as const,
   RETURNED: 'RETURNED' as const
}

export const enumDeviceMode = {
   AUTO: 'AUTO' as const,
   COOL: 'COOL' as const,
   HEAT: 'HEAT' as const,
   OFF: 'OFF' as const
}

export const enumDevicePlatform = {
   ANDROID: 'ANDROID' as const,
   IOS: 'IOS' as const
}

export const enumDiscussionCommentsOrderBy = {
   CREATED_AT_ASC: 'CREATED_AT_ASC' as const,
   CREATED_AT_DESC: 'CREATED_AT_DESC' as const
}

export const enumDiscussionPublishStatus = {
   PENDING: 'PENDING' as const,
   PUBLISHED: 'PUBLISHED' as const,
   REJECTED: 'REJECTED' as const,
   UNPUBLISHED: 'UNPUBLISHED' as const
}

export const enumDiscussionType = {
   DISCUSSION: 'DISCUSSION' as const,
   POLL: 'POLL' as const
}

export const enumDiscussionsOrderBy = {
   PUBLISHED_AT_ASC: 'PUBLISHED_AT_ASC' as const,
   PUBLISHED_AT_DESC: 'PUBLISHED_AT_DESC' as const
}

export const enumEmbeddedApplicationAuthenticationMethod = {
   NONE: 'NONE' as const,
   SAML2: 'SAML2' as const
}

export const enumEmbeddedApplicationCallbackHandlingMethod = {
   CALLBACK_URL: 'CALLBACK_URL' as const,
   IFRAME_HOOK: 'IFRAME_HOOK' as const,
   NONE: 'NONE' as const,
   WINDOW_EVENT: 'WINDOW_EVENT' as const
}

export const enumEmbeddedApplicationType = {
   MARKETPLACE: 'MARKETPLACE' as const,
   MOVE_IN_TASK: 'MOVE_IN_TASK' as const,
   RENT_PAYMENT: 'RENT_PAYMENT' as const
}

export const enumEntityType = {
   BUILDING: 'BUILDING' as const,
   UNIT: 'UNIT' as const
}

export const enumEventOwnershipType = {
   EXTERNAL: 'EXTERNAL' as const,
   IN_HOUSE: 'IN_HOUSE' as const
}

export const enumEventStatus = {
   FUTURE: 'FUTURE' as const,
   ON_GOING: 'ON_GOING' as const,
   PAST: 'PAST' as const
}

export const enumEventSuggestionTimeframe = {
   AFTERNOON: 'AFTERNOON' as const,
   EVENING: 'EVENING' as const,
   MORNING: 'MORNING' as const
}

export const enumEventsOrderBy = {
   START_DAY_ASC: 'START_DAY_ASC' as const,
   START_DAY_DESC: 'START_DAY_DESC' as const
}

export const enumFanMode = {
   AUTO: 'AUTO' as const,
   HIGH: 'HIGH' as const,
   LOW: 'LOW' as const,
   MED: 'MED' as const,
   OFF: 'OFF' as const
}

export const enumFeaturedSlidesOrderBy = {
   CreatedAt_ASC: 'CreatedAt_ASC' as const,
   CreatedAt_DESC: 'CreatedAt_DESC' as const,
   Name_ASC: 'Name_ASC' as const,
   Name_DESC: 'Name_DESC' as const,
   Priority_ASC: 'Priority_ASC' as const,
   Priority_DESC: 'Priority_DESC' as const,
   PublishStartDate_ASC: 'PublishStartDate_ASC' as const,
   PublishStartDate_DESC: 'PublishStartDate_DESC' as const
}

export const enumGender = {
   FEMALE: 'FEMALE' as const,
   MALE: 'MALE' as const,
   NA: 'NA' as const,
   NON_BINARY: 'NON_BINARY' as const
}

export const enumGeneralInfoPagesOrderBy = {
   CREATED_AT_ASC: 'CREATED_AT_ASC' as const,
   CREATED_AT_DESC: 'CREATED_AT_DESC' as const
}

export const enumInterestGroupSentiment = {
   NEGATIVE: 'NEGATIVE' as const,
   NEUTRAL: 'NEUTRAL' as const,
   POSITIVE: 'POSITIVE' as const
}

export const enumInviteStatus = {
   ACCEPTED: 'ACCEPTED' as const,
   EXPIRED: 'EXPIRED' as const,
   PENDING: 'PENDING' as const
}

export const enumJobItemEntityType = {
   BUILDING: 'BUILDING' as const,
   DELIVERY_PACKAGE: 'DELIVERY_PACKAGE' as const,
   LEASE_CONTRACT: 'LEASE_CONTRACT' as const,
   RESIDENT: 'RESIDENT' as const,
   SUPPORT_CATEGORY: 'SUPPORT_CATEGORY' as const,
   SUPPORT_TICKET: 'SUPPORT_TICKET' as const,
   SUPPORT_TICKET_ATTACHMENT: 'SUPPORT_TICKET_ATTACHMENT' as const,
   SUPPORT_TICKET_COMMENT: 'SUPPORT_TICKET_COMMENT' as const,
   UNIT: 'UNIT' as const
}

export const enumJobItemStatus = {
   COMPLETED: 'COMPLETED' as const,
   DISABLED: 'DISABLED' as const,
   FAILED: 'FAILED' as const,
   QUEUED: 'QUEUED' as const
}

export const enumLanguage = {
   EN: 'EN' as const,
   HE: 'HE' as const
}

export const enumLeaseContractOrderBy = {
   CREATED_AT_ASC: 'CREATED_AT_ASC' as const,
   CREATED_AT_DESC: 'CREATED_AT_DESC' as const
}

export const enumLeaseRecommendationStatus = {
   NOT_RECOMMENDED: 'NOT_RECOMMENDED' as const,
   PENDING_SCREENING: 'PENDING_SCREENING' as const,
   RECOMMENDED: 'RECOMMENDED' as const
}

export const enumLinkType = {
   chatGroup: 'chatGroup' as const,
   createDiscussion: 'createDiscussion' as const,
   custom: 'custom' as const,
   event: 'event' as const,
   generalInfoPage: 'generalInfoPage' as const,
   place: 'place' as const,
   selfProfile: 'selfProfile' as const,
   service: 'service' as const,
   user: 'user' as const
}

export const enumLockProviderType = {
   BMX: 'BMX' as const,
   BRIVO: 'BRIVO' as const,
   MOCK: 'MOCK' as const,
   OPENPATH: 'OPENPATH' as const,
   SALTO: 'SALTO' as const
}

export const enumMonth = {
   APRIL: 'APRIL' as const,
   AUGUST: 'AUGUST' as const,
   DECEMBER: 'DECEMBER' as const,
   FEBRUARY: 'FEBRUARY' as const,
   JANUARY: 'JANUARY' as const,
   JULY: 'JULY' as const,
   JUNE: 'JUNE' as const,
   MARCH: 'MARCH' as const,
   MAY: 'MAY' as const,
   NOVEMBER: 'NOVEMBER' as const,
   OCTOBER: 'OCTOBER' as const,
   SEPTEMBER: 'SEPTEMBER' as const
}

export const enumMoveInFunnelStatus = {
   APP_DOWNLOADED: 'APP_DOWNLOADED' as const,
   CHECKLIST_COMPLETED: 'CHECKLIST_COMPLETED' as const,
   CHECKLIST_IN_PROGRESS: 'CHECKLIST_IN_PROGRESS' as const,
   DONE: 'DONE' as const,
   NOT_STARTED: 'NOT_STARTED' as const
}

export const enumMoveOutReason = {
   EVICTION: 'EVICTION' as const,
   NOTICE: 'NOTICE' as const
}

export const enumNotificationMedium = {
   EMAIL: 'EMAIL' as const,
   MOBILE_APP: 'MOBILE_APP' as const,
   SLACK: 'SLACK' as const,
   SMS: 'SMS' as const,
   VENN_INBOX: 'VENN_INBOX' as const
}

export const enumNotificationRecipientStatus = {
   CLICK: 'CLICK' as const,
   DELIVERED: 'DELIVERED' as const,
   FAILED: 'FAILED' as const,
   OPEN: 'OPEN' as const,
   SPAM: 'SPAM' as const,
   UNDETERMINED: 'UNDETERMINED' as const
}

export const enumNotificationStatus = {
   DENIED: 'DENIED' as const,
   FAILURE: 'FAILURE' as const,
   NEW: 'NEW' as const,
   PROCESSING: 'PROCESSING' as const,
   RETRY: 'RETRY' as const,
   SUCCESS: 'SUCCESS' as const
}

export const enumNotificationType = {
   BILLINGS_AND_PAYMENTS: 'BILLINGS_AND_PAYMENTS' as const,
   BILLING_NOTICE: 'BILLING_NOTICE' as const,
   BROADCAST: 'BROADCAST' as const,
   BROADCAST_TEST: 'BROADCAST_TEST' as const,
   COMMUNITY_CHAT: 'COMMUNITY_CHAT' as const,
   EVENT_CHECK_IN_NOTIFY: 'EVENT_CHECK_IN_NOTIFY' as const,
   EVENT_NOTIFY: 'EVENT_NOTIFY' as const,
   EVENT_SURVEY: 'EVENT_SURVEY' as const,
   MAINTENANCE: 'MAINTENANCE' as const,
   MEMBERS_HIGHLIGHT: 'MEMBERS_HIGHLIGHT' as const,
   NEW_DISCUSSION_COMMENT: 'NEW_DISCUSSION_COMMENT' as const,
   NEW_FINDS: 'NEW_FINDS' as const,
   NEW_MOVE_INS: 'NEW_MOVE_INS' as const,
   NEW_SOCIAL_REACTION: 'NEW_SOCIAL_REACTION' as const,
   NPS: 'NPS' as const,
   SPECIAL_OFFERS: 'SPECIAL_OFFERS' as const,
   VENN_HAPPENINGS: 'VENN_HAPPENINGS' as const,
   VENN_NETWORK: 'VENN_NETWORK' as const,
   ZD_TICKET_UPDATE: 'ZD_TICKET_UPDATE' as const
}

export const enumNotificationsOrderBy = {
   CREATED_AT_ASC: 'CREATED_AT_ASC' as const,
   CREATED_AT_DESC: 'CREATED_AT_DESC' as const
}

export const enumOrganizationMemberType = {
   MEMBER: 'MEMBER' as const,
   OWNER: 'OWNER' as const
}

export const enumPaymentMethod = {
   ACH: 'ACH' as const,
   CASH: 'CASH' as const,
   CHARGE_ON_UNIT: 'CHARGE_ON_UNIT' as const,
   CHECK: 'CHECK' as const,
   CREDIT_CARD: 'CREDIT_CARD' as const
}

export const enumPermission = {
   BROADCASTER: 'BROADCASTER' as const,
   MANAGER: 'MANAGER' as const
}

export const enumPetSex = {
   FEMALE: 'FEMALE' as const,
   MALE: 'MALE' as const
}

export const enumPetType = {
   CAT: 'CAT' as const,
   DOG: 'DOG' as const
}

export const enumPlaidLinkType = {
   ASSET: 'ASSET' as const,
   IDV: 'IDV' as const,
   INCOME_BANK: 'INCOME_BANK' as const,
   INCOME_DOCUMENT: 'INCOME_DOCUMENT' as const,
   INCOME_EXISTING_ITEM: 'INCOME_EXISTING_ITEM' as const,
   INCOME_PAYROLL: 'INCOME_PAYROLL' as const
}

export const enumPmsSyncJobStatus = {
   COMPLETED: 'COMPLETED' as const,
   COMPLETED_WITH_ERRORS: 'COMPLETED_WITH_ERRORS' as const,
   FAILED: 'FAILED' as const,
   QUEUED: 'QUEUED' as const,
   RUNNING: 'RUNNING' as const
}

export const enumRealEstateAmenityStatus = {
   IN_MAINTENANCE: 'IN_MAINTENANCE' as const,
   ISSUE_REPORTED: 'ISSUE_REPORTED' as const,
   WORKING: 'WORKING' as const
}

export const enumRecurrenceEndType = {
   NEVER: 'NEVER' as const,
   NUMBER_OF_OCCURRENCES: 'NUMBER_OF_OCCURRENCES' as const,
   SPECIFIC_DATE: 'SPECIFIC_DATE' as const
}

export const enumRefundMethod = {
   CASH: 'CASH' as const,
   CHARGE_ON_UNIT: 'CHARGE_ON_UNIT' as const,
   CHECK: 'CHECK' as const,
   ELECTRONIC: 'ELECTRONIC' as const
}

export const enumRenewalSurveyAssignmentResponse = {
   INTERESTED_IN_TRANSFER: 'INTERESTED_IN_TRANSFER' as const,
   NO: 'NO' as const,
   STILL_DECIDING: 'STILL_DECIDING' as const,
   YES: 'YES' as const
}

export const enumRenewalSurveyAssignmentStatus = {
   ACTIVE: 'ACTIVE' as const,
   CANCELLED: 'CANCELLED' as const,
   COMPLETED: 'COMPLETED' as const,
   SNOOZED: 'SNOOZED' as const
}

export const enumRentingType = {
   BY_BEDROOM: 'BY_BEDROOM' as const,
   WHOLE_UNIT: 'WHOLE_UNIT' as const
}

export const enumReservationQuotaFrequency = {
   DAILY: 'DAILY' as const,
   MONTHLY: 'MONTHLY' as const,
   NONE: 'NONE' as const,
   WEEKLY: 'WEEKLY' as const
}

export const enumReservationQuotaType = {
   NONE: 'NONE' as const,
   UNIT: 'UNIT' as const,
   USER: 'USER' as const
}

export const enumResidentRiskSortField = {
   LEASE_END_DATE_ASC: 'LEASE_END_DATE_ASC' as const,
   LEASE_END_DATE_DESC: 'LEASE_END_DATE_DESC' as const,
   LEASE_RENT_ASC: 'LEASE_RENT_ASC' as const,
   LEASE_RENT_DESC: 'LEASE_RENT_DESC' as const,
   RISK_CATEGORY_ASC: 'RISK_CATEGORY_ASC' as const,
   RISK_CATEGORY_DESC: 'RISK_CATEGORY_DESC' as const,
   TIME_TO_RESOLVE_ASC: 'TIME_TO_RESOLVE_ASC' as const,
   TIME_TO_RESOLVE_DESC: 'TIME_TO_RESOLVE_DESC' as const
}

export const enumRiskCategory = {
   HIGH: 'HIGH' as const,
   LOW: 'LOW' as const,
   MEDIUM: 'MEDIUM' as const
}

export const enumRuleType = {
   DATE_REACHED: 'DATE_REACHED' as const,
   FIELD_CHANGED: 'FIELD_CHANGED' as const,
   TIME_REACHED: 'TIME_REACHED' as const
}

export const enumSatisfactionScore = {
   BAD: 'BAD' as const,
   GOOD: 'GOOD' as const
}

export const enumScreeningCheckStatus = {
   EMPTY: 'EMPTY' as const,
   ERRORED: 'ERRORED' as const,
   FINISHED: 'FINISHED' as const,
   IN_PROGRESS: 'IN_PROGRESS' as const,
   PENDING: 'PENDING' as const,
   SKIPPED: 'SKIPPED' as const
}

export const enumScreeningStatus = {
   COMPLETED: 'COMPLETED' as const,
   IN_PROGRESS: 'IN_PROGRESS' as const,
   PENDING: 'PENDING' as const
}

export const enumSectionType = {
   FEEDBACK_ITEM: 'FEEDBACK_ITEM' as const,
   MAIN_UTILITIES: 'MAIN_UTILITIES' as const,
   USEFUL_LINKS: 'USEFUL_LINKS' as const
}

export const enumServiceProductType = {
   ON_DEMAND: 'ON_DEMAND' as const,
   RECURRING: 'RECURRING' as const
}

export const enumServiceRequestTicketStatus = {
   CANCELLED: 'CANCELLED' as const,
   CLOSED: 'CLOSED' as const,
   COMPLETED: 'COMPLETED' as const,
   ESCALATED: 'ESCALATED' as const,
   INAPPLICABLE: 'INAPPLICABLE' as const,
   IN_PROGRESS: 'IN_PROGRESS' as const,
   ON_HOLD: 'ON_HOLD' as const,
   OPEN: 'OPEN' as const,
   PENDING: 'PENDING' as const,
   SEEN: 'SEEN' as const,
   SOLVED: 'SOLVED' as const
}

export const enumServiceRequestedTarget = {
   BUILDING: 'BUILDING' as const,
   LEASE: 'LEASE' as const,
   UNIT: 'UNIT' as const
}

export const enumShiftNoteOrderBy = {
   CREATED_AT_ASC: 'CREATED_AT_ASC' as const,
   CREATED_AT_DESC: 'CREATED_AT_DESC' as const
}

export const enumShiftNoteStatus = {
   CLOSED: 'CLOSED' as const,
   OPEN: 'OPEN' as const
}

export const enumSocialInteractionType = {
   GOOD_VIBES: 'GOOD_VIBES' as const
}

export const enumSocialProfileBlockStatus = {
   BLOCKED_BY_CURRENT_USER: 'BLOCKED_BY_CURRENT_USER' as const,
   BLOCKED_BY_PROFILE_OWNER: 'BLOCKED_BY_PROFILE_OWNER' as const,
   MUTUAL_BLOCK: 'MUTUAL_BLOCK' as const,
   NOT_BLOCKED: 'NOT_BLOCKED' as const
}

export const enumSocialProfileCallToActionType = {
   SMS: 'SMS' as const
}

export const enumSocialProfilePrivacy = {
   PRIVATE: 'PRIVATE' as const,
   PUBLIC: 'PUBLIC' as const
}

export const enumSocialProfilePronouns = {
   HE_HIM_HIS: 'HE_HIM_HIS' as const,
   OTHER: 'OTHER' as const,
   SHE_HER_HERS: 'SHE_HER_HERS' as const,
   THEY_THEM_THEIR: 'THEY_THEM_THEIR' as const
}

export const enumSummaryStatus = {
   ERRORED: 'ERRORED' as const,
   FAILED: 'FAILED' as const,
   IN_PROGRESS: 'IN_PROGRESS' as const,
   PASSED: 'PASSED' as const,
   PENDING: 'PENDING' as const,
   SKIPPED: 'SKIPPED' as const
}

export const enumSupportDay = {
   FRIDAY: 'FRIDAY' as const,
   MONDAY: 'MONDAY' as const,
   SUNDAY: 'SUNDAY' as const,
   THURSDAY: 'THURSDAY' as const,
   TUESDAY: 'TUESDAY' as const,
   WEDNESDAY: 'WEDNESDAY' as const
}

export const enumSupportTicketSource = {
   APP: 'APP' as const,
   HUB: 'HUB' as const,
   THIRD_PARTY: 'THIRD_PARTY' as const
}

export const enumSupportTicketStatus = {
   CANCELLED: 'CANCELLED' as const,
   CLOSED: 'CLOSED' as const,
   COMPLETED: 'COMPLETED' as const,
   ESCALATED: 'ESCALATED' as const,
   INAPPLICABLE: 'INAPPLICABLE' as const,
   IN_PROGRESS: 'IN_PROGRESS' as const,
   ON_HOLD: 'ON_HOLD' as const,
   OPEN: 'OPEN' as const,
   PENDING: 'PENDING' as const,
   SEEN: 'SEEN' as const,
   SOLVED: 'SOLVED' as const
}

export const enumSupportTime = {
   AFTERNOON: 'AFTERNOON' as const,
   MORNING: 'MORNING' as const
}

export const enumSurveyAssignmentRelatedEntityType = {
   LEASE_CONTRACT: 'LEASE_CONTRACT' as const,
   SUPPORT_TICKET: 'SUPPORT_TICKET' as const
}

export const enumSurveyAssignmentStatus = {
   DONE: 'DONE' as const,
   PENDING: 'PENDING' as const,
   VIEWED: 'VIEWED' as const
}

export const enumSurveyQuestionType = {
   CHOICE: 'CHOICE' as const,
   FREE_TEXT: 'FREE_TEXT' as const,
   SCALE: 'SCALE' as const
}

export const enumSurveyType = {
   CSAT_QUARTERLY: 'CSAT_QUARTERLY' as const,
   CSAT_SERVICE_REQUEST: 'CSAT_SERVICE_REQUEST' as const
}

export const enumSyncJobExportStatus = {
   FAILED: 'FAILED' as const,
   NOT_READY: 'NOT_READY' as const,
   PROCESSING: 'PROCESSING' as const,
   READY: 'READY' as const
}

export const enumSyncJobItemStatus = {
   COMPLETED: 'COMPLETED' as const,
   DISABLED: 'DISABLED' as const,
   FAILED: 'FAILED' as const,
   QUEUED: 'QUEUED' as const
}

export const enumSyncJobStatus = {
   COMPLETED: 'COMPLETED' as const,
   COMPLETED_WITH_ERRORS: 'COMPLETED_WITH_ERRORS' as const,
   FAILED: 'FAILED' as const,
   QUEUED: 'QUEUED' as const,
   RUNNING: 'RUNNING' as const
}

export const enumSyncJobType = {
   SyncJobTypePMS: 'SyncJobTypePMS' as const,
   SyncJobTypeServiceRequest: 'SyncJobTypeServiceRequest' as const
}

export const enumTaskListType = {
   INSPECTION: 'INSPECTION' as const,
   MOVE_IN: 'MOVE_IN' as const
}

export const enumTaskTemplate = {
   EMBEDDED_APP: 'EMBEDDED_APP' as const,
   FREE_HTML: 'FREE_HTML' as const
}

export const enumTaskType = {
   INSPECTION: 'INSPECTION' as const,
   MOVE_IN: 'MOVE_IN' as const
}

export const enumTermsAndConditionsStatus = {
   ACCEPTED: 'ACCEPTED' as const,
   NOT_ACCEPTED: 'NOT_ACCEPTED' as const
}

export const enumTicketAttachmentType = {
   DOCUMENT: 'DOCUMENT' as const,
   IMAGE: 'IMAGE' as const,
   VIDEO: 'VIDEO' as const
}

export const enumTimeline = {
   CURRENT: 'CURRENT' as const,
   PAST: 'PAST' as const,
   UPCOMING: 'UPCOMING' as const
}

export const enumUnitStatus = {
   DISABLED: 'DISABLED' as const,
   ENABLED: 'ENABLED' as const
}

export const enumUnitType = {
   DUPLEX: 'DUPLEX' as const,
   STUDIO: 'STUDIO' as const
}

export const enumUserRole = {
   OWNER: 'OWNER' as const
}

export const enumUserTaskListStatus = {
   COMPLETED: 'COMPLETED' as const,
   IN_PROGRESS: 'IN_PROGRESS' as const,
   NOT_STARTED: 'NOT_STARTED' as const,
   PENDING_REVIEW: 'PENDING_REVIEW' as const,
   PICKUP_KEY: 'PICKUP_KEY' as const
}

export const enumUserTaskStatus = {
   APPROVAL_DENIED: 'APPROVAL_DENIED' as const,
   COMPLETED: 'COMPLETED' as const,
   NOT_COMPLETED: 'NOT_COMPLETED' as const,
   NOT_STARTED: 'NOT_STARTED' as const,
   PENDING_APPROVAL: 'PENDING_APPROVAL' as const
}

export const enumUserType = {
   LEGACY_TENANT: 'LEGACY_TENANT' as const,
   MEMBER: 'MEMBER' as const
}

export const enumValidationConstraint = {
   email: 'email' as const,
   max: 'max' as const,
   maxLength: 'maxLength' as const,
   min: 'min' as const,
   minLength: 'minLength' as const,
   phoneNumber: 'phoneNumber' as const,
   required: 'required' as const,
   ssn: 'ssn' as const,
   url: 'url' as const
}

export const enumVisitTime = {
   CURRENT: 'CURRENT' as const,
   PAST: 'PAST' as const,
   UPCOMING: 'UPCOMING' as const
}

export const enumVisitorOrderBy = {
   END_DATE_ASC: 'END_DATE_ASC' as const,
   END_DATE_DESC: 'END_DATE_DESC' as const,
   START_DATE_ASC: 'START_DATE_ASC' as const,
   START_DATE_DESC: 'START_DATE_DESC' as const,
   VISITOR_NAME_ASC: 'VISITOR_NAME_ASC' as const,
   VISITOR_NAME_DESC: 'VISITOR_NAME_DESC' as const
}

export const enumVisitorStatus = {
   CANCELED: 'CANCELED' as const,
   CHECKED_IN: 'CHECKED_IN' as const,
   CHECKED_OUT: 'CHECKED_OUT' as const
}

export const enumAuthChallengeType = {
   EMAIL: 'EMAIL' as const,
   SMS: 'SMS' as const
}
